import React, { useEffect, useState } from "react";
import {
  Col,
  Nav,
  Row,
  Card,
  Table,
  Button,
  Container,
  Badge,
} from "react-bootstrap";
import { format } from "date-fns";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { MaintenanceInspectionForm } from "../../Components/MaintenanceRecords/MaintenanceInspectionForm";

const MaintenanceInspection = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { maintenanceRecordId } = useParams();

  const [fetching, setFetching] = useState(true);
  const [inspectors, setInspectors] = useState({});
  const [statusOptions, setStatusOptions] = useState([]);
  const [maintenanceRecord, setMaintenanceRecord] = useState({});

  useEffect(() => {
    setFetching(true);
    const getMaintenanceRecordInspection = async () => {
      try {
        const response = await get(`maintenance/${maintenanceRecordId}`);
        setStatusOptions(response.data.statuses);
        setInspectors(response.data.logistic_officers);
        setMaintenanceRecord(response.data.maintenance_record);
        
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getMaintenanceRecordInspection();
  }, []);

  const displayedStatuses = [];

  statusOptions.map((status) => {
    if (
      status === "approved" ||
      status === "approved with adjustment" ||
      status === "rejected"
    ) {
      displayedStatuses.push(status);
    }
    return displayedStatuses;
  });

  return (
    <div className="page-wrapper">
      {((!!maintenanceRecord?.inspected_by_employee_code &&
        maintenanceRecord?.status === "completed") ||
        (!maintenanceRecord?.inspected_by_employee_code &&
          maintenanceRecord?.status === "awaiting approval")) && (
        <Navigate to={"/maintenance-records"} replace={true} />
      )}
      <Container fluid>
        {!!fetching && <Loading />}

        {!!maintenanceRecord?.id && !fetching && (
          <>
            <div className="page-header d-print-none">
              <Row className="align-items-center">
                <Col>
                  <div className="page-pretitle">MAINTENANCE INSPECTION</div>
                </Col>
              </Row>
            </div>

            <div className="page-body">
              <Nav className="justify-content-end">
                <Nav.Item>
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </Nav.Item>
              </Nav>

              <br />

              <>
                <Card className="mb-4">
                  <Card.Header className="d-flex justify-content-between mt-2 mb-2">
                    <Card.Title>RECORD SUMARRY </Card.Title>
                  </Card.Header>

                  <Card.Body className="mb-4">
                    <Row className="mb-4 g-0">
                      <Col md={6}>
                        <Table
                          responsive
                          hover
                          bordered
                          className="table table-sm table-bordered m-0"
                        >
                          <tbody>
                            <tr>
                              <th width={45}>VOUCHER NO.</th>
                              <td>
                                {maintenanceRecord?.request_voucher_number ||
                                  "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <th>VEHICLE</th>
                              <td>
                                {`${maintenanceRecord?.vehicle?.vehicle_model?.vehicle_make?.name} - ${maintenanceRecord?.vehicle?.vehicle_model?.name}` ||
                                  "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <th>REPORTED DATE</th>
                              <td>
                                {format(
                                  new Date(maintenanceRecord?.reported_date),
                                  "dd-MM-yyyy",
                                  {
                                    awareOfUnicodeTokens: true,
                                  }
                                ) || "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <th>REQUESTED BY</th>
                              <td>
                                {maintenanceRecord?.requested_by?.id 
                                  ? `${maintenanceRecord?.requester?.first_name} ${maintenanceRecord?.requester?.surname}` 
                                  : "N/A"
                                }
                              </td>
                            </tr>
                            <tr>
                              <th>CONFIRMED BY</th>
                              <td>
                                <ul>
                                  <li>
                                    {`${maintenanceRecord?.confirmer1?.first_name} ${maintenanceRecord?.confirmer1?.surname}` ??
                                      ""}
                                  </li>
                                  <li>
                                    {`${maintenanceRecord?.confirmer2?.first_name} ${maintenanceRecord?.confirmer1?.surname}` ||
                                      "N/A"}
                                  </li>
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <th>VENDOR</th>
                              <td>
                                {maintenanceRecord?.vendor ? (
                                  <>
                                    {maintenanceRecord?.vendor.name}
                                    <br />
                                    {maintenanceRecord?.vendor.primary_phone}
                                  </>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>ACCOUNT DETAILS</th>
                              <td>
                                {!!maintenanceRecord?.vendor?.bank ? (
                                  <>
                                    <span>
                                      {`${maintenanceRecord?.vendor?.bank?.name}`}
                                    </span>
                                    <br />
                                    {`${maintenanceRecord?.vendor?.account_number}`}
                                  </>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>

                      <Col md={6}>
                        <Table
                          responsive
                          hover
                          bordered
                          className="table table-sm table-bordered m-0"
                        >
                          <tbody>
                            <tr>
                              <th width={45}>INVOICE DOCUMENT</th>
                              <td>
                                {!!maintenanceRecord?.invoice_document
                                  ? `${maintenanceRecord?.invoice_document}`
                                  : "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <th>INVOICE DATE</th>
                              <td>
                                {format(
                                  new Date(maintenanceRecord?.invoice_date),
                                  "dd-MM-yyyy",
                                  {
                                    awareOfUnicodeTokens: true,
                                  }
                                ) || "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <th>INVOICE NUMBER</th>
                              <td>
                                {!!maintenanceRecord?.invoice_number
                                  ? `${maintenanceRecord?.invoice_number}`
                                  : "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <th>WARRANTY EXPIRY DATE</th>
                              <td>
                                {!!maintenanceRecord?.warranty_expiry_date
                                  ? `${maintenanceRecord?.warranty_expiry_date}`
                                  : "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <th>STATUS</th>
                              <td>
                                <Badge bg="secondary" className="fs-5 mt-2">
                                  {maintenanceRecord?.status || "N/A"}
                                </Badge>
                              </td>
                            </tr>
                            <tr>
                              <th>INSPECTION</th>
                              <td>
                                {!!maintenanceRecord?.inspected_date
                                  ? `${maintenanceRecord?.inspected_date}`
                                  : "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <th>LOCATION</th>
                              <td>
                                {!!maintenanceRecord?.vendor?.bank ? (
                                  <>
                                    <span>
                                      {`${maintenanceRecord?.vendor?.bank?.name}`}
                                    </span>
                                    <br />
                                    {`${maintenanceRecord?.vendor?.account_number}`}
                                  </>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>

                    <Table
                      responsive
                      hover
                      bordered
                      className=" table-vcenter card-table mt-2 mb-4 "
                    >
                      <thead>
                        <tr>
                          <th>TYPE</th>
                          <th>PART</th>
                          <th>QUANTITY</th>
                          <th>RATE (&#8358;)</th>
                          <th>AMOUNT (&#8358;)</th>
                        </tr>
                      </thead>

                      <tbody>
                        {maintenanceRecord.maintenance_items.map(
                          (maintenanceItem) => (
                            <tr key={maintenanceItem.id}>
                              <td
                                data-label="TYPE"
                                className="text-capitalize fw-bolder"
                              >
                                {maintenanceItem?.type}
                              </td>

                              <td data-label="PART">
                                {maintenanceItem?.vehicle_part?.name || "N/A"}
                              </td>
                              <td data-label="QUANTITY">
                                {maintenanceItem?.quantity}
                              </td>

                              <td data-label="RATE (&#8358;)">
                                {maintenanceItem?.rate}
                              </td>
                              <td data-label="AMOUNT (&#8358;)">
                                {maintenanceItem.amount}
                              </td>
                            </tr>
                          )
                        )}
                        <tr>
                          <td
                            data-label="TYPE"
                            className="text-capitalize fw-bolder"
                          >
                            Requested Amount
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            {Number(
                              maintenanceRecord?.request_amount
                            ).toLocaleString() || "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>

                <Card className="mb-5">
                  <Card.Body>
                    <MaintenanceInspectionForm
                      inspectors={inspectors}
                      maintenanceRecord={maintenanceRecord}
                    />
                  </Card.Body>
                </Card>
              </>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default MaintenanceInspection;
