import React from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ClientCreationForm } from "../../Components/Clients/ClientCreationForm";

const CreateClient = () => {
  const navigate = useNavigate();
  return (
    <>
      <Card className="container">
        <Card.Header className="d-flex justify-content-between mt-0 mb-5">
          <Card.Title>
            <div className="row">
              <div className="col-md-12">
                <h1 className="page-title">Create Client</h1>
              </div>
            </div>
          </Card.Title>

          <div className="row">
            <div className="col-md-12">
              <Button onClick={() => navigate(-1)}>Back</Button>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <ClientCreationForm />
        </Card.Body>
      </Card>
    </>
  );
};

export default CreateClient;
