import React from "react";
import useAxios from "../../hooks/useAxios";
import { Link, useNavigate} from "react-router-dom";
import { permissions } from "../../utils/permissions";
import maleAvatar from "../../images/male_avatar.png";
import femaleAvatar from "../../images/female_avatar.jpg";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import { handleCustomEvent } from "../../utils/customEventLister";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";

export const DisplayedStaffsTable = ({ staffs, getStaffs }) => {
  const { get } = useAxios();
  const navigate = useNavigate();
  const { hasPermission, isSuperAdmin } = usePermissionAndDomain();

  const onSyncDataClick = (staff) => {
    handleCustomEvent("show-confirmation-modal", {
      action: async () => {
        try {
          await get(`hrimax-sync/employees/${staff?.hrimax_user_id}`);

          await get(`hrimax-sync/employees/${staff?.hrimax_user_id}`);

          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          alertSuccess(
            `${staff?.first_name} ${staff?.middle_name} ${staff?.surname} successfully synced!`,
            null,
            5000
          );
          getStaffs();

          navigate(`/staffs/view/${staff.id}`);

        } catch (error) {
          alertWarning("Your request failed!", null, 500);
        }
      },
      question: `Are you sure you want to sync ${staff?.first_name} ${staff?.middle_name} ${staff?.surname}?`,
    });
  };

  return (
    <>
      <Table
        hover
        bordered
        responsive
        className=" table-vcenter table-mobile-md card-table"
      >
        <thead>
          <tr>
            <th>HRIMAX ID</th>
            <th>NAME</th>
            <th>DESIGNATION</th>
            <th>ROLE</th>
            <th>BRANCH</th>
            <th>CLIENT</th>
            <th>CONTROLS</th>
          </tr>
        </thead>

        <tbody>
          {staffs.map((staff) => (
            <tr key={staff.id}>
              <td data-label="HRIMAX ID">{staff?.hrimax_user_id || "N/A"}</td>
              <td data-label="NAME">
                <div className="d-flex py-1 align-items-center">
                  {staff?.gender === "female" ? (
                    <span>
                      <img
                        src={femaleAvatar}
                        width="110"
                        height="32"
                        alt="Female avatar"
                        className="avatar me-2"
                      />
                    </span>
                  ) : (
                    <span>
                      <img
                        src={maleAvatar}
                        width="110"
                        height="32"
                        alt="Male avatar"
                        className="avatar me-2"
                      />
                    </span>
                  )}
                  <div className="flex-fill">
                    <div className="text-uppercase font-weight-medium">
                      <Link to={`/staffs/view/${staff.id}`}>
                        {`${staff?.first_name} ${staff?.middle_name} ${staff?.surname}` || "N/A"}
                      </Link>
                    </div>
                    <div className="text-muted text-uppercase">
                      {`${staff?.actual_employee_code}` || "N/A"}
                    </div>
                  </div>
                </div>
              </td>
              <td data-label="DESIGNATION" className="text-uppercase">
                {staff?.designation?.name || "N/A"}
              </td>
              {staff?.roles?.length ? (
                <td data-label="ROLE">{`${staff?.roles[0]?.name}`}</td>
              ) : (
                <td data-label="ROLE">{"N/A"}</td>
              )}

              <td data-label="BRANCH">{staff?.office_location?.name || "N/A"}</td>
              <td data-label="CLIENT">{staff?.client_location?.name || "N/A"}</td>
              <td className="d-flex justify-content-center ">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  {hasPermission(permissions.VIEW_ANY_RECRUIT) && (
                    <Dropdown.Item
                      as={Link}
                      to={`/staffs/view/${staff.id}`}
                      size="sm"
                      className="justify-content-center"
                    >
                      View
                    </Dropdown.Item>
                  )}

                  {hasPermission(permissions.EDIT_RECRUIT) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/staffs/edit/${staff.id}`}
                    >
                      Edit
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item
                    as={Link}
                    size="sm"
                    to="#"
                    className="justify-content-center"
                    onClick={() => onSyncDataClick(staff)}
                  >
                    Sync Data
                  </Dropdown.Item>

                  {isSuperAdmin() && (
                    <>
                      <Dropdown.Item
                        as={Link}
                        size="sm"
                        className="justify-content-center"
                        to={`/staffs/set_role/${staff.id}`}
                      >
                        Set role
                      </Dropdown.Item>

                      <Dropdown.Item
                        as={Link}
                        size="sm"
                        className="justify-content-center"
                        to={`/staffs/password_update/${staff.id}`}
                      >
                        Change password
                      </Dropdown.Item>
                    </>
                  )}

                  {hasPermission(permissions.UPLOAD_EMPLOYEE_SIGNATURE) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/staffs/signature_upload/${staff.id}`}
                    >
                      Upload signature
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <br />
    </>
  );
};
