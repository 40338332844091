import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { FaExclamationCircle } from "react-icons/fa";
import { permissions } from "../../utils/permissions";
import { Card, Col, Nav, Row } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import ReactPagination from "../../Components/ReactPagination";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { MaintenanceFilterSearch } from "../../Components/MaintenanceRecords/MaintenanceFilterSearch";
import { MaintenanceRecordDataTable } from "../../Components/MaintenanceRecords/MaintenanceRecordDataTable";

const VehicleMaintenanceRecordIndex = () => {
  const { get } = useAxios();
  const [vendors, setVendors] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [vehicleParts, setVehicleParts] = useState({});
  const [maintenanceRecords, setMaintenanceRecords] = useState([]);
  const [searchPageParams, setSearchPageParams] = useSearchParams();
  const [maintenanceStatuses, setMaintenanceStatuses] = useState([]);
  const [maintenanceRecordsData, setMaintenanceRecordsData] = useState({});
  const [isFilterSearchClicked, setIsFilterSearchClicked] = useState(true);

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;
  let statusParams = searchPageParams.get("status") || "";
  let toDateParams = searchPageParams.get("toDate") || "";
  let vendorParams = searchPageParams.get("vendor_id") || "";
  let fromDateParams = searchPageParams.get("fromDate") || "";
  let vehicleParams = searchPageParams.get("vehicle_id") || "";
  let vehiclePartParams = searchPageParams.get("vehicle_part_id") || "";
  let voucherNumberParams = searchPageParams.get("voucher_number") || "";

  const getMaintenanceRecord = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;
      const response = await get(
        `maintenance?sort=desc&page=${currentPage}&page_size=${pageSize}&vendor_id=${vendorParams}&vehicle_part_id=${vehiclePartParams}&vehicle_id=${vehicleParams}&fromDate=${fromDateParams}&toDate=${toDateParams}&status=${statusParams}&voucher_number=${voucherNumberParams}`
      );

      setVendors(response.data.vendors);
      setVehicles(response.data.vehicle);
      setVehicleParts(response.data.vehicle_part);
      setMaintenanceStatuses(response.data.statuses);
      setMaintenanceRecordsData(response.data.maintenanceRecords);
      setMaintenanceRecords(response.data.maintenanceRecords.data);
    } catch (error) {
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  const pageChangeHandler = (page) => {
    setSearchPageParams(
      {
        ...Object.fromEntries(searchPageParams),
        page,
      },
      { replace: true }
    );
  };

  useEffect(() => {
    setFetching(true);
    const initialiaseMaintenanceRecord = async () =>
      await getMaintenanceRecord();
    initialiaseMaintenanceRecord();
  }, [
    currentPage,
    statusParams,
    toDateParams,
    vendorParams,
    vehicleParams,
    fromDateParams,
    vehiclePartParams,
    voucherNumberParams,
  ]);

  const handleFilterSelectSearchChange = (values) => {
    setSearchPageParams(
      {
        ...values,
        page: 1,
      },
      { replace: true }
    );
  };

  return (
    <>
      {!!fetching ? (
        <Loading />
      ) : (
        <Card className="container d-flex justify-content-center ms-3">
          <Card.Header className="d-flex justify-content-between mt-2 mb-2">
            <Card.Title>
              <Row>
                <Col md="auto">
                  <h2 className="page-title">ALL MAINTENANCE RECORDS</h2>
                </Col>
              </Row>
            </Card.Title>

            {hasPermission(permissions.CREATE_MAINTENANCE_RECORDS) && (
              <Row>
                <Col md="auto">
                  <Link
                    to="/maintenance-records/create"
                    className="btn btn-primary"
                  >
                    Add Record
                  </Link>
                </Col>
              </Row>
            )}
          </Card.Header>

          <Card.Body className="mt-4">
            <Row>
              <Nav className="fw-bolder fs-4 mb-3">
                <Nav.Item>
                  <Nav.Link
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setIsFilterSearchClicked(!isFilterSearchClicked);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="6" cy="10" r="2" />
                      <line x1="6" y1="4" x2="6" y2="8" />
                      <line x1="6" y1="12" x2="6" y2="20" />
                      <circle cx="12" cy="16" r="2" />
                      <line x1="12" y1="4" x2="12" y2="14" />
                      <line x1="12" y1="18" x2="12" y2="20" />
                      <circle cx="18" cy="7" r="2" />
                      <line x1="18" y1="4" x2="18" y2="5" />
                      <line x1="18" y1="9" x2="18" y2="20" />
                    </svg>
                    Filter
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Row>

            <Row className="mb-2">
              <Col>
                {isFilterSearchClicked && (
                  <MaintenanceFilterSearch
                    onhandleFilteredSearchChange={(value) =>
                      handleFilterSelectSearchChange(value)
                    }
                    initialSearchFilterInputs={{
                      vendor_id: vendorParams,
                      vehicle_id: vehicleParams,
                      fromDate: fromDateParams,
                      toDate: toDateParams,
                      status: statusParams,
                      vehicle_part_id: vehiclePartParams,
                      voucher_number: voucherNumberParams,
                    }}
                    vendors={vendors}
                    vehicles={vehicles}
                    vehicleParts={vehicleParts}
                    maintenanceRecords={maintenanceRecords}
                    maintenanceStatuses={maintenanceStatuses}
                  />
                )}
              </Col>
            </Row>

            {!!(
              vendorParams ||
              vehicleParams ||
              fromDateParams ||
              toDateParams ||
              statusParams ||
              vehiclePartParams ||
              voucherNumberParams
            ) &&
              !maintenanceRecords?.length && (
                <div className="mt-4 p-2 border text-muted text-center">
                  <span>
                    <FaExclamationCircle size={19} />
                  </span>

                  <span className="fs-2 ms-2 ">
                    No Vehicle Maintenance Record Found!
                  </span>
                </div>
            )}

            {((!!(
              vendorParams ||
              vehicleParams ||
              fromDateParams ||
              toDateParams ||
              statusParams ||
              vehiclePartParams ||
              voucherNumberParams
            ) &&
              !!maintenanceRecords?.length) ||
              !!maintenanceRecords?.length) && (
              <>
                <p className="text-end text-muted">
                  Page {maintenanceRecordsData.current_page} of{" "}
                  {maintenanceRecordsData.last_page}
                </p>
                <Row>
                  <Col className="p-0">
                    <>
                      <MaintenanceRecordDataTable
                        maintenanceRecords={maintenanceRecords}
                      />

                      <br />

                      <ReactPagination
                        links={maintenanceRecordsData.links}
                        onPageChange={pageChangeHandler}
                        current_page={maintenanceRecordsData.current_page}
                        last_page={maintenanceRecordsData.last_page}
                      />
                    </>
                  </Col>
                </Row>
              </>
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default VehicleMaintenanceRecordIndex;
