import React from "react";
import { AsideComponent } from "./AsideComponenet";
import AlertToast from "../Components/CustomAlerts/AlertToast";
import ConfirmationModal from "../Components/Modals/ConfirmationModal";

import { UserInfo } from "./UserInfo";
import { Theme } from "../Components/Theme";
import { NotificationIcon } from "./NotificationIcon";
import CopyRight from "../Components/CopyRight";

export const UserArea = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      <div className="wrapper">
        <AsideComponent />
        <div className="page-wrapper">
          <div className="container-xl d-print-none d-none d-lg-flex justify-content-end p-3  ">
            <Theme />

            {!!user?.admin_notifications?.length && (
              <NotificationIcon />
            )}
            
            <UserInfo />
          </div>

          <div className="page-body">{children}</div>

          <footer className="footer footer-transparent d-print-none">
            <div className="container-fluid">
              <div className="row text-center align-items-center flex-row-reverse">
                <div className="col-12 col-lg-auto mt-3 mt-lg-0">
                  <ul className="list-inline list-inline-dots mb-0">
                    <li className="list-inline-item">
                      <CopyRight />
                      <a href="#" className="link-secondary">
                        UNIFIED
                      </a>
                      . All rights reserved.
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="link-secondary" rel="noopener">
                        v1.6.0
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <AlertToast />
      <ConfirmationModal />
    </>
  );
};
