import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { VehicleForm } from "../../Components/Vehicles/VehicleForm";

const EditVehicle = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  const { vehicleId } = useParams();
  const [drivers, setDrivers] = useState({});
  const [statuses, setStatuses] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [usageTypes, setUsageTypes] = useState([]);
  const [vehicleModel, setVehicleModel] = useState([]);
  const [insuranceTypes, setInsuranceTypes] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [branchLocations, setBranchLocations] = useState({});
  const [insuranceCompanies, setInsuranceCompanies] = useState({});

  useEffect(() => {
    setFetching(true);
    const getEditDetails = async () => {
      try {
        const response = await get(`vehicles/${vehicleId}/edit`);
        setDrivers(response.data.drivers);
        setUsageTypes(response.data.usage);
        setStatuses(response.data.statuses);
        setBranchLocations(response.data.branch);
        setVehicleDetails(response.data.vehicle);
        setVehicleModel(response.data.vehicleModel);
        setInsuranceTypes(response.data.insuranceTypes);
        setInsuranceCompanies(response.data.insuranceCompanies);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getEditDetails();
  }, [vehicleId]);
  
  return (
    <>
      <Card className="container">
        {!!fetching && <Loading />}

        {!fetching && !!vehicleDetails?.id && (
          <>
            <Card.Header className="d-flex justify-content-between mt-0 mb-5">
              <Card.Title>
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="page-title">Edit Vehicle Information</h1>
                  </div>
                </div>
              </Card.Title>

              <div className="row">
                <div className="col-md-12">
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </div>
              </div>
            </Card.Header>

            <Card.Body>
              <VehicleForm
                drivers={drivers}
                statuses={statuses}
                usageTypes={usageTypes}
                vehicleModel={vehicleModel}
                editVehicle={vehicleDetails}
                insuranceTypes={insuranceTypes}
                branchLocations={branchLocations}
                insuranceCompanies={insuranceCompanies}
              />
            </Card.Body>
          </>
        )}
      </Card>
    </>
  );
};

export default EditVehicle;
