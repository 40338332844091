import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { PageCount } from "../DisplayTable/PageCount";
import { permissions } from "../../utils/permissions";
import { DataTable } from "../DisplayTable/DataTable";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";

export const LincenseExpirationTable = ({ licenseData }) => {
  const { hasPermission } = usePermissionAndDomain();

  const columns = [
    { accessorKey: "reg_number", header: "REG. NUMBER" },

    {
      id: "vehicle_make",
      header: "MAKE",
      cell: ({ row }) => {
        const licenseData = row.original;
        return (
          <>
            {!!licenseData?.vehicle_model?.vehicle_make?.id ?
              `${licenseData?.vehicle_model?.vehicle_make?.name}`
              : "N/A"}
          </>
        );
      },
    },

    {
      id: "vehicle_model",
      header: "MODEL",
      cell: ({ row }) => {
        const licenseData = row.original;
        return (
          <>
            {!!licenseData?.vehicle_model?.id ?
              `${licenseData?.vehicle_model?.name}`
              : "N/A"}
          </>
        );
      },
    },

    { accessorKey: "usage_type", header: "USAGE" },

    {
      id: "office_location",
      header: "LOCATION",
      cell: ({ row }) => {
        const licenseData = row.original;
        return (
          <>
            {!!licenseData?.office_location?.id ?
              `${licenseData?.office_location?.name}`
              : "N/A"}
          </>
        );
      },
    },

    { accessorKey: "license_expiry_date", header: "LICENSE EXPIRY DATE" },

    { accessorKey: "N/A", header: "DRIVERS" },

    {
      id: "actions",
      cell: ({ row }) => {
        const licenseExpiration = row.original;

        return (
          <DropdownButton id="dropdown-basic-button" title="ACTION" align="end">
            {hasPermission(permissions.VIEWS) && (
              <Dropdown.Item
                as={Link}
                to={`/vehicles/view/${licenseExpiration.id}`}
                size="sm"
                className="justify-content-start"
              >
                View
              </Dropdown.Item>
            )}

            {hasPermission(permissions.EDIT_DRIVER_DOCUMENTS) && (
              <Dropdown.Item
                as={Link}
                to={`/vehicles/${licenseExpiration.id}/edit`}
                size="sm"
                className="justify-content-start"
              >
                Edit
              </Dropdown.Item>
            )}
          </DropdownButton>
        );
      },
    },
  ];

  const data = licenseData;

  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters,
      globalFilter,
    },
  });

  return (
    <div className="rounded-md border">
      <div>
        <div className="flex items-center py-4 ms-4 me-4 ">
          <input
            placeholder="Filter input..."
            value={globalFilter ?? ""}
            onChange={(event) => {
              return setGlobalFilter(event.target.value);
            }}
            className="max-w-sm form-control"
          />
        </div>
      </div>

      <DataTable data={data} columns={columns} table={table} />

      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            table.previousPage();
          }}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            table.nextPage();
          }}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>

        <PageCount table={table} />
      </div>
    </div>
  );
};
