import React from "react";
import { RequireAuth } from "../auth";
import { Loading } from "../Components/Loading";
import { Route, Routes } from "react-router-dom";
import { Error404 } from "../Pages/Errors/Error404";
import VehicleMaintenanceRecordIndex from "../Pages/MaintenanceRecords/index";
import ViewVehicleMaintenanceRecord from "../Pages/MaintenanceRecords/view";
import MaintenanceRecordStatusUpdate from "../Pages/MaintenanceRecords/statusUpdate";
import MaintenanceInspection from "../Pages/MaintenanceRecords/inspection";
import CreateMaintenanceRecord from "../Pages/MaintenanceRecords/create";
import EditMaintenanceRecord from "../Pages/MaintenanceRecords/edit";



export const VehicleMaintenanceRecordRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            vehicleParts
            element={
              <React.Suspense fallback={<Loading />}>
                <VehicleMaintenanceRecordIndex />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="/create"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateMaintenanceRecord />
              </React.Suspense>
            }
          />

          <Route
            path="view/:maintenanceRecordId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewVehicleMaintenanceRecord />
              </React.Suspense>
            }
          />

          <Route
            path="edit/:maintenanceRecordId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditMaintenanceRecord />
              </React.Suspense>
            }
          />

          <Route
            path="approve_reject/:maintenanceRecordId"
            element={
              <React.Suspense fallback={<Loading />}>
                <MaintenanceRecordStatusUpdate />
              </React.Suspense>
            }
          />

          <Route
            path="inspection/:maintenanceRecordId"
            element={
              <React.Suspense fallback={<Loading />}>
                <MaintenanceInspection />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
};
