import React, { useState } from "react";
import * as yup from "yup";
import {
  Card,
  Form,
  Badge,
  Button,
  Container,
  InputGroup,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import useAxios from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "../LoadingButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object().shape({
  old_password: yup.string().min(5).max(15).required("Enter your old password"),
  password: yup.string().min(5).max(15).required("Enter your new password"),
  password_confirmation: yup
    .string()
    .min(5)
    .max(15)
    .required("Enter your new password"),
});

export const PasswordChangeForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      old_password: "",
      password: "",
      password_confirmation: "",
    },
  });

  const { post } = useAxios();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] =
    useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await post(`user/profile/change_password`, data);

      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Password successfully change!", null, 5000);
      setIsSaved(true);
      navigate(-1);
    } catch (error) {
      setLoading(false);
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
    }
  };
  return (
    <>
      <Container className="container-tight py-4 ">
        {/* {isSaved && <Navigate to={"/"} replace={true} />} */}
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Card className="w-100 mt-5 mb-5 border-radius-rounded">
            <Card.Body>
              <Form.Group className="mb-3 p-2" controlId="oldPassword">
                <Form.Label className="required mb-1">Old Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showOldPassword ? "text" : "password"}
                    autoComplete="off"
                    placeholder="Old password"
                    {...register("old_password")}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <Button
                      variant="outline-secondary"
                      id="button-addon1"
                      className="border-0"
                      onClick={() => {
                        setShowOldPassword(!showOldPassword);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon m-0 "
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="12" r="2" />
                        <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                      </svg>
                    </Button>
                  </InputGroup.Text>
                </InputGroup>

                {!!errors.old_password && (
                  <p className="justify-content-end mt-1">
                    <Badge bg="danger" className="fs-6 fw-normal">
                      {errors.old_password?.message}
                    </Badge>
                  </p>
                )}
              </Form.Group>

              <Form.Group className="mb-3 p-2" controlId="newPassword">
                <Form.Label className="required mb-1">New Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showNewPassword ? "text" : "password"}
                    autoComplete="off"
                    placeholder="New password"
                    {...register("password")}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <Button
                      variant="outline-secondary"
                      id="button-addon1"
                      className="border-0"
                      onClick={() => {
                        setShowNewPassword(!showNewPassword);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon m-0 "
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="12" r="2" />
                        <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                      </svg>
                    </Button>
                  </InputGroup.Text>
                </InputGroup>

                {!!errors.password && (
                  <p className="justify-content-end mt-1">
                    <Badge bg="danger" className="fs-6 fw-normal">
                      {errors.password?.message}
                    </Badge>
                  </p>
                )}
              </Form.Group>

              <Form.Group className="mb-3 p-2" controlId="confirmationPassword">
                <Form.Label className="required mb-1">
                  Confirm New Password
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showNewPasswordConfirmation ? "text" : "password"}
                    autoComplete="off"
                    placeholder="Password"
                    {...register("password_confirmation")}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <Button
                      variant="outline-secondary"
                      id="button-addon1"
                      className="border-0"
                      onClick={() => {
                        setShowNewPasswordConfirmation(
                          !showNewPasswordConfirmation
                        );
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon m-0 "
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="12" r="2" />
                        <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                      </svg>
                    </Button>
                  </InputGroup.Text>
                </InputGroup>

                {!!errors.password_confirmation && (
                  <p className="justify-content-end mt-1">
                    <Badge bg="danger" className="fs-6 fw-normal">
                      {errors.password_confirmation?.message}
                    </Badge>
                  </p>
                )}
              </Form.Group>

              <div className=" d-flex justify-content-center mt-4 mb-3  ">
                {loading ? (
                  <LoadingButton />
                ) : (
                  <Button
                    data-testid="bs-btn "
                    variant="success"
                    type="submit"
                    className=""
                    disabled={!!loading || !isDirty}
                  >
                    Update Password
                  </Button>
                )}
              </div>
            </Card.Body>
          </Card>
        </Form>
      </Container>
    </>
  );
};
