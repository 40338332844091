import React from "react";
import { RequireAuth } from "../../auth";
import { Route, Routes } from "react-router-dom";
import { Loading } from "../../Components/Loading";
import { Error404 } from "../../Pages/Errors/Error404";
import PurchaseOrderIndex from "../../Pages/PurchaseOrder";
import CreatePurchaseOrder from "../../Pages/PurchaseOrder/create";
import ViewPurchaseOrder from "../../Pages/PurchaseOrder/view";
import EditPurchaseOrder from "../../Pages/PurchaseOrder/edit";
import PurchaseOrderSupplyRecord from "../../Pages/PurchaseOrder/supplyRecord";

export const PurchaseOrderRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <PurchaseOrderIndex />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="/create"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreatePurchaseOrder />
              </React.Suspense>
            }
          />

          <Route
            path="view/:purchaseOrderId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewPurchaseOrder />
              </React.Suspense>
            }
          />

          <Route
            path="edit/:purchaseOrderId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditPurchaseOrder />
              </React.Suspense>
            }
          />

          <Route
            path="supply_record/:purchaseOrderId"
            element={
              <React.Suspense fallback={<Loading />}>
                <PurchaseOrderSupplyRecord />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
};
