import React, {useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { permissions } from "../../utils/permissions";
import maleAvatar from "../../images/male_avatar.png";
import femaleAvatar from "../../images/female_avatar.jpg";
import { Link, useNavigate, useParams } from "react-router-dom";
import GuarantorDetailsModal from "../../Components/Staffs/GuarantorModal";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { handleCustomEvent } from "../../utils/customEventLister";
import { alertSuccess, alertWarning } from "../../Components/CustomAlerts/AlertToast";

const ViewStaff = () => {
  const { get } = useAxios();
  let { staffId } = useParams();
  const navigate = useNavigate();
  const handleShow = () => setShow(true);
  const [staff, setStaff] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [fetching, setFetching] = useState(true);
  const [guarantorDetails, setGuarantorDetails] = useState({});
  const [uploadedSignatue, setUploadedSignatue] = useState("");
  const { hasPermission, isSuperAdmin } = usePermissionAndDomain();


  const getDetailedStaff = async () => {
    setFetching(true);
    try {
      const response = await get(`human_resources/staffs/${staffId}/show`);
      setStaff(response.data.userData);
      setUploadedSignatue(response.data.userData.signature_path);
    } catch (error) {
      if (error.response.status === 500) {
      }
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    setFetching(true);
    const initialiaseStaff = async () => await getDetailedStaff();
    initialiaseStaff();
    // setFetching(true);
    // const getDetailedStaff = async () => {
    //   try {
    //     const response = await get-(`human_resources/staffs/${staffId}/show`);
    //     setStaff(response.data.userData);
    //     setUploadedSignatue(response.data.userData.signature_path);
    //   } catch (error) {
    //     if (error.response.status === 500) {
    //     }
    //   } finally {
    //     setFetching(false);
    //   }
    // };
    // getDetailedStaff();
  }, []);


  const onSyncDataClick = (staff) => {
    handleCustomEvent("show-confirmation-modal", {
      action: async () => {
        try {
          await get(`hrimax-sync/employees/${staff?.hrimax_user_id}`);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          alertSuccess(
            `${staff?.first_name} ${staff?.middle_name} ${staff?.surname} successfully synced!`,
            null,
            5000
          );
          getDetailedStaff();
        } catch (error) {
          alertWarning("Your request failed!", null, 500);
        }
      },
      question: `Are you sure you want to sync ${staff?.first_name} ${staff?.middle_name} ${staff?.surname}?`,
    });
  };

  const onGuarantorSelect = (event, guarantor) => {
    event.preventDefault();
    setGuarantorDetails(guarantor);
    handleShow();
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between mt-2 mb-5">
          <Card.Title>
            <Row>
              <Col>
                <h1>Staff Details</h1>
              </Col>
            </Row>
          </Card.Title>

          <Row className="ms-1">
            <Col className="mb-2">
              <Button onClick={() => navigate(-1)}>Back</Button>
            </Col>

            <Col className="me-2">
              <DropdownButton
                id="dropdown-basic-button"
                title="ACTION"
                className="justify-content-center"
              >
                {hasPermission(permissions.EDIT_RECRUIT) && (
                  <Dropdown.Item
                    as={Link}
                    size="sm"
                    className="justify-content-center"
                    to={`/staffs/edit/${staffId}`}
                  >
                    Edit
                  </Dropdown.Item>
                )}

                <Dropdown.Item
                  as={Link}
                  size="sm"
                  to="#"
                  className="justify-content-center"
                  onClick={() => onSyncDataClick(staff)}
                >
                  Sync Data
                </Dropdown.Item>

                {isSuperAdmin() && (
                  <>
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/staffs/set_role/${staffId}`}
                    >
                      Set role
                    </Dropdown.Item>

                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/staffs/password_update/${staffId}`}
                    >
                      Change password
                    </Dropdown.Item>
                  </>
                )}

                {hasPermission(permissions.UPLOAD_EMPLOYEE_SIGNATURE) && (
                  <Dropdown.Item
                    as={Link}
                    size="sm"
                    className="justify-content-center"
                    to={`/staffs/signature_upload/${staffId}`}
                  >
                    Upload signature
                  </Dropdown.Item>
                )}
              </DropdownButton>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          {!!fetching && <Loading />}

          {!!staff?.id && !fetching && (
            <>
              <div className="d-flex py-1 align-items-center mb-5">
                {staff?.gender === "female" ? (
                  <span>
                    <img
                      src={femaleAvatar}
                      width="110"
                      height="100"
                      alt="Female avatar"
                    />
                  </span>
                ) : (
                  <span>
                    <img
                      src={maleAvatar}
                      width="110"
                      height="100"
                      alt="Male avatar"
                    />
                  </span>
                )}

                <div>
                  <div className="text-uppercase font-weight-medium">
                    {`${staff?.first_name} ${staff?.middle_name} ${staff?.surname}` || "N/A"}
                  </div>
                  <div className="text-muted text-uppercase">
                    {staff?.actual_employee_code || "(NO EMPLOYEE CODE FOUND)"}
                  </div>
                  <div className="text-muted text-uppercase">
                    {staff?.designation?.name}
                  </div>

                  {!!staff?.roles?.length && (
                    <div className="text-muted text-uppercase">
                      {`Role: ${staff?.roles[0]?.name}`}
                    </div>
                  )}
                </div>
              </div>

              <div className="page-wrapper">
                <Row className="d-flex mb-5">
                  <Col md={3}>
                    <h3 className="pe-5">Personal Details</h3>
                  </Col>

                  <Col md={9}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col>
                            <strong>Date of Join</strong>
                            <p className="border-bottom py-2">
                              {staff?.date_of_join || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Office Branch</strong>
                            <p className="border-bottom py-2">
                              {staff?.office_location?.name || "N/A"}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <strong>Client</strong>
                            <p className="border-bottom py-2">
                              {staff?.client_location?.client?.name || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Client Location</strong>
                            <p className="border-bottom py-2">
                              {staff?.client_location?.name || "N/A"}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <strong>Phone</strong>
                            <p className="border-bottom py-2">
                              {staff?.phone || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>HRIMAX Id</strong>
                            <p className="border-bottom py-2">
                              {staff?.hrimax_user_id || "N/A"}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                        <Col>
                            <strong>Personal Email</strong>
                            <p className="border-bottom py-2">
                              {staff?.email || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Official Email</strong>
                            <p className="border-bottom py-2">
                              {staff?.email || "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row className="d-flex mb-5">
                  <Col md={3}>
                    <h3 className="pe-5">Financial Details</h3>
                  </Col>

                  <Col md={9}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col>
                            <strong>Bank</strong>
                            <p className="border-bottom py-2">
                              {staff?.bank_name || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Account Name</strong>
                            <p className="border-bottom py-2">
                              {staff?.account_name || "N/A"}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <strong>Account Number</strong>
                            <p className="border-bottom py-2">
                              {staff?.account_number || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Tax Id</strong>
                            <p className="border-bottom py-2">
                              {staff?.tax_id_number || "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Card.Body>
      </Card>

      {!!guarantorDetails.id && (
        <GuarantorDetailsModal
          show={show}
          handleShow={handleShow}
          handleClose={handleClose}
          guarantorDetails={guarantorDetails}
        />
      )}
    </>
  );
};

export default ViewStaff;
