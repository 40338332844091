import React from "react";
import { Link } from "react-router-dom";
import { HtmlContent } from "../../HtmlContent";
import { permissions } from "../../../utils/permissions";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import { usePermissionAndDomain } from "../../../hooks/usePermissionAndDomain";

const DisplayedVehiclePartsTable = ({ vehicleParts }) => {
  const { hasPermission } = usePermissionAndDomain();

  return (
    <>
      <Table
        hover
        bordered
        responsive
        className=" table-vcenter table-mobile-md card-table"
      >
        <thead>
          <tr>
            <th>NAME</th>
            <th>DESCRIPTION</th>
            <th>CONTROLS</th>
          </tr>
        </thead>

        <tbody>
          {vehicleParts.map((vehiclePart) => (
            <tr key={vehiclePart.id}>
              <td data-label="NAME">
                <Link to={`/vehicle-parts/view/${vehiclePart.id}`}>
                  {vehiclePart?.name || "N/A"}
                </Link>
              </td>
              <td data-label="DESCRIPTION">
                <HtmlContent>{vehiclePart?.description || "N/A"}</HtmlContent>
              </td>

              <td className="d-flex justify-content-center ">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  {hasPermission(permissions.VIEW_VEHICLE_PART) && (
                    <Dropdown.Item
                      as={Link}
                      to={`/vehicle-parts/view/${vehiclePart.id}`}
                      size="sm"
                      className="justify-content-center"
                    >
                      View
                    </Dropdown.Item>
                  )}

                  {hasPermission(permissions.EDIT_VEHICLE_PART) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/vehicle-parts/edit/${vehiclePart.id}`}
                    >
                      Edit
                    </Dropdown.Item>
                  )}

                  {/* <Dropdown.Item
                    as={Link}
                    size="sm"
                    to={"#"}
                    className="justify-content-center"
                    onClick={() => onDeleteClick(vehiclePart)}
                  >
                    Delete
                  </Dropdown.Item> */}
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <br />
    </>
  );
};

export default DisplayedVehiclePartsTable;
