import React, { useRef, useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Badge, Button, Modal, Col, Form, Row } from "react-bootstrap";

const schema = yup.object({
  comment: yup.string(),
});

export const VehicleStatusCommentModal = () => {
  const {
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      comment: "",
    },
  });

  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [selectedVehicleData, setSelecetedVehicleData] = useState({});
  let passedCommentValueHandler = useRef(() => {});

  useEffect(() => {
    window.addEventListener("statusReportComment:add", (event) => {
      handleShow();
      setSelecetedVehicleData(event.detail.field);
      passedCommentValueHandler.current = event.detail.handleCommentText;

    });
  });

  const onSubmit = async (data) => {
    passedCommentValueHandler.current(data.comment);

    handleClose();
    reset()
  };

  return (
    <Modal
      centered
      show={show}
      backdrop="static"
      keyboard={false}
      onHide={handleClose}
      className="modal modal-dialog-centered modal-blur fade"
    >
      <Modal.Header closeButton>
        <Modal.Title>{`${selectedVehicleData?.reg_number} - ${selectedVehicleData?.label}`}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          id="vehicleStatusCommentModalForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Form.Group as={Row} className="mb-4">
            <Form.Label column sm="4" className="required">
              COMMENT
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Comment"
                {...register("comment")}
                data-vehicle={selectedVehicleData.vehicle_id}
                onChange={(event) => {
                  setValue("comment", event.target.value, {
                    shouldValidate: true,
                  });
                }}
              />
            </Col>

            {!!errors.comment && (
              <p>
                <Badge className="mt-1" bg="danger">
                  {errors.comment?.message}
                </Badge>
              </p>
            )}
          </Form.Group>

          <div className="d-flex justify-content-center mt-3">
            <Button
              variant="primary"
              type="submit"
              className=" d-flex justify-content-center mt-3 me-3"
              disabled={!isDirty}
            >
              Save
            </Button>

            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                handleClose();
              }}
              className=" d-flex justify-content-center mt-3"
            >
              Cancle
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
