import React, { useContext, useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import {useSearchParams } from "react-router-dom";
import ReactPagination from "../../Components/ReactPagination";
import { alertWarning } from "../../Components/CustomAlerts/AlertToast";
import { NotificationsListingTable } from "../../Components/Notifications/NotificationsListingTable";
import { FaExclamationCircle } from "react-icons/fa";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { API_URL } from "../../config";
import { AuthContext } from "../../auth";


const NotificationsIndex = () => {
  const { get } = useAxios()
  const [fetching, setFetching] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [searchPageParams, setSearchPageParams] = useSearchParams({});
  const [selectedNotification, setSelectedNotification] = useState({})

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;

  const auth = useContext(AuthContext);

  const token = localStorage.getItem("access_token")

  let deviceViewPortWidth = document.documentElement.clientWidth < 576
 

  const getNotifications = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;
      const response = await get(
        `auth/notifications?sort=desc&page=${currentPage}&page_size=${pageSize}`
      );
      
      setNotifications(response?.data?.notifications);
    } catch (error) {
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
    } finally {
      setFetching(false);
    }
  };

  
  useEffect(() => {
    setFetching(true);
    const notifications = async () => await getNotifications();
    notifications();
  }, [currentPage]);
  
  const pageChangeHandler = (page) => {
    setSearchPageParams(
      {
        ...Object.fromEntries(searchPageParams),
        page,
      },
      { replace: true }
      );
    };
    
  const handlePushNotification = async () => {
    // /pusher/beams-auth
    
    Notification.requestPermission().then(async (permission) => {
      if (permission !== "granted") {
        return
      }

      try {

        const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
          url: `${API_URL}/auth/pusher/beams-auth`,
          headers: {
            Authorization: "Bearer " + token, // Headers your auth endpoint needs
          },
        });

        
        const beamsClient = new PusherPushNotifications.Client({
          instanceId: "a380f41d-9e7b-4aa0-a502-893bf6e40868",
        });

        beamsClient
          .start()
          .then(() => beamsClient.setUserId((auth?.user?.id).toString(), beamsTokenProvider))
          .then(() => console.log("Successfully registered and subscribed!"))
          .catch(console.error);
  
        
      } catch (error) {
        
      }
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        const notification = new Notification("Hi there!");
        // …
      }
    })
  }
  
  return (
    <>
      {!!fetching && <Loading />}

      <>
        <Card className="container d-flex justify-content-center ms-3">
          <Card.Header className="d-flex justify-content-between mb-2">
            <Card.Title style={{width: "100%"}}>
              <Row className="d-flex justify-content-between align-items-center">
                <Col sm="auto">
                  <h2 className="page-title">Notifications</h2>
                </Col>


                {Notification.permission !== "granted" && (
                  <Col sm="auto" className="d-flex align-items-center">
                    <div style={{backgroundColor: "#FFF3CD", fontSize: "14px", fontWeight: "normal", padding: "0.5rem"}}>
                      <span className="pe-3">Notification is not enabled!</span>
                    
                      <Button className="btn-sm" onClick={handlePushNotification}>Enable</Button>
                    </div>


                  </Col>
                )}
              </Row>
            </Card.Title>

          </Card.Header>

          <Card.Body>
            {!fetching && !notifications?.data?.length && (
              <div className="mt-4 p-2 border text-muted text-center">
              <span>
                <FaExclamationCircle size={19} />
              </span>

              <span className="fs-2 ms-2 ">
                No Notifications Found!
              </span>
            </div>
            )}

            {!!notifications?.data?.length && !fetching && (
              <>
                <Button 
                  className={(selectedNotification?.id  && !!deviceViewPortWidth) ? "d-block d-sm-none mb-3" : "d-none mb-3"}
                  onClick={() => setSelectedNotification({})}
                >
                  Back
                </Button>

                <div className="d-flex justify-content-start">
           
                  <Card className={!!selectedNotification?.id ? "d-none d-sm-block p-3" : "p-3"}>
                      <NotificationsListingTable 
                        notifications={notifications?.data} 
                        selectedNotification={selectedNotification} 
                        setSelectedNotification={setSelectedNotification} 
                      />
                  </Card>


                  <Card className="flex-grow-1">
                    <div 
                      className={!selectedNotification?.id ? "d-flex flex-column justify-content-center align-items-center mt-5" : "d-none"}
                      >
                      <img 
                        src="https://res.cdn.office.net/owamail/hashed-v1/resources/images/illustration_mail-hash-4338a4a7.m.svg" 
                        alt="email envolops"
                        style={{
                          width: 100,
                          height: 100
                        }}
                      />

                      <div className="d-flex flex-column align-items-center">
                        <span className="fw-bold text-muted">Select a notification to read</span>
                        <span className="text-muted">Nothing is selected</span>
                      </div>


                    </div>
                    {selectedNotification?.id && (
                      <>
                        <Card.Header className="fw-bold">{selectedNotification?.subject}</Card.Header>
                        <Card.Body>
                            <iframe
                            dangerouslySetInnerHTML={{
                              __html: selectedNotification?.subject,
                            }}
                            sandbox="allow-same-origin"
                            srcDoc={selectedNotification?.msg_body}
                            referrerPolicy="no-referrer"
                            width="100%" 
                            height="100%" title={selectedNotification?.subject}
                          >
                          </iframe>
                          
                        </Card.Body>
                      </>
                    )}
                  </Card>
                  
                </div>

                <br />

                <ReactPagination
                  links={notifications.links}
                  onPageChange={pageChangeHandler}
                  current_page={notifications.current_page}
                  last_page={notifications.last_page}
                />
              </>
            )}
          </Card.Body>
        </Card>
      </>
    </>
  );
};

export default NotificationsIndex;
