import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const TopRecordsBarChart = ({ topVehicles }) => {
  let labels = [];
  let myDataSet = [];

  topVehicles.map((vehicleName) => {
    labels.push(
      `${vehicleName?.vehicle?.reg_number} (${vehicleName?.vehicle?.vehicle_model?.name})`
    );
  });

  topVehicles.map((data) => {
    myDataSet.push(`${Number(Math.round(data.amount))}`);
  });

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Top Records",
      },
    },
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Amount",
        data: labels.map((myLabel, index) => myDataSet[index]),
        // myDataSet,
        fill: false,
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
        ],
        minBarLength: 2,
        maxBarThickness: 30,
      },
    ],
  };


  return <Bar data={chartData} options={options} />;
};
