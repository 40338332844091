import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  Dropdown,
  ListGroup,
  DropdownButton,
} from "react-bootstrap";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { HtmlContent } from "../../Components/HtmlContent";
import { Link, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";

const ViewNotification = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { notificationId } = useParams();
  const [fetching, setFetching] = useState(true);
  const [notification, setNotification] = useState({});
  const [notificationHistory, setNotificationHistory] = useState([]);

  useEffect(() => {
    setFetching(true);
    const getDetailedNotification = async () => {
      try {
        const response = await get(`admin_notification/${notificationId}/show`);
        setNotification(response.data.adminNotifications);
        setNotificationHistory(response.data.history);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedNotification();
  }, []);

  return (
    <>
      {!!fetching && <Loading />}

      {!!notification.id && !fetching && (
        <>
          <Card>
            <Card.Header className="d-flex justify-content-between mt-2 mb-5">
              <Card.Title>
                <Row className="me-4">
                  <Col>
                    <h1>Notification Details</h1>
                  </Col>
                </Row>
              </Card.Title>

              <Row className="me-4">
                <Col className="mb-2">
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </Col>

                <Col sm={6}>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title="ACTION"
                    className="justify-content-center"
                  >
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/notifications/edit/${notificationId}`}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/notifications/manage_recipients/${notificationId}`}
                    >
                      Manage Recipients
                    </Dropdown.Item>
                  </DropdownButton>
                </Col>
              </Row>
            </Card.Header>

            <Card.Body className="mb-4">
              <ListGroup className="list-group-flush">
                <ListGroup.Item>
                  <label className="fw-bolder fs-3">TITLE</label>
                  <Card.Text className="fs-3">
                    {notification.title || "N/A"}
                  </Card.Text>
                </ListGroup.Item>

                <ListGroup.Item>
                  <label className="fw-bolder fs-3">DESCRIPTION</label>
                  <HtmlContent>{notification.description || "N/A"}</HtmlContent>
                </ListGroup.Item>

                <ListGroup.Item>
                  <label className="fw-bolder fs-3 ">
                    CONFIGURED RECIPIENTS
                  </label>

                  {!!notification?.admin_users.length ? (
                    <div className="fs-3 mt-3">
                      <ul>
                        {notification.admin_users.map((recipient) => (
                          <li key={recipient.id}>
                            {recipient.first_name} {recipient.surname} (
                            {recipient.email || "NO EMAIL FOUND"})
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <Card.Text className="fs-3">
                      No recipient configured for this notification yet!
                    </Card.Text>
                  )}
                </ListGroup.Item>

                <ListGroup.Item>
                  <label className="fw-bolder fs-3 ">MOST RECENT HISTORY</label>

                  <ListGroup className="mt-3">
                    {notificationHistory.map((history) => (
                      <ListGroup.Item
                        key={history.id}
                        className="fs-3 mt-3 d-flex justify-content-between"
                      >
                        <span className="text-uppercase">
                          {history.user.first_name} {history.user.surname}
                        </span>
                        <span className="text-muted">
                          {format(
                            new Date(history?.created_at),
                            "MMM d, yyyy 'at' hh:mm:ss aaa",
                            {
                              awareOfUnicodeTokens: true,
                            }
                          )}
                        </span>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>

            {/* <Card.Footer>
              <ListGroup className="list-group-flush">
                <ListGroup.Item className="d-flex justify-content-end">
                  <Row>
                    <Col className="mb-3">
                      <label className="fw-bolder fs-3">CREATED</label>
                      <Card.Text className="fs-3">
                        {format(
                          new Date(notification.created_at),
                          "EEE, MMM d, yyyy 'at' HH:mm:ss",
                          {
                            awareOfUnicodeTokens: true,
                          }
                        ) || "N/A"}
                      </Card.Text>
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item className="d-flex justify-content-end">
                  <Row>
                    <Col>
                      <label className="fw-bolder fs-3">UPDATED</label>
                      <Card.Text className="fs-3">
                        {format(
                          new Date(notification.updated_at),
                          "EEE, MMM d, yyyy 'at' HH:mm:ss",
                          {
                            awareOfUnicodeTokens: true,
                          }
                        ) || "N/A"}
                      </Card.Text>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Card.Footer> */}
          </Card>
        </>
      )}
    </>
  );
};

export default ViewNotification;
