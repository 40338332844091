import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { permissions } from "../../utils/permissions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";

const ViewDriver = () => {
  const { get } = useAxios();
  let { driverId } = useParams();
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [driverDetails, setDriverDetails] = useState([]);

  useEffect(() => {
    setFetching(true);
    const getDetailedDriver = async () => {
      try {
        const response = await get(`user/drivers/${driverId}`);
        setDriverDetails(response.data.driver);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedDriver();
  }, []);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between mt-2 mb-5">
        <Card.Title>
          <Row>
            <Col>
              <h1>Driver Details</h1>
            </Col>
          </Row>
        </Card.Title>

        <Row className="ms-1">
          <Col className="mb-2">
            <Button onClick={() => navigate(-1)}>Back</Button>
          </Col>

          <Col className="me-2">
            <DropdownButton
              id="dropdown-basic-button"
              title="ACTION"
              className="justify-content-center"
            >
              {hasPermission(permissions.EDIT_DRIVER_DOCUMENTS) && (
                <Dropdown.Item
                  as={Link}
                  size="sm"
                  className="justify-content-center"
                  to={`/drivers/edit/${driverId}`}
                >
                  Edit
                </Dropdown.Item>
              )}
            </DropdownButton>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body>
        {!!fetching && <Loading />}

        {!fetching && !!driverDetails?.id && (
          <div className="page-wrapper">
            <Row className="d-flex mb-5">
              <Col md={3}>
                <h3 className="pe-5">General Details</h3>
              </Col>

              <Col md={9}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <strong>Name</strong>
                        <p className="border-bottom py-2">
                          {`${driverDetails?.first_name} ${driverDetails?.middle_name} ${driverDetails?.surname}` ||
                            "N/A"}
                        </p>
                      </Col>

                      <Col>
                        <strong>Employee code</strong>
                        <p className="border-bottom py-2">
                          {driverDetails?.actual_employee_code || "N/A"}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <strong>Gender</strong>
                        <p className="border-bottom py-2">
                          {driverDetails?.gender || "N/A"}
                        </p>
                      </Col>

                      <Col>
                        <strong>Email</strong>
                        <p className="border-bottom py-2">
                          {driverDetails?.email || "N/A"}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <strong>Primary Phone</strong>
                        <p className="border-bottom py-2">
                          {driverDetails?.phone || "N/A"}
                        </p>
                      </Col>

                      <Col>
                        <strong>Secondary Phone</strong>
                        <p className="border-bottom py-2">
                          {driverDetails?.other_phones || "N/A"}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <strong>Location</strong>
                        <p className="border-bottom py-2">
                          {driverDetails?.office_location?.name || "N/A"}
                        </p>
                      </Col>

                      <Col>
                        {/* no designation detail, just id */}
                        <strong>Designation</strong>
                        <p className="border-bottom py-2">
                          {driverDetails?.last_service_date || "N/A"}
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="d-flex mb-5">
              <Col md={3}>
                <h3 className="pe-5">Driver's Documents</h3>
              </Col>

              <Col md={9}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <strong>Class</strong>
                        <p className="border-bottom py-2">
                          {driverDetails?.license_class || "N/A"}
                        </p>
                      </Col>

                      <Col>
                        <strong>Driver's license number</strong>
                        <p className="border-bottom py-2">
                          {driverDetails?.license_number || "N/A"}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <strong>License Expiry Date</strong>
                        <p className="border-bottom py-2">
                          {driverDetails?.license_expiry_date || "N/A"}
                        </p>
                      </Col>

                      <Col>
                        <strong>Lasdri number</strong>
                        <p className="border-bottom py-2">
                          {driverDetails?.lasdri_number || "N/A"}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <strong>Lasdri expiry dater</strong>
                        <p className="border-bottom py-2">
                          {driverDetails?.lasdri_expiry_date || "N/A"}
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="d-flex mb-5">
              <Col md={3}>
                <h3 className="pe-5">Assigned Vehicle</h3>
              </Col>

              <Col md={9}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <strong>Reg. Number</strong>
                        <p className="border-bottom py-2">
                          {driverDetails?.reg_number || "N/A"}
                        </p>
                      </Col>

                      <Col>
                        <strong>Type</strong>
                        <p className="border-bottom py-2">
                          {driverDetails?.license_reg_date || "N/A"}
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ViewDriver;
