import React from "react";
import { RequireAuth } from "../auth";
import { Route, Routes } from "react-router-dom";
import { Loading } from "./../Components/Loading";
import RemindersIndex from "../Pages/Reminders/index";



export const RemindersRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <RemindersIndex />
              </React.Suspense>
            }
          />

        </Route>
      </Routes>
    </RequireAuth>
  );
};
