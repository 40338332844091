import React from "react";
import "./style.css";
import Login from "./Components/Login";
import { Routes, Route } from "react-router-dom";
import { StaffRoutes } from "./Routes/StaffRoutes";
import { AuthProvider, RequireAuth } from "./auth";
import DashBoard from "./Pages/Dashboard/Dashboard";
import { ClientRoutes } from "./Routes/ClientRoutes";
import { DriverRoutes } from "./Routes/DriverRoutes";
import { VehicleRoutes } from "./Routes/VehicleRoutes";
import { SettingsRoutes } from "./Routes/SettingsRoutes";
import { DashBoardRoutes } from "./Routes/DashBoardRoutes";
import { RemindersRoutes } from "./Routes/RemindersRoutes";
import WeeklyReportRoutes from "./Routes/WeeklyReportRoutes";
import { VehiclePartsRoutes } from "./Routes/VehiclePartsRoutes";
import EscortScheduleRoutes from "./Routes/EscortScheduleRoutes";
import IncidentReportRoutes from "./Routes/IncidentReportRoutes";
import { NotificationRoutes } from "./Routes/NotificationRoutes";
import { VehicleStatusReport } from "./Routes/VehicleStatusReport";
import { VendorRoutes } from "./Routes/LogisticsRoute/VendorRoutes";
import { GuardKitItemsRoutes } from "./Routes/LogisticsRoute/GuardKitItemsRoutes";
import { PurchaseOrderRoutes } from "./Routes/LogisticsRoute/PurchaseOrderRoutes";
import { PermissionsDomainsRoutes } from "./Routes/LogisticsRoute/PermissionsDomains";
import { RolesPermissionsRoutes } from "./Routes/LogisticsRoute/RolesPermissionsRoutes";
import { GuardKitsRequestRoutes } from "./Routes/LogisticsRoute/GuardKitsRequestRoutes";
import { VehicleMaintenanceRecordRoutes } from "./Routes/VehicleMaintenanceRecordRoutes";
import ViewFromMail from "./Pages/IncidentReports/viewFromMail";
import { SalaryComplaintsRoutes } from "./Routes/SalaryComplaintsRoutes";





function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <DashBoard />
            </RequireAuth>
          }
        />

        <Route
          path="/vehicles/status-reports/*"
          element={<VehicleStatusReport />}
        />

        <Route
          path="/guardkits-request/*"
          element={<GuardKitsRequestRoutes />}
        />

        {/* <Route path="*" element={<Error404 />} /> */}

        <Route
          path="/maintenance-records/*"
          element={<VehicleMaintenanceRecordRoutes />}
        />
        <Route path="/staffs/*" element={<StaffRoutes />} />
        <Route path="/vendors/*" element={<VendorRoutes />} />
        <Route path="/clients/*" element={<ClientRoutes />} />
        <Route path="/drivers/*" element={<DriverRoutes />} />
        <Route path="/vehicles/*" element={<VehicleRoutes />} />
        <Route path="/settings/*" element={<SettingsRoutes />} />
        <Route path="/reminders/*" element={<RemindersRoutes />} />
        <Route path="/dashboard/*" element={<DashBoardRoutes />} />
        <Route path="/roles/*" element={<RolesPermissionsRoutes />} />
        <Route path="/guardkit/*" element={<GuardKitItemsRoutes />} />
        <Route path="/vehicle-parts/*" element={<VehiclePartsRoutes />} />
        <Route path="/notifications/*" element={<NotificationRoutes />} />
        <Route path="/weekly-reports/*" element={<WeeklyReportRoutes />} />
        <Route path="/purchase-order/*" element={<PurchaseOrderRoutes />} />
        <Route path="/permissions/*" element={<PermissionsDomainsRoutes />} />
        <Route path="/incident-reports/*" element={<IncidentReportRoutes />} />
        <Route path="/escort-schedules/*" element={<EscortScheduleRoutes />} />

        <Route
          path="/incident-reports/from-mail/:loginToken"
          element={<ViewFromMail />}
        />
        <Route
          path="/salary-complaints/*"
          element={<SalaryComplaintsRoutes />}
        />
      </Routes>
    </AuthProvider>
  );
}

export default App;
