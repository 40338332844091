import React from 'react'

export const HtmlContent = ({children}) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    />
  );
}
