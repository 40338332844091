import React from "react";
import { RequireAuth } from "../auth";
import { Loading } from "../Components/Loading";
import { Route, Routes } from "react-router-dom";
import { Error404 } from "../Pages/Errors/Error404";
import PasswordChange from "../Pages/Settings/passwordChange";
import ViewProfile from "../Pages/Settings/viewProfile";



export const SettingsRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="profile"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewProfile />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="security"
            element={
              <React.Suspense fallback={<Loading />}>
                <PasswordChange />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
};
