import React from "react";
import { RequireAuth } from "../auth";
import { Loading } from "../Components/Loading";
import { Route, Routes } from "react-router-dom";
import { Error404 } from "../Pages/Errors/Error404";
import SetRole from "../Pages/Staffs/setRole";
import PasswordUpdate from "../Pages/Staffs/passwordUpdate";
import SignatureUpload from "../Pages/Staffs/SignatureUpload";
import ViewStaff from "../Pages/Staffs/view";
import EditStaff from "../Pages/Staffs/edit";
import StaffsIndex from "../Pages/Staffs/index";
import CreateStaff from "../Pages/Staffs/create";



export const StaffRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <StaffsIndex />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="/create"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateStaff />
              </React.Suspense>
            }
          />

          <Route
            path="edit/:staffId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditStaff />
              </React.Suspense>
            }
          />

          <Route
            path="view/:staffId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewStaff />
              </React.Suspense>
            }
          />

          <Route
            path="password_update/:staffId"
            element={
              <React.Suspense fallback={<Loading />}>
                <PasswordUpdate />
              </React.Suspense>
            }
          />

          <Route
            path="set_role/:staffId"
            element={
              <React.Suspense fallback={<Loading />}>
                <SetRole />
              </React.Suspense>
            }
          />

          <Route
            path="signature_upload/:staffId"
            element={
              <React.Suspense fallback={<Loading />}>
                <SignatureUpload />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
};
