import React from "react";
import DatePicker from "react-datepicker";
import { Row, Col, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";

const EscortScheduleDateRange = ({
  dateTo,
  location,
  dateFrom,
  handleFilterParamsChange,
}) => {


  const handleDateChange = (dates) => {
    const [start, end] = dates;
    handleFilterParamsChange(start, end, location);
  };

  return (
    <>
      <Row>
        <Col>
          <DatePicker
            inline
            selectsRange
            strictParsing
            endDate={dateTo}
            showYearDropdown
            showMonthDropdown
            selected={dateFrom}
            startDate={dateFrom}
            dropdownMode="select"
            onChange={handleDateChange}
          />

          {/* <Controller
          name="calendarDate"
          control={control}
          render={({ field }) => (
            <DatePicker
              {...field}
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          )}
        /> */}
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Form.Label>Location</Form.Label>
          <Form.Select
            id=""
            defaultValue={location}
            onChange={(event) =>
              handleFilterParamsChange(dateFrom, dateTo, event.target.value)
            }
          >
            <option disabled>Select one</option>
            <option value="all">All</option>
            <option value="lagos">Lagos</option>
            <option value="upstate">Upstate</option>
          </Form.Select>
        </Col>
      </Row>
    </>
  );
};

export default EscortScheduleDateRange;
