import React, { useState } from "react";
import * as yup from "yup";
import Select from "react-select";
import { Navigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import NumberFormat from "react-number-format";
import { LoadingButton } from "../LoadingButtons";
import TinyMCETextEditor from "../TinyMCETextEditor";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import { Row, Col, Form, Badge, Button, FormGroup } from "react-bootstrap";

const schema = yup.object({
  vendor_id: yup.string().required("Vendor is required"),
  items: yup.array().of(
    yup.object({
      item_id: yup.string().required("Kit item is required"),
      quantity: yup.string().required("Quantity is required"),
      rate: yup.string(),
      amount: yup.string(),
    })
  ),
  terms: yup.string().required("Terms of delivery is required"),
  total: yup.string(),
});

export const PurchaseOrderForm = ({
  vendors,
  guardKitItems,
  editPurchaseOrder,
}) => {
  const {
    control,
    setValue,
    register,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      vendor_id: editPurchaseOrder?.vendor_id || "",
      items: editPurchaseOrder?.items || [],
      terms: editPurchaseOrder?.terms || "",
      total: editPurchaseOrder?.total || "",
    },
  });

  const { post } = useAxios();
  const [loading, setLoading] = useState(false);
  const [kitItem, setKitItem] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const calculateTotal = () => {
    let total = 0;
    document.querySelectorAll(`[data-amount-index]`).forEach((amount) => {
      total += Number(amount.value) || 0;
    });

    setValue("total", total);
  };

  const calculateAmount = (index) => {
    const quantity = document.querySelector(
      `[data-quantity-index="${index}"]`
    ).value;

    const rate = document.querySelector(`[data-rate-index="${index}"]`).value;

    setValue(
      `items.${index}.amount`,
      (Number(quantity) || 0) * (Number(rate) || 0)
    );

    setTimeout(() => {
      calculateTotal();
    }, 50);
  };

  const vendorOptions = Object.keys(vendors).map((key) => ({
    value: key,
    label: vendors[key],
  }));

  const guardKitItemOptions = Object.keys(guardKitItems).map((key) => ({
    value: key,
    label: guardKitItems[key],
  }));


  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const endpointPath = !editPurchaseOrder?.id
        ? "purchaseorder/store"
        : `purchaseorder/${editPurchaseOrder.id}/update`;

      await post(endpointPath, data);
      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Purchase order successfully saved!", null, 5000);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 422) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry, something went wrong and your request was unsuccessful!",
          null,
          5000
        );
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error.response.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to create purchase order!",
          null,
          5000
        );
      }
    }
  };

  return (
    <>
      {isSaved && (
        <Navigate
          to={
            !editPurchaseOrder?.id
              ? "/purchase-order/"
              : `/purchase-order/view/${editPurchaseOrder?.id}`
          }
          replace={true}
        />
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        {!editPurchaseOrder?.id ? (
          <Form.Group controlId="vendor ">
            <Form.Label className="required">VENDOR NAME</Form.Label>
            <Controller
              name="vendor_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <Select
                    className="text-dark"
                    name={field.name}
                    ref={field.ref}
                    options={vendorOptions}
                    onChange={(selectedVendorOption) => {
                      setValue("vendor_id", selectedVendorOption.value, {
                        shouldValidate: true,
                      });
                    }}
                    defaultValue={{
                      value: editPurchaseOrder?.vendors?.id || "",
                      label: editPurchaseOrder?.vendors?.name || "",
                    }}
                    placeholder="Select Vendor"
                  />
                );
              }}
            />

            {!!errors.vendor_id && (
              <p>
                <Badge bg="danger">{errors.vendor_id?.message}</Badge>
              </p>
            )}
          </Form.Group>
        ) : (
          <div className="vendorDetail mt-4 mb-4 border">
            <Row className="d-flex justify-content-between ms-0 me-0 mb-2 p-2 ">
              <Col>
                <Form.Group controlId="vendor ">
                  <Form.Label>VENDOR NAME</Form.Label>
                  <Form.Text>{editPurchaseOrder?.vendors?.name}</Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </div>
        )}

        <div className="kitItemDetail mt-4 mb-4 border">
          <Form.Label className="p-2">GUARD KIT ITEM DETAILS</Form.Label>
          {fields.map((field, index) => (
            <Row
              key={field.id}
              className="d-flex justify-content-between ms-0 me-0 mb-2 p-2 "
            >
              <Row className="mt-3 mb-3">
                <Col className="kit-item mb-3" md={4}>
                  <Form.Group controlId="kitItem ">
                    <Form.Label className="required">
                      KIT ITEM (Select one below)
                    </Form.Label>
                    <Controller
                      key={index}
                      name={`items.${index}.item_id`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <Select
                            name={field.name}
                            ref={field.ref}
                            options={guardKitItemOptions}
                            onChange={(selectedGuardKitItemOption) => {
                              setValue(
                                `items.${index}.item_id`,
                                selectedGuardKitItemOption.value,
                                {
                                  shouldValidate: true,
                                }
                              );
                            }}
                            defaultValue={{
                              value:
                                editPurchaseOrder?.items[index]?.kit_item?.id ||
                                "",
                              label:
                                editPurchaseOrder?.items[index]?.kit_item
                                  ?.name || "",
                            }}
                            placeholder="Select kit item"
                          />
                        );
                      }}
                    />

                    {!!errors.items?.[index]?.item_id && (
                      <p>
                        <Badge bg="danger">
                          {errors.items?.[index]?.item_id?.message}
                        </Badge>
                      </p>
                    )}
                  </Form.Group>
                </Col>

                <Col className="kit-item-quantity mb-3" md={4}>
                  <Form.Label className="required">QUANTITY</Form.Label>
                  <Form.Control
                    key={index}
                    type="number"
                    min={1}
                    data-quantity-index={index}
                    placeholder="Enter quantity needed"
                    {...register(`items.${index}.quantity`)}
                    onChange={(event) => {
                      calculateAmount(index);
                      setValue(`items.${index}.quantity`, event.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  />

                  {!!errors.quantity && (
                    <p>
                      <Badge bg="danger">
                        {errors.items?.[index].quantity?.message}
                      </Badge>
                    </p>
                  )}
                </Col>

                <Col className="rate mb-3" md={4}>
                  <FormGroup controlId="rate">
                    <Form.Label>
                      <span className="form-hint">
                        RATE (N.....................K)
                      </span>
                    </Form.Label>
                    <Controller
                      key={index}
                      name={`items.${index}.rate`}
                      control={control}
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          data-rate-index={index}
                          className="form-control"
                          placeholder="Cost price of kit item"
                          onChange={(event) => {
                            calculateAmount(index);
                            setValue(
                              `items.${index}.rate`,
                              event.target.value,
                              {
                                shouldValidate: true,
                              }
                            );
                          }}
                        />
                      )}
                    />

                    {!!errors.rate && (
                      <p>
                        <Badge bg="danger">
                          {errors.items?.[index].rate?.message}
                        </Badge>
                      </p>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="amount mb-3">
                  <FormGroup controlId="amount">
                    <Form.Label>
                      <span className="form-hint">
                        PRICE (N.....................K)
                      </span>
                    </Form.Label>
                    <Controller
                      key={index}
                      name={`items.${index}.amount`}
                      control={control}
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          className="form-control"
                          disabled
                          data-amount-index={index}
                          onChange={() => {}}
                        />
                      )}
                    />

                    {!!errors.amount && (
                      <p>
                        <Badge bg="danger">
                          {errors.items?.[index].amount?.message}
                        </Badge>
                      </p>
                    )}
                  </FormGroup>
                </Col>

                <Col className="align-self-center mt-2 pt-2">
                  <Button variant="danger" onClick={() => remove(index)}>
                    Remove
                  </Button>
                </Col>
              </Row>
              <hr />
            </Row>
          ))}
        </div>

        <Row className=" more-po-button mb-4">
          <Col>
            <Button className="me-3" type="button" onClick={() => append({})}>
              Add Kit Item
            </Button>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col className="total mb-3">
            <FormGroup controlId="total">
              <Form.Label>
                <span className="form-hint">
                  TOTAL (N.....................K)
                </span>
              </Form.Label>
              <Controller
                name="total"
                control={control}
                render={({ field }) => (
                  <NumberFormat
                    {...field}
                    disabled
                    className="form-control"
                    data-total="total"
                    onChange={() => {}}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <div className="delivery-terms mb-4">
          <Form.Label className="required">
            DELIVERY TERMS & CONDITIONS
          </Form.Label>
          <Form.Text id="deliveryTerms" muted>
            Describe the agreement with vendor on &nbsp;
            <span className="text-decoration-underline">What</span>
            &nbsp; is to be delivered and &nbsp;
            <span className="text-decoration-underline">When</span> to deliver
            it (date & time).
          </Form.Text>
          <Form.Text></Form.Text>
          <Controller
            control={control}
            name="terms"
            render={({ field }) => (
              <TinyMCETextEditor field={field} setValue={setValue} />
            )}
          />

          {!!errors.terms && (
            <p>
              <Badge bg="danger">{errors.terms?.message}</Badge>
            </p>
          )}
        </div>

        <div className="submit col-md-12 text-center mb-3">
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              type="submit"
              variant="success"
              disabled={!!loading || !isDirty}
            >
              Submit
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};
