import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import { PasswordChangeForm } from "../../Components/Settings/PasswordChangeForm";

const PasswordChange = () => {
  const navigate = useNavigate();
  return (
    <>
      <Card className="container">
        <Card.Header className="d-flex justify-content-between mt-0 ">
          <Card.Title>
            <Row>
              <Col>
                <h1 className="page-title">Change Password</h1>
              </Col>
            </Row>
          </Card.Title>
          <Row>
            <Col>
              <Button onClick={() => navigate(-1)}>Back</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {/* {!!fetching && <Loading />} */}

          <PasswordChangeForm />
        </Card.Body>
      </Card>
    </>
  );
};


export default PasswordChange;