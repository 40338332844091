import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { Card, Col, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { ClientsLocationsDataTable } from "../../Components/Clients/ClientsLocationsDataTable";

const ClientsLocations = () => {
  const { get } = useAxios();
  const [clientsLocations, setClientsLocations] = useState([]);
  const [searchPageParams] = useSearchParams();
  const [fetching, setFetching] = useState(true);

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;

  const getClientsLocations = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;
      const response = await get(`client_locations`);
      setClientsLocations(response.data.allClients);
    } catch (error) {
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    setFetching(true);
    const initialiaseClientsLocations = async () => await getClientsLocations();
    initialiaseClientsLocations();
  }, [currentPage]);

  return (
    <>
      {!!fetching && <Loading />}
      <Card className="container d-flex justify-content-center ms-3">
        <Card.Header className="d-flex justify-content-between mt-2 mb-2">
          <Card.Title>
            <Row>
              <Col md="auto">
                <h2 className="page-title">ALL CLIENTS LOCATIONS</h2>
              </Col>
            </Row>
          </Card.Title>
        </Card.Header>

        <Card.Body className="mt-4">
          {!fetching && !!clientsLocations?.length === 0 && (
            <div> No Client Location Found</div>
          )}

          {!fetching && !!clientsLocations?.length && (
            <>
              <Row>
                <Col className="p-0">
                  <>
                    <ClientsLocationsDataTable clientsLocations={clientsLocations} />
                  </>
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );

};

export default ClientsLocations;
