import React from "react";
import { Link } from "react-router-dom";
import { permissions } from "../../utils/permissions";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";

export const DisplayedReportTable = ({ incidentReports }) => {
  const { hasPermission } = usePermissionAndDomain();

  return (
    <>
      <Table
        responsive
        hover
        bordered
        className=" table-vcenter table-mobile-md card-table"
      >
        <thead>
          <tr>
            <th>REPORTER</th>
            <th>CLIENT NAME</th>
            <th>CLIENT LOCATION</th>
            <th>INCIDENT TYPE</th>
            <th>INCIDENT DATE</th>
            <th>REPORT DATE</th>
            <th>CONTROLS</th>
          </tr>
        </thead>

        <tbody>
          {incidentReports.map((incidentReport) => (
            <tr key={incidentReport.id}>
              <td data-label="REPORTER">{incidentReport.reporterName}</td>
              <td data-label="CLIENT NAME">{incidentReport.clients.name}</td>
              <td data-label="CLIENT LOCATION">
                {incidentReport.client_offices.name}
              </td>
              <td data-label="INCIDENT TYPE">{incidentReport.incidentType}</td>
              <td data-label="INCIDENT DATE">{incidentReport.incidentDate}</td>
              <td data-label="REPORT DATE">{incidentReport.reportDate}</td>

              <td className="d-flex justify-content-center ">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  {hasPermission(permissions.CREATE_INCIDENT_REPORT) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/incident-reports/view/${incidentReport.id}`}
                    >
                      View
                    </Dropdown.Item>
                  )}

                  {!incidentReport?.position &&
                    !incidentReport?.incidentIntro &&
                    !incidentReport?.incidentFindings &&
                    hasPermission(permissions.EDIT_INCIDENT_REPORT) && (
                      <Dropdown.Item
                        as={Link}
                        size="sm"
                        className="justify-content-center"
                        to={`/incident-reports/edit/${incidentReport.id}`}
                      >
                        Edit
                      </Dropdown.Item>
                    )}

                  {hasPermission(permissions.COMMENT_INCIDENT_REPORT) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/incident-reports/add-comment/${incidentReport.id}`}
                    >
                      Comment
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
