import React from 'react'
import { RootCauseBarChart } from './statCharts/RootCauseBarChart'
import { BranchPieChart } from './statCharts/BranchPieChart'
import { Badge } from 'react-bootstrap';

export const StatsComponent = ({salaryComplaintIndexsData, }) => {
    let totalComplaints = 0;
    const statusStatistics = salaryComplaintIndexsData.statusStats
    let paidStatusComplaints = 0;
    

    
    for (let i = 0; i < statusStatistics.length; i++) {
        totalComplaints += Number(statusStatistics[i].number_of_complaints);
        
        if (statusStatistics[i].payment_status === "paid") {
            paidStatusComplaints = statusStatistics[i].number_of_complaints;
        }
    }

    const paidStatusComplaintsPercentage = Math.ceil((Number(paidStatusComplaints) / totalComplaints) * 100) + `%`

    return (
        <div className="mb-5">
            <div className="row">
                <div className="col-sm-6">
                    <RootCauseBarChart
                        // style={{width: "541.4pageXOffset", display: "blockpageXOffset", boxSizing: "border-boxpageXOffset", height: "300pageXOffset"}}
                        // width="812"
                        // height="405"
                        rootCauseStats={salaryComplaintIndexsData.rootCauseStats}
                    />
                </div>

                <div className="col-sm-3">
                    <BranchPieChart
                        // style={{width: "263.2px", display: "block", boxSizing: "border-box", height: "263px"}}
                        // width="396"
                        // height="396"
                        branchStats={salaryComplaintIndexsData.branchStats}
                    />
                </div>

                <div className="col-sm-3 text-center">
                    <div className="row flex-column">
                        {!!statusStatistics?.length && (
                            <div className="col">
                                <Badge bg="success" className="fs-2 px-2 py-3">{paidStatusComplaintsPercentage} Paid</Badge>
                            </div>
                        )}

                        {/* <div className="col mt-3">
                            <Button variant="secondary" className="fs-3">Export <FiDownload className="ms-2" /> </Button>
                        </div> */}

                    </div>
                </div>
            </div>
        </div>
    )
}
