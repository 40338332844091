import React from "react";
import Pagination from "react-bootstrap/Pagination";
import unEscape from "../utils/unEscapeFunction";

const ReactPagination = ({ links, current_page, last_page, onPageChange }) => {
  return (
    <Pagination>
      {current_page > 1 && (
        <Pagination.Prev onClick={() => onPageChange(current_page - 1)}>
          Prev
        </Pagination.Prev>
      )}

      {links.slice(1, links.length - 1).map((link, index) => (
        <Pagination.Item
          key={index}
          active={link.active}
          onClick={() => onPageChange(link.label)}
        >
          {unEscape(link.label)}
        </Pagination.Item>
      ))}

      {current_page < last_page && (
        <Pagination.Item onClick={() => onPageChange(current_page + 1)}>
          Next
        </Pagination.Item>
      )}
    </Pagination>
  );
};

export default ReactPagination;
