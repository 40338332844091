import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import useAxios from "../../../hooks/useAxios";
import { Loading } from "../../../Components/Loading";
import { permissions } from "../../../utils/permissions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { usePermissionAndDomain } from "../../../hooks/usePermissionAndDomain";

const ViewVehiclePart = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { vehiclePartId } = useParams();
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [vehiclePartDetails, setVehiclePartDetails] = useState({});
  const [maintenanceItem, setMaintenanceItem] = useState([]);

  useEffect(() => {
    setFetching(true);
    const getVehiclePartDetail = async () => {
      try {
        const response = await get(`vehicle_parts/${vehiclePartId}`);
        setVehiclePartDetails(response.data.vehiclePart);
        setMaintenanceItem(response.data.vehiclePart.maintenance_item);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getVehiclePartDetail();
  }, []);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between mt-2 mb-5">
        <Card.Title>
          <Row>
            <Col>
              <h1>{vehiclePartDetails.name}</h1>
            </Col>
          </Row>
        </Card.Title>

        <Row className="ms-1">
          <Col className="mb-2">
            <Button onClick={() => navigate(-1)}>Back</Button>
          </Col>

          <Col className="me-2">
            <DropdownButton
              id="dropdown-basic-button"
              title="ACTION"
              className="justify-content-center"
            >
              {hasPermission(permissions.EDIT_VEHICLE_PART) && (
                <Dropdown.Item
                  as={Link}
                  size="sm"
                  className="justify-content-center"
                  to={`/vehicle-parts/edit/${vehiclePartId}`}
                >
                  Edit
                </Dropdown.Item>
              )}
            </DropdownButton>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body>
        {!!fetching && <Loading />}

        {!fetching && !!vehiclePartDetails?.id && !maintenanceItem?.length && (
          <div>No Maintenance Records found</div>
        )}

        {!fetching && !!vehiclePartDetails?.id && !!maintenanceItem?.length && (
          <div className="page-wrapper">
            <Table
              responsive
              hover
              bordered
              className=" table-vcenter table-mobile-md card-table mt-3"
            >
              <thead>
                <tr>
                  <th>VOUCHER NO.</th>
                  <th>DATE</th>
                  <th>VEHICLE</th>
                  <th>QUANTITY</th>
                  <th>VENDOR</th>
                  <th>REQUESTED (&#8358;)</th>
                </tr>
              </thead>

              <tbody>
                {maintenanceItem.map((maintenanceRecord) => (
                  <tr key={maintenanceRecord.id}>
                    <td data-label="VOUCHER NO.">
                      {maintenanceRecord?.maintenance_record
                        ?.request_voucher_number || "N/A"}
                    </td>

                    <td data-label="DATE">
                      {format(
                        new Date(maintenanceRecord?.created_at),
                        "d MMM yyyy ",
                        {
                          awareOfUnicodeTokens: true,
                        }
                      ) || "N/A"}
                    </td>

                    <td data-label="VEHICLE">
                      <div>
                        {maintenanceRecord?.maintenance_record?.vehicle
                          ?.reg_number || "N/A"}
                      </div>
                      <div>
                        {`${maintenanceRecord?.maintenance_record?.vehicle?.vehicle_model?.vehicle_make?.name} - ${maintenanceRecord?.maintenance_record?.vehicle?.vehicle_model?.name}` ||
                          "N/A"}
                      </div>
                    </td>
                    <td data-label="QUANTITY">
                      {maintenanceRecord?.quantity || "N/A"}
                    </td>
                    <td data-label="VENDOR">
                      {`${maintenanceRecord?.maintenance_record?.vendor?.name}` ||
                        "N/A"}
                    </td>
                    <td data-label="REQUESTED (&#8358;)">
                      {Number(
                        maintenanceRecord?.maintenance_record?.request_amount
                      ).toLocaleString() || "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ViewVehiclePart;
