import React, { useState } from "react";
import * as yup from "yup";
import Select from "react-select";
import { Navigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { LoadingButton } from "../LoadingButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import { Row, Col, Form, Badge, Button} from "react-bootstrap";
import { useClientData } from "../../hooks/useClientsData";

const schema = yup.object({
  client_id: yup.string().required("Client is required"),
  items: yup.array().of(
    yup.object({
      quantity: yup.string().required("Quantity is required"),
      kit_item_id: yup.string().required("Kit item is required"),
    })
  ),
  client_location_id: yup.string().required("Client location is required"),
});

export const GuardKitsRequestForm = ({
  guardKitItems,
  clients,
  editGuardKitsRequest,
}) => {
  const {
    register,
    setError,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      client_id: editGuardKitsRequest?.client_id || "",
      client_location_id: editGuardKitsRequest?.client_locations?.id || "",
      items: editGuardKitsRequest?.items || [],
    },
  });

  const { post } = useAxios();
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const {clientOptions,
    clientOptionsReady,
    clientLocationsOptions,
  } = useClientData({clients})


  const guardKitItemOptions = Object.keys(guardKitItems).map((key) => ({
    value: key,
    label: guardKitItems[key],
  }));

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const endpointPath = !editGuardKitsRequest?.id
        ? "uniformrequest/store"
        : `uniformrequest/${editGuardKitsRequest.id}/update`;

      await post(endpointPath, data);

      setIsSaved(true);

      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Guard kit request successfully saved!", null, 5000);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 422) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry, something went wrong and your request was unsuccessful!",
          null,
          5000
        );
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error.response.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to request for kit items!",
          null,
          5000
        );
      }
    }
  };

  return (
    <>
      {isSaved && (
        <Navigate
          to={
            !editGuardKitsRequest?.id
              ? "/guardkits-request"
              : `/guardkits-request/view/${editGuardKitsRequest?.id}`
          }
          replace={true}
        />
      )}

      {!!clientOptionsReady && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          {!editGuardKitsRequest?.id ? (
            <div className="clientDetail mt-4 mb-4 border">
              <Form.Label className="mt-2 p-2">CLIENT DETAILS</Form.Label>
              <Row className="d-flex justify-content-between ms-0 me-0 mb-2 p-2 ">
                <Col className="mb-3" md={6}>
                  <Form.Group controlId="client ">
                    <Form.Label className="required">NAME</Form.Label>
                    <Controller
                      name="client_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <Select
                            className="text-dark"
                            name={field.name}
                            ref={field.ref}
                            options={clientOptions}
                            onChange={(selectedClientOption) => {
                              setValue(
                                "client_id",
                                selectedClientOption.value,
                                {
                                  shouldValidate: true,
                                }
                              );
                              setValue("client_location_id", "", {
                                shouldValidate: true,
                              });
                              setLocationOptions(
                                clientLocationsOptions[
                                  selectedClientOption?.value
                                ] ?? []
                              );
                            }}
                            defaultValue={{
                              value: editGuardKitsRequest?.clients?.id || "",
                              label: editGuardKitsRequest?.clients?.name || "",
                            }}
                            placeholder="Select Client"
                          />
                        );
                      }}
                    />

                    {!!errors.client_id && (
                      <p>
                        <Badge bg="danger">{errors.client_id?.message}</Badge>
                      </p>
                    )}
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group controlId="client-location ">
                    <Form.Label className="required">LOCATION</Form.Label>
                    <Controller
                      name="client_location_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <Select
                            className="text-dark"
                            name={field.name}
                            ref={field.ref}
                            options={locationOptions}
                            onChange={(selectedClientLocationOption) => {
                              setValue(
                                "client_location_id",
                                selectedClientLocationOption.value,
                                {
                                  shouldValidate: true,
                                }
                              );
                            }}
                            defaultValue={{
                              value:
                                editGuardKitsRequest?.client_locations?.id ||
                                "",
                              label:
                                editGuardKitsRequest?.client_locations?.name ||
                                "",
                            }}
                            placeholder="Select client location"
                          />
                        );
                      }}
                    />
                    <Form.Text className="text-danger">
                      Please select a client to see location options.
                    </Form.Text>

                    {!!errors.client_location_id && (
                      <p>
                        <Badge bg="danger">
                          {errors.client_location_id?.message}
                        </Badge>
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="clientDetail mt-4 mb-4 border">
              <Form.Label className="mt-2 p-2">CLIENT DETAILS</Form.Label>
              <Row className="d-flex justify-content-between ms-0 me-0 mb-2 p-2 ">
                <Col className="mb-3" md={6}>
                  <Form.Group controlId="client ">
                    <Form.Label>NAME</Form.Label>
                    <Form.Text>{editGuardKitsRequest?.clients?.name}</Form.Text>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group controlId="client-location ">
                    <Form.Label>LOCATION</Form.Label>
                    <Form.Text>
                      {editGuardKitsRequest?.client_locations?.name}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          )}

          <div className="kitItemDetail mt-4 mb-4 border">
            <Form.Label className="mt-2 p-2">GUARD KIT ITEM DETAILS</Form.Label>
            {fields.map((field, index) => (
              <Row
                key={field.id}
                className="d-flex justify-content-between ms-0 me-0 mb-1 p-2 "
              >
                <Col className="kit-item mb-3" md={4}>
                  <Form.Group controlId="kitItem ">
                    <Form.Label className="required">
                      KIT ITEM (Select one below)
                    </Form.Label>
                    <Controller
                      key={index}
                      name={`items.${index}.kit_item_id`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <Select
                            name={field.name}
                            ref={field.ref}
                            options={guardKitItemOptions}
                            onChange={(selectedKitItemOption) => {
                              setValue(
                                `items.${index}.kit_item_id`,
                                selectedKitItemOption.value,
                                {
                                  shouldValidate: true,
                                }
                              );
                            }}
                            defaultValue={{
                              value:
                                editGuardKitsRequest?.items[index]?.guard_kit
                                  ?.id || "",
                              label:
                                editGuardKitsRequest?.items[index]?.guard_kit
                                  ?.name || "",
                            }}
                            placeholder="Select kit item"
                          />
                        );
                      }}
                    />

                    {!!errors.items?.[index]?.item_id && (
                      <p>
                        <Badge bg="danger">
                          {errors.items?.[index]?.item_id?.message}
                        </Badge>
                      </p>
                    )}
                  </Form.Group>
                </Col>

                <Col className="kit-item-quantity mb-3" md={4}>
                  <Form.Label className="required">QUANTITY</Form.Label>
                  <Form.Control
                    key={index}
                    type="number"
                    min={1}
                    placeholder="Enter quantity needed"
                    {...register(`items.${index}.quantity`)}
                    onChange={(event) => {
                      setValue(`items.${index}.quantity`, event.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  />

                  {!!errors.quantity && (
                    <p>
                      <Badge bg="danger">
                        {errors.items?.[index].quantity?.message}
                      </Badge>
                    </p>
                  )}
                </Col>

                <Col className="align-self-center mt-1 pt-2" md={4}>
                  <Button variant="danger" onClick={() => remove(index)}>
                    Remove
                  </Button>
                </Col>

                <hr className="mt-4" />
              </Row>
            ))}
          </div>

          <Row className=" more-witness-button mb-4">
            <Col>
              <Button className="me-3" type="button" onClick={() => append({})}>
                Add Kit Item
              </Button>
            </Col>
          </Row>

          <div className="submit col-md-12 text-center mb-3">
            {loading ? (
              <LoadingButton />
            ) : (
              <Button
                type="submit"
                variant="success"
                disabled={!!loading || !isDirty}
              >
                Submit
              </Button>
            )}
          </div>
        </Form>
      )}
    </>
  );
};
