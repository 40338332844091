import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useSearchParams } from "react-router-dom";
import { Loading } from "../../Components/Loading";
import { alertWarning } from "../../Components/CustomAlerts/AlertToast";
import { Col, Row } from "react-bootstrap";
import { LincenseExpirationTable } from "../../Components/Reminders/LincenseExpirationTable";
import { RoadWorthinessTable } from "../../Components/Reminders/RoadWorthinessTable";
import { ServiceReminderTable } from "../../Components/Reminders/ServiceReminderTable";
import { InsuranceEpirationTable } from "../../Components/Reminders/InsuranceEpirationTable";

const RemindersIndex = () => {
  const { get } = useAxios();
  const [fetching, setFetching] = useState(true);
  const [licenseData, setLicenseData] = useState([]);
  const [insuranceData, setInsuranceData] = useState([]);
  const [roadWorthinessData, setRoadWorthinessData] = useState([]);
  const [searchPageParams] = useSearchParams({});
  const [vehicleDueForSeviceData, setVehicleDueForSeviceData] = useState([]);

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;

  const getReminders = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;
      const response = await get(
        `reminder?sort=desc&page=${currentPage}&page_size=${pageSize}`
      );

      setLicenseData(response.data.license);
      setInsuranceData(response.data.insurance);
      setRoadWorthinessData(response.data.roadWorthiness);
      setVehicleDueForSeviceData(response.data.vehicleDueForService);
    } catch (error) {
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    setFetching(true);

    const notifications = async () => await getReminders();
    notifications();
  }, [currentPage]);

  return (
    <>
      {!!fetching ? (
        <Loading />
      ) : (
        <div className="container">
          <nav>
            <div
              className="nav nav-tabs justify-content-between"
              id="nav-tab"
              role="tablist"
            >
              <button
                className="nav-link active"
                id="nav-expiring-vehicle-licence-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-expiring-vehicle-licence"
                type="button"
                role="tab"
                aria-controls="nav-expiring-vehicle-licence"
                aria-selected="true"
              >
                <div className="card-body d-flex flex-column justify-content-between align-items-center">
                  <div className="row align-items-center">
                    <div className="col">
                      <div className=" text-dark">
                        <h1>{vehicleDueForSeviceData?.length}</h1>
                        <span className="card-text">Vehicles due service</span>
                      </div>
                    </div>
                  </div>
                </div>
              </button>

              <button
                className="nav-link"
                id="nav-expiring-road-worthiness-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-expiring-road-worthiness"
                type="button"
                role="tab"
                aria-controls="nav-expiring-road-worthiness"
                aria-selected="false"
              >
                <div className="card-body d-flex flex-column justify-content-between align-items-center">
                  <div className="row align-items-center">
                    <div className="col">
                      <div className=" text-dark">
                        <h1>{licenseData?.length}</h1>
                        <span className="card-text">
                          Expiring vehicle license
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </button>

              <button
                className="nav-link"
                id="nav-vehicle-service-reminder-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-vehicle-service-reminder"
                type="button"
                role="tab"
                aria-controls="nav-vehicle-service-reminder"
                aria-selected="false"
              >
                <div className="card-body d-flex flex-column justify-content-between align-items-center">
                  <div className="row align-items-center">
                    <div className="col">
                      <div className=" text-dark">
                        <h1>{roadWorthinessData?.length}</h1>
                        <span className="card-text">
                          Expiring road worthiness
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </button>

              <button
                className="nav-link"
                id="nav-expiring-vehicle-insurance-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-expiring-vehicle-insurance"
                type="button"
                role="tab"
                aria-controls="nav-expiring-vehicle-insurance"
                aria-selected="false"
              >
                <div className="card-body d-flex flex-column justify-content-between align-items-center">
                  <div className="row align-items-center">
                    <div className="col">
                      <div className=" text-dark">
                        <h1>{insuranceData?.length}</h1>
                        <span className="card-text">
                          Expiring vehicle insurance
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </nav>

          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade"
              id="nav-vehicle-service-reminder"
              role="tabpanel"
              aria-labelledby="nav-vehicle-service-reminder-tab"
            >
              <ServiceReminderTable
                vehicleDueForSeviceData={vehicleDueForSeviceData}
              />
            </div>

            <div
              className="tab-pane fade show active"
              id="nav-expiring-vehicle-licence"
              role="tabpanel"
              aria-labelledby="nav-expiring-vehicle-licence-tab"
            >
              <LincenseExpirationTable licenseData={licenseData} />
            </div>

            <div
              className="tab-pane fade"
              id="nav-expiring-road-worthiness"
              role="tabpanel"
              aria-labelledby="nav-expiring-road-worthiness-tab"
            >
              <RoadWorthinessTable roadWorthinessData={roadWorthinessData} />
            </div>

            <div
              className="tab-pane fade"
              id="nav-expiring-vehicle-insurance"
              role="tabpanel"
              aria-labelledby="nav-expiring-vehicle-insurance-tab"
            >
              <InsuranceEpirationTable insuranceData={insuranceData} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RemindersIndex;
