export const permissions = {
  ADD_RECRUITE: "add recruit",
  EDIT_RECRUIT: "edit recruit",
  VIEW_ANY_RECRUIT: "view any recruit",
  VERIFY_RECRUIT_INFORMATION: "verify recruit information",
  
  EDIT_EMPLOYEE: "edit employee",
  VIEW_ANY_EMPLOYEE: "view any employee",
  ASSIGN_EMPLOYEE_CODE: "assign employee code",
  UPLOAD_EMPLOYEE_DATA: "upload employee data",
  UPLOAD_EMPLOYEE_SIGNATURE: "upload employee signature",
  MANAGE_DATA_UPLOAD_FORMATS: "Manage data upload formats",
  SYNC_EMPLOYEES_INFORMATION: "sync employees information",

  VIEW_AUDIT_DOCUMENT: "view audit document",
  MANAGE_AUDIT_DOCUMENT_TYPE: "manage audit document type",
  UPLOAD_AUDIT_DOCUMENT: "upload audit document",
  DOWNLOAD_CLIENT_DOCUMENT_REQUEST: "download client document request",
  MANAGE_CLIENT_DOCUMENT_SETUPS: "manage client document setups",

  VIEW_TRAINING_TYPES: "view training types",
  MANAGE_TRAINING_TYPES: "manage training types",
  VIEW_BATCHES: "view training batches",
  MANAGE_BATCHES: "manage training batches",
  VIEW_GUARD_DETAILS: "view guard details",

  DELETE_INSURANCE_COMPANIES: "delete insurance_companies",
  FORCE_DELETE_INSURANCE_COMPANIES: "force_delete insurance_companies",
  CREATE_VENDOR: "create vendor",
  EDIT_VENDOR: "edit vendor",
  VIEW_VENDORS: "view vendors",
  DELETE_VENDOR: "delete vendor",
  FORCE_DELETE_VENDOR: "force_delete vendor",
  CREATE_VEHICLE_MAKES: "create vehicle_makes",
  EDIT_VEHICLE_MAKES: "edit vehicle_makes",
  VIEW_VEHICLE_MAKES: "view vehicle_makes",
  DELETE_VEHICLE_MAKES: "delete vehicle_makes",
  FORCE_DELETE_VEHICLE_MAKES: "force_delete vehicle_makes",
  CREATE_VEHICLE_MODELS: "create vehicle_models",
  EDIT_VEHICLE_MODELS: "edit vehicle_models",
  VIEW_VEHICLE_MODELS: "view vehicle_models",
  DELETE_VEHICLE_MODELS: "delete vehicle_models",
  FORCE_DELETE_VEHICLE_MODELS: "force_delete vehicle_models",
  CREATE_MAINTENANCE_RECORDS: "create maintenance records",
  EDIT_MAINTENANCE_RECORDS: "edit maintenance records",
  VIEW_MAINTENANCE_RECORDS: "view maintenance records",
  DELETE_MAINTENANCE_RECORDS: "delete maintenance records",
  FORCE_DELETE_MAINTENANCE_RECORDS: "force delete maintenance records",
  CREATE_ROLES: "create roles",
  EDIT_ROLES: "edit roles",
  VIEW_ROLES: "view roles",
  DELETE_ROLES: "delete roles",
  FORCE_DELETE_ROLES: "force_delete roles",
  APPROVE_MAINTENANCE_RECORDS: "approve maintenance records",
  INSPECT_MAINTENANCE_RECORDS: "inspect maintenance records",
  CREATE_TYRE_BRANDS: "create tyre_brands",
  EDIT_TYRE_BRANDS: "edit tyre_brands",
  VIEW_TYRE_BRANDS: "view tyre_brands",
  DELETE_TYRE_BRANDS: "delete tyre_brands",
  FORCE_DELETE_TYRE_BRANDS: "force delete tyre_brands",
  CREATE_VEHICLE_STATUS_PARAMETERS: "create vehicle_status_parameters",
  EDIT_VEHICLE_STATUS_PARAMETERS: "edit vehicle_status_parameters",
  VIEW_VEHICLE_STATUS_PARAMETERS: "view vehicle_status_parameters",
  DELETE_VEHICLE_STATUS_PARAMETERS: "delete vehicle_status_parameters",
  CREATE_VEHICLES_STATUS_REPORT: "create vehicles_status_reports",
  EDIT_VEHICLES_STATUS_REPORT: "edit vehicles_status_reports",
  VIEW_VEHICLES_STATUS_REPORT: "view vehicles_status_reports",
  DELETE_VEHICLES_STATUS_REPORT: "delete vehicles_status_reports",
  CREATE_VEHICLES_STATUS_COMPILATIONS: "create vehicles_status_compilations",
  EDIT_VEHICLES_STATUS_COMPILATIONS: "edit vehicles_status_compilations",
  VIEW_VEHICLES_STATUS_COMPILATIONS: "view vehicles_status_compilations",
  DELETE_VEHICLES_STATUS_COMPILATIONS: "delete vehicles_status_compilations",
  VIEW_REPORTS: "view reports",
  VIEW_DASHBOARD: "view dashboard",
  CREATE_USERS: "create users",
  EDIT_USERS: "edit users",
  VIEW_USERS: "view users",
  DELETE_USERS: "delete users",
  FORCE_DELETE_USERS: "force delete users",
  CREATE_VEHICLE: "create vehicle",
  EDIT_VEHICLE: "edit vehicle",
  VIEW_VEHICLES: "view vehicles",
  DELETE_VEHICLES: "delete vehicles",
  FORCE_DELETE_VEHICLES: "force delete vehicles",
  CREATE_VEHICLE_PART: "create vehicle part",
  EDIT_VEHICLE_PART: "edit vehicle part",
  VIEW_VEHICLE_PART: "view vehicle part",
  DELETE_VEHICLE_PART: "delete vehicle part",
  FORCE_DELETE_VEHICLE_PART: "force delete vehicle part",
  CREATE_INSURANCE_COMPANIES: "create insurance_companies",
  EDIT_INSURANCE_COMPANIES: "edit insurance_companies",
  VIEW_INSURANCE_COMPANIES: "view insurance_companies",
  ASSIGN_VEHICLE_TO_AN_ESCORT_SCHEDULE: "assign vehicle to an escort schedule",
  VIEW_DRIVERS: "view drivers",
  APPROVE_AN_ESCORT_SCHEDULE_NEED_REQUEST: "approve an escort schedule need request",
  EDIT_DRIVER_DOCUMENTS: "edit driver documents",
  ASSIGN_DRIVER_TO_AN_ESCORT_SCHEDULE: "assign driver to an escort schedule",
  VIEW_ANY_KIT_REQUEST: "view any kit request",
  MAKE_KIT_REQUEST: "make kit request",
  EDIT_KIT_REQUEST: "edit kit request",
  APPROVAL_OF_KIT_REQUEST: "approval of kit request",
  APPROVAL_OF_PURCHASE_ORDER: "approval of purchase order",
  DISBURSE_KIT_ITEMS: "disburse kit items",
  VIEW_ALL_GUARD_KIT_ITEMS: "view all guard kit items",
  VIEW_SINGLE_GUARD_KIT_ITEM: "view single guard kit item",
  CREATE_GUARD_KIT_ITEM: "create guard kit item",
  EDIT_GUARD_KIT_ITEM: "edit guard kit item",
  DELETE_GUARD_KIT_ITEM: "delete guard kit item",
  CREATE_PURCHASE_ORDER: "create purchase order",
  VIEW_ALL_PURCHASE_ORDERS: "view all purchase orders",
  VIEW_SINGLE_PURCHASE_ORDER: "view single purchase order",
  EDIT_PURCHASE_ORDER: "edit purchase order",
  CLOSE_PURCHASE_ORDER: "close purchase order",
  RECORD_PURCHASE_ORDER_SUPPLY: "record purchase order supply",

  VIEW_OPERATIVES: "view operatives",
  MANAGE_OPERATIVES_POSTING: "manage operative posting",
  VIEW_ALL_ESCORT_SCHEDULES: "view all escort schedules",
  VIEW_SINGLE_ESCORT_SCHEDULE: "view single escort schedule",
  CREATE_AN_ESCORT_SCHEDULE: "create an escort schedule",
  EDIT_AN_ESCORT_SCHEDULE: "edit an escort schedule",
  DELETE_AN_ESCORT_SCHEDULE: "delete an escort schedule",
  UPDTAE_AN_ESCORT_STATUS: "update an escort schedule status",
  ASSIGN_OFFICERS_TO_AN_ESCORT_SCHEDULE:
    "assign officers to an escort schedule",
  MAKE_REQUEST_FOR_ESCORT_SCHEDULE_NEEDS:
    "make request for escort schedule needs",

  VIEW_INCIDENT_REPORTS: "view incident reports",
  EDIT_INCIDENT_REPORT: "edit incident report",
  CREATE_INCIDENT_REPORT: "create incident report",
  COMMENT_INCIDENT_REPORT: "comment incident report",

  VIEW_BRANCH_WEEKLY_REPORTS: "view branch weekly reports",
  CREATE_BRANCH_WEEKLY_REPORT: "create branch weekly report",

  ADD_CLIENT: "add client",
  EDIT_CLIENT: "edit client",
  VIEW_ANY_CLIENT: "view any client",
  DELETE_CLIENT: "delete client",

  VIEW_SALARY_COMPLAINTS: "view salary complaints",
  EDIT_SALARY_COMPLAINT: "edit salary complaint",
  CREATE_SALARY_COMPLAINT: "create salary complaint",
  PROCESS_SALARY_COMPLAINT: "process salary complaint",
  DELETE_SALARY_COMPLAINT: "delete salary complaint",
};

export const domains = {
  STAFF: "staff",
  RECRUITMENT: "recruitment",
  AUDIT_DOCUMENT: "audit document",
  TRAINING: "training",
  LOGISTICS: "logistics",
  OPERATIONS: "operations",
  GENERAL: "general",
  MANAGEMENT_REPORT: "management report",
  ANALYTICAL_REPORTS: "analytical reports",
};

export const domainPermissions = {
  [domains.STAFF]: {
    [permissions.EDIT_EMPLOYEE]: true,
    [permissions.VIEW_ANY_EMPLOYEE]: true,
    [permissions.ASSIGN_EMPLOYEE_CODE]: true,
    [permissions.UPLOAD_EMPLOYEE_DATA]: true,
    [permissions.UPLOAD_EMPLOYEE_SIGNATURE]: true,
    [permissions.MANAGE_DATA_UPLOAD_FORMATS]: true,
    [permissions.VIEW_SALARY_COMPLAINTS]: true,
    [permissions.EDIT_SALARY_COMPLAINT]: true,
    [permissions.CREATE_SALARY_COMPLAINT]: true,
    [permissions.PROCESS_SALARY_COMPLAINT]: true,
    [permissions.DELETE_SALARY_COMPLAINT]: true,
    [permissions.SYNC_EMPLOYEES_INFORMATION]: true,
  },

  [domains.RECRUITMENT]: {
    [permissions.ADD_RECRUITE]: true,
    [permissions.EDIT_RECRUIT]: true,
    [permissions.VIEW_ANY_RECRUIT]: true,
    [permissions.VERIFY_RECRUIT_INFORMATION]: true,
  },

  [domains.AUDIT_DOCUMENT]: {
    [permissions.VIEW_AUDIT_DOCUMENT]: true,
    [permissions.MANAGE_AUDIT_DOCUMENT_TYPE]: true,
    [permissions.UPLOAD_AUDIT_DOCUMENT]: true,
    [permissions.DOWNLOAD_CLIENT_DOCUMENT_REQUEST]: true,
    [permissions.MANAGE_CLIENT_DOCUMENT_SETUPS]: true,
  },

  [domains.TRAINING]: {
    [permissions.VIEW_TRAINING_TYPES]: true,
    [permissions.MANAGE_TRAINING_TYPES]: true,
    [permissions.VIEW_BATCHES]: true,
    [permissions.MANAGE_BATCHES]: true,
    [permissions.VIEW_GUARD_DETAILS]: true,
  },

  [domains.LOGISTICS]: {
    [permissions.DELETE_INSURANCE_COMPANIES]: true,
    [permissions.FORCE_DELETE_INSURANCE_COMPANIES]: true,
    [permissions.CREATE_VENDOR]: true,
    [permissions.EDIT_VENDOR]: true,
    [permissions.VIEW_VENDORS]: true,
    [permissions.DELETE_VENDOR]: true,
    [permissions.FORCE_DELETE_VENDOR]: true,
    [permissions.CREATE_VEHICLE_MAKES]: true,
    [permissions.EDIT_VEHICLE_MAKES]: true,
    [permissions.VIEW_VEHICLE_MAKES]: true,
    [permissions.DELETE_VEHICLE_MAKES]: true,
    [permissions.FORCE_DELETE_VEHICLE_MAKES]: true,
    [permissions.CREATE_VEHICLE_MODELS]: true,
    [permissions.EDIT_VEHICLE_MODELS]: true,
    [permissions.VIEW_VEHICLE_MODELS]: true,
    [permissions.DELETE_VEHICLE_MODELS]: true,
    [permissions.FORCE_DELETE_VEHICLE_MODELS]: true,
    [permissions.CREATE_MAINTENANCE_RECORDS]: true,
    [permissions.EDIT_MAINTENANCE_RECORDS]: true,
    [permissions.VIEW_MAINTENANCE_RECORDS]: true,
    [permissions.DELETE_MAINTENANCE_RECORDS]: true,
    [permissions.FORCE_DELETE_MAINTENANCE_RECORDS]: true,
    [permissions.CREATE_ROLES]: true,
    [permissions.EDIT_ROLES]: true,
    [permissions.DELETE_ROLES]: true,
    [permissions.FORCE_DELETE_ROLES]: true,
    [permissions.APPROVE_MAINTENANCE_RECORDS]: true,
    [permissions.INSPECT_MAINTENANCE_RECORDS]: true,
    [permissions.CREATE_TYRE_BRANDS]: true,
    [permissions.EDIT_TYRE_BRANDS]: true,
    [permissions.VIEW_TYRE_BRANDS]: true,
    [permissions.DELETE_TYRE_BRANDS]: true,
    [permissions.FORCE_DELETE_TYRE_BRANDS]: true,
    [permissions.CREATE_VEHICLE_STATUS_PARAMETERS]: true,
    [permissions.EDIT_VEHICLE_STATUS_PARAMETERS]: true,
    [permissions.VIEW_VEHICLE_STATUS_PARAMETERS]: true,
    [permissions.DELETE_VEHICLE_STATUS_PARAMETERS]: true,
    [permissions.CREATE_VEHICLES_STATUS_REPORT]: true,
    [permissions.EDIT_VEHICLES_STATUS_REPORT]: true,
    [permissions.VIEW_VEHICLES_STATUS_REPORT]: true,
    [permissions.DELETE_VEHICLES_STATUS_REPORT]: true,
    [permissions.CREATE_VEHICLES_STATUS_COMPILATIONS]: true,
    [permissions.EDIT_VEHICLES_STATUS_COMPILATIONS]: true,
    [permissions.VIEW_VEHICLES_STATUS_COMPILATIONS]: true,
    [permissions.DELETE_VEHICLES_STATUS_COMPILATIONS]: true,
    [permissions.VIEW_REPORTS]: true,
    [permissions.VIEW_DASHBOARD]: true,
    [permissions.CREATE_USERS]: true,
    [permissions.EDIT_USERS]: true,
    [permissions.VIEW_USERS]: true,
    [permissions.DELETE_USERS]: true,
    [permissions.FORCE_DELETE_USERS]: true,
    [permissions.CREATE_VEHICLE]: true,
    [permissions.EDIT_VEHICLE]: true,
    [permissions.VIEW_VEHICLES]: true,
    [permissions.DELETE_VEHICLES]: true,
    [permissions.FORCE_DELETE_VEHICLES]: true,
    [permissions.CREATE_VEHICLE_PART]: true,
    [permissions.EDIT_VEHICLE_PART]: true,
    [permissions.VIEW_VEHICLE_PART]: true,
    [permissions.DELETE_VEHICLE_PART]: true,
    [permissions.FORCE_DELETE_VEHICLE_PART]: true,
    [permissions.CREATE_INSURANCE_COMPANIES]: true,
    [permissions.EDIT_INSURANCE_COMPANIES]: true,
    [permissions.VIEW_INSURANCE_COMPANIES]: true,
    [permissions.ASSIGN_VEHICLE_TO_AN_ESCORT_SCHEDULE]: true,
    [permissions.APPROVE_AN_ESCORT_SCHEDULE_NEED_REQUEST]: true,
    [permissions.VIEW_DRIVERS]: true,
    [permissions.EDIT_DRIVER_DOCUMENTS]: true,
    [permissions.ASSIGN_DRIVER_TO_AN_ESCORT_SCHEDULE]: true,
    [permissions.VIEW_ANY_KIT_REQUEST]: true,
    [permissions.MAKE_KIT_REQUEST]: true,
    [permissions.EDIT_KIT_REQUEST]: true,
    [permissions.APPROVAL_OF_KIT_REQUEST]: true,
    [permissions.DISBURSE_KIT_ITEMS]: true,
    [permissions.VIEW_ALL_GUARD_KIT_ITEMS]: true,
    [permissions.VIEW_SINGLE_GUARD_KIT_ITEM]: true,
    [permissions.CREATE_GUARD_KIT_ITEM]: true,
    [permissions.EDIT_GUARD_KIT_ITEM]: true,
    [permissions.DELETE_GUARD_KIT_ITEM]: true,
    [permissions.CREATE_PURCHASE_ORDER]: true,
    [permissions.VIEW_ALL_PURCHASE_ORDERS]: true,
    [permissions.VIEW_SINGLE_PURCHASE_ORDER]: true,
    [permissions.EDIT_PURCHASE_ORDER]: true,
    [permissions.APPROVAL_OF_PURCHASE_ORDER]: true,
    [permissions.CLOSE_PURCHASE_ORDER]: true,
    [permissions.RECORD_PURCHASE_ORDER_SUPPLY]: true,
  },

  [domains.OPERATIONS]: {
    [permissions.VIEW_OPERATIVES]: true,
    [permissions.MANAGE_OPERATIVES_POSTING]: true,
    [permissions.VIEW_ALL_ESCORT_SCHEDULES]: true,
    [permissions.VIEW_SINGLE_ESCORT_SCHEDULE]: true,
    [permissions.CREATE_AN_ESCORT_SCHEDULE]: true,
    [permissions.EDIT_AN_ESCORT_SCHEDULE]: true,
    [permissions.UPDTAE_AN_ESCORT_STATUS]: true,
    [permissions.ASSIGN_OFFICERS_TO_AN_ESCORT_SCHEDULE]: true,
    [permissions.MAKE_REQUEST_FOR_ESCORT_SCHEDULE_NEEDS]: true,
  },

  [domains.MANAGEMENT_REPORT]: {
    [permissions.VIEW_INCIDENT_REPORTS]: true,
    [permissions.EDIT_INCIDENT_REPORT]: true,
    [permissions.CREATE_INCIDENT_REPORT]: true,
    [permissions.COMMENT_INCIDENT_REPORT]: true,

    [permissions.VIEW_BRANCH_WEEKLY_REPORTS]: true,
    [permissions.CREATE_BRANCH_WEEKLY_REPORT]: true,
  },

  [domains.ANALYTICAL_REPORTS]: {},

  [domains.GENERAL]: {
    [permissions.ADD_CLIENT]: true,
    [permissions.EDIT_CLIENT]: true,
    [permissions.VIEW_ANY_CLIENT]: true,
    [permissions.DELETE_CLIENT]: true,
  },
};
