import React from "react";
import useAxios from "../../hooks/useAxios";
import { Card, Col, Nav, Row } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { useEffect, useState } from "react";
import { permissions } from "../../utils/permissions";
import { Link, useSearchParams } from "react-router-dom";
import ReactPagination from "../../Components/ReactPagination";
import { DisplayedReportTable } from "../../Components/IncidentReports/DisplayedReportTable";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { FaExclamationCircle } from "react-icons/fa";
import { IncidentFilterSearch } from "../../Components/IncidentReports/IncidentFilterSearch";
import { IncidentStats } from "./incidentStats";

const IncidentReportIndex = () => {
  const [fetching, setFetching] = useState(true);
  const { get } = useAxios();
  const { hasPermission } = usePermissionAndDomain();
  const [incidentReports, setIncidentReports] = useState({});
  const [types, setTypes] = useState([]);
  const [incidentReportData, setIncidentReportData] = useState({});
  const [searchPageParams, setSearchPageParams] = useSearchParams();
  const [isFilterSearchClicked, setIsFilterSearchClicked] = useState(true);

  let currentPage = searchPageParams.get("page");
  let pageSize = searchPageParams.get("page_size");
  let typeParams = searchPageParams.get("type") || "";
  let toDateParams = searchPageParams.get("to") || "";
  let fromDateParams = searchPageParams.get("from") || "";

  const getIncidentReports = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;
      const response = await get(
        `incidents?page=${currentPage}&page_size=${pageSize}&type=${typeParams}&from=${fromDateParams}&to=${toDateParams}&sort=desc`
      );

      setTypes(response.data.types);
      setIncidentReportData(response.data);
      setIncidentReports(response.data.data);
    } catch (error) {
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  const pageChangeHandler = (page) => {
    setSearchPageParams(
      {
        ...Object.fromEntries(searchPageParams),
        page,
      },
      { replace: true }
    );
  };

  useEffect(() => {
    setFetching(true);
    const initialiaseIncidentReports = async () =>
      await getIncidentReports();
    initialiaseIncidentReports();
  }, [
      currentPage,
      typeParams,
      toDateParams,
      fromDateParams
    ]);


  const handleFilterSelectSearchChange = (values) => {
    setSearchPageParams(
      {
        ...values,
        page: 1,
      },
      { replace: true }
    );
  };

  return (
    <>
      {!!fetching 
        ? <Loading />
      :
      <Card className="container d-flex justify-content-center ms-3">
        <Card.Header className="d-flex justify-content-between mt-2 mb-5">
          <Card.Title>
            <Row>
              <Col md="auto">
                <h2 className="page-title">Incident Reports</h2>
              </Col>
            </Row>
          </Card.Title>

          {hasPermission(permissions.CREATE_INCIDENT_REPORT) && (
            <Row md="auto">
              <Col md="auto" className="mb-2">
                <Link
                  to="/incident-reports/create-full-report"
                  className="btn btn-primary"
                >
                  Create Full Report
                </Link>
              </Col>
              <Col md="auto">
                <Link
                  to="/incident-reports/create-short-report"
                  className="btn btn-warning"
                >
                  Create Short Report
                </Link>
              </Col>
            </Row>
          )}
        </Card.Header>

        <Card.Body className="mt-4">
          <Row>
            <Nav className="fw-bolder fs-4 mb-3">
              <Nav.Item>
                <Nav.Link
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    setIsFilterSearchClicked(!isFilterSearchClicked);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="6" cy="10" r="2" />
                    <line x1="6" y1="4" x2="6" y2="8" />
                    <line x1="6" y1="12" x2="6" y2="20" />
                    <circle cx="12" cy="16" r="2" />
                    <line x1="12" y1="4" x2="12" y2="14" />
                    <line x1="12" y1="18" x2="12" y2="20" />
                    <circle cx="18" cy="7" r="2" />
                    <line x1="18" y1="4" x2="18" y2="5" />
                    <line x1="18" y1="9" x2="18" y2="20" />
                  </svg>
                  Filter
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Row>

          <Row className="mb-2">
            <Col>
              {isFilterSearchClicked && (
                <IncidentFilterSearch
                  onhandleFilteredSearchChange={(value) =>
                    handleFilterSelectSearchChange(value)
                  }
                  initialSearchFilterInputs={{
                    from: fromDateParams,
                    to: toDateParams,
                    type: typeParams,
                  }}
                  types={types}
                />
              )}
            </Col>
          </Row>

          {!!(
              typeParams ||
              toDateParams ||
              fromDateParams
            ) &&
              !incidentReports?.data?.length && (
                <div className="mt-4 p-2 border text-muted text-center">
                  <span>
                    <FaExclamationCircle size={19} />
                  </span>

                  <span className="fs-2 ms-2 ">
                    No Incident Report Found!
                  </span>
                </div>
            )}

            {((!!(
               typeParams ||
               toDateParams ||
               fromDateParams
            ) &&
              !!incidentReports?.data?.length) ||
              !!incidentReports?.data?.length) && (
              <>

                <div className="mb-5">
                  <IncidentStats incidentReportData={incidentReportData} />
                </div>

                <br />

                <p className="text-end text-muted">
                  Page {incidentReports?.current_page} of{" "}
                  {incidentReports?.last_page}
                </p>

                <Row>
                  <Col className="p-0">
                    <>
                    <DisplayedReportTable
                    incidentReports={incidentReports.data}
                  />

                  <br />

                  <ReactPagination
                    links={incidentReports.links}
                    onPageChange={pageChangeHandler}
                    current_page={incidentReports.current_page}
                    last_page={incidentReports.last_page}
                  />
                    </>
                  </Col>
                </Row>
              </>
            )}

          {/* {!!incidentReports?.data?.length && !fetching && (
            <Row>
              <Col className="p-0">
                <>
                  <DisplayedReportTable
                    incidentReports={incidentReports.data}
                  />

                  <br />

                  <ReactPagination
                    links={incidentReports.links}
                    onPageChange={pageChangeHandler}
                    current_page={incidentReports.current_page}
                    last_page={incidentReports.last_page}
                  />
                </>
              </Col>
            </Row>
          )} */}
        </Card.Body>
      </Card>
      }

    </>
  );
};

export default IncidentReportIndex;
