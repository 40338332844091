import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  ListGroup,
  Row,
  Button,
  Table,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { format } from "date-fns";
import { SERVER_URL } from "../../config";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { permissions } from "../../utils/permissions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";

const ViewClient = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { clientId } = useParams();
  const [client, setClient] = useState({});
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [clientLocations, setClientLocations] = useState({});

  useEffect(() => {
    setFetching(true);
    const getClientDetails = async () => {
      try {
        const response = await get(`clients/${clientId}`);
        setClient(response.data);
        setClientLocations(response.data.locations);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getClientDetails();
  }, []);

  return (
    <>
      {!!fetching && <Loading />}

      <Card>
        {!!client?.id && !fetching && (
          <>
            <Card.Header className="d-flex justify-content-between mt-2 mb-5">
              <Card.Title>
                <Row>
                  <Col>
                    <h1>Client Details</h1>
                  </Col>
                </Row>
              </Card.Title>

              <Row className="ms-2">
                <Col>
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </Col>

                <Col className="me-3">
                  <DropdownButton
                    id="dropdown-basic-button"
                    title="ACTION"
                    className="justify-content-center"
                  >
                    {hasPermission(permissions.EDIT_CLIENT) && (
                      <Dropdown.Item
                        as={Link}
                        size="sm"
                        className="justify-content-center"
                        to={`/clients/edit/${clientId}`}
                      >
                        Edit
                      </Dropdown.Item>
                    )}

                    {/* <Dropdown.Item
                    as={Link}
                    size="sm"
                    className="justify-content-center"
                    to={`#`}
                  >
                    Add Location
                  </Dropdown.Item> */}

                    {/* <Dropdown.Item
                    as={Link}
                    size="sm"
                    to={"#"}
                    className="justify-content-center"
                    onClick={() => onDeleteClick(client)}
                  >
                    Delete
                  </Dropdown.Item> */}
                  </DropdownButton>
                </Col>
              </Row>
            </Card.Header>

            <Card.Body className="mb-4">
              <ListGroup className="list-group-flush">
                <ListGroup.Item>
                  <label className="fw-bolder fs-3">NAME</label>
                  <Card.Text className="fs-3">{client.name || "N/A"}</Card.Text>
                </ListGroup.Item>

                <ListGroup.Item>
                  <label className="fw-bolder fs-3">EMAIL</label>
                  <Card.Text className="fs-3">
                    {client.email || "N/A"}
                  </Card.Text>
                </ListGroup.Item>

                <ListGroup.Item>
                  <label className="fw-bolder fs-3">TELEPHONE</label>
                  <Card.Text className="fs-3">
                    {client.mobile || "N/A"}
                  </Card.Text>
                </ListGroup.Item>

                <ListGroup.Item>
                  <label className="fw-bolder fs-3 ">LOCATION DETAILS</label>

                  <Row className="mt-4">
                    <Col className="mb-4" md={6}>
                      {" "}
                      <label className="fw-bold fs-3">
                        TOTAL NUMBER OF LOCATIONS
                      </label>
                      <Card.Text className="fs-3">
                        {clientLocations.length}
                      </Card.Text>
                    </Col>
                    <Col md={6}>
                      {" "}
                      <label className="fw-bold fs-3">
                        TOTAL NUMBER OF OPERATIVES
                      </label>
                      <Card.Text className="fs-3">
                        {client.numbers_of_operatives || "N/A"}
                      </Card.Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mb-3">
                      <Table
                        responsive
                        hover
                        bordered
                        className=" table-vcenter table-mobile-md card-table mt-3"
                      >
                        <thead>
                          <tr>
                            <th>NAME</th>
                            <th>SLA DOCUMENT</th>
                            <th>NUMBER OF GUARDS</th>
                            <th>LAST KIT ALLOCATION DATE</th>
                            <th>NEXT KIT ALLOCATION DATE</th>
                          </tr>
                        </thead>

                        <tbody>
                          {clientLocations.map((location) => (
                            <tr key={location.id}>
                              <td data-label="NAME">
                                {location.name || "N/A"}
                              </td>
                              <td data-label="SLA DOCUMENT">
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`${SERVER_URL}/sla/${location.sla_doc}`}
                                >
                                  {location.sla_doc || "N/A"}
                                </a>
                              </td>
                              <td data-label="NUMBER OF GUARDS">
                                {location.guards_num}
                              </td>
                              <td data-label="LAST KIT ALLOCATION DATE">
                                {location.last_kit_allocation_date || "N/A"}
                              </td>
                              <td data-label="LAST KIT ALLOCATION DATE">
                                {location.next_kit_allocation_date || "N/A"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>

            <Card.Footer>
              <ListGroup className="list-group-flush">
                <ListGroup.Item className="d-flex justify-content-end">
                  <Row>
                    <Col className="mb-3">
                      <label className="fw-bolder fs-3">CREATED</label>{" "}
                      <Card.Text className="fs-3">
                        {format(
                          new Date(client.created_at),
                          "EEE, MMM d, yyyy 'at' HH:mm:ss",
                          {
                            awareOfUnicodeTokens: true,
                          }
                        )}
                      </Card.Text>
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item className="d-flex justify-content-end">
                  <Row>
                    <Col>
                      <label className="fw-bolder fs-3">UPDATED</label>{" "}
                      <Card.Text className="fs-3">
                        {format(
                          new Date(client.created_at),
                          "EEE, MMM d, yyyy 'at' HH:mm:ss",
                          {
                            awareOfUnicodeTokens: true,
                          }
                        )}
                      </Card.Text>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Card.Footer>
          </>
        )}
      </Card>
    </>
  );
};

export default ViewClient;
