import React from "react";

const eventSubscription = (customEventName, listener) => {
  document.addEventListener(customEventName, listener);
};

const eventUnsubscription = (customEventName, listener) => {
  document.removeEventListener(customEventName, listener);
};

const handleCustomEvent = (customEventName, data) => {
  const myCustomEvent = new CustomEvent(customEventName, { detail: data });
  document.dispatchEvent(myCustomEvent);
};

export { eventSubscription, eventUnsubscription, handleCustomEvent };
