import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import ApprovalWithAdjustmentForm from "../../Components/GuardKitsRequest/approvalWithAdjustmentForm";

const ApprovalWithAdjustment = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  const [status, setStatus] = useState();
  let { guardKitsRequestId } = useParams();
  const [client, setClient] = useState({});
  const [kitItems, setKitItems] = useState({});
  const [fetching, setFetching] = useState(true);
  const [clientLocation, setClientLocation] = useState({});
  const [guardKitsRequest, setGuardKitsRequest] = useState({});

  useEffect(() => {
    const getDetailedGuardKitsRequest = async () => {
      try {
        const response = await get(`uniformrequest/${guardKitsRequestId}/edit`);
        setKitItems(response.data.uniformRequest.items);
        setStatus(response.data.uniformRequest.status);
        setClient(response.data.uniformRequest.clients);
        setGuardKitsRequest(response.data.uniformRequest);
        setClientLocation(response.data.uniformRequest.client_locations);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };

    getDetailedGuardKitsRequest();
  }, []);

  return (
    <>
      {!!fetching && <Loading />}
      {!fetching && !!guardKitsRequest?.id && (
        <Card className="container">
          <Card.Header className="d-flex justify-content-between mt-0 mb-5">
            <Card.Title>
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">
                    Approve Kits Request With Adjustment
                  </h1>
                </div>
              </div>
            </Card.Title>

            <div className="row">
              <div className="col-md-12">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </div>
            </div>
          </Card.Header>

          <Card.Body>
            <ApprovalWithAdjustmentForm
              client={client}
              status={status}
              kitItems={kitItems}
              clientLocation={clientLocation}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ApprovalWithAdjustment;
