import React, { useState, useContext } from "react";
import * as yup from "yup";
import { format } from "date-fns";
import Select from "react-select";
import { AuthContext } from "../../auth";
import useAxios from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "../LoadingButtons";
import "react-datepicker/dist/react-datepicker.css";
import TinyMCETextEditor from "../TinyMCETextEditor";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Form, Row, Col, Badge, Button } from "react-bootstrap";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object({
  client_id: yup.string().required("Client name is required"),
  destination: yup
    .string()
    .max(30)
    .matches(/^[aA-zZ\s]+$/, "Destination is required ")
    .required("Enter Destination"),
  escortDate: yup.string().nullable().required("Escort date is required"),
  escortTime: yup.string().required("Escort time is required"),
  escortNeeds: yup.string().max(5000),
  escortType: yup.string().required("Select an Escort type"),
  requestDate: yup.string().nullable().required("Request date is required"),
  requestMode: yup.string().required("Select the mode of request"),
  escortDays: yup.string().required("How many days is the escort?"),
  // vehicle_type: yup.string().required("What type of vehicle?"),
  officeBranch: yup.string().required("Select an office branch"),
  requesterName: yup.string().max(30).required("Requester's name is required"),
  requesterMobile: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Requester's telephone number is required"
    )
    .required("Requester's mobile number is required"),
  requesterEmail: yup
    .string()
    .email()
    .required("Requester's email is required"),
  pickupPoint: yup
    .string()
    .max(30)
    .matches(/^[aA-zZ\s]+$/, "Pickup point is required")
    .required("Pickup point is required"),
  email: yup.string().email(),
  password: yup.string().max(25),
  created_by: yup.string(),
});

export const EscortScheduleForm = ({
  clients,
  officeLocations,
  escortTypes,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    control,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      client_id: "",
      requestMode: "",
      escortType: "",
      requestDate: "",
      escortDate: "",
      escortTime: "",
      escortDays: "",
      // vehicle_type: "",
      destination: "",
      pickupPoint: "",
      escortNeeds: "",
      officeBranch: "",
      requesterName: "",
      requesterMobile: "",
      requesterEmail: "",
    },
  });

  const { post } = useAxios();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const today = new Date().toISOString().slice(0, 10);

  // const [isSaved, setIsSaved] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [hasServerError, setHasServerError] = useState(false);

  const [escortDate, setEscortDate] = useState(
    format(new Date(), "yyyy-MM-dd", { awareOfUnicodeTokens: true })
  );

  const [requestDate, setRequestDate] = useState(
    format(new Date(), "yyyy-MM-dd", { awareOfUnicodeTokens: true })
  );

  const [escortTime, setEscortTime] = useState(
    format(new Date(), "HH:mm:ss", { awareOfUnicodeTokens: true })
  );

  const clientOptions = Object.keys(clients).map((key) => ({
    value: key,
    label: clients[key],
  }));

  const officeLocationOptions = Object.keys(officeLocations).map((key) => ({
    value: key,
    label: officeLocations[key],
  }));

  const escortTypeOptions = Object.keys(escortTypes).map((key) => ({
    value: key,
    label: escortTypes[key],
  }));

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await post(`escorts/store`, {
        ...data,
      });

      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Escort schedule successfully saved!", null, 5000);

      navigate("/escort-schedules");
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 422) {
        const handleServerErrors = ({ errors, setHasServerError }) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setHasServerError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(hasServerError, setHasServerError);
        setLoading(false);
        return;
      }
      if (error?.response?.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to create escort schedule!",
          null,
          5000
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* {isSaved && <Navigate to="/escort-schedules" replace={true} />} */}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-center mb-4 ">
          {!!hasServerError && (
            <p className="justify-content-end mt-3 text-wrap">
              Something went wrong will processing your request. Please try
              again!
            </p>
          )}
        </div>

        <Row className="py-1">
          <Col className="client-name mb-3">
            <Form.Label className="required">CLIENT NAME</Form.Label>
            <Controller
              name="client_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  className="text-dark"
                  options={clientOptions}
                  onChange={(event) => {
                    setValue("client_id", event.value, {
                      shouldValidate: true,
                    });
                  }}
                  value={field.label}
                  placeholder="Select Client"
                />
              )}
            />
            {!!errors.client_id && (
              <p>
                <Badge bg="danger">{errors.client_id?.message}</Badge>
              </p>
            )}
          </Col>

          <Col className="request-mode mb-3" sm={4}>
            <Form.Label className="required">REQUEST MODE</Form.Label>
            <Form.Select {...register("requestMode")}>
              <option value="" disabled>
                Select Request Mode
              </option>
              <option value="SMS">SMS</option>
              <option value="Email">EMAIL</option>
            </Form.Select>

            {!!errors.requestMode && (
              <p>
                <Badge bg="danger">{errors.requestMode?.message}</Badge>
              </p>
            )}
          </Col>

          <Col className="request-date">
            <Form.Label className="required">REQUEST DATE</Form.Label>
            {/* <Controller
                control={control}
                name="requestDate"
                rules={{ required: true }}
                render={({ field }) => (
                  <DatePicker
                    field={field}
                    withPortal
                    peekNextMonth
                    showYearDropdown
                    showMonthDropdown
                    maxDate={new Date()}
                    dropdownMode="select"
                    selected={requestDate}
                    className="form-control"
                    isClearable
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    value={requestDate}
                    onChange={(date) => {
                      setRequestDate(date);
                    }}
                  />
                )}
              /> */}
            <Form.Control
              type="date"
              max={today}
              placeholder="Request Date"
              {...register("requestDate")}
              value={requestDate}
              onChange={(e) => setRequestDate(e.target.value)}
            />

            {!!errors.requestDate && (
              <p>
                <Badge bg="danger">{errors.requestDate?.message}</Badge>
              </p>
            )}
          </Col>
        </Row>

        <Row className="escort-details mt-4">
          <Col className="escort-date mb-3">
            <Form.Label className="required">ESCORT DATE</Form.Label>
            <Form.Control
              type="date"
              placeholder="Escort Date"
              {...register("escortDate")}
              value={escortDate}
              onChange={(e) => setEscortDate(e.target.value)}
            />

            {!!errors.escortDate && (
              <p>
                <Badge bg="danger">{errors.escortDate?.message}</Badge>
              </p>
            )}
          </Col>

          <Col className="escort-time mb-3" sm={4}>
            <Form.Label className="required">ESCORT TIME</Form.Label>
            <Form.Control
              type="time"
              placeholder="Escort Time"
              {...register("escortTime")}
              value={escortTime}
              onChange={(e) => setEscortTime(e.target.value)}
            />

            {!!errors.escortTime && (
              <p>
                <Badge bg="danger">{errors.escortTime?.message}</Badge>
              </p>
            )}
          </Col>

          <Col className="escort-type">
            <Form.Label className="required">ESCORT TYPE</Form.Label>
            <Controller
              name="escortType"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  className="text-dark"
                  options={escortTypeOptions}
                  onChange={(event) => {
                    setValue("escortType", event.value, {
                      shouldValidate: true,
                    });
                  }}
                  value={field.label}
                  placeholder="Select Escort Type"
                />
              )}
            />

            {!!errors.escortType && (
              <p>
                <Badge bg="danger">{errors.escortType?.message}</Badge>
              </p>
            )}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="pickup-point mb-3">
            <Form.Label className="required">PICKUP POINT</Form.Label>
            <Form.Control
              {...register("pickupPoint")}
              type="text"
              placeholder="Pickup Point"
            />

            {!!errors.pickupPoint && (
              <p>
                <Badge bg="danger">{errors.pickupPoint?.message}</Badge>
              </p>
            )}
          </Col>

          <Col className="destination mb-3" sm={4}>
            <Form.Label className="required">DESTINATION</Form.Label>
            <Form.Control
              type="text"
              placeholder="Destination"
              {...register("destination")}
            />

            {!!errors.destination && (
              <p>
                <Badge bg="danger">{errors.destination?.message}</Badge>
              </p>
            )}
          </Col>

          <Col className="office-location">
            <Form.Label className="required">BRANCH LOCATION</Form.Label>
            <Controller
              name="officeBranch"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  className="text-dark"
                  options={officeLocationOptions}
                  onChange={(event) => {
                    setValue("officeBranch", event.value, {
                      shouldValidate: true,
                    });
                  }}
                  value={field.label}
                  placeholder="Select Branch Location"
                />
              )}
            />
            {!!errors.officeBranch && (
              <p>
                <Badge bg="danger">{errors.officeBranch?.message}</Badge>
              </p>
            )}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="escort-days">
            <Form.Label className="required">ESCORT DAYS</Form.Label>
            <Form.Control
              type="number"
              min={1}
              placeholder="Select number of days "
              {...register("escortDays")}
            />

            {!!errors.escortDays && (
              <p>
                <Badge bg="danger">{errors.escortDays?.message}</Badge>
              </p>
            )}
          </Col>

          {/* <Col className="vehicle-type mt-3" md={6}>
            <Form.Label className="required">VEHICLE TYPE</Form.Label>
            <Form.Select {...register("vehicle_type")}>
              <option value="" disabled>
                Select VEHICLE TYPE
              </option>
              <option value="SMS">SMS</option>
              <option value="Email">EMAIL</option>
            </Form.Select>

            {!!errors.vehicle_type && (
              <p>
                <Badge bg="danger">{errors.vehicle_type?.message}</Badge>
              </p>
            )}
          </Col> */}
        </Row>

        <div className="escort-needs mt-4 mb-3">
          <Form.Label>ADDITIONAL ESCORT NEEDS</Form.Label>
          <Form.Text>
            Extra needs aside from the standard escort team requested for by the
            client and operatives carrying out the escort.
          </Form.Text>
          <Controller
            control={control}
            name="escortNeeds"
            render={({ field }) => (
              <TinyMCETextEditor field={field} setValue={setValue} />
            )}
          />

          {!!errors.escortNeeds && (
            <p>
              <Badge bg="danger">{errors.escortNeeds?.message}</Badge>
            </p>
          )}
        </div>

        <div className="mt-4 border pt-3 ps-2">
          <Form.Label>REQUESTER DETAILS</Form.Label>
          <Row className="ps-3 pe-3 p-3">
            <Col className="requester-name mt-3 mb-2" md={4}>
              <Form.Label className="required">NAME</Form.Label>
              <Form.Control
                type="text"
                placeholder="Requester's Fullname"
                {...register("requesterName")}
              />

              {!!errors.requesterName && (
                <p>
                  <Badge bg="danger">{errors.requesterName?.message}</Badge>
                </p>
              )}
            </Col>

            <Col className="requester-mobile mt-3 mb-2" md={4}>
              <Form.Label className="required">TELEPHONE</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Requester's Telephone Number"
                {...register("requesterMobile")}
              />

              {!!errors.requesterMobile && (
                <p>
                  <Badge bg="danger">{errors.requesterMobile?.message}</Badge>
                </p>
              )}
            </Col>

            <Col className="requester-email mt-3" md={4}>
              <Form.Label className="required">EMAIL </Form.Label>
              <Form.Control
                type="email"
                placeholder="Requester's Email"
                {...register("requesterEmail")}
              />

              {!!errors.requesterEmail && (
                <p>
                  <Badge bg="danger">{errors.requesterEmail?.message}</Badge>
                </p>
              )}
            </Col>
          </Row>
        </div>

        {/* <Row className="mt-5">
          <Col className="escort-needs mb-3">
            <Form.Label className="required">ESCORT NEEDS</Form.Label>
            <Form.Text>
              Extra needs aside from the standard escort team requested for by
              the client and operatives carrying out the escort.
            </Form.Text>
            <Form.Control
              as="textarea"
              rows={4}
              {...register("escortNeeds")}
              onChange={(event) => {
                setValue("escortNeeds", event.target.value, {
                  shouldValidate: true,
                });
              }}
            />

            {!!errors.escortNeeds && (
              <p>
                <Badge bg="danger">{errors.escortNeeds?.message}</Badge>
              </p>
            )}
          </Col>

          <Col className="requester-details" sm={6}>
            <Row>
              <Col className="requester-name mb-2">
                <Form.Label className="required"> REQUESTER NAME</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Requester's Fullname"
                  {...register("requesterName")}
                />

                {!!errors.requesterName && (
                  <p>
                    <Badge bg="danger">{errors.requesterName?.message}</Badge>
                  </p>
                )}
              </Col>

              <Col className="requester-mobile mt-3 mb-2" sm={12}>
                <Form.Label className="required">
                  REQUESTER TELEPHONE
                </Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Requester's Telephone Number"
                  {...register("requesterMobile")}
                />

                {!!errors.requesterMobile && (
                  <p>
                    <Badge bg="danger">{errors.requesterMobile?.message}</Badge>
                  </p>
                )}
              </Col>

              <Col className="requester-email mt-3" sm={12}>
                <Form.Label className="required">REQUESTER EMAIL </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Requester's Email"
                  {...register("requesterEmail")}
                />

                {!!errors.requesterEmail && (
                  <p>
                    <Badge bg="danger">{errors.requesterEmail?.message}</Badge>
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row> */}

        {!!auth.user?.isControlRoomOfficer && (
          <>
            <div className="dropdown-divider mt-5"></div>
            <footer className="creation-authorization mt-4 ps-0 pe-0">
              <Row>
                <Col className="email-employeeCode">
                  <Form.Label className="required">
                    EMPLOYEE EMAIL/CODE
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Employee email/code"
                    {...register("email")}
                  />

                  {!!errors.email && (
                    <p>
                      <Badge bg="danger">{errors.email?.message}</Badge>
                    </p>
                  )}
                </Col>

                <Col className="employee-password">
                  <Form.Label className="required">PASSWORD</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    {...register("password")}
                  />
                  {!!errors.password && (
                    <p>
                      <Badge bg="danger">{errors.password?.message}</Badge>
                    </p>
                  )}
                </Col>
              </Row>
            </footer>
          </>
        )}

        <div className="mt-3 position-relative ">
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              variant="success"
              type="submit"
              className="px-4 mt-3"
              disabled={!!loading || !isDirty}
            >
              Create Schedule
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};
