import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Card, Col, Row } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { Link, useSearchParams } from "react-router-dom";
import ReactPagination from "../../Components/ReactPagination";
import { alertWarning } from "../../Components/CustomAlerts/AlertToast";
import { DisplayedNotificationsConfigTable } from "../../Components/Notifications/DisplayedNotificationsConfigTable";

const NotificationsConfigIndex = () => {
  const { get } = useAxios();
  const [fetching, setFetching] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [searchPageParams, setSearchPageParams] = useSearchParams({});

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;

  const getNotifications = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;
      const response = await get(
        `admin_notification?sort=desc&page=${currentPage}&page_size=${pageSize}`
      );
      
      setNotifications(response.data.adminNotifications);
    } catch (error) {
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    setFetching(true);
    const notifications = async () => await getNotifications();
    notifications();
  }, [currentPage]);

  const pageChangeHandler = (page) => {
    setSearchPageParams(
      {
        ...Object.fromEntries(searchPageParams),
        page,
      },
      { replace: true }
    );
  };

  return (
    <>
      {!!fetching && <Loading />}

      <>
        <Card className="container d-flex justify-content-center ms-3">
          <Card.Header className="d-flex justify-content-between mt-2 mb-2">
            <Card.Title>
              <Row>
                <Col md="auto">
                  <h2 className="page-title">Notifications</h2>
                </Col>
              </Row>
            </Card.Title>

            <Row>
              <Col md="auto">
                <Link to="/notifications/create" className="btn btn-primary">
                  Create Notifications
                </Link>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            {!fetching && !!notifications?.data?.length === 0 && (
              <div> No Notifications Found</div>
            )}

            {!!notifications?.data?.length && !fetching && (
              <Row>
                <Col className="p-0">
                  <>
                    <DisplayedNotificationsConfigTable
                      notifications={notifications.data}
                    />

                    <br />

                    <ReactPagination
                      links={notifications.links}
                      onPageChange={pageChangeHandler}
                      current_page={notifications.current_page}
                      last_page={notifications.last_page}
                    />
                  </>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      </>
    </>
  );
};

export default NotificationsConfigIndex;
