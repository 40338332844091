import React, { useState } from "react";
import * as yup from "yup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Navigate} from "react-router-dom";
import { format, parseISO } from "date-fns";
import useAxios from "../../hooks/useAxios";
import { LoadingButton } from "../LoadingButtons";
import "react-datepicker/dist/react-datepicker.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Col, Row, Form, Badge, Button } from "react-bootstrap";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object({
  completed_date: yup.string().required("Date of completed required"),
  inspected_date: yup.string().required("Date of inspected required"),
  inspected_by_employee_code: yup.string().required("This field is required"),
  inspection_pass: yup.string().required("Please select one"),
  inspection_fail_comment: yup.string().max(500).when(["inspection_pass"], {
    is: "no",
    then: yup.string().required("Reason for inspection failure required"),
    otherwise: yup.string().optional().nullable(),
  }),
  warranty_expiry_date: yup.string().when(["inspection_pass"], {
    is: "yes",
    then: yup.string().required("Date of warranty expiration required"),
    otherwise: yup.string().optional().nullable(),
  }),
});

export const MaintenanceInspectionForm = ({
  inspectors,
  maintenanceRecord,
}) => {
  const {
    watch,
    control,
    register,
    setError,
    setValue,
    unregister,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      completed_date: "",
      inspected_date: "",
      inspection_pass: "",
      warranty_expiry_date: "",
      inspection_fail_comment: "",
      inspected_by_employee_code: "",
    },
  });

  const { post } = useAxios();
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inspecedDate, setInspectedDate] = useState(null);
  const [completedDate, setCompletedDate] = useState(null);
  const [warrantyExpirationDate, setWarrantyExpirationDate] = useState(null);

  const showCommentOrWarrantyExp = watch("inspection_pass");

  const inspectorOptions = Object.keys(inspectors).map((key) => ({
    value: key,
    label: inspectors[key],
  }));

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const inspectionData = {};

      if (data.inspection_pass === "no") {
        inspectionData.inspection_pass = false;
        inspectionData.inspection_fail_comment = data.inspection_fail_comment;
        inspectionData.inspected_by_employee_code =
          data.inspected_by_employee_code;
        inspectionData.completed_date = format(
          new Date(data.completed_date),
          "yyyy-MM-dd",
          {
            awareOfUnicodeTokens: true,
          }
        );
        inspectionData.inspected_date = format(
          new Date(data.inspected_date),
          "yyyy-MM-dd",
          {
            awareOfUnicodeTokens: true,
          }
        );
      } else {
        inspectionData.inspection_pass = true;
        inspectionData.inspected_by_employee_code =
          data.inspected_by_employee_code;
        inspectionData.completed_date = format(
          new Date(data.completed_date),
          "yyyy-MM-dd",
          {
            awareOfUnicodeTokens: true,
          }
        );
        inspectionData.inspected_date = format(
          new Date(data.inspected_date),
          "yyyy-MM-dd",
          {
            awareOfUnicodeTokens: true,
          }
        );
        inspectionData.warranty_expiry_date = format(
          new Date(data.warranty_expiry_date),
          "yyyy-MM-dd",
          {
            awareOfUnicodeTokens: true,
          }
        );
      }

      await post(
        `maintenance/${maintenanceRecord.id}/add_inspection`,
        inspectionData
      );

      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

      alertSuccess(
        "Maintenance record inspection successfully updated!",
        null,
        5000
      );
    } catch (error) {
      setLoading(false);
      if (error.response.status === 422) {
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error.response.status === 500) {
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      }
    }
  };

  return (
    <>
      {isSaved && (
        <Navigate
          to={`/maintenance-records/view/${maintenanceRecord.id}`}
          replace={true}
        />
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="m-4">
          <Col md={6} className="me-4">
            <Form.Group as={Row} className="mb-4">
              <Form.Label column sm="4" className="required">
                COMPLETED DATE
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="completed_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      isClearable
                      strictParsing
                      showYearDropdown
                      showMonthDropdown
                      autoComplete="off"
                      minDate={parseISO(maintenanceRecord.reported_date)}
                      maxDate={new Date()}
                      closeOnScroll={true}
                      dropdownMode="select"
                      selected={completedDate}
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      onChange={(date) => {
                        setCompletedDate(date);
                        setValue("completed_date", date);
                      }}
                      placeholderText="Enter completed date"
                    />
                  )}
                />
                <Form.Text>Date work was completed</Form.Text>
              </Col>

              {!!errors.completed_date && (
                <p>
                  <Badge className="mt-1" bg="danger">
                    {errors.completed_date?.message}
                  </Badge>
                </p>
              )}
            </Form.Group>

            <Form.Group as={Row} className="mb-4">
              <Form.Label column sm="4" className="required">
                INSPECTED DATE
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="inspected_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      isClearable
                      strictParsing
                      showYearDropdown
                      showMonthDropdown
                      autoComplete="off"
                      maxDate={new Date()}
                      minDate={parseISO(maintenanceRecord.reported_date)}
                      closeOnScroll={true}
                      dropdownMode="select"
                      selected={inspecedDate}
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      onChange={(date) => {
                        setInspectedDate(date);
                        setValue("inspected_date", date);
                      }}
                      placeholderText="Enter inspected date"
                    />
                  )}
                />
                <Form.Text>Date inspection was completed</Form.Text>
              </Col>

              {!!errors.inspected_date && (
                <p>
                  <Badge className="mt-1" bg="danger">
                    {errors.inspected_date?.message}
                  </Badge>
                </p>
              )}
            </Form.Group>

            <Form.Group as={Row} className="mb-4 me">
              <Form.Label column sm="4" className="required">
                INSPECTED BY
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="inspected_by_employee_code"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      name={field.name}
                      ref={field.ref}
                      className="text-dark"
                      options={inspectorOptions}
                      onChange={(inspectorOption) => {
                        setValue(
                          "inspected_by_employee_code",
                          inspectorOption.value,
                          {
                            shouldValidate: true,
                          }
                        );
                      }}
                      defaultValue={null}
                      placeholder={"Select inspector"}
                    />
                  )}
                />
              </Col>

              {!!errors.inspected_by_employee_code && (
                <p>
                  <Badge className="mt-1" bg="danger">
                    {errors.inspected_by_employee_code?.message}
                  </Badge>
                </p>
              )}
            </Form.Group>
          </Col>

          <Col>
            <Form.Group as={Row} className="mb-4">
              <Form.Label column sm="4" className="required">
                PASSED
              </Form.Label>
              <Col sm="8" className="mt-2">
                {["radio"].map((type) => (
                  <div key={`inline-${type}`}>
                    <Form.Check
                      inline
                      label="YES"
                      value="yes"
                      type={type}
                      id={`inline-${type}-YES`}
                      onClick={() => unregister("inspection_fail_comment")}
                      {...register("inspection_pass")}
                    />

                    <Form.Check
                      inline
                      label="NO"
                      value="no"
                      type={type}
                      id={`inline-${type}-NO`}
                      onClick={() => unregister("warranty_expiry_date")}
                      {...register("inspection_pass")}
                    />
                  </div>
                ))}
              </Col>

              {!!errors.inspection_pass && (
                <p>
                  <Badge className="mt-1" bg="danger">
                    {errors.inspection_pass?.message}
                  </Badge>
                </p>
              )}
            </Form.Group>

            {showCommentOrWarrantyExp === "no" && (
              <Form.Group as={Row} className="mb-4">
                <Form.Label column sm="4" className="required">
                  INSPECTION FAIL COMMENT
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Comment"
                    {...register("inspection_fail_comment")}
                    onChange={(event) => {
                      setValue("inspection_fail_comment", event.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  />
                </Col>

                {!!errors.inspection_fail_comment && (
                  <p>
                    <Badge className="mt-1" bg="danger">
                      {errors.inspection_fail_comment?.message}
                    </Badge>
                  </p>
                )}
              </Form.Group>
            )}

            {showCommentOrWarrantyExp === "yes" && (
              <Form.Group as={Row} className="mb-2">
                <Form.Label column sm="4" className="required">
                  WARRANTY EXPIRY DATE
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="warranty_expiry_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        isClearable
                        strictParsing
                        showYearDropdown
                        showMonthDropdown
                        autoComplete="off"
                        closeOnScroll={true}
                        dropdownMode="select"
                        minDate={parseISO(maintenanceRecord.reported_date)}
                        selected={warrantyExpirationDate}
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                        onChange={(date) => {
                          setWarrantyExpirationDate(date);
                          setValue("warranty_expiry_date", date);
                        }}
                        placeholderText="Enter warranty expiration date"
                      />
                    )}
                  />
                </Col>

                {!!errors.warranty_expiry_date && (
                  <p>
                    <Badge className="mt-1" bg="danger">
                      {errors.warranty_expiry_date?.message}
                    </Badge>
                  </p>
                )}
              </Form.Group>
            )}
          </Col>

          <div className="d-flex justify-content-center">
            {loading ? (
              <LoadingButton />
            ) : (
              <Button
                variant="success"
                type="submit"
                className=" d-flex justify-content-center mt-3"
                disabled={!!loading || !isDirty}
              >
                Submit
              </Button>
            )}
          </div>
        </Row>
      </Form>
    </>
  );
};
