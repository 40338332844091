import React, { createContext, useContext, useEffect, useState } from "react";
import { UserArea } from "./Layout/UserArea";
import { useLocation, Navigate } from "react-router-dom";
import { HorizontalLayout } from "./Layout/HorizontalLayout";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const auth = {
    user: {},
    assigned_document_type: [],
    userPermissions: {},
    userDomains: {},
    isAuthenticated: false,
    token: localStorage.getItem("access_token"),
    login: () => { },

    logout: () => {
      localStorage.removeItem("user");
      localStorage.removeItem("access_token");
      window.location.href = "/";
    },
  };

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const RequireAuth = ({ children }) => {
  let auth = useContext(AuthContext);
  let location = useLocation();
  let [checkingAuth, setCheckingAuth] = useState(true);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      auth.user = JSON.parse(user);
      auth.isAuthenticated = true;
    }

    const userDomains = localStorage.getItem("userDomains");
    if (userDomains) {
      auth.userDomains = JSON.parse(userDomains);
    }

    const userPermissions = localStorage.getItem("userPermissions");
    if (userPermissions) {
      auth.userPermissions = JSON.parse(userPermissions);
    }
    setCheckingAuth(false);
  }, [auth]);

  if (!checkingAuth && !auth.isAuthenticated) {
    return <Navigate to="/" state {...{ from: location }} replace />;
  }

  const TargetLayout =
    !!auth.user?.isControlRoomOfficer || !!auth.user?.isEscortCommander
      ? HorizontalLayout
      : UserArea;


  return !checkingAuth && <TargetLayout>{children}</TargetLayout>;
};

export { AuthProvider, AuthContext, RequireAuth };
