import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Col, Form, Row } from "react-bootstrap";
import weekRangeOptions from "../../utils/weekRangeOptions";

export const VehicleStatusReportFilterSearch = ({
  statusOptions,
  officeLocation,
  initialSearchFilterInputs,
  onhandleFilteredSearchChange,
}) => {
  const [searchFilterInputs, setSearchFilterInputs] = useState({
    status: initialSearchFilterInputs.status ?? "",
    branch: initialSearchFilterInputs.branch ?? "",
    weekNum: initialSearchFilterInputs.weekNum ?? "",
  });

  const [displayedWeek, setDisplayedWeek] = useState(null);
  const [displayedBranch, setDisplayedBranch] = useState(null);
  const [officeLocationOptions, setOfficeLocationOptions] = useState([]);
  const [filterWeekRangeOptions, setFilterWeekRangeOptions] = useState([]);

  useEffect(() => {
    const _officeLocationOptions = [];

    Object.keys(officeLocation).forEach((key) => {
      _officeLocationOptions.push({
        value: key,
        label: officeLocation[key],
      });

      if (searchFilterInputs.branch === key) {
        setDisplayedBranch({
          value: key,
          label: officeLocation[key],
        });
      }
    });

    const calculatedWeekRangeOptions = weekRangeOptions();

    const _filterWeekRangeOptions = [];
    calculatedWeekRangeOptions.map((currentWeekRange, index) => {
      _filterWeekRangeOptions.push({
        value: index + 1,
        label: currentWeekRange,
      });

      if (searchFilterInputs.weekNum === index + 1) {
        setDisplayedWeek({
          value: index + 1,
          label: currentWeekRange,
        });
      }
    });

    setOfficeLocationOptions(_officeLocationOptions);
    setFilterWeekRangeOptions(_filterWeekRangeOptions);
  }, []);

  return (
    <>
      <Form
        className="mt-0"
        method="GET"
        onSubmit={(event) => {
          event.preventDefault();
          onhandleFilteredSearchChange(searchFilterInputs);
        }}
      >
        <Row className="mb-3">
          <Col className="py-2 mx-1">
            <Form.Label>WEEK</Form.Label>
            <Select
              className="text-dark"
              name="weekNum"
              placeholder="Select week"
              onChange={(selectedWeekOption) => {
                setDisplayedWeek({
                  value: selectedWeekOption.value,
                  label: selectedWeekOption.label,
                });
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  weekNum: selectedWeekOption.value,
                });
              }}
              value={displayedWeek}
              options={filterWeekRangeOptions}
            />
          </Col>

          <Col className="py-2 mx-1" sm={4}>
            <Form.Label>STATUS</Form.Label>
            <Form.Select
              name="status"
              className="form-control"
              defaultValue={searchFilterInputs.status}
              onChange={(event) =>
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  status: event.target.value,
                })
              }
            >
              <option value="">Select Status</option>{" "}
              {statusOptions.map((statusIndex) => (
                <option value={statusIndex} key={statusIndex}>
                  {statusIndex}
                </option>
              ))}
            </Form.Select>
          </Col>

          <Col className="py-2 mx-1">
            <Form.Label>BRANCH</Form.Label>
            <Select
              className="text-dark"
              name="branch"
              placeholder="Select branch"
              onChange={(selectedBranchOption) => {
                setDisplayedBranch({
                  value: selectedBranchOption.value,
                  label: selectedBranchOption.label,
                });
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  branch: selectedBranchOption.value,
                });
              }}
              value={displayedBranch}
              options={officeLocationOptions}
            />
          </Col>
        </Row>

        <Button variant="secondary" className="me-3" type="submit">
          Filter
        </Button>
      </Form>
    </>
  );
};
