import React, { useState } from "react";
import * as yup from "yup";
import { format } from "date-fns";
import Select from "react-select";
import DatePicker from "react-datepicker";
import useAxios from "../../hooks/useAxios";
import { LoadingButton } from "../LoadingButtons";
import "react-datepicker/dist/react-datepicker.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { Navigate, useParams } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import { Row, Col, Form, Badge, Button, FormGroup } from "react-bootstrap";

const schema = yup.object({
  requestStatus: yup.string(),
  disbursed_by: yup.string().required("Who disbursed the kit items?"),
  received_by: yup.string().required("Who collected the kit items?"),
  disbursementDate: yup.string().nullable().required("Date of delivery required"),
  disbursementTime: yup.string().required("Time of delivery required"),
  items: yup.array().of(
    yup.object({
      item_id: yup.string(),
      disbursedQuantity: yup.string().required("Quantity is required"),
    })
  ),
});

const DisbursementRecordForm = ({
  status,
  kitRequestDetail,
  logisticsStaff,
  clientOperative,
}) => {
  const { post } = useAxios();
  let { guardKitsRequestId } = useParams();
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [itemsErrors, setItemsErrors] = useState([]);
  const [fullyDisbursedItems, setFullyDisbursedItems] = useState([]);
  const [partiallyDisbursedItems, setPartiallyDisbursedItems] = useState([]);

  const notFullyDisbursed = (fields) => {
    if (partiallyDisbursedItems.length) {
      return partiallyDisbursedItems;
    }

    const newPartiallyDisbursedItems = [...partiallyDisbursedItems];
    const newFullyDisbursedItems = [...fullyDisbursedItems];

    fields.forEach((field) => {
      const undisbursedQuantity =
        field?.approved_quantity - field?.quantity_disbursed;
      const isPartiallyDisbursed = undisbursedQuantity > 0;
      if (isPartiallyDisbursed) {
        newPartiallyDisbursedItems.push(field);
      } else {
        newFullyDisbursedItems.push(field);
      }
    });

    setPartiallyDisbursedItems(newPartiallyDisbursedItems);
    setFullyDisbursedItems(newFullyDisbursedItems);
    return newPartiallyDisbursedItems;
  };

  const {
    control,
    setValue,
    register,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    defaultValues: {
      mode: "onBlur",
      requestStatus: status || "",
      disbursed_by: "",
      received_by: "",
      disbursementDate: "",
      disbursementTime: "",
      items: notFullyDisbursed(kitRequestDetail?.items) || "",
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "items",
  });

  const disburserOptions = Object.keys(logisticsStaff).map((staff) => ({
    value: staff,
    label: logisticsStaff[staff],
  }));

  const collectorOptions = Object.keys(clientOperative).map((operative) => ({
    value: operative,
    label: clientOperative[operative],
  }));

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const itemsArray = data.items;
      const itemsData = itemsArray.map((itemProperty) => {
        return {
          item_id: itemProperty.kit_item_id,
          quantity: itemProperty.disbursedQuantity,
        };
      });

      fullyDisbursedItems.forEach((item) => {
        itemsData.push({
          item_id: item.guard_kit.id,
          quantity: 0,
        });
      });

      const disbursementData = {
        status: data.requestStatus,

        receive_by: data.received_by,

        disbursed_by: data.disbursed_by,

        date_created: format(new Date(data.disbursementDate), "yyyy-MM-dd", {
          awareOfUnicodeTokens: true,
        }),

        time_created: format(new Date(data.disbursementTime), "HH:mm:ss", {
          awareOfUnicodeTokens: true,
        }),

        items: itemsData,
      };

      await post(
        `uniformrequest/${guardKitsRequestId}/disburs`,
        disbursementData
      );

      setIsSaved(true);

      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Kit request successfully disbursed!", null, 5000);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 422) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry, something went wrong and your request was unsuccessful!",
          null,
          5000
        );
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error.response.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        const hasItemsError = !!error?.response?.data?.items_errors;

        const errorMessage = hasItemsError
          ? "Please correct the quantity entered for the indicated items"
          : "Items completed disbursed";

        setItemsErrors(error?.response?.data?.items_errors ?? []);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(errorMessage, null, 5000);
      }
    }
  };

  return (
    <>
      {isSaved && (
        <Navigate
          to={`/guardkits-request/view/${guardKitsRequestId}`}
          replace={true}
        />
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="delivery-details mt-4 mb-4 border">
          <Row className="delivery-date-time ms-0 me-0 p-2">
            <Col className="delivery-date mt-2 mb-3" md={6}>
              <FormGroup controlId="disbursementDate">
                <Form.Label className="required">DATE</Form.Label>
                <Controller
                  name="disbursementDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      isClearable
                      strictParsing
                      showYearDropdown
                      showMonthDropdown
                      maxDate={new Date()}
                      closeOnScroll={true}
                      dropdownMode="select"
                      selected={field.value}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      onChange={(date) => {
                        setValue("disbursementDate", date);
                      }}
                      placeholderText="Select disbursement date"
                    />
                  )}
                />

                {!!errors.disbursementDate && (
                  <p>
                    <Badge bg="danger">
                      {errors.disbursementDate?.message}
                    </Badge>
                  </p>
                )}
              </FormGroup>
            </Col>

            <Col className="delivery-time mt-2 mb-2" md={6}>
              <FormGroup controlId="disbursementTime">
                <Form.Label className="required">TIME</Form.Label>
                <Controller
                  name="disbursementTime"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      isClearable
                      showTimeSelect
                      timeIntervals={1}
                      timeCaption="Time"
                      showTimeSelectOnly
                      closeOnScroll={true}
                      dateFormat="HH:mm:ss"
                      selected={field.value}
                      className="form-control"
                      onChange={(date) => {
                        setValue("disbursementTime", date);
                      }}
                      placeholderText="Select disbursement time"
                    />
                  )}
                />

                {!!errors.disbursementTime && (
                  <p>
                    <Badge bg="danger">
                      {errors.disbursementTime?.message}
                    </Badge>
                  </p>
                )}
              </FormGroup>
            </Col>
          </Row>

          <div className="recipient-name ms-2 me-2 mb-2 p-2">
            <FormGroup controlId="recipientName">
              <Form.Label className="required">DISBURSER NAME</Form.Label>
              <Controller
                name="disbursed_by"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="text-dark"
                    options={disburserOptions}
                    onChange={(event) => {
                      setValue("disbursed_by", event.value, {
                        shouldValidate: true,
                      });
                    }}
                    value={field.label}
                    placeholder="Select who gave out kit items"
                  />
                )}
              />
              {!!errors.disbursed_by && (
                <p>
                  <Badge bg="danger">{errors.disbursed_by?.message}</Badge>
                </p>
              )}
            </FormGroup>
          </div>

          <div className="recipient-name ms-2 me-2 mb-2 p-2">
            <Form.Group controlId="recipientName">
              <Form.Label className="required">RECIPIENT NAME</Form.Label>
              <Controller
                name="received_by"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="text-dark"
                    options={collectorOptions}
                    onChange={(event) => {
                      setValue("received_by", event.value, {
                        shouldValidate: true,
                      });
                    }}
                    value={field.label}
                    placeholder="Select who collected kit items"
                  />
                )}
              />

              {!!errors.received_by && (
                <p>
                  <Badge bg="danger">{errors.received_by?.message}</Badge>
                </p>
              )}
            </Form.Group>
          </div>
        </div>

        {!!itemsErrors?.length && (
          <div className="alert alert-danger alert-important">
            {itemsErrors.map((error) => (
              <ul key={error}>
                <li>{error}</li>
              </ul>
            ))}
          </div>
        )}

        <div className="kitItem-details mt-4 mb-4 border">
          <Form.Label className="mt-2 p-2">GUARD KIT ITEM DETAILS</Form.Label>
          {fields.map((field, index) => (
            <Row
              key={field.id}
              className="d-flex justify-content-between ms-0 me-0 mb-1 p-2 "
            >
              <Col className="kit-item mb-3" md={8}>
                <Form.Group controlId="kitItem ">
                  <Form.Label>KIT ITEM</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name={`items.${index}.item_id`}
                    defaultValue={`${field?.guard_kit?.name}`}
                  />
                </Form.Group>
              </Col>

              <Col className="kit-item-quantity mb-3" md={4}>
                <Form.Label className="required">QUANTITY DISBURSED</Form.Label>
                <Form.Control
                  min={0}
                  key={index}
                  type="number"
                  max={field.approved_quantity - field.quantity_disbursed}
                  data-quantity-recieved-index={index}
                  placeholder="Enter quantity received"
                  {...register(`items.${index}.disbursedQuantity`)}
                  onChange={(event) => {
                    setValue(
                      `items.${index}.disbursedQuantity`,
                      event.target.value,
                      {
                        shouldValidate: true,
                      }
                    );
                  }}
                  defaultValue={0}
                />

                {!!errors.disbursedQuantity && (
                  <p>
                    <Badge bg="danger">
                      {errors.items?.[index].disbursedQuantity?.message}
                    </Badge>
                  </p>
                )}
              </Col>

              <hr className="mt-4" />
            </Row>
          ))}
        </div>
        <div className="submit col-md-12 text-center mb-3">
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              type="submit"
              variant="success"
              disabled={!!loading || !isDirty}
            >
              Submit
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default DisbursementRecordForm;
