import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../../Components/Loading";
import { PermissionForm } from "../../../Components/RolesPermissions/Permissions/PermissionForm";

const CreatePermissionsDomain = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  const [domains, setDomains] = useState([]);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    setFetching(true);
    const getPermissionsAndDomainsDetails = async () => {
      try {
        const response = await get(`permissions/create`);

        setDomains(response.data.domains);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getPermissionsAndDomainsDetails();
  }, []);

  return (
    <>
      {!!fetching && <Loading />}

      {!fetching && !!domains?.length && (
        <Card className="container">
          <Card.Header className="d-flex justify-content-between mt-0 mb-5">
            <Card.Title>
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">Create Permission</h1>
                </div>
              </div>
            </Card.Title>

            <div className="row">
              <div className="col-md-12">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <PermissionForm domains={domains} />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default CreatePermissionsDomain;
