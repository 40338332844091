import React from "react";
import { Form } from "react-bootstrap";
import colorCodedTypes from "./ColourCodedTypes";

const EscortTypeColourCode = () => {
  return (
    <div>
      <Form.Label>Escort types & colour codes </Form.Label>
      {Object.entries(colorCodedTypes).map(([key, value]) => (
        <div className="d-flex align-items-center mb-2" key={key}>
          <div
            className="d-flex align-items-center text-white me-2"
            style={{
              backgroundColor: value.color,
              height: "20px",
              padding: "5px",
            }}
          >
            {value.icon}
          </div>
          <span>{key}</span>
        </div>
      ))}

      {/* <div className="d-flex align-items-center mb-2">
        <div
          className="d-flex align-items-center text-white me-2"
          style={{ backgroundColor: "#1aa179", height: "20px", padding: "5px" }}
        >
          <AiFillCar />
        </div>
        <span>Pickup/Dropoff</span>
      </div>

      <div className="d-flex align-items-center mb-2">
        <div
          className="d-flex align-items-center text-white me-2"
          style={{ backgroundColor: "#087990", height: "20px", padding: "5px" }}
        >
          <BsFillPinMapFill />
        </div>
        <span>Pin down</span>
      </div>

      <div className="d-flex align-items-center mb-2">
        <div
          className="d-flex align-items-center text-white me-2"
          style={{ backgroundColor: "#052c65", height: "20px", padding: "5px" }}
        >
          <FaPlaneDeparture />
        </div>
        <span>Airport Dropoff</span>
      </div>

      <div className="d-flex align-items-center mb-2">
        <div
          className="d-flex align-items-center text-white me-2"
          style={{ backgroundColor: "#997404", height: "20px", padding: "5px" }}
        >
          <FaPlaneArrival />
        </div>
        <span>Airport Pickup</span>
      </div>

      <div className="d-flex align-items-center mb-2">
        <div
          className="d-flex align-items-center text-white me-2"
          style={{ backgroundColor: "#b02a37", height: "20px", padding: "5px" }}
        >
          <GiReceiveMoney />
        </div>
        <span>Trade Visit</span>
      </div> */}
    </div>
  );
};

export default EscortTypeColourCode;
