import React, { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Error404 } from "../Errors/Error404";
import { Loading } from "../../Components/Loading";
import { useGetIncidentViewData } from "../../hooks/useGetIncidentViewData";
import { useGetIncidentCreateData } from "../../hooks/useGetIncidentCreateData";
import { FullIncidentReportForm } from "../../Components/IncidentReports/FullIncidentReportForm";

const EditIncidentReport = () => {
  const navigate = useNavigate();

  const { clients, fetching, fetchIncidentClientsData } =
    useGetIncidentCreateData();

  const { incidentReport, fetchDetailedIncidentReport } =
    useGetIncidentViewData();

  useEffect(() => {
    const getClientsDetail = async () => {
      fetchIncidentClientsData();
    };

    getClientsDetail();

    const getDetailedIncidentReport = async () => {
      fetchDetailedIncidentReport();
    };
    getDetailedIncidentReport();
  }, []);

  return (
    <>
      {!fetching &&
        !!incidentReport?.position &&
        !!incidentReport?.incidentIntro &&
        !!incidentReport?.incidentFindings && <Error404 />}

      {fetching ? (
        <Loading />
      ) : (
        <Card className="container">
          <Card.Header className="d-flex justify-content-between mt-0 mb-5">
            <Card.Title>
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">Edit Incident Report</h1>
                </div>
              </div>
            </Card.Title>

            <div className="row">
              <div className="col-md-12">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <FullIncidentReportForm
              clients={clients}
              editIncidentReport={incidentReport}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default EditIncidentReport;
