import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { Loading } from '../Loading';
import { createPortal } from 'react-dom';
import useAxios from '../../hooks/useAxios';
import { IoCloseSharp } from "react-icons/io5";
import ReactPagination from '../ReactPagination';
import { IoSearchSharp, IoPerson } from "react-icons/io5";
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'

export const EmployeeSelector = ({
    affectedStaffPlaceholder, 
    defaultSelectedEmployee,
    onHandleAffectedEmployeeClick, 
}) => {
    const { get } = useAxios();
    const [fetching, setFetching] = useState(true);
    const [isClearable, setIsClearable] = useState(true);
    const [clientOptions, setClientOptions] = useState([]);
    const [officeLocations, setOfficeLocations] = useState({});
    const [locationOptions, setLocationOptions] = useState([]);
    const [clientLocationsOptions, setClientLocationsOptions] = useState({});
    
    const [searchText, setSearchText] = useState("")
    const [selectedBranch, setSelectedBranch] = useState("")
    const [selectedClient, setSelectedClient] = useState("");
    const [selectedClientLocation, setSelectedClientLocation] = useState("");
    const [selectedClientLocationOption, setSelectedLocationClientOption] =
    useState(null);


    const [selectedEmployee, setSelectedEmployee] = useState(defaultSelectedEmployee ?? {})
    
    const [filteredEmployeeData, setFilteredEmployeeData] = useState({});

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const getFilteredStaffs = async (currentPage=1) => {
        setFetching(true);
        try {
          const response = await get(
            `human_resources/staffs?sort=desc&page=${currentPage}&page_size=${10}&client=${selectedClient}&branch=${selectedBranch}&search_key=${searchText}&department=&client_location=${selectedClientLocation}`
          );
          setFilteredEmployeeData(response.data.userData);
        } catch (error) {
          setFetching(false);
        } finally {
          setFetching(false);
        }
    };
    
    const getStaffFiltrationData = async () => {
        setFetching(true);
        try {
            const response = await get(`human_resources/staffs/create`);
            setOfficeLocations(response.data.branches);

            const _clientOptions = [];
            const clientLocations = {};

            response.data.clients.forEach((client) => {
            const currentClientLocationOptions = [];

            _clientOptions.push({
                value: client.id,
                label: client.name,
            });


            client.locations.forEach((location) => {
                currentClientLocationOptions.push({
                value: location.id,
                label: location.name,
                });

            });

            clientLocations[client.id] = currentClientLocationOptions;
            });

            setClientOptions(_clientOptions);
            setClientLocationsOptions(clientLocations);

        } catch (error) {
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        getStaffFiltrationData();
    
    }, [])


  return (
    <>
       <div 
            className="form-control d-flex justify-content-between align-items-center" 
            style={{cursor: "pointer"}}  
            onClick={handleShow}
        >
            {
                !!selectedEmployee?.id ? (
                    <>
                        <div>
                           { `${selectedEmployee?.first_name} ${selectedEmployee?.surname}`}
                        </div>

                        <button type="button" style={{border: 0, textAlign: "center", background: "#216ba5", borderRadius: "50%"}} onClick={(event) => {
                            event.stopPropagation()
                            setSelectedEmployee({})
                            setSearchText("")
                            setSelectedBranch("")
                            setSelectedClient("")
                            setSelectedClientLocation("")
                            setFilteredEmployeeData({})
                            onHandleAffectedEmployeeClick({});
                        }}>
                            <IoCloseSharp size={14}  style = {{color:"#fff"}} />
                        </button>


                    </>
                ) :
                (
                    <>
                        <div style={{color: "#a5a9b1", fontSize: "1rem"}}>{affectedStaffPlaceholder}</div>

                        <IoSearchSharp size={20} style = {{color:"#216ba5"}} />

                    </>
                )
            }
            
        </div>

        
        {createPortal(
            <Modal  show={show}
                size="lg"
                centered
                backdrop="static"
                keyboard={false}
                onHide={handleClose}
                className="modal modal-blur fade" 
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                <Modal.Title>EMPLOYEE</Modal.Title>
                </Modal.Header>
        
                <Modal.Body>
                    <Row className="mb-1">
                        <Col className="py-2 mx-1 ">
                            <Form.Control
                                name="employee"
                                type="text"
                                onChange={(event) => setSearchText(event.target.value)}
                                autoComplete="off"
                                defaultValue={searchText}
                                placeholder="Employee name/code"
                            />
                        </Col>

                        <Col className="py-2 mx-1">
                            <Select
                                className="text-dark"
                                name="client"
                                isClearable={isClearable}
                                placeholder="Select Client"
                                defaultValue={
                                !!selectedClient?.id && {
                                    label: selectedClient?.name,
                                    value: selectedClient?.id,
                                }
                                }
                                onChange={(option) => {
                                    if (!!option?.value) {
                                        setSelectedClient(option.value);
                                        
                                        setLocationOptions(clientLocationsOptions[option.value]);
                                    } else {
                                        setLocationOptions({});
                                    }
                                }}
                                options={clientOptions}
                            />
                        </Col>

                        <Col className="py-2 mx-1">
                            <Select
                                className="text-dark"
                                name="client_location"
                                placeholder="Select Location"
                                isClearable={isClearable}
                                defaultValue={
                                !!selectedClientLocationOption?.id && {
                                    label: selectedClientLocationOption?.name,
                                    value: selectedClientLocationOption?.id,
                                }
                                }
                                onChange={(option) => {
                                if (!!option?.value) {
                                    setSelectedClientLocation(option.value);
                                } else {
                                    setLocationOptions({});
                                }
                                }}
                                options={locationOptions}
                            />

                        </Col>

                        <Col className="py-2 mx-1">
                            <select
                                name="branch"
                                className="form-control"
                                value={selectedBranch}
                                onChange={(event) => setSelectedBranch(event.target.value)}
                            >
                                <option value="">Select Branch</option>{" "}
                                {Object.entries(officeLocations).map(([branch, id]) => (
                                <option value={id} key={id}>
                                    {" "}
                                    {branch}
                                </option>
                                ))}
                            </select>
                        </Col>
                    </Row>

                    <div>
                    <Form.Text className="text-danger">
                        Please select a client to see location options.
                    </Form.Text>
                    </div>

                    <Button variant="primary" className="mt-3 me-3" type="button" onClick={() => getFilteredStaffs()}>
                        <span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="10" cy="10" r="7" />
                            <line x1="21" y1="21" x2="15" y2="15" />
                        </svg>
                        </span>
                        Search
                    </Button>

                    {!!fetching && (<Loading />)}

                    {!fetching && !!filteredEmployeeData?.data?.length && (
                        <>
                            <p className="text-end text-muted">
                                Result {filteredEmployeeData.to} of {filteredEmployeeData.total}
                            </p>

                            {filteredEmployeeData.data.map((employeeData) => (
                                <Card key={employeeData.id} className="mb-2" >
                                    <Card.Body 
                                        className="d-flex align-items-center" 
                                        style={{cursor: "pointer"}} 
                                        onClick={() => {
                                            setSelectedEmployee(employeeData)
                                            onHandleAffectedEmployeeClick(employeeData);
                                            handleClose()
                                        }}
                                    >
                                        <IoPerson  className="me-2"/> 
                                        <p className="mb-0 me-4">{`${employeeData?.first_name} ${employeeData?.surname} (${employeeData?.employee_code})`}</p>
                                    </Card.Body>
                                </Card>
                            ))}

                            <br />

                            <ReactPagination
                                links={filteredEmployeeData.links}
                                onPageChange={(page) => {
                                    getFilteredStaffs(page)

                                }}
                                current_page={filteredEmployeeData.current_page}
                                last_page={filteredEmployeeData.last_page}
                            />

                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>,
            document.body

        )}
        
    </>
  )
}
