import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { VendorForm } from "../../Components/Vendors/VendorForm";

const CreateVendor = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  const [banks, setBanks] = useState({});
  const [fetching, setFetching] = useState(true);
  const [specialities, setSpecialities] = useState([]);

  useEffect(() => {
    setFetching(true);
    const getBankAndSpeciality = async () => {
      try {
        const response = await get(`vendors/create`);
        setBanks(response.data.banks);
        setSpecialities(response.data.speciality);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getBankAndSpeciality();
  }, []);

  return (
    <>
      {fetching ? (
        <Loading />
      ) : (
        <Card className="container">
          <Card.Header className="d-flex justify-content-between mt-0 mb-5">
            <Card.Title>
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">Create Vendor</h1>
                </div>
              </div>
            </Card.Title>

            <div className="row">
              <div className="col-md-12">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <VendorForm bankNames={banks} vendorSpecialities={specialities} />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default CreateVendor;
