import React from 'react'
import { Badge } from 'react-bootstrap';

export const statusBadge = (updatedStatus) => {
    if (updatedStatus === "pending") {
        return (
          <Badge bg="secondary" className="fs-5 mt-2 px-2 text-capitalize">
            {updatedStatus }
          </Badge>
        )
      } else if (updatedStatus === "paid") {
        return (
          <Badge bg="success" className="fs-5 mt-2 px-2 text-capitalize">
            {updatedStatus }
          </Badge>
        )
      } else return (
          <Badge bg="danger" className="fs-5 mt-2 px-2 text-capitalize">
            {updatedStatus }
          </Badge>
        );
}
