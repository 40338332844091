import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Autocolors from 'chartjs-plugin-autocolors';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';


ChartJS.register(
  Title,
  Tooltip,
  Legend,
  Autocolors,
  BarElement,
  LinearScale,
  CategoryScale,
  DataLabelsPlugin
);

export const RootCauseBarChart = ({ rootCauseStats }) => {
  let labels = [];
  let myDataSet = [];

  rootCauseStats.map((rootCause) => {
    labels.push(
      `${rootCause?.root_cause_category}`
    );
  });

  rootCauseStats.map((data) => {
    myDataSet.push(`${Number(Math.round(data.number_of_complaints))}`);
  });


  const options = {
    indexAxis: "y",
    scales: {
      y: {
        grid: {
          display: false,
        },
        border: {
          color: 'rgba(255, 99, 132, 1'
        },
        ticks: {
          autoSkipPadding: 5,
          font: {
            size: 11
          }
        },
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          stepSize: 2, 
        },
      }
    },
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Root Causes",
        padding: {
          bottom: 1
        },
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 15,
          boxHeight: 8,
          font: {
            weight: 'lighter',
            size: 11
          }
        }
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        offset: 2,
        color: 'blue',
        textAlign: "end",
        labels: {
          title: {
            font: {
              weight: 'lighter',
              size: 11
            }
          },
        }
      },
      autocolors: {
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        mode: 'color'
      }
    },
  };


  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Number of Complaints",
        data: labels.map((myLabel, index) => myDataSet[index]),
        fill: false,
        minBarLength: 2,
        barThickness: 16,
        maxBarThickness: 20,
        barPercentage: 0.35,
        categoryPercentage: 1,
      },
    ],
  };


  return <Bar data={chartData} options={options} />;
};
