import React, { useEffect, useState } from "react";
import * as yup from "yup";
import Select from "react-select";
import useAxios from "../../hooks/useAxios";
import { LoadingButton } from "../LoadingButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Navigate} from "react-router-dom";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import { Form, Card, Badge, Button } from "react-bootstrap";

const schema = yup.object({
  users: yup.array().of(yup.string()).min(1, "Recipient is required"),
});

export const RecipientManagementForm = ({
  hqStaffs,
  notificationDetails,
  configuredRecipients,
}) => {
  const {
    control,
    setValue,
    setError,
    handleSubmit,
    formState: { errors},
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      users: [],
    },
  });

  const { post } = useAxios();
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [hqStaffOptions, setHqStaffOptions] = useState([]);
  const [defaultRecipients, setDefaultRecipients] = useState([]);
  const [isHqStaffOptionsReady, setIsHqStaffOptionsReady] = useState(false);

  useEffect(() => {
    const recipientsOptions = configuredRecipients.map((staff) => ({
      value: staff.id,
      label: `${staff.first_name} ${staff.surname}`.toLocaleUpperCase(),
    }));

    setDefaultRecipients(recipientsOptions);

    setHqStaffOptions(
      Object.keys(hqStaffs).map((staff) => ({
        value: staff,
        label: hqStaffs[staff],
      }))
    );

    setIsHqStaffOptionsReady(true);
  }, []);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await post(
        `admin_notification/${notificationDetails.id}/add_admin`,
        data
      );
      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Notification recipients successfully saved!", null, 5000);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 422) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry, something went wrong and your request was unsuccessful!",
          null,
          5000
        );
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error.response.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to set notifiction recipients!",
          null,
          5000
        );
      }
    }
  };

  return (
    <>
      {isSaved && (
        <Navigate
          to={`/notifications/view/${notificationDetails.id}`}
          replace={true}
        />
      )}

      {!!isHqStaffOptionsReady && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card className="">
            <Card.Body>
              <div className="mb-3">
                <h4 className="border-bottom">NOTIFICATION TITLE</h4>
                {notificationDetails.title}
              </div>

              <Form.Group
                className="mt-3 mb-3"
                controlId="notificationRecipients "
              >
                <Form.Label className="required">RECIPIENT</Form.Label>
                <Controller
                  name="users"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <Select
                        isMulti
                        name={field.name}
                        ref={field.ref}
                        options={hqStaffOptions}
                        onChange={(option) => {
                          const hqStaffValues = option.map(
                            (staffValue) => staffValue.value
                          );

                          setValue("users", hqStaffValues, {
                            shouldValidate: true,
                          });
                        }}
                        defaultValue={defaultRecipients}
                        placeholder="Select Recipient"
                      />
                    );
                  }}
                />

                {!!errors.users && (
                  <p>
                    <Badge bg="danger">{errors.users?.message}</Badge>
                  </p>
                )}
              </Form.Group>

              <div className="submit col-md-12 text-center mb-3">
                {loading ? (
                  <LoadingButton />
                ) : (
                  <Button
                    type="submit"
                    variant="success"
                    //   disabled={!isDirty || Object.keys(errors).length}
                    //   (!!loading) || (!isDirty && Object.keys(errors).length)
                  >
                    Submit
                  </Button>
                )}
              </div>
            </Card.Body>
          </Card>
        </Form>
      )}
    </>
  );
};
