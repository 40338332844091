import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationCreationForm } from "../../Components/Notifications/NotificationCreationForm";

const EditNotification = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { notificationId } = useParams();
  const [fetching, setFetching] = useState(true);
  const [notification, setNotification] = useState({});
  const [notificationDetails, setNotificationDetails] = useState({});
  const [notificationClass, setNotificationClass] = useState([]);

  useEffect(() => {
    setFetching(true);

    const getEditDetails = async () => {
      try {
        const response = await get(`admin_notification/${notificationId}/edit`);
        setNotification(response.data);
        setNotificationClass(response.data.classNames);
        setNotificationDetails(response.data.adminNotifications);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getEditDetails();
  }, []);

  return (
    <>
      <Card className="container">
        <Card.Header className="d-flex justify-content-between mt-0 mb-5">
          <Card.Title>
            <div className="row">
              <div className="col-md-12">
                <h1 className="page-title">Edit Notification</h1>
              </div>
            </div>
          </Card.Title>

          <div className="row">
            <div className="col-md-12">
              <Button onClick={() => navigate(-1)}>Back</Button>
            </div>
          </div>
        </Card.Header>

        <Card.Body>
          {!!fetching && <Loading />}

          {!fetching && !!notification?.adminNotifications?.id && (
            <NotificationCreationForm
              editNotification={notificationDetails}
              notificationClass={notificationClass}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default EditNotification;
