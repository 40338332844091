import React from 'react'
import { getYear } from "date-fns";

const CopyRight = () => {
  const currentYear = getYear(new Date());

  return (
    <>Copyright &copy; {currentYear}{" "}</>
  )
}

export default CopyRight