import React from "react";
import { Link } from "react-router-dom";
import { permissions } from "../../utils/permissions";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";

export const DisplayedClientsTable = ({ clients }) => {
  const { hasPermission } = usePermissionAndDomain();

  return (
    <>
      <Table
        hover
        bordered
        responsive
        className=" table-vcenter table-mobile-md card-table"
      >
        <thead>
          <tr>
            <th>NAME</th>
            <th>LOCATIONS</th>
            <th>EXPECTED OPERATIVES NUMBER</th>
            <th>ASSIGNED OPERATIVES NUMBER</th>
            <th>CONTROLS</th>
          </tr>
        </thead>

        <tbody>
          {clients.map((client) => (
            <tr key={client.id}>
              <td data-label="NAME">
                <Link to={`/clients/view/${client.id}`}>
                  {client.name || "N/A"}
                </Link>
              </td>
              <td data-label="LOCATIONS">{client.locations_count || "N/A"}</td>
              <td data-label="EXPECTED OPERATIVES NUMBER">
                {client.operatives || "N/A"}
              </td>
              <td data-label="ASSIGNED OPERATIVES NUMBER">
                {client.numbers_of_assigned_operatives || "N/A"}
              </td>
              <td className="d-flex justify-content-center ">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  {hasPermission(permissions.VIEW_ANY_CLIENT) && (
                    <Dropdown.Item
                      as={Link}
                      to={`/clients/view/${client.id}`}
                      size="sm"
                      className="justify-content-center"
                    >
                      View
                    </Dropdown.Item>
                  )}

                  {hasPermission(permissions.EDIT_CLIENT) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/clients/edit/${client.id}`}
                    >
                      Edit
                    </Dropdown.Item>
                  )}

                  {/* <Dropdown.Item
                    as={Link}
                    size="sm"
                    className="justify-content-center"
                    to={`#`}
                  >
                    Add Location
                  </Dropdown.Item> */}

                  {/* <Dropdown.Item
                    as={Link}
                    size="sm"
                    to={"#"}
                    className="justify-content-center"
                    onClick={() => onDeleteClick(client)}
                  >
                    Delete
                  </Dropdown.Item> */}
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <br />
    </>
  );
};
