import React from "react";
import { UserInfo } from "./UserInfo";
import { FaCar } from "react-icons/fa";
import { FaBook } from "react-icons/fa";
import { Link } from "react-router-dom";
import { GiCarWheel } from "react-icons/gi";
import AscoLogo from "../images/Asco_logo.png";
import { CgOrganisation } from "react-icons/cg";
import { HiOutlineUserGroup } from "react-icons/hi";
import { Dashboard } from "../Components/icons/Dashboard";
import { permissions, domains } from "../utils/permissions";
import { usePermissionAndDomain } from "../hooks/usePermissionAndDomain";
import { Theme } from "../Components/Theme";
import { NotificationIcon } from "./NotificationIcon";
import { TbZoomMoney } from "react-icons/tb";

export const AsideComponent = () => {
  const { hasPermission, hasDomain, isSuperAdmin } = usePermissionAndDomain();
  const user = JSON.parse(localStorage.getItem("user"));

  

  return (
    <aside className="navbar navbar-vertical navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-menu"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <h1 className="navbar-brand">
          <Link to=".">
            <img
              src={AscoLogo}
              width="110"
              height="32"
              alt="ASCO"
              className="navbar-brand-image"
            />
          </Link>
        </h1>

        <div className="navbar-nav flex-row d-lg-none">
          <Theme className="" />

          {(!!user?.admin_notifications?.length || isSuperAdmin()) && (
            <NotificationIcon className="nav-item d-none d-md-flex me-3"/>
          )}

          <UserInfo className="d-none d-md-flex" />
        </div>

        <div className="collapse navbar-collapse" id="navbar-menu">
          <ul className="navbar-nav pt-lg-3">
            <li className=" dashboard nav-item">
              <a className="nav-link" href="/dashboard">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <Dashboard />
                </span>

                <span className="nav-link-title">Dashboard</span>
              </a>
            </li>

            {!!user?.admin_notifications?.length && (
              <li className="notifications nav-item">
                <Link className="nav-link" to="/notifications">
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="icon icon-tabler icon-tabler-bell-ringing-filled"
                      width="24" 
                      height="24" 
                      viewBox="0 0 24 24" 
                      strokeWidth="1.5" 
                      stroke="#2c3e50" 
                      fill="none" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M17.451 2.344a1 1 0 0 1 1.41 -.099a12.05 12.05 0 0 1 3.048 4.064a1 1 0 1 1 -1.818 .836a10.05 10.05 0 0 0 -2.54 -3.39a1 1 0 0 1 -.1 -1.41z" strokeWidth="0" fill="currentColor" />
                        <path d="M5.136 2.245a1 1 0 0 1 1.312 1.51a10.05 10.05 0 0 0 -2.54 3.39a1 1 0 1 1 -1.817 -.835a12.05 12.05 0 0 1 3.045 -4.065z" strokeWidth="0" fill="currentColor" />
                        <path d="M14.235 19c.865 0 1.322 1.024 .745 1.668a3.992 3.992 0 0 1 -2.98 1.332a3.992 3.992 0 0 1 -2.98 -1.332c-.552 -.616 -.158 -1.579 .634 -1.661l.11 -.006h4.471z" strokeWidth="0" fill="currentColor" />
                        <path d="M12 2c1.358 0 2.506 .903 2.875 2.141l.046 .171l.008 .043a8.013 8.013 0 0 1 4.024 6.069l.028 .287l.019 .289v2.931l.021 .136a3 3 0 0 0 1.143 1.847l.167 .117l.162 .099c.86 .487 .56 1.766 -.377 1.864l-.116 .006h-16c-1.028 0 -1.387 -1.364 -.493 -1.87a3 3 0 0 0 1.472 -2.063l.021 -.143l.001 -2.97a8 8 0 0 1 3.821 -6.454l.248 -.146l.01 -.043a3.003 3.003 0 0 1 2.562 -2.29l.182 -.017l.176 -.004z" strokeWidth="0" fill="currentColor" />
                    </svg> 
                  </span>
                  <span className="nav-link-title">Notifications</span>
                </Link>
              </li>
            )}

            {hasPermission(permissions.VIEW_SALARY_COMPLAINTS) && (
              <li className="salary-complaints nav-item">
                <Link className="nav-link" to="/salary-complaints">
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <TbZoomMoney size={22} />
                  </span>
                  <span className="nav-link-title">Salary Complaints</span>
                </Link>
              </li>

            )}
                

            {hasDomain(domains.MANAGEMENT_REPORT) && (
              <li className="reports nav-item dropdown">
                <a
                  role="button"
                  href="#navbar-base"
                  aria-expanded="false"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="false"
                  className="nav-link dropdown-toggle"
                >
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
                      <path d="M18 14v4h4" />
                      <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2" />
                      <rect x="8" y="3" width="6" height="4" rx="2" />
                      <circle cx="18" cy="18" r="4" />
                      <path d="M8 11h4" />
                      <path d="M8 15h3" />
                    </svg>
                  </span>
                  <span className="nav-link-title">Management Reports</span>
                </a>

                <div className="dropdown-menu">
                  <div className="dropdown-menu-columns">
                    <div className="dropdown-menu-column">
                      {hasPermission(permissions.VIEW_INCIDENT_REPORTS) && (
                        <Link className="dropdown-item" to="/incident-reports">
                          Incident Reports
                        </Link>
                      )}

                      {hasPermission(
                        permissions.VIEW_BRANCH_WEEKLY_REPORTS
                      ) && (
                          <Link className="dropdown-item" to="/weekly-reports">
                            Branch Weekly Reports
                          </Link>
                        )}
                    </div>
                  </div>
                </div>
              </li>
            )}

            {hasPermission(permissions.VIEW_ALL_ESCORT_SCHEDULES) && (
              <li className="escort-schedules nav-item">
                <Link className="nav-link" to="/escort-schedules">
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <polyline points="9 11 12 14 20 6" />
                      <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" />
                    </svg>
                  </span>
                  <span className="nav-link-title">Escort Schedules</span>
                </Link>
              </li>
            )}

            <li className="inventory nav-item dropdown">
              <Link
                role="button"
                to="#navbar-base"
                aria-expanded="true"
                data-bs-toggle="dropdown"
                data-bs-auto-close="false"
                className="nav-link dropdown-toggle"
              >
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <CgOrganisation size={19} />
                </span>
                <span className="nav-link-title">Organization</span>
              </Link>

              <div className="dropdown-menu">
                <div className="dropdown-menu-columns">
                  <div className="dropdown-menu-column">
                    {hasPermission(permissions.VIEW_ANY_CLIENT) && (
                      <Link className="dropdown-item" to="/clients">
                        Clients
                      </Link>
                    )}

                    <Link className="dropdown-item" to="/clients/locations">
                      Locations
                    </Link>
                  </div>
                </div>
              </div>
            </li>

            {hasPermission(permissions.VIEW_VENDORS) && (
              <li className="vendors nav-item">
                <Link className="nav-link" to="/vendors">
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="6" cy="19" r="2" />
                      <circle cx="17" cy="19" r="2" />
                      <path d="M17 17h-11v-14h-2" />
                      <path d="M6 5l14 1l-1 7h-13" />
                    </svg>
                  </span>
                  <span className="nav-link-title">Vendors</span>
                </Link>
              </li>
            )}

            {hasDomain(domains.LOGISTICS) && (
              <>
                <li className="inventory nav-item dropdown">
                  <Link
                    role="button"
                    to="#navbar-base"
                    aria-expanded="true"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="false"
                    className="nav-link dropdown-toggle"
                  >
                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M3 21v-13l9 -4l9 4v13" />
                        <path d="M13 13h4v8h-10v-6h6" />
                        <path d="M13 21v-9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v3" />
                      </svg>
                    </span>
                    <span className="nav-link-title">Logistics Items</span>
                  </Link>

                  <div className="dropdown-menu">
                    <div className="dropdown-menu-columns">
                      <div className="dropdown-menu-column">
                        {hasPermission(permissions.VIEW_ANY_KIT_REQUEST) && (
                          <Link
                            className="dropdown-item"
                            to="/guardkits-request"
                          >
                            Kit Request
                          </Link>
                        )}

                        {hasPermission(
                          permissions.VIEW_ALL_GUARD_KIT_ITEMS
                        ) && (
                            <Link className="dropdown-item" to="/guardkit">
                              Inventory
                            </Link>
                          )}

                        {hasPermission(
                          permissions.VIEW_ALL_PURCHASE_ORDERS
                        ) && (
                            <Link className="dropdown-item" to="/purchase-order">
                              Purchase Order
                            </Link>
                          )}
                      </div>
                    </div>
                  </div>
                </li>

                {hasPermission(permissions.VIEW_DRIVERS) && (
                  <li className="drivers nav-item">
                    <Link className="nav-link" to="/drivers">
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <HiOutlineUserGroup size={19} />
                      </span>
                      <span className="nav-link-title">Drivers</span>
                    </Link>
                  </li>
                )}

                <li className="inventory nav-item dropdown">
                  <Link
                    role="button"
                    to="#navbar-base"
                    aria-expanded="true"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="false"
                    className="nav-link dropdown-toggle"
                  >
                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                      <FaCar size={18} />
                    </span>
                    <span className="nav-link-title">Vehicles</span>
                  </Link>

                  <div className="dropdown-menu">
                    <div className="dropdown-menu-columns">
                      <div className="dropdown-menu-column">
                        {hasPermission(permissions.VIEW_VEHICLES) && (
                          <Link className="dropdown-item" to="/vehicles">
                            All Vehicles
                          </Link>
                        )}

                        <Link
                          className="dropdown-item"
                          to="/vehicles/status-reports"
                        >
                          Vehicles Status
                        </Link>

                        {/* 

                    <Link
                      className="dropdown-item"
                      to="#"
                    >
                      Status Compilation
                    </Link>

                    <Link
                      className="dropdown-item"
                      to="#"
                    >
                      Expenditure
                    </Link> */}
                      </div>
                    </div>
                  </div>
                </li>

                <li className="vehicle-parts nav-item">
                  <Link className="nav-link" to="/vehicle-parts">
                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <GiCarWheel size={19} />
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-settings-cog"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M12.003 21c-.732 .001 -1.465 -.438 -1.678 -1.317a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c.886 .215 1.325 .957 1.318 1.694"></path>
                        <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                        <path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                        <path d="M19.001 15.5v1.5"></path>
                        <path d="M19.001 21v1.5"></path>
                        <path d="M22.032 17.25l-1.299 .75"></path>
                        <path d="M17.27 20l-1.3 .75"></path>
                        <path d="M15.97 17.25l1.3 .75"></path>
                        <path d="M20.733 20l1.3 .75"></path>
                      </svg> */}
                    </span>
                    <span className="nav-link-title">Parts</span>
                  </Link>
                </li>

                <li className="maintenance-records nav-item">
                  <Link className="nav-link" to="/maintenance-records">
                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                      <FaBook />
                    </span>
                    <span className="nav-link-title">Maintenance</span>
                  </Link>
                </li>
              </>
            )}

            <li className="reminders nav-item">
              <Link className="nav-link" to="/reminders">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="icon icon-tabler icon-tabler-alarm-filled" 
                  width="24" height="24" 
                  viewBox="0 0 24 24" 
                  strokeWidth="1.5" 
                  stroke="currentColor" 
                  fill="none" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M16 6.072a8 8 0 1 1 -11.995 7.213l-.005 -.285l.005 -.285a8 8 0 0 1 11.995 -6.643zm-4 2.928a1 1 0 0 0 -1 1v3l.007 .117a1 1 0 0 0 .993 .883h2l.117 -.007a1 1 0 0 0 .883 -.993l-.007 -.117a1 1 0 0 0 -.993 -.883h-1v-2l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" fill="currentColor" />
                  <path d="M6.412 3.191a1 1 0 0 1 1.273 1.539l-.097 .08l-2.75 2a1 1 0 0 1 -1.273 -1.54l.097 -.08l2.75 -2z" strokeWidth="0" fill="currentColor" />
                  <path d="M16.191 3.412a1 1 0 0 1 1.291 -.288l.106 .067l2.75 2a1 1 0 0 1 -1.07 1.685l-.106 -.067l-2.75 -2a1 1 0 0 1 -.22 -1.397z" strokeWidth="0" fill="currentColor" />
                </svg>
                  
                </span>
                <span className="nav-link-title">Reminders</span>
              </Link>
            </li>

            {isSuperAdmin() && (
              <li className="notification-configs nav-item dropdown">
                <Link
                  role="button"
                  to="#navbar-base"
                  aria-expanded="true"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="false"
                  className="nav-link dropdown-toggle"
                >
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                      <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                    </svg>
                  </span>
                  <span className="nav-link-title">Notification Config</span>
                </Link>

                <div className="notification-configs dropdown-menu">
                  <div className="dropdown-menu-columns">
                    <div className="dropdown-menu-column">
                      {isSuperAdmin() && (
                        <Link className="dropdown-item" to="/notifications/configurations">
                          All Notifications
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            )}


            {hasPermission(permissions.VIEW_ANY_EMPLOYEE) && (
              <li className="access-controls nav-item">
                <Link className="nav-link" to="/staffs">
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="12" cy="7" r="4" />
                      <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    </svg>
                  </span>
                  <span className="nav-link-title">Employees</span>
                </Link>
              </li>
            )}

            {isSuperAdmin() && (
              <li className="access-controls nav-item dropdown">
                <Link
                  role="button"
                  to="#navbar-base"
                  aria-expanded="true"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="false"
                  className="nav-link dropdown-toggle"
                >
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M9 12l2 2l4 -4" />
                      <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" />
                    </svg>
                  </span>
                  <span className="nav-link-title">Access Controls</span>
                </Link>

                <div className="dropdown-menu">
                  <div className="dropdown-menu-columns">
                    <div className="dropdown-menu-column">
                      <Link className="dropdown-item" to="/roles">
                        Roles
                      </Link>
                      <Link className="dropdown-item" to="/permissions">
                        Permissions
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </aside>
  );
};
