import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../auth";
import useAxios from "../../hooks/useAxios";
import { handleCustomEvent } from "../../utils/customEventLister";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { permissions } from "../../utils/permissions";

export const DisplayedKitsRequestTable = ({
  guardKitsRequest,
  getGuardKitsRequest,
}) => {
  const { post } = useAxios();
  const auth = useContext(AuthContext);
  const { hasPermission } = usePermissionAndDomain();

  const onCompletedClick = (kitsRequest) => {
    handleCustomEvent("show-confirmation-modal", {
      action: async () => {
        try {
          await post(`uniformrequest/${kitsRequest.id}/editStatus`, {
            status: "completed",
          });
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          alertSuccess(
            ` Kit request with REQUEST ID ${kitsRequest.id} successfully completed!`,
            null,
            5000
          );
          getGuardKitsRequest();
        } catch (error) {
          alertWarning("Your request failed!", null, 500);
        }
      },
      question: `Are you sure all the items requested by ${kitsRequest.created_by_employee_code.first_name} ${kitsRequest.created_by_employee_code.surname} for ${kitsRequest.client_locations.name} with REQUEST ID ${kitsRequest.id}?`,
    });
  };
  return (
    <>
      <Table
        hover
        bordered
        responsive
        className=" table-vcenter table-mobile-md card-table"
      >
        <thead>
          <tr>
            <th>REQUESTED BY</th>
            <th>CLIENT NAME</th>
            <th>CLIENT LOCATION</th>
            <th>KIT ITEM(S)</th>
            <th>REQUEST STATUS</th>
            <th>CONTROLS</th>
          </tr>
        </thead>

        <tbody>
          {guardKitsRequest.map((kitsRequest) => (
            <tr key={kitsRequest.id}>
              <td data-label="REQUESTED BY">
                {kitsRequest?.created_by?.first_name}{" "}
                {kitsRequest?.created_by?.surname}
              </td>
              <td data-label="CLIENT NAME">{kitsRequest?.clients?.name}</td>
              <td data-label="CLIENT LOCATION">
                {kitsRequest?.client_locations?.name}
              </td>
              <td data-label="KIT ITEM(S)">
                <ul>
                  {kitsRequest.items.slice(0, 3).map((item) => (
                    <li key={item.id}>{item.guard_kit.name}</li>
                  ))}
                </ul>
                {kitsRequest.items.length > 3 && (
                  <p> +{kitsRequest.items.length - 3} more kit item(s)</p>
                )}
              </td>

              <td data-label="REQUEST STATUS">
                {`${kitsRequest.status}`.replace(/_/g, " ") || "N/A"}
              </td>
              <td className="d-flex justify-content-center ">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  {hasPermission(permissions.VIEW_ANY_KIT_REQUEST) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/guardkits-request/view/${kitsRequest.id}`}
                    >
                      View
                    </Dropdown.Item>
                  )}

                  {hasPermission(permissions.EDIT_KIT_REQUEST) &&
                    kitsRequest?.status === "awaiting_approval" && (
                      <Dropdown.Item
                        as={Link}
                        size="sm"
                        className="justify-content-center"
                        to={`/guardkits-request/edit/${kitsRequest.id}`}
                      >
                        Edit
                      </Dropdown.Item>
                    )}

                  {hasPermission(permissions.APPROVAL_OF_KIT_REQUEST) &&
                    kitsRequest?.status === "awaiting_approval" && (
                      <Dropdown.Item
                        as={Link}
                        size="sm"
                        className="justify-content-center"
                        to={`/guardkits-request/status_update/${kitsRequest.id}`}
                      >
                        Approve/Reject
                      </Dropdown.Item>
                    )}

                  {hasPermission(permissions.DISBURSE_KIT_ITEMS) &&
                    (kitsRequest?.status === "approve_with_adjustment" ||
                      kitsRequest?.status === "approved" ||
                      kitsRequest?.status === "partially_disbursed") && (
                      <Dropdown.Item
                        as={Link}
                        to={`/guardkits-request/disburse/${kitsRequest.id}`}
                        size="sm"
                        className="justify-content-center"
                      >
                        Disburse
                      </Dropdown.Item>
                    )}

                  {(!!Number(auth?.user?.is_super_admin) ||
                    !!auth?.user?.isLogisticsOfficer ||
                    !!auth?.user?.isLogisticsAssistant) && (
                    <>
                      {kitsRequest?.status === "completely_disbursed" && (
                        <Dropdown.Item
                          as={Link}
                          size="sm"
                          to={`#`}
                          className="justify-content-center"
                          onClick={() => onCompletedClick(kitsRequest)}
                        >
                          Items Received
                        </Dropdown.Item>
                      )}
                    </>
                  )}
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
