import React, { useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { LoadingButton } from "../../LoadingButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import TinyMCETextEditor from "../../TinyMCETextEditor";
import { Alert, Badge, Button, Card, Form } from "react-bootstrap";
import { alertSuccess, alertWarning } from "../../CustomAlerts/AlertToast";

const schema = yup.object({
  name: yup
    .string()
    .required("Name of role required")
    .min(2, "Enter at least 2 characters")
    .max(50, "This should not be more 50 characters."),
  description: yup.string().max(5000),
});

export const VehiclePartForm = ({editVehiclePart}) => {
  const {
    control,
    register,
    setValue,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      name: editVehiclePart?.name || "",
      description: editVehiclePart?.description || "",
      is_tyre: !!Number(editVehiclePart?.is_tyre) || "",
    },
  });

  const { post } = useAxios();
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const vehiclePartData = {
        name: data.name,
        description: data.description,
        is_tyre: Boolean(data.is_tyre),
      };

        const endpointPath = !editVehiclePart?.id
          ? "vehicle_parts/store"
          : `vehicle_parts/${editVehiclePart.id}/update`;

      await post(endpointPath, vehiclePartData);

      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Vehicle part successfully saved!", null, 5000);
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 422) {
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error?.response?.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to create vehicle part!",
          null,
          5000
        );
      }
    }
  };

  return (
    <>
      {isSaved && (
        <Navigate
          to={
            !editVehiclePart?.id
              ? "/vehicle-parts"
              : `/vehicle-parts/view/${editVehiclePart?.id}`
          }
          replace={true}
        />
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        {hasServerError && (
          <Alert variant="danger">
            <p>
              Something went wrong will processing your request. Please try
              again!
            </p>
          </Alert>
        )}
        <Card className="">
          <Card.Body>
            <div className="vehicle-part-name mb-4">
              <Form.Group controlId="vehiclePartName">
                <Form.Label className="required">NAME</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  {...register("name")}
                />

                {!!errors.name && (
                  <p>
                    <Badge bg="danger">{errors.name?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <div className="vehicle-part-description mb-4">
              <Form.Group controlId="vehiclePartDescription">
                <Form.Label>DESCRIPTION</Form.Label>
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <TinyMCETextEditor field={field} setValue={setValue} />
                  )}
                />

                {!!errors.description && (
                  <p>
                    <Badge bg="danger">{errors.description?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <div className="is-a-tyre mb-4">
              <Form.Group controlId="isATyre">
                <Form.Check
                  {...register("is_tyre")}
                  type="checkbox"
                  name="is_tyre"
                  id="is_tyre"
                  label="Is A Tyre"
                  value={true}
                />
              </Form.Group>
            </div>
          </Card.Body>
        </Card>

        <Card.Footer className=" d-flex justify-content-center  ">
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              variant="success"
              type="submit"
              className=" d-flex justify-content-center mt-3"
              disabled={!!loading || !isDirty}
            >
              Submit
            </Button>
          )}
        </Card.Footer>
      </Form>
    </>
  );
};
