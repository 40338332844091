import React, {useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Table,
  Badge,
  Button,
  Dropdown,
  ListGroup,
  DropdownButton,
} from "react-bootstrap";
import { format } from "date-fns";
import { SERVER_URL } from "../../config";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { permissions } from "../../utils/permissions";
import { HtmlContent } from "../../Components/HtmlContent";
import { Link, useNavigate, useParams } from "react-router-dom";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import CloseOrderModal from "../../Components/PurchaseOrder/DisregardOrderModal";

const ViewPurchaseOrder = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  let { purchaseOrderId } = useParams();
  const [vendor, setVendor] = useState({});
  const [kitItems, setKitItems] = useState({});
  const [fetching, setFetching] = useState(true);
  const [approvedBy, setApprovedBy] = useState({});
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [signatueOfApprover, setSignatueOfApprover] = useState("");
  const { hasPermission, isSuperAdmin } = usePermissionAndDomain();
  const [purchaseOrderHistory, setPurchaseOrderHistory] = useState({});

  useEffect(() => {
    setFetching(true);
    const getDetailedPurchaseOrder = async () => {
      try {
        const response = await get(`purchaseorder/${purchaseOrderId}`);
        setPurchaseOrder(response.data);
        setUser(response.data.created_by);
        setKitItems(response.data.items);
        setVendor(response.data.vendors);
        setApprovedBy(response.data.approve_by);
        setPurchaseOrderHistory(response.data.purchase_history);
        setSignatueOfApprover(response.data.approve_by.signature_path);
      } catch (error) {
      } finally {
        setFetching(false);
      }
    };
    getDetailedPurchaseOrder();
  }, []);

  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [closeOrder, setCloseOrder] = useState({});
  const onCloseOrderClick = (event, purchaseOrder) => {
    event.preventDefault();
    setCloseOrder(purchaseOrder);
    handleShow();
  };

  // const onApproveClick = (purchaseOrder) => {
  //   handleCustomEvent("show-confirmation-modal", {
  //     action: async () => {
  //       try {
  //         // await post(`purchaseOrders/${purchaseOrder.id}/destroy`);
  //         window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //         alertSuccess(
  //           `Purchase order with PO number ${purchaseOrder.purchase_order_number} successfully approved!`,
  //           null,
  //           5000
  //         );
  //         getPurchaseOrders();
  //       } catch (error) {
  //         alertWarning("Your request failed!", null, 500);
  //       }
  //     },
  //     question: `Are you sure you want to approve purchase order PO number ${purchaseOrder.purchase_order_number}?`,
  //   });
  // };

  // const onRejectClick = (purchaseOrder) => {
  //   handleCustomEvent("show-confirmation-modal", {
  //     action: async () => {
  //       try {
  //         // await post(`purchaseOrders/${purchaseOrder.id}/destroy`);
  //         window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //         alertSuccess(
  //           `Purchase order with PO number ${purchaseOrder.purchase_order_number} rejected!`,
  //           null,
  //           5000
  //         );
  //         getPurchaseOrders();
  //       } catch (error) {
  //         alertWarning("Your request failed!", null, 500);
  //       }
  //     },
  //     question: `Are you sure you want to reject purchase order PO number ${purchaseOrder.purchase_order_number}?`,
  //   });
  // };

  const paymentAlert = (purchaseOrder) => {
    const poStatus = purchaseOrder.status;
    const dueDate = purchaseOrder.payment_count_down;

    while (poStatus !== "closed_order") {
      if (dueDate === null) {
        return (
          <Badge bg="secondary" className="fs-5 mt-2">
            {"N/A"}
          </Badge>
        );
      } else if (dueDate > "10") {
        return (
          <Badge bg="success" className="fs-5 mt-2">
            {`IN ${dueDate} DAYS` || "N/A"}
          </Badge>
        );
      } else if (dueDate > "0" && "0" <= "10") {
        return (
          <Badge bg="warning" className="fs-5 mt-2">
            {`IN ${dueDate} DAYS OVERDUE` || "N/A"}
          </Badge>
        );
      } else if (dueDate < "0") {
        return (
          <Badge bg="danger" className="fs-5 mt-2">
            {`IN ${dueDate} DAYS OVERDUE` || "N/A"}
          </Badge>
        );
      }
    }
    return (
      <Badge bg="secondary" className="fs-5 mt-2">
        {"N/A"}
      </Badge>
    );
  };

  return (
    <>
      {!!fetching && <Loading />}

      {!!purchaseOrder?.id && !fetching && (
        <>
          <Card>
            <Card.Header className="d-flex d-print-none justify-content-between mt-2 mb-5">
              <Card.Title>
                <Row className="me-4">
                  <Col>
                    <h1>Purchase Order Details</h1>
                  </Col>
                </Row>
              </Card.Title>

              <Row className="me-4">
                <Col className="mb-2">
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </Col>

                <Col className="mb-2" sm={4}>
                  <Button onClick={() => window.print()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />
                      <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />
                      <rect x="7" y="13" width="10" height="8" rx="2" />
                    </svg>
                    Print
                  </Button>
                </Col>

                <Col>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title="ACTION"
                    className="justify-content-center"
                  >
                    {purchaseOrder?.status === "issued" && (
                      <>
                        {hasPermission(permissions.EDIT_PURCHASE_ORDER) && (
                          <Dropdown.Item
                            as={Link}
                            size="sm"
                            className="justify-content-center"
                            to={`/purchase-order/edit/${purchaseOrderId}`}
                          >
                            Edit
                          </Dropdown.Item>
                        )}

                        <>
                          {purchaseOrder?.approval_status === "initialize" && (
                            <>
                              {/* {hasPermission(
                                permissions.APPROVAL_OF_PURCHASE_ORDER
                              ) && (
                                <>
                                  <Dropdown.Item
                                    as={Link}
                                    size="sm"
                                    to={"#"}
                                    className="justify-content-center"
                                    onClick={() =>
                                      onApproveClick(purchaseOrder)
                                    }
                                  >
                                    Approve
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    as={Link}
                                    size="sm"
                                    to={"#"}
                                    className="justify-content-center"
                                    onClick={() => onRejectClick(purchaseOrder)}
                                  >
                                    Reject
                                  </Dropdown.Item>
                                </>
                              )} */}
                            </>
                          )}
                        </>
                      </>
                    )}

                    {hasPermission(
                      permissions.RECORD_PURCHASE_ORDER_SUPPLY
                    ) && (
                      <>
                        {(purchaseOrder?.status === "issued" ||
                          purchaseOrder?.status === "partial_delivery") &&
                          purchaseOrder?.approval_status === "approved" && (
                            <Dropdown.Item
                              as={Link}
                              size="sm"
                              className="justify-content-center"
                              to={`/purchase-order/supply_record/${purchaseOrderId}`}
                            >
                              Supply Record
                            </Dropdown.Item>
                          )}
                      </>
                    )}

                    {/* {(purchaseOrder?.status === "issued" ||
                      purchaseOrder?.status === "partial_delivery") && (
                      <Dropdown.Item
                        as={Link}
                        size="sm"
                        to={`#`}
                        className="justify-content-center"
                        onClick={(event) =>
                          onDisregardOrder(event, purchaseOrder)
                        }
                      >
                        Disregard Order
                      </Dropdown.Item>
                    )} */}

                    {hasPermission(permissions.CLOSE_PURCHASE_ORDER) && (
                      <>
                        {(purchaseOrder?.status === "disregard" ||
                          purchaseOrder?.status === "delivery_complete") && (
                          <Dropdown.Item
                            as={Link}
                            size="sm"
                            to={`#`}
                            className="justify-content-center"
                            onClick={() => onCloseOrderClick(purchaseOrder)}
                          >
                            Close Order
                          </Dropdown.Item>
                        )}
                      </>
                    )}
                  </DropdownButton>
                </Col>
              </Row>
            </Card.Header>

            <Card.Body className="mb-4" id="printableArea">
              <ListGroup className="list-group-flush">
                <ListGroup.Item>
                  <label className="fw-bolder fs-3">
                    PURCHASE ORDER NUMBER
                  </label>
                  <Card.Text className="fs-3">
                    {purchaseOrder.purchase_order_number || "N/A"}
                  </Card.Text>
                </ListGroup.Item>
                <ListGroup.Item>
                  <label className="fw-bolder fs-3">PAYMENT DUE</label>
                  <Card.Text>{paymentAlert(purchaseOrder)}</Card.Text>
                </ListGroup.Item>

                <ListGroup.Item>
                  <label className="fw-bolder fs-3 ">VENDOR DETAILS</label>

                  <Row className="mb-3">
                    <Col className="mt-3 mb-3" md={6}>
                      <label className="fw-bold fs-3 ">NAME</label>
                      <Card.Text className="fs-3">
                        {vendor.name || "N/A"}
                      </Card.Text>
                    </Col>

                    <Col className="mt-3" md={6}>
                      <label className="fw-bold fs-3">EMAIL</label>
                      <Card.Text className="fs-3">
                        {vendor.email || "N/A"}
                      </Card.Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mb-3" md={6}>
                      <label className="fw-bold fs-3 ">PRIMARY TELEPHONE</label>
                      <Card.Text className="fs-3">
                        {vendor.primary_phone || "N/A"}
                      </Card.Text>
                    </Col>

                    <Col md={6}>
                      <label className="fw-bold fs-3 ">
                        SECONDARY TELEPHONE
                      </label>
                      <Card.Text className="fs-3">
                        {vendor.secondary_phone || "N/A"}
                      </Card.Text>
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <label className="fw-bolder fs-3">ORDER WAS CREATED BY</label>
                  <Card.Text className="fs-3">
                    {user.first_name} {user.surname}
                  </Card.Text>
                </ListGroup.Item>

                <ListGroup.Item>
                  <label className="fw-bolder fs-3">ORDER STATUS</label>
                  <Card.Text className="fs-3">
                    {`${purchaseOrder.status}`.replace(/_/g, " ") || "N/A"}
                  </Card.Text>
                </ListGroup.Item>

                <ListGroup.Item>
                  <label className="fw-bolder fs-3">TOTAL</label>
                  <Card.Text className="fs-3">
                    &#8358; {Number(purchaseOrder.total).toLocaleString()}
                  </Card.Text>
                </ListGroup.Item>

                <ListGroup.Item>
                  <label className="fw-bolder fs-3 mb-2">
                    DELIVERY TERMS & CONDITIONS
                  </label>
                  <HtmlContent>{purchaseOrder.terms || "N/A"}</HtmlContent>{" "}
                </ListGroup.Item>

                <ListGroup.Item>
                  <label className="fw-bolder fs-3 ">
                    GUARD KIT ITEMS DETAILS
                  </label>
                  <Table
                    responsive
                    hover
                    bordered
                    className=" table-vcenter table-mobile-md card-table mt-3"
                  >
                    <thead>
                      <tr>
                        <th>NAME</th>
                        <th>QUANTITY ORDERED</th>
                        <th>RATE</th>
                        <th>PRICE</th>
                        <th>QUANTITY DELIVERED</th>
                      </tr>
                    </thead>

                    <tbody>
                      {kitItems.map((kitItem) => (
                        <tr key={kitItem.id}>
                          <td data-label="NAME">{kitItem.kit_item.name}</td>

                          <td data-label="QUANTITY ORDERED">
                            {kitItem.quantity}
                          </td>

                          <td data-label="RATE">{kitItem.rate}</td>
                          <td data-label="PRICE">{kitItem.amount}</td>
                          <td data-label="QUANTITY DELIVERED">
                            {kitItem.quantity_delivered || "N/A"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </ListGroup.Item>

                {!!approvedBy && (
                  <ListGroup.Item>
                    <label className="fw-bolder fs-3">
                      ORDER WAS APPROVED BY
                    </label>

                    <Card.Text className="fs-3">
                      <img
                        src={`${SERVER_URL}/${signatueOfApprover}`}
                        alt={signatueOfApprover}
                        padding={5}
                        width={150}
                      />
                    </Card.Text>

                    <Card.Text className="fs-3">
                      {approvedBy.first_name} {approvedBy.surname}
                    </Card.Text>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Card.Body>

            <Card.Body className="d-print-none">
              <ListGroup className="list-group-flush">
                <ListGroup.Item className="mt-3">
                  <label className="fw-bolder fs-3 ">ORDER HISTORY</label>
                  {!purchaseOrderHistory?.length && (
                    <p>NO HISTORY OF SUPPLY FOR THIS ITEM</p>
                  )}
                  {!!purchaseOrderHistory?.length && (
                    <Table
                      responsive
                      hover
                      bordered
                      className=" table-vcenter table-mobile-md card-table mt-3"
                    >
                      <thead>
                        <tr>
                          <th>DELIVERY DATE</th>
                          <th>COLLECTOR</th>
                          <th>ITEMS AND QUANTITY DELIVERED</th>
                        </tr>
                      </thead>

                      <tbody>
                        {purchaseOrderHistory.map((history) => (
                          <tr key={history.id}>
                            <td data-label="DELIVERY DATE">
                              {history.date_received || "N/A"}
                            </td>
                            <td data-label="COLLECTOR">
                              {`${history.receive_by_employee_code.first_name} ${history.receive_by_employee_code.surname}` ||
                                "N/A"}
                            </td>
                            <td data-label="ITEMS AND QUANTITY">
                              <ul>
                                {history.stock_transaction.map(
                                  (itemTransaction) => (
                                    <li key={itemTransaction.id}>
                                      {`${itemTransaction.guard_kit.name} (${itemTransaction.quantity})` ||
                                        "N/A"}
                                    </li>
                                  )
                                )}
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>

            <Card.Footer className="d-print-none">
              <ListGroup className="list-group-flush">
                <ListGroup.Item className="d-flex justify-content-end">
                  <Row>
                    <Col className="mb-3">
                      <label className="fw-bolder fs-3">CREATED</label>
                      <Card.Text className="fs-3">
                        {format(
                          new Date(purchaseOrder.created_at),
                          "EEE, MMM d, yyyy 'at' HH:mm:ss",
                          {
                            awareOfUnicodeTokens: true,
                          }
                        ) || "N/A"}
                      </Card.Text>
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item className="d-flex justify-content-end">
                  <Row>
                    <Col>
                      <label className="fw-bolder fs-3">UPDATED</label>
                      <Card.Text className="fs-3">
                        {format(
                          new Date(purchaseOrder.updated_at),
                          "EEE, MMM d, yyyy 'at' HH:mm:ss",
                          {
                            awareOfUnicodeTokens: true,
                          }
                        ) || "N/A"}
                      </Card.Text>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Card.Footer>
          </Card>

          {/* <CloseOrderModal
            closeOrder={closeOrder}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            onCloseOrderSuccess={() => {
              getDetailedPurchaseOrder();
              handleClose();
            }}
          /> */}
        </>
      )}
    </>
  );
};

export default ViewPurchaseOrder;
