import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../Components/Loading";
import { GuardKitsRequestForm } from "../../Components/GuardKitsRequest/GuardKitsRequestForm.jsx";

const CreateGuardKitsRequest = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  const [clients, setClients] = useState({});
  const [requester, setRequester] = useState({});
  const [fetching, setFetching] = useState(true);
  const [guardKitItems, setGuardKitItems] = useState({});

  useEffect(() => {
    setFetching(true);
    const getDetailedKitsRequest = async () => {
      try {
        const response = await get(`uniformrequest/create`);
        setClients(response.data.clients);
        setRequester(response.data.requested_by);
        setGuardKitItems(response.data.items);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedKitsRequest();
  }, []);

  return (
    <>
      {fetching ? (
        <Loading />
      ) : (
        <Card className="container">
          <Card.Header className="d-flex justify-content-between mt-0 mb-5">
            <Card.Title>
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">Request for Kits</h1>
                </div>
              </div>
            </Card.Title>

            <div className="row">
              <div className="col-md-12">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <GuardKitsRequestForm
              clients={clients}
              requester={requester}
              guardKitItems={guardKitItems}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default CreateGuardKitsRequest;
