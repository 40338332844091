import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { SalaryComplaintForm } from "../../Components/SalaryComplaints/SalaryComplaintForm";

const CreateSalaryComplaint = () => {
    const { get } = useAxios();
    const navigate = useNavigate();
    const [banks, setBanks] = useState({});
    const [fetching, setFetching] = useState(true);
    const [rootCauses, seRootCauses] = useState({});
    const [complaintCategories, setComplaintCategories] = useState({});

    useEffect(() => {
    setFetching(true);
    const getCreateDetails = async () => {
      try {
        const response = await get(`salary-complaints/create`);
        setBanks(response?.data?.banks)
        seRootCauses(response.data.root_causes)
        setComplaintCategories(response.data.complaint_categories)
      } catch (error) {
        if (error) {
        }
      } finally {
        setFetching(false);
      }
    };
    getCreateDetails();
  }, []);

    return (
        <>
        {!!fetching ? (
          <Loading />
        ) : (
          <div className="container fluid">
            <div className="mx-4">
              <div className="d-flex justify-content-between mt-0 mb-4">
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="page-title">Salary Complaint</h1>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <Button onClick={() => navigate(-1)}>Back</Button>
                  </div>
                </div>
              </div>

              <SalaryComplaintForm
                bankNames={banks}
                rootCauses={rootCauses}
                complaintCategories={complaintCategories}
              />

            </div>
          </div>
        )}
      </>
    )
}

export default CreateSalaryComplaint