import React from "react";
import { getWeek, getYear } from "date-fns";
import getDateRangeOfWeek from "./dateRangeFunction";

const weekRangeOptions = () => {
  const currentWeekNumber = getWeek(new Date());

  const _weekRangeOptions = [];

  for (let i = 1; i <= currentWeekNumber; i++) {
    const specificWeekRange = getDateRangeOfWeek(i, getYear(new Date()));
    _weekRangeOptions.push(`Week ${i}: ${specificWeekRange}`);
  }

  return _weekRangeOptions;
};

export default weekRangeOptions;
