import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Error404 } from "../Errors/Error404";
import { Loading } from "../../Components/Loading";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Col, Row, Card, Button, Table } from "react-bootstrap";
import getDateRangeOfWeek from "../../utils/dateRangeFunction";

const ViewVehicleStatusReport = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { vehicleStatusReportId } = useParams();
  const [fetching, setFetching] = useState(true);
  const [statusReport, setStatusReport] = useState({});
  const [partStatusArray, setPartStatusArray] = useState([]);
  const [vehiclePartStatusData, setVehiclePartStatusData] = useState([]);

  useEffect(() => {
    setFetching(true);
    const getDetailedStatusReport = async () => {
      try {
        const response = await get(`vehicle_status/${vehicleStatusReportId}`);
        setStatusReport(response.data.vehicleStatusReport);
        setVehiclePartStatusData(
          response.data.vehicleStatusReport.vehicle_statuses
        );

        const statusesToBeUsed = [];
        response.data.vehicleStatusReport.vehicle_statuses.forEach(
          (statusReportToBeUsed) => {
            const _statusesToBeUsed = JSON.parse(
              statusReportToBeUsed.status_json
            );
            statusesToBeUsed.push(_statusesToBeUsed);
          }
        );

        setPartStatusArray(statusesToBeUsed);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedStatusReport();
  }, []);

  return (
    <>
      {!statusReport?.id && <Error404 />}

      <Card>
        <Card.Header className="d-flex justify-content-between mt-2 mb-5">
          <Card.Title>
            <Row>
              <Col>
                <h1>Vehicle Status Report Details</h1>
              </Col>
            </Row>
          </Card.Title>

          <Row className="ms-1">
            <Col className="mb-2">
              <Button onClick={() => navigate(-1)}>Back</Button>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          {!!fetching && <Loading />}

          {!fetching && !!statusReport?.id && (
            <div className="page-wrapper">
              <p className="text-center">
                Weekly vehicle status report (
                {!!statusReport?.office_location
                  ? `${statusReport?.office_location?.name}`
                  : "N/A"}
                )
              </p>

              <Row className="text-center">
                <Col className="" sm={6}>
                  <strong>Week {statusReport?.week_number}, </strong>
                  {getDateRangeOfWeek(
                    Number(statusReport.week_number),
                    Number(statusReport.year)
                  )}
                </Col>
                <Col className="m-0">
                  <strong>Reported By:</strong>{" "}
                  {!!statusReport?.reported_by
                    ? `${statusReport?.reported_by?.first_name} ${statusReport?.reported_by?.surname}`
                    : "N/A"}
                </Col>
                <br />
              </Row>

              <Card.Body>
                <Table
                  responsive
                  hover
                  bordered
                  className="table table-sm table-bordered m-0"
                >
                  <thead>
                    <tr className="vertical-header">
                      <th data-no-rotate>VEHICLE</th>

                      {Object.keys(partStatusArray[0]).map(
                        (partStatusHeader) => (
                          <th key={partStatusHeader}>{partStatusHeader}</th>
                        )
                      )}
                      <th data-no-rotate>COMMENT</th>
                    </tr>
                  </thead>

                  <tbody>
                    {vehiclePartStatusData.map((partStatusData) => (
                      <tr key={partStatusData?.id}>
                        <td>
                          <strong>{partStatusData?.vehicle?.reg_number}</strong>
                          <br />
                          {`${partStatusData?.vehicle?.vehicle_model?.vehicle_make?.name} - ${partStatusData?.vehicle?.vehicle_model?.name}` ||
                            "N/A"}
                        </td>

                        {Object.values(partStatusArray[0]).map(
                          (partStatusData, index) => (
                            <td key={index}>{partStatusData}</td>
                          )
                        )}

                        <td>{partStatusData?.comment}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default ViewVehicleStatusReport;
