import React from 'react'
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";

export const TopRequestersTable = ({
  topRequesters,
  currentDateOfMonth,
  startOfCurrentMonth,
}) => {
  return (
    <Table
      hover
      bordered
      responsive
      className=" table-vcenter table-mobile-md card-table"
    >
      <thead>
        <tr>
          <th>NAME</th>
          <th>NUMBER OF REQUESTS</th>
          <th>REQUESTED (&#8358;)</th>
          <th>APPROVED (&#8358;)</th>
        </tr>
      </thead>

      <tbody>
        {topRequesters.map((topRequester) => (
          <tr key={topRequester?.requester?.id}>
            <td data-label="NAME">
              <Link to={"#"}>
                {`${topRequester?.requester?.first_name} ${topRequester?.requester?.surname}`}
                {topRequester?.requester?.office_location?.name}
              </Link>
            </td>

            <td data-label="NUMBER OF REQUESTS">
              {topRequester?.maintenance_count || "N/A"}
            </td>
            <td data-label="REQUESTED (&#8358;)">
              {Number(topRequester?.requested_amount).toLocaleString() || "N/A"}
            </td>
            <td data-label="APPROVED (&#8358;)">
              {Number(topRequester?.amount).toLocaleString() || "N/A"}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
