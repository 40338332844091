import React, { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { VehiclePartForm } from "../../../Components/Vehicles/Parts/VehiclePartForm ";

const EditVehiclePart = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  const { vehiclePartId } = useParams();
  const [fetching, setFetching] = useState(true);
  const [vehiclePartDetail, setVehiclePartDetail] = useState({});

   useEffect(() => {
     setFetching(true);
     const getEditDetails = async () => {
       try {
         const response = await get(`vehicle_parts/${vehiclePartId}`);
         setVehiclePartDetail(response.data.vehiclePart);
       } catch (error) {
         if (error.response.status === 500) {
         }
       } finally {
         setFetching(false);
       }
     };
     getEditDetails();
   }, []);

  return (
    <>
      <Card className="container">
        {!!fetching && <Loading />}

        {!fetching && !!vehiclePartDetail?.id && (
          <>
            <Card.Header className="d-flex justify-content-between mt-0 mb-5">
              <Card.Title>
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="page-title">Edit Vehicle Part</h1>
                  </div>
                </div>
              </Card.Title>

              <div className="row">
                <div className="col-md-12">
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </div>
              </div>
            </Card.Header>

            <Card.Body>
              <VehiclePartForm editVehiclePart={vehiclePartDetail} />
            </Card.Body>
          </>
        )}
      </Card>
    </>
  );
};

export default EditVehiclePart;
