import React, { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import useAxios from "../hooks/useAxios";
import { Navigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { alertSuccess, alertWarning } from "./CustomAlerts/AlertToast";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "./LoadingButtons";
import { Col, Row, Button, Form, Badge, Alert } from "react-bootstrap";

const schema = yup.object({
  approved_amount: yup.string(),
  rejected_reason: yup.string().max(500),
  status: yup.string().required("You have not updated status!"),
});

export const  ApproveRejectsStatusForm = ({
  statusOptions,
  maintenanceRecord,
  postEndpoint,
}) => {
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      status: "",
      approved_amount: "",
      rejected_reason: "",
    },
  });

  const { post } = useAxios();
  let { maintenanceRecordId } = useParams();
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);

  const selectedStatus = watch("status");
  const approvedAmount = watch("approved_amount");

  
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      let statusUpdateData = {};

      if (data.status === "rejected") {
        statusUpdateData.status = data.status;
        statusUpdateData.rejected_reason = data.rejected_reason;
      } else if (data.status === "approved with adjustment") {
        statusUpdateData.status = data.status;
        statusUpdateData.approved_amount = data.approved_amount;
      } else {
        statusUpdateData.status = data.status;
      }

      if (window.location.pathname.includes("escort-schedule")) {
        await post(postEndpoint, { ...statusUpdateData, is_sent: true });
      } else {
        await post(postEndpoint, statusUpdateData);
      }

      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

      alertSuccess(
        "Maintenance record status successfully updated!",
        null,
        5000
      );
    } catch (error) {
      setLoading(false);
      if (error.response.status === 422) {
        const handleServerErrors = (errors, setHasServerError) => {
          if (errors) {
            const serverValidation = error.response.data.error;
            return Object.keys(serverValidation).map((key) => {
              setHasServerError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setHasServerError);
        return;
      }
      if (error.response.status === 500) {
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      }
    }
  };


  return (
    <>
      {isSaved && (
        <Navigate
          to={`/maintenance-records/view/${maintenanceRecordId}`}
          replace={true}
        />
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        {hasServerError && (
          <Alert variant="danger">
            <p>
              Something went wrong will processing your request. Please try
              again!
            </p>
          </Alert>
        )}
        <Form.Group as={Row} className="mb-3" controlId="requestStatus">
          <Form.Label column sm="2" className="required">
            STATUS
          </Form.Label>
          <Col sm="10">
            <Form.Select
              className="form-control"
              {...register("status")}
              onChange={(event) => {
                setValue("status", event.target.value, {
                  shouldValidate: true,
                });
              }}
            >
              <option value="" disabled>
                Select Status
              </option>
              {statusOptions.map((statusOption, index) => (
                <option key={index} value={statusOption}>
                  {`${statusOption}`.replace(/_/g, " ")}
                </option>
              ))}
            </Form.Select>
            {!!errors.status && (
              <p>
                <Badge className="mt-1" bg="danger">
                  {errors.status?.message}
                </Badge>
              </p>
            )}
          </Col>
        </Form.Group>

        {selectedStatus === "rejected" && (
          <Form.Group as={Row} className="mb-3" controlId="rejectReason">
            <Form.Label column sm="2" className="required">
              REASON
            </Form.Label>
            <Col sm="10">
              <Form.Text>
                What is the reason for rejecting the requested amount?
              </Form.Text>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Reason"
                {...register("rejected_reason")}
                onChange={(event) => {
                  setValue("rejected_reason", event.target.value, {
                    shouldValidate: true,
                  });
                }}
              />
            </Col>

            {!!errors.rejected_reason && (
              <p>
                <Badge className="mt-1" bg="danger">
                  {errors.rejected_reason?.message}
                </Badge>
              </p>
            )}
          </Form.Group>
        )}

        {selectedStatus === "approved with adjustment" && (
          <Form.Group as={Row} className="mb-3" controlId="approvedAmount">
            <Form.Label column sm="2" className="required">
              APPROVED AMOUNT
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                max={maintenanceRecord?.request_amount}
                {...register("approved_amount")}
              />
            </Col>

            {Number(approvedAmount) > Number(maintenanceRecord?.request_amount) && (
              <p>
                <Badge className="mt-1" bg="danger">
                  Approved Amount must be less than or equal to
                  <span className="ms-1">
                    {" "}
                    &#8358;
                    {Number(maintenanceRecord?.request_amount).toLocaleString()}
                  </span>

                  Approved amount: {approvedAmount}
                </Badge>
              </p>
            )}
            {!!errors.approved_amount && (
              <p>
                <Badge className="mt-1" bg="danger">
                  {errors.approved_amount?.message}
                </Badge>
              </p>
            )}
          </Form.Group>
        )}

       <div className="d-flex justify-content-center mt-3">
          {
          // approvedAmount > maintenanceRecord?.request_amount 
          // ? 
          // <Button
          //     variant="primary"
          //     type="submit"
          //     className=" d-flex justify-content-center mt-3"
          //     disabled
          //   >
          //     SUBMIT
          //   </Button>
          //   :
             loading ? (
            <LoadingButton />
          ) : (
            <Button
              variant="primary"
              type="submit"
              className=" d-flex justify-content-center mt-3"
              disabled={
                !!loading ||
                !isDirty || (Number(approvedAmount) > Number(maintenanceRecord?.request_amount))
              }
            >
              SUBMIT
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};
