import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useAxios from "./useAxios";

export const useGetIncidentViewData = () => {
  const { get } = useAxios();
  let { reportId } = useParams();
  const [user, setUser] = useState([]);
  const [client, setClient] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [witnessInfo, setWitnessInfo] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [clientLocation, setClientLocation] = useState([]);
  const [incidentReport, setIncidentReport] = useState({});

  const fetchDetailedIncidentReport = async () => {
    setFetching(true);
    try {
      const response = await get(`incidents/${reportId}`);

      setUser(response.data.users);
      setClient(response.data.clients);
      setIncidentReport(response.data);
      setWitnessInfo(response.data.witnesses);
      setClientLocation(response.data.client_offices);
      setUploadedFiles(response.data.incidentEvidences);
    } catch (error) {
      if (error.response.status === 500) {
      }
    } finally {
      setFetching(false);
    }
  };

  return {
    user,
    client,
    reportId,
    fetching,
    witnessInfo,
    uploadedFiles,
    clientLocation,
    incidentReport,
    fetchDetailedIncidentReport,
  };
};
