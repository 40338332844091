import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Card, Col, Row } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { permissions } from "../../utils/permissions";
import { Link, useSearchParams } from "react-router-dom";
import ReactPagination from "../../Components/ReactPagination";
import { alertWarning } from "../../Components/CustomAlerts/AlertToast";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { DisplayedPurchaseOrderTable } from "../../Components/PurchaseOrder/DisplayedPurchaseOrderTable";

const PurchaseOrderIndex = () => {
  const { get } = useAxios();
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [searchPageParams, setSearchPageParams] = useSearchParams({});

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;

  const getPurchaseOrders = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;
      const response =
        await get(`purchaseorder?sort=desc&page=${currentPage}&page_size=${pageSize}
        `);
      setPurchaseOrders(response.data.purchaseOrder);
    } catch (error) {
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    setFetching(true);
    const purchaseOrders = async () => await getPurchaseOrders();
    purchaseOrders();
  }, [currentPage]);

  const pageChangeHandler = (page) => {
    setSearchPageParams(
      {
        ...Object.fromEntries(searchPageParams),
        page,
      },
      { replace: true }
    );
  };

  return (
    <>
      {!!fetching && <Loading />}

      <Card className="container d-flex justify-content-center ms-3">
        <Card.Header className="d-flex justify-content-between mt-2 mb-2">
          <Card.Title>
            <Row>
              <Col md="auto">
                <h2 className="page-title">Purchase Orders</h2>
              </Col>
            </Row>
          </Card.Title>

         {hasPermission(permissions.CREATE_PURCHASE_ORDER) && (
            <Row>
              <Col md="auto">
                <Link to="/purchase-order/create" className="btn btn-primary">
                  Create Purchase Order
                </Link>
              </Col>
            </Row>
          )}
        </Card.Header>

        <Card.Body>
          {!fetching && !!purchaseOrders?.data?.length === 0 && (
            <div> No Purchase Order Found</div>
          )}

          {!!purchaseOrders?.data?.length && !fetching && (
            <Row className="mt-3">
              <Col className="p-0">
                <>
                  <DisplayedPurchaseOrderTable
                    purchaseOrders={purchaseOrders.data}
                    getPurchaseOrders={getPurchaseOrders}
                  />

                  <br />

                  <ReactPagination
                    links={purchaseOrders.links}
                    onPageChange={pageChangeHandler}
                    current_page={purchaseOrders.current_page}
                    last_page={purchaseOrders.last_page}
                  />
                </>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default PurchaseOrderIndex;
