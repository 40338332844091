import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button, Dropdown, Table, DropdownButton } from "react-bootstrap";
import { usePermissionAndDomain } from "../../../hooks/usePermissionAndDomain";
import { permissions } from "../../../utils/permissions";
import { PageCount } from "../../DisplayTable/PageCount";

export const VehicleDataTable = ({ vehicles }) => {
  const { hasPermission } = usePermissionAndDomain();
  const columns = [
    {
      id: "reg_number",
      header: "REG. NUMBERS",
      cell: ({ row }) => {
        const vehicle = row.original;
        return (
          <>
            {!!vehicle?.reg_number
              ? `${vehicle?.reg_number}`
              : "N/A"
            }
          </>
        );
      },
    },

    {
      id: "vehicle_make_name",
      header: "MAKE",
      cell: ({ row }) => {
        const vehicle = row.original;
        return (
          <>
            {!!vehicle?.vehicle_model?.vehicle_make
              ? `${vehicle?.vehicle_model?.vehicle_make?.name}`
              : "N/A"
            }
          </>
        );
      },
    },

    {
      id: "vehicle_model_name",
      header: "MODEL",
      cell: ({ row }) => {
        const vehicle = row.original;
        return (
          <>
            {!!vehicle?.vehicle_model
              ? `${vehicle?.vehicle_model?.name}`
              : "N/A"
            }
          </>
        );
      },
    },

    {
      id: "usage_type",
      header: "USAGE",
      cell: ({ row }) => {
        const vehicle = row.original;
        return (
          <>
            {!!vehicle?.usage_type
              ? `${vehicle?.usage_type}`
              : "N/A"
            }
          </>
        );
      },
    },

    {
      id: "office_location_name",
      header: "LOCATION",
      cell: ({ row }) => {
        const vehicle = row.original;
        return (
          <>
            {!!vehicle?.office_location
              ? `${vehicle?.office_location?.name}`
              : "N/A"
            }
          </>
        );
      },
    },
    {
      id: "license_expiry_date",
      header: "LICENSE EXPIRY DATE",
      cell: ({ row }) => {
        const vehicle = row.original;
        return (
          <>
            {!!vehicle?.license_expiry_date
              ? `${vehicle?.license_expiry_date}`
              : "N/A"
            }
          </>
        );
      },
    },

    {
      id: "drivers",
      header: "DRIVERS",
      cell: ({ row }) => {
        const drivers = row.original;
        return (
          <>
            {!!drivers?.primary_driver && !!drivers?.secondary_driver
              ? `${drivers?.primary_driver?.first_name} ${drivers?.primary_driver?.surname} & ${drivers?.secondary_driver?.first_name} ${drivers?.secondary_driver?.surname}`
              : !!drivers?.primary_driver
                ? `${drivers?.primary_driver?.first_name} ${drivers?.primary_driver?.surname}`
                : "Not Set"
            }
          </>
        );
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const vehicle = row.original;

        return (
          <DropdownButton id="dropdown-basic-button" title="ACTION" align="end">
            {hasPermission(permissions.VIEW_VEHICLES) && (
              <Dropdown.Item
                as={Link}
                to={`/vehicles/view/${vehicle.id}`}
                size="sm"
                className="justify-content-start"
              >
                View
              </Dropdown.Item>
            )}

            {hasPermission(permissions.EDIT_VEHICLE) && (
              <Dropdown.Item
                as={Link}
                size="sm"
                className="justify-content-start"
                to={`/vehicles/edit/${vehicle.id}`}
              >
                Edit
              </Dropdown.Item>
            )}
          </DropdownButton>
        );
      },
    },
  ];

  const data = vehicles;

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters,
      globalFilter,
    },
  });

  return (
    <div className="rounded-md border">
      <div>
        <div className="flex items-center py-4 ms-4 me-4 ">
          <input
            placeholder="Filter input..."
            value={globalFilter ?? ""}
            onChange={(event) => {
              return setGlobalFilter(event.target.value);
            }}
            className="max-w-sm form-control"
          />
        </div>
      </div>

      <Table
        responsive
        hover
        bordered
        className=" table-vcenter table-mobile-md card-table"
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <tr key={row.id} data-state={row.getIsSelected() && "selected"}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="h-24 text-center">
                No results.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            table.previousPage();
          }}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            table.nextPage();
          }}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>

        <PageCount table={table} />
      </div>
    </div>
  );
};
