import React from 'react'
import { RequireAuth } from "../auth";
import { Loading } from "../Components/Loading";
import { Route, Routes } from "react-router-dom";
import { Error404 } from "../Pages/Errors/Error404";
import SalaryComplaintsIndex from '../Pages/SalaryComplaints';
import CreateSalaryComplaint from '../Pages/SalaryComplaints/create';
import ViewSalaryComplaint from '../Pages/SalaryComplaints/view';
import EditSalaryComplaint from '../Pages/SalaryComplaints/edit';
import PaymentStatus from '../Pages/SalaryComplaints/statusUpdate';

export const SalaryComplaintsRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <SalaryComplaintsIndex />
              </React.Suspense>
            }
          />


          <Route
            path="/create"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateSalaryComplaint />
              </React.Suspense>
            }
          />

          <Route
            path="view/:salaryComplaintId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewSalaryComplaint />
              </React.Suspense>
            }
          />

          <Route
            path="update-status/:salaryComplaintId"
            element={
              <React.Suspense fallback={<Loading />}>
                <PaymentStatus />
              </React.Suspense>
            }
          />

          <Route
            path="edit/:salaryComplaintId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditSalaryComplaint />
              </React.Suspense>
            }
          />
        </Route>

        <Route path="*" element={<Error404 />} />
      </Routes>
    </RequireAuth>
  )
}
