import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryDetailsForm } from "../../Components/Staffs/PrimaryDetailsForm";

const EditStaff = () => {
  const { get } = useAxios();
  let { staffId } = useParams();
  const navigate = useNavigate();
  const [genders, setGenders] = useState({});
  const [fetching, setFetching] = useState(true);
  const [departments, setDepartments] = useState({});
  const [designations, setDesignations] = useState({});
  const [staffDetails, setStaffDetails] = useState({});
  const [branchLocations, setBranchLocations] = useState({});
  const [maritalStatuses, setMaritalStatuses] = useState({});

  useEffect(() => {
    setFetching(true);
    const getEditDetails = async () => {
      try {
        const response = await get(`human_resources/staffs/${staffId}/edit`);
        setGenders(response.data.gender);
        setStaffDetails(response.data.userData);
        setDepartments(response.data.departments);
        setDesignations(response.data.designation);
        setBranchLocations(response.data.office_location);
        setMaritalStatuses(response.data.maritalStatuses);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getEditDetails();
  }, []);

  return (
    <>
      <Card className="container">
        {!!fetching && <Loading />}

        {!fetching && !!staffDetails?.id && (
          <>
            <Card.Header className="d-flex justify-content-between mt-0 mb-5">
              <Card.Title>
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="page-title">
                      Edit Employee Personal Details
                    </h1>
                  </div>
                </div>
              </Card.Title>

              <div className="row">
                <div className="col-md-12">
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </div>
              </div>
            </Card.Header>

            <Card.Body>
              <PrimaryDetailsForm
                genders={genders}
                departments={departments}
                designations={designations}
                editPersonalInfo={staffDetails}
                branchLocations={branchLocations}
                maritalStatuses={maritalStatuses}
              />
            </Card.Body>
          </>
        )}
      </Card>
    </>
  );
};

export default EditStaff;
