import React from "react";
import { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../Components/Loading";
import { useRolesAndPermissionsData } from "../../../hooks/useRolesAndPermissionsData";
import { RolesAndPermissionsForm } from "../../../Components/RolesPermissions/Roles/RolesAndPermissionsForm";

const CreateRolesPermissions = () => {
  const {
    fetching,
    failedFetching,
    permissions,
    fetchRolesAndPermissionsData,
  } = useRolesAndPermissionsData(`roles/create`);

  const navigate = useNavigate();

  useEffect(() => {
    const getRolesAndPermissionsData = async () => {
      fetchRolesAndPermissionsData();
    };
    getRolesAndPermissionsData();
  }, []);

  return (
    <>
      {!!fetching ? (
        <Loading />
      ) : (
        <Card className="container">
          <Card.Header className="d-flex justify-content-between mt-0 mb-5">
            <Card.Title>
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">Create Role</h1>
                </div>
              </div>
            </Card.Title>

            <div className="row">
              <div className="col-md-12">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <RolesAndPermissionsForm permissions={permissions} />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default CreateRolesPermissions;
