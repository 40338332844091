import React from "react";
import { Link } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { BsPencilSquare } from "react-icons/bs";
import { permissions } from "../../utils/permissions";
import getDateRangeOfWeek from "../../utils/dateRangeFunction";
import { handleCustomEvent } from "../../utils/customEventLister";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import { Badge, Dropdown, DropdownButton, Table } from "react-bootstrap";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";

export const DisplayedStatusReportsTable = ({
  statusReports,
  getVehiclesStatusReport,
}) => {
  const { axiosDelete } = useAxios();
  const { hasPermission } = usePermissionAndDomain();

  const onDeleteClick = (statusReport) => {
    handleCustomEvent("show-confirmation-modal", {
      action: async () => {
        try {
          await axiosDelete(`vehicle_status/${statusReport.id}`);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          alertSuccess(
            `Report from ${statusReport?.office_location?.name} for Week ${statusReport?.week_number} successfully deleted!`,
            null,
            5000
          );
          getVehiclesStatusReport();
        } catch (error) {
          alertWarning("Your request failed!", null, 500);
        }
      },
      question: `Are you sure you want to delete report from ${statusReport?.office_location?.name} for Week ${statusReport?.week_number}?`,
    });
  };

  return (
    <>
      <Table
        hover
        bordered
        responsive
        className=" table-vcenter table-mobile-md card-table"
      >
        <thead>
          <tr>
            <th>WEEK</th>
            <th>LOCATION</th>
            <th>REPORTED BY</th>
            <th>STATUS</th>
            <th>CONTROLS</th>
          </tr>
        </thead>

        <tbody>
          {statusReports.map((statusReport) => (
            <tr key={statusReport?.id}>
              <td data-label="WEEK" className="text-uppercase">
                <strong>Week {statusReport?.week_number}, </strong>

                <br />

                {getDateRangeOfWeek(
                  Number(statusReport.week_number),
                  Number(statusReport.year)
                )}
              </td>

              <td data-label="LOCATION">
                {!!statusReport?.office_location
                  ? `${statusReport?.office_location?.name}`
                  : "N/A"}
              </td>

              <td data-label="REPORTED BY">
                {!!statusReport?.reported_by
                  ? `${statusReport?.reported_by?.first_name} ${statusReport?.reported_by?.surname}`
                  : "N/A"}
              </td>
              <td data-label="STATUS">
                {!!Number(statusReport?.is_sent) ? (
                  <Badge bg="success" className="fs-5 mt-2">
                    &#x2713; SENT
                  </Badge>
                ) : (
                  <Badge bg="secondary" className="fs-5 mt-2">
                    <span>
                      <BsPencilSquare /> DRAFT
                    </span>
                  </Badge>
                )}
              </td>
              <td className="d-flex justify-content-center ">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  {hasPermission(permissions.VIEW_VEHICLES_STATUS_REPORT) &&
                    statusReport?.is_sent != 0 && (
                      <Dropdown.Item
                        as={Link}
                        to={`/vehicles/status-reports/view/${statusReport?.id}`}
                        size="sm"
                        className="justify-content-center"
                      >
                        View
                      </Dropdown.Item>
                    )}
                  {hasPermission(permissions.EDIT_VEHICLES_STATUS_REPORT) &&
                    statusReport?.is_sent == 0 && (
                      <Dropdown.Item
                        as={Link}
                        to={`/vehicles/status-reports/edit/${statusReport?.id}`}
                        size="sm"
                        className="justify-content-center"
                      >
                        Edit
                      </Dropdown.Item>
                    )}
                  {hasPermission(permissions.DELETE_VEHICLES_STATUS_REPORT) &&
                    statusReport?.is_sent == 0 && (
                      <Dropdown.Item
                        as={Link}
                        size="sm"
                        to={"#"}
                        className="justify-content-center"
                        onClick={() => {
                          onDeleteClick(statusReport);
                        }}
                      >
                        Delete
                      </Dropdown.Item>
                    )}
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <br />
    </>
  );
};
