import React from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { permissions } from "../../utils/permissions";
import { handleStatusBadge } from "./handleStatusBadge";
import { handleInspectionBage } from "./handleInspectionBadge";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";

export const MaintenanceRecordDataTable = ({ maintenanceRecords }) => {
  const { hasPermission } = usePermissionAndDomain();

  return (
    <>
      <Table
        hover
        bordered
        responsive
        className=" table-vcenter table-mobile-md card-table"
      >
        <thead>
          <tr>
            <th>VOUCHER NO.</th>
            <th>REPORTED DATE</th>
            <th>APPROVED DATE</th>
            <th>VEHICLE</th>
            <th>VENDOR</th>
            <th>REQUESTED AMOUNT (&#8358;)</th>
            <th>APPROVED AMOUNT (&#8358;)</th>
            <th>STATUS</th>
            <th>INSPECTION</th>
            <th>CONTROLS</th>
          </tr>
        </thead>

        <tbody>
          {maintenanceRecords.map((maintenanceRecord) => (
            <tr key={maintenanceRecord.id}>
              <td data-label="VOUCHER NO.">
                <Link to={`/maintenance-records/view/${maintenanceRecord.id}`}>
                  {maintenanceRecord?.request_voucher_number || "N/A"}
                </Link>
              </td>

              <td data-label="REPORTED DATE">
                <Link to={`/maintenance-records/view/${maintenanceRecord.id}`}>
                  {format(
                    new Date(maintenanceRecord?.reported_date),
                    "dd-MM-yyyy",
                    {
                      awareOfUnicodeTokens: true,
                    }
                  ) || "N/A"}
                </Link>
              </td>
              <td data-label="APPROVED DATE">
                {maintenanceRecord?.approved_date || "N/A"}
              </td>
              <td data-label="VEHICLE">
                <div>
                  {!!maintenanceRecord?.vehicle ? (
                    <>
                      <div>{maintenanceRecord?.vehicle?.reg_number}</div>
                      <div>
                        {
                          maintenanceRecord?.vehicle?.vehicle_model
                            ?.vehicle_make?.name
                        }{" "}
                        - {maintenanceRecord?.vehicle?.vehicle_model?.name}
                      </div>
                    </>
                  ) : (
                    "N/A"
                  )}
                </div>
              </td>
              <td data-label="VENDOR">
                {maintenanceRecord?.vendor
                  ? `${maintenanceRecord?.vendor.name}`
                  : "N/A"}
              </td>
              <td data-label="REQUESTED AMOUNT (&#8358;)">
                {Number(maintenanceRecord?.request_amount).toLocaleString() ||
                  "N/A"}
              </td>
              <td data-label="APPROVED AMOUNT (&#8358;)">
                {Number(maintenanceRecord?.approved_amount).toLocaleString() ||
                  "N/A"}
              </td>
              <td data-label="STATUS">
                {handleStatusBadge(maintenanceRecord)}
              </td>

              <td data-label="INSPECTION">
                {handleInspectionBage(maintenanceRecord)}
              </td>
              <td className="d-flex justify-content-center ">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  {hasPermission(permissions.VIEW_MAINTENANCE_RECORDS) && (
                    <Dropdown.Item
                      as={Link}
                      to={`/maintenance-records/view/${maintenanceRecord.id}`}
                      size="sm"
                      className="justify-content-center"
                    >
                      View
                    </Dropdown.Item>
                  )}

                  {maintenanceRecord?.status === "awaiting approval" && (
                    <>
                      {hasPermission(permissions.EDIT_MAINTENANCE_RECORDS) && (
                        <Dropdown.Item
                          as={Link}
                          size="sm"
                          className="justify-content-center"
                          to={`/maintenance-records/edit/${maintenanceRecord.id}`}
                        >
                          Edit
                        </Dropdown.Item>
                      )}

                      {hasPermission(
                        permissions.APPROVE_MAINTENANCE_RECORDS
                      ) && (
                        <Dropdown.Item
                          as={Link}
                          size="sm"
                          className="justify-content-center"
                          to={`/maintenance-records/approve_reject/${maintenanceRecord.id}`}
                        >
                          Approve/Reject
                        </Dropdown.Item>
                      )}
                    </>
                  )}

                  {!maintenanceRecord?.inspected_by_employee_code &&
                    maintenanceRecord?.inspection_pass !== 1 &&
                    (maintenanceRecord?.status === "approved" ||
                      maintenanceRecord?.status ===
                        "approved with adjustment") && (
                      <>
                        {hasPermission(
                          permissions.INSPECT_MAINTENANCE_RECORDS
                        ) && (
                          <Dropdown.Item
                            as={Link}
                            size="sm"
                            className="justify-content-center"
                            to={`/maintenance-records/inspection/${maintenanceRecord.id}`}
                          >
                            Add Inspection Detail
                          </Dropdown.Item>
                        )}
                      </>
                    )}
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <br />
    </>
  );
};
