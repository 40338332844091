import React, { useState } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Badge,
  Button,
  Container,
} from "react-bootstrap";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Navigate} from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { LoadingButton } from "../LoadingButtons";
import maleAvatar from "../../images/male_avatar.png";
import { yupResolver } from "@hookform/resolvers/yup";
import femaleAvatar from "../../images/female_avatar.jpg";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object().shape({
  role_id: yup.string().required("Role is reqired"),
});

const SetRoleForm = ({ staff, roles, staffId }) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      role_id: "",
    },
  });

  const { post } = useAxios();
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await post(`human_resources/staffs/${staffId}/setRole`, data);
      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Employee role successfully set!", null, 5000);
    } catch (error) {
      setLoading(false);
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
      setHasError(true);
    }
  };

  return (
    <>
      <Container className="container-tight py-4 ">
        {isSaved && <Navigate to={`/staffs/view/${staffId}`} replace={true} />}
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Card className="w-100 mt-5 mb-5 border-radius-rounded">
            <Card.Header>
              <div className="d-flex py-1 align-items-center mt-2 mb-2">
                {staff.gender === "female" ? (
                  <span className="avatar avatar-lg me-2 border">
                    <img
                      src={femaleAvatar}
                      width="80"
                      height="78"
                      alt="Female avatar"
                    />
                  </span>
                ) : (
                  <span className="avatar avatar-lg me-2 border">
                    <img
                      src={maleAvatar}
                      width="80"
                      height="78"
                      alt="Male avatar"
                    />
                  </span>
                )}
                <div className="flex-fill">
                  <div className="text-uppercase font-weight-medium">
                    {`${staff?.first_name} ${staff?.surname}` || "N/A"}
                  </div>
                  <div className="text-muted text-uppercase">
                    {staff?.actual_employee_code || "(NO EMPLOYEE CODE FOUND)"}
                  </div>
                  <div className="text-muted text-uppercase">
                    {staff?.designation.name}
                  </div>
                </div>
              </div>
            </Card.Header>
            
            <Card.Body>
              <Form.Group
                as={Row}
                className="mt-3 mb-3 p-2"
                controlId="formPassword"
              >
                <Form.Label className="required mb-1">ROLE</Form.Label>
                <Col>
                  <Form.Select
                    {...register("role_id")}
                    autoComplete="off"
                    className="form-control"
                    onChange={(event) => {
                      setValue("role_id", event.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  >
                    <option value="" disabled>
                      Select Role
                    </option>
                    {roles.map((role, id) => (
                      <option value={role.id} key={id}>
                        {role.name}
                      </option>
                    ))}
                  </Form.Select>

                  {!!errors.role_id && (
                    <p className="justify-content-end mt-1">
                      <Badge bg="danger" className="fs-6 fw-normal">
                        {errors.role_id?.message}
                      </Badge>
                    </p>
                  )}
                </Col>
              </Form.Group>

              <div className=" d-flex justify-content-center mt-3 mb-3  ">
                {loading ? (
                 <LoadingButton />
                ) : (
                  <Button
                    data-testid="bs-btn "
                    variant="success"
                    type="submit"
                    className=""
                    disabled={!!loading || !isDirty}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </Card.Body>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default SetRoleForm;
