import React from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { format, startOfMonth } from "date-fns";

export const TopPartsTable = ({ topParts }) => {
  let startOfCurrentMonth = format(startOfMonth(new Date()), "yyyy-MM-dd", {
    awareOfUnicodeTokens: true,
  });

  let currentDateOfMonth = format(new Date(), "yyyy-MM-dd", {
    awareOfUnicodeTokens: true,
  });

  return (
    <Table
      hover
      bordered
      responsive
      className=" table-vcenter table-mobile-md card-table"
    >
      <thead>
        <tr>
          <th>PART</th>
          <th>MAINTENANCE</th>
          <th>QUANTITY</th>
          <th>REQUESTED AMOUNT (&#8358;)</th>
        </tr>
      </thead>

      <tbody>
        {topParts.map((topPart) => (
          <tr key={topPart.vehicle_part_id}>
            <td data-label="PART">
              <Link
                to={`/maintenance-records?vendor_id=&vehicle_id=&fromDate=${startOfCurrentMonth}&toDate=${currentDateOfMonth}&status=&vehicle_part_id=${topPart.vehicle_part_id}&voucher_number=&page=1`}
              >
                {topPart?.vehicle_part?.name || "N/A"}
              </Link>
            </td>

            <td data-label="MAINTENANCE">
              <Link to={"#"}>{topPart?.maintenance_count || "N/A"}</Link>
            </td>
            <td data-label="QUANTITY">{topPart?.total_quantity || "N/A"}</td>
            <td data-label="REQUESTED AMOUNT (&#8358;)">
              {Number(topPart?.total_amount).toLocaleString() || "N/A"}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
