import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Table,
  Alert,
  Badge,
  Button,
} from "react-bootstrap";
import * as yup from "yup";
import { format } from "date-fns";
import Select from "react-select";
import { FaEdit } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { Navigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { ImCancelCircle } from "react-icons/im";
import { LoadingButton } from "../LoadingButtons";
import "react-datepicker/dist/react-datepicker.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { MaintenanceTypeModal } from "./MaintenanceTypeModal";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object({
  vehicle_id: yup.string().required("Vehicle is required"),
  vendor_id: yup.string().required("Vendor is required"),
  reported_date: yup.string().nullable().required("Date of report required"),
  requested_by_employee_code: yup.string().required("This field is required"),
  invoice_date: yup
    .string()
    .when(["invoice_number"], {
      is: (invoiceNoInput) => invoiceNoInput !== "",
      then: yup.string().nullable().required("Invoice date is required"),
      otherwise: yup.string().optional().nullable(),
    })
    .nullable(),
  invoice_number: yup.string(),
  request_voucher_number: yup
    .string()
    .required("Request voucher number is required"),
  invoice_document: yup.mixed().notRequired(),
  confirmed_by_employee_code: yup.string().required("This field is required"),
  confirmed_by_2_employee_code: yup.string().required("This field is required"),
  approved_by_employee_code: yup.string(),
  approved_amount: yup.string(),
  approval_document_file: yup
    .mixed()
    .test("type", "Invoice document must be pdf or image.", (value) => {
      if (value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          return (
            value[i].type === "application/pdf" ||
            value[i].type === "image/jpeg" ||
            value[i].type === "image/png" ||
            value[i].type === "image/jpg"
          );
        }
      } else if (!value.length) {
        return true;
      }
    })
    .notRequired(),
});

export const MaintenanceRecordForm = ({
  vendors,
  vehicles,
  approvedBy,
  confirmedBy,
  vehiclePart,
  maintenanceType,
  employeeWithVehicle,
  editMaintenanceItems,
  editMaintenanceRecord,
}) => {
  const {
    watch,
    control,
    register,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      vendor_id: editMaintenanceRecord?.vendor?.id || "",
      vehicle_id: editMaintenanceRecord?.vehicle?.id || "",
      invoice_date: !!editMaintenanceRecord?.id
        ? editMaintenanceRecord?.invoice_date
        : "",
      reported_date: !!editMaintenanceRecord?.id
        ? editMaintenanceRecord?.reported_date
        : "",
      invoice_number: editMaintenanceRecord?.invoice_number || "",
      approved_amount: editMaintenanceRecord?.approved_amount || "",
      already_approved: !!Number(editMaintenanceRecord?.already_approved),
      invoice_document: editMaintenanceRecord?.invoice_document || "",
      approval_document_file:
        editMaintenanceRecord?.approval_document_file || "",
      request_voucher_number:
        editMaintenanceRecord?.request_voucher_number || "",
      approved_by_employee_code:
        editMaintenanceRecord?.approved_by_employee_code || "",
      confirmed_by_employee_code:
        editMaintenanceRecord?.confirmed_by_employee_code || "",
      requested_by_employee_code:
        editMaintenanceRecord?.requested_by_employee_code || "",
      confirmed_by_2_employee_code:
        editMaintenanceRecord?.confirmed_by_2_employee_code || "",
    },
  });

  const { postWithFile } = useAxios();
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [requestedTotal, setRequestedTotal] = useState(
    !editMaintenanceRecord?.id
      ? 0
      : Number(editMaintenanceRecord?.request_amount)
  );
  const [invoiceDocument, setInvoiceDocument] = useState([]);
  const [hasServerError, setHasServerError] = useState(false);
  const [approvalDocument, setApprovalDocument] = useState([]);
  const [requesterOptions, setRequesterOptions] = useState([]);
  const [vehicleOptionReady, setVehicleOptionReady] = useState(false);
  const [selectedRequesterValue, setSelectedRequesterValue] = useState(null);
  const [allInvoiceDataHasValue, setAllInvoiceDataHasValue] = useState(false);
  const [maintenanceItemHasValue, setMaintenanceItemHasValue] = useState(false);
  const [someInvoiceDataHasValue, setSomeInvoiceDataHasValue] = useState(false);
  const [locationRequesterOptions, setLocationRequesterOptions] = useState([]);

  const [maintenanceItems, setMaintenanceItems] = useState(
    !editMaintenanceRecord?.id ? [] : editMaintenanceItems
  );

  useEffect(() => {
    const _vehicleOptions = [];

    vehicles.forEach((vehicle) => {
      _vehicleOptions.push({
        value: vehicle?.id,
        branch_code: vehicle?.office_location_code,
        label: `${vehicle?.reg_number} (${vehicle?.vehicle_model?.vehicle_make.name} - ${vehicle?.vehicle_model?.name} )`,
      });
    });

    setVehicleOptionReady(true);
    setVehicleOptions(_vehicleOptions);

    window.addEventListener("mi:add_submit", (event) => {
      const _maintenanceItemsReturnedData = [...maintenanceItems];

      _maintenanceItemsReturnedData.push(event?.detail);

      setMaintenanceItems(_maintenanceItemsReturnedData);

      calculateRequestedTotal(_maintenanceItemsReturnedData);
    });

    window.addEventListener("mi:edit_submit", (event) => {
      const _maintenanceItemsCopy = [...maintenanceItems];

      _maintenanceItemsCopy[event?.detail?.index] =
        event.detail.maintenanceItemsAddReturnedData;

      setMaintenanceItems(_maintenanceItemsCopy);

      calculateRequestedTotal(_maintenanceItemsCopy);
    });
  }, [maintenanceItems, vehicles]);


  const onHandleRequestersOptions = (selectedVehicleOption) => {
    const _maintenanceRequesters = [];

    employeeWithVehicle.forEach((employee) => {
      if (
        selectedVehicleOption?.branch_code ===employee?.office_location_code
      ) {
        _maintenanceRequesters.push({
          value: employee?.employee_code,
          branch_code: employee?.office_location_code,
          label: `${employee?.first_name} ${employee?.middle_name} ${employee?.surname}`,
        });
      }
    });
    setLocationRequesterOptions(_maintenanceRequesters);
  };

  const showAlreadyApprovedBackDating = watch("already_approved");

  const vendorOptions = Object.keys(vendors).map((key) => ({
    value: key,
    label: vendors[key],
  }));

  const approvedByOptions = Object.keys(approvedBy).map((key) => ({
    value: key,
    label: approvedBy[key],
  }));

  const confirmedByOptions = Object.keys(confirmedBy).map((key) => ({
    value: key,
    label: confirmedBy[key],
  }));

  const handleInvoiceDocumentUpload = (event) => {
    setInvoiceDocument(event.target.files);
  };

  const handleApprovalDocumentUpload = (event) => {
    setApprovalDocument(event.target.files);
  };

  const calculateRequestedTotal = (maintenanceItems) => {
    let _requestedTotal = 0;

    maintenanceItems.forEach((item) => {
      _requestedTotal = Number(item.amount) + _requestedTotal;
    });

    setRequestedTotal(_requestedTotal);
  };

  const onHandleDeleteItem = (index) => {
    const newMaintenanceItemArray = [];

    for (let key in maintenanceItems) {
      if (key !== index) {
        newMaintenanceItemArray.push(maintenanceItems[key]);
      }
    }

    setMaintenanceItems(newMaintenanceItemArray);

    calculateRequestedTotal(newMaintenanceItemArray);
  };

  const invoiceNoInput = watch("invoice_number");

  const [reportDate, setReportDate] = useState("");

  const [invoiceDate, setInvoiceDate] = useState("");

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const endpointPath = !editMaintenanceRecord?.id
        ? "maintenance/store"
        : `maintenance/${editMaintenanceRecord?.id}/update`;

      const invoiceData = {
        invoice_date: data?.invoice_date,
        invoice_number: data?.invoice_number,
      };

      let invoiceDataObject;
      let invoiceDataValidation;

      invoiceDataObject = Object.values(invoiceData).some(
        (invoiceValue) => invoiceValue !== ""
      );

      setSomeInvoiceDataHasValue(invoiceDataObject);

      if (!!invoiceDataObject) {
        invoiceDataValidation = Object.values(invoiceData).every(
          (invoiceValue) => invoiceValue !== ""
        );

        setAllInvoiceDataHasValue(invoiceDataValidation);
      }

      if (!maintenanceItems?.length) {
        setLoading(false);
        setIsSaved(false);
        setIsSubmitted(false);
        setMaintenanceItemHasValue(false);
      }

      setMaintenanceItemHasValue(true);

      if (!invoiceDataValidation && !!invoiceDataObject) {
        setLoading(false);
        setIsSaved(false);
        setIsSubmitted(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }

      const submittedData = !!data?.invoice_date ? {
        ...data,
        reported_date: format(new Date(data?.reported_date), "yyyy-MM-dd", {
          awareOfUnicodeTokens: true,
        }),

        invoice_date: format(new Date(data?.invoice_date), "yyyy-MM-dd", {
          awareOfUnicodeTokens: true,
        }),

        invoice_document: invoiceDocument,

        approval_document_file: approvalDocument,

        maintenance_item: maintenanceItems,

        request_amount: requestedTotal
      }
      :
      {
        ...data,
        reported_date: format(new Date(data?.reported_date), "yyyy-MM-dd", {
          awareOfUnicodeTokens: true,
        }),
        invoice_date: "",

        invoice_document: invoiceDocument,

        approval_document_file: approvalDocument,

        maintenance_item: maintenanceItems,

        request_amount: requestedTotal
      }

      await postWithFile(
        endpointPath,
        submittedData,
        false
      );
      setIsSubmitted(true);
      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Maintenance record successfully saved!", null, 5000);
    } catch (error) {
      setLoading(false);
      setIsSaved(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
      if (error?.response?.status === 422) {
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            setLoading(false);
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) =>
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              })
            );
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error?.response?.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to create maintenance record!",
          null,
          5000
        );
      }
    }
  };

  return (
    <>
      {isSaved && <Navigate to={"/maintenance-records"} replace={true} />}

      {!allInvoiceDataHasValue &&
        !!someInvoiceDataHasValue &&
        !!isSubmitted && (
          <Alert variant="danger">
            <p>All invoice related input must have value!</p>
          </Alert>
        )}

      {!!vehicleOptionReady && (
        <>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {hasServerError && (
              <Alert variant="danger">
                <p>
                  Something went wrong will processing your request. Please try
                  again!
                </p>
              </Alert>
            )}

            <Card>
              <Card.Body>
                <Row>
                  <Col className="vehicle-id mb-4">
                    <Form.Group>
                      <Form.Label className="required">VEHICLE</Form.Label>
                      <Controller
                        name="vehicle_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            name={field.name}
                            ref={field.ref}
                            className="text-dark"
                            options={vehicleOptions}
                            onChange={(selectedVehicleOption) => {
                              setValue(
                                "vehicle_id",
                                selectedVehicleOption.value,
                                {
                                  shouldValidate: true,
                                }
                              );

                              setSelectedRequesterValue(null);

                              onHandleRequestersOptions(selectedVehicleOption);

                              setValue("requested_by_employee_code", "", {
                                shouldValidate: true,
                              });

                              setRequesterOptions(
                                requesterOptions[
                                selectedVehicleOption?.value
                                ] ?? []
                              );
                            }}
                            defaultValue={
                              !!editMaintenanceRecord?.id
                                ? {
                                  value:
                                    editMaintenanceRecord?.vehicle_model?.id,
                                  label: `${editMaintenanceRecord?.vehicle?.reg_number} (${editMaintenanceRecord?.vehicle?.vehicle_model?.vehicle_make?.name} - ${editMaintenanceRecord?.vehicle?.vehicle_model?.name} )`,
                                }
                                : null
                            }
                            placeholder={"Select vehicle"}
                          />
                        )}
                      />

                      {!!errors?.vehicle_id && (
                        <p>
                          <Badge bg="danger">
                            {errors?.vehicle_id?.message}
                          </Badge>
                        </p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col className="reported-date mb-4" sm={6}>
                    <Form.Group>
                      <Form.Label className="required">
                        REPORTED DATE
                      </Form.Label>
                      <Controller
                        name="reported_date"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            isClearable
                            strictParsing
                            showYearDropdown
                            showMonthDropdown
                            autoComplete="off"
                            maxDate={new Date()}
                            closeOnScroll={true}
                            dropdownMode="select"
                            selected={reportDate}
                            dateFormat="dd-MM-yyyy"
                            className="form-control"
                            onChange={(date) => {
                              setReportDate(date);
                              setValue("reported_date", date, {
                                shouldValidate: true,
                              });
                            }}
                            placeholderText="Enter reported date"
                          />
                        )}
                      />

                      {!!errors?.reported_date && (
                        <p>
                          <Badge bg="danger">
                            {errors?.reported_date?.message}
                          </Badge>
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="requested-by-employee-code mb-4">
                    <Form.Group>
                      <Form.Label className="required">REQUESTED BY</Form.Label>
                      <Controller
                        name="requested_by_employee_code"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            name={field.name}
                            ref={field.ref}
                            className="text-dark"
                            options={locationRequesterOptions}
                            onChange={(selectedRequesterOption) => {
                              setValue(
                                "requested_by_employee_code",
                                selectedRequesterOption?.value,
                                {
                                  shouldValidate: true,
                                }
                              );

                              setSelectedRequesterValue({
                                value: selectedRequesterOption?.value,
                                label: selectedRequesterOption?.label,
                              });
                            }}
                            value={
                              !!editMaintenanceRecord?.requester
                                ? {
                                  value:
                                    editMaintenanceRecord?.requester
                                      ?.employee_code,
                                  label: `${editMaintenanceRecord?.requester?.first_name} ${editMaintenanceRecord?.requester?.surname}`,
                                }
                                : selectedRequesterValue
                            }
                            placeholder={"Select requester"}
                          />
                        )}
                      />

                      {!!errors?.requested_by_employee_code && (
                        <p>
                          <Badge bg="danger">
                            {errors?.requested_by_employee_code?.message}
                          </Badge>
                        </p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col className="request-voucher-number mb-4" sm={6}>
                    <Form.Group controlId="requestVoucherNumber">
                      <Form.Label className="required">
                        REQUEST VOUCHER NUMBER
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Request voucher number"
                        {...register("request_voucher_number")}
                      />

                      {!!errors?.request_voucher_number && (
                        <p>
                          <Badge bg="danger">
                            {errors?.request_voucher_number?.message}
                          </Badge>
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="confirmed-by-employee-code mb-4">
                    <Form.Group>
                      <Form.Label className="required">CONFIRMED BY</Form.Label>
                      <Controller
                        name="confirmed_by_employee_code"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            name={field.name}
                            ref={field.ref}
                            className="text-dark"
                            options={confirmedByOptions}
                            onChange={(confirmerOneOption) => {
                              setValue(
                                "confirmed_by_employee_code",
                                confirmerOneOption?.value,
                                {
                                  shouldValidate: true,
                                }
                              );
                            }}
                            defaultValue={
                              !!editMaintenanceRecord?.confirmer1
                                ? {
                                  value:
                                    editMaintenanceRecord?.confirmer1?.id,
                                  label: `${editMaintenanceRecord?.confirmer1?.first_name} ${editMaintenanceRecord?.confirmer1?.surname}`,
                                }
                                : null
                            }
                            placeholder={"Select one"}
                          />
                        )}
                      />

                      {!!errors?.confirmed_by_employee_code && (
                        <p>
                          <Badge bg="danger">
                            {errors?.confirmed_by_employee_code?.message}
                          </Badge>
                        </p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col className="confirmed-by-2-employee-code mb-4" sm={6}>
                    <Form.Group>
                      <Form.Label className="required">
                        CONFIRMED BY 2
                      </Form.Label>
                      <Controller
                        name="confirmed_by_2_employee_code"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            name={field.name}
                            ref={field.ref}
                            className="text-dark"
                            options={confirmedByOptions}
                            onChange={(confirmerTwoOption) => {
                              setValue(
                                "confirmed_by_2_employee_code",
                                confirmerTwoOption?.value,
                                {
                                  shouldValidate: true,
                                }
                              );
                            }}
                            defaultValue={
                              !!editMaintenanceRecord?.confirmer2
                                ? {
                                  value:
                                    editMaintenanceRecord?.confirmer2?.id,
                                  label: `${editMaintenanceRecord?.confirmer2?.first_name} ${editMaintenanceRecord?.confirmer2?.surname}`,
                                }
                                : null
                            }
                            placeholder={"Select one"}
                          />
                        )}
                      />

                      {!!errors?.confirmed_by_2_employee_code && (
                        <p>
                          <Badge bg="danger">
                            {errors?.confirmed_by_2_employee_code?.message}
                          </Badge>
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="vendor-id mb-4">
                    <Form.Group>
                      <Form.Label className="required">VENDOR</Form.Label>
                      <Controller
                        name="vendor_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            name={field.name}
                            ref={field.ref}
                            className="text-dark"
                            options={vendorOptions}
                            onChange={(vendorOption) => {
                              setValue("vendor_id", vendorOption.value, {
                                shouldValidate: true,
                              });
                            }}
                            defaultValue={
                              !!editMaintenanceRecord?.vendor
                                ? {
                                  value: editMaintenanceRecord?.vendor?.id,
                                  label: editMaintenanceRecord?.vendor?.name,
                                }
                                : null
                            }
                            placeholder={"Select vendor"}
                          />
                        )}
                      />

                      {!!errors.vendor_id && (
                        <p>
                          <Badge bg="danger">{errors.vendor_id?.message}</Badge>
                        </p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col className="invoice-date mb-4" sm={6}>
                    <Form.Group>
                      <Form.Label
                        className={invoiceNoInput !== "" ? "required" : ""}
                      >
                        INVOICE DATE
                      </Form.Label>
                      <Controller
                        name="invoice_date"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            isClearable
                            strictParsing
                            showYearDropdown
                            showMonthDropdown
                            autoComplete="off"
                            maxDate={new Date()}
                            closeOnScroll={true}
                            dropdownMode="select"
                            selected={invoiceDate}
                            dateFormat="dd-MM-yyyy"
                            className="form-control"
                            onChange={(date) => {
                              setInvoiceDate(date);
                              setValue("invoice_date", date, {
                                shouldValidate: true,
                              });
                            }}
                            placeholderText="Enter invoice date"
                          />
                        )}
                      />

                      {!!errors?.invoice_date && (
                        <p>
                          <Badge bg="danger">
                            {errors?.invoice_date?.message}
                          </Badge>
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="invoice-number mb-4" sm={6}>
                    <Form.Group controlId="invoiceNumber">
                      <Form.Label
                        className={invoiceDate !== "" ? "required" : ""}
                      >
                        INVOICE NUMBER
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Invoice number"
                        {...register("invoice_number")}
                      />

                      {!!errors?.invoice_number && (
                        <p>
                          <Badge bg="danger">
                            {errors?.invoice_number?.message}
                          </Badge>
                        </p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col className="invoice-document mb-4" sm={6}>
                    <Form.Group controlId="invoiceDocument">
                      <Form.Label
                      >
                        INVOICE DOCUMENT
                      </Form.Label>
                      <Form.Control
                        className="text-dark"
                        {...register("invoice_document")}
                        type="file"
                        onChange={handleInvoiceDocumentUpload}
                        accept=".pdf, .jpeg, .png, .jpg"
                      // defaultValue={
                      //   !!editMaintenanceRecord?.id
                      //     ? editMaintenanceRecord?.invoice_document
                      //     : null
                      // }
                      />

                      {!!errors?.invoice_document && (
                        <p>
                          <Badge bg="danger">
                            {errors?.invoice_document?.message}
                          </Badge>
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <div className="mb-4">
                  <Button
                    type="button"
                    onClick={(event) => {
                      dispatchEvent(new CustomEvent("mi:add"));
                    }}
                  >
                    Add TYPE
                  </Button>
                </div>

                {!maintenanceItemHasValue && !!isSubmitted && (
                  <Alert variant="danger">
                    <p>A maintenace type must be added before submitting!</p>
                  </Alert>
                )}

                <Table
                  hover
                  bordered
                  responsive
                  className=" table-vcenter table-mobile-md card-table"
                >
                  <thead>
                    <tr>
                      <th>TYPE</th>
                      <th>VEHICLE PART</th>
                      <th>QUANTITY</th>
                      <th>RATE (&#8358;)</th>
                      <th>AMOUNT (&#8358;)</th>
                      <th>CONTROLS</th>
                    </tr>
                  </thead>

                  <tbody>
                    {!maintenanceItems?.length ? (
                      <tr>
                        <td colSpan={5} className="text-center ">
                          No maintenance type added yet!
                        </td>
                      </tr>
                    ) : (
                      <>
                        {maintenanceItems.map((maintenanceItem, index) => (
                          <tr key={index}>
                            <td data-label="TYPE">
                              {maintenanceItem?.type || "N/A"}
                            </td>
                            <td data-label="VEHICLE PART">
                              {vehiclePart[maintenanceItem?.vehicle_part_id] ||
                                "N/A"}
                            </td>
                            <td data-label="QUANTITY">
                              {maintenanceItem?.quantity || "N/A"}
                            </td>
                            <td data-label="RATE (&#8358;)">
                              {maintenanceItem?.rate || "N/A"}
                            </td>
                            <td data-label="AMOUNT (&#8358;)">
                              {maintenanceItem?.amount || "N/A"}
                            </td>
                            <td data-label="CONTROLS">
                              <Button
                                variant="primary"
                                size="sm"
                                type="button"
                                className="m-2"
                                onClick={(event) => {
                                  dispatchEvent(
                                    new CustomEvent("mi:edit", {
                                      detail: {
                                        editMaintenanceRecord,
                                        maintenanceItem,
                                        index,
                                      },
                                    })
                                  );
                                }}
                              >
                                <FaEdit />
                              </Button>

                              <Button
                                variant="danger"
                                size="sm"
                                className="m-2"
                                onClick={() => onHandleDeleteItem(index)}
                              >
                                <ImCancelCircle />
                              </Button>
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <th colSpan={4}>Total Amount Requested</th>
                          <td className="text-left" colSpan={2}>
                            {requestedTotal}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>

                <div className="mt-4 mb-4">
                  <Form.Group controlId="alreadyApprovedCheck">
                    <Form.Check
                      {...register("already_approved")}
                      type="checkbox"
                      name="already_approved"
                      id="already_approved"
                      label="Already Approved"
                      value={true}
                    />
                  </Form.Group>
                </div>

                {showAlreadyApprovedBackDating === "true" && (
                  <div className="mb-4">
                    <Form.Group
                      as={Row}
                      className="mb-4"
                      controlId="approvedAmount"
                    >
                      <Form.Label column sm="4" className="required">
                        APPROVED AMOUNT (&#8358;)
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          {...register("approved_amount")}
                          placeholder="Approved amount"
                        />
                      </Col>

                      {!!errors?.approved_amount && (
                        <p>
                          <Badge className="mt-1" bg="danger">
                            {errors?.approved_amount?.message}
                          </Badge>
                        </p>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-4"
                      controlId="approvedBy"
                    >
                      <Form.Label column sm="4" className="required">
                        APPROVED BY
                      </Form.Label>
                      <Col sm="8">
                        <Controller
                          name="approved_by_employee_code"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Select
                              name={field.name}
                              ref={field.ref}
                              className="text-dark"
                              options={approvedByOptions}
                              onChange={(approverOption) => {
                                setValue(
                                  "approved_by_employee_code",
                                  approverOption.value,
                                  {
                                    shouldValidate: true,
                                  }
                                );
                              }}
                              defaultValue={null}
                              placeholder={"Select Approver"}
                            />
                          )}
                        />
                      </Col>

                      {!!errors?.approved_by_employee_code && (
                        <p>
                          <Badge className="mt-1" bg="danger">
                            {errors?.approved_by_employee_code?.message}
                          </Badge>
                        </p>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-4"
                      controlId="approvalDocument"
                    >
                      <Form.Label column sm="4">
                        APPROVAL DOCUMEMNT
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          className="text-dark"
                          {...register("approval_document_file")}
                          type="file"
                          onChange={handleApprovalDocumentUpload}
                          accept=".pdf, .jpeg, .png, .jpg"
                        />
                      </Col>

                      {!!errors?.approval_document_file && (
                        <p>
                          <Badge className="mt-1" bg="danger">
                            {errors?.approval_document_file?.message}
                          </Badge>
                        </p>
                      )}
                    </Form.Group>
                  </div>
                )}
              </Card.Body>
            </Card>

            <Card.Footer className="d-flex justify-content-center">
              {loading ? (
                <LoadingButton />
              ) : (
                <Button
                  variant="success"
                  type="submit"
                  className=" d-flex justify-content-center mt-3"
                  onClick={(event) => {
                    setIsSubmitted(true);
                    // handleStatusAlert();
                  }}
                // disabled={
                //   !editMaintenanceRecord?.id
                //     ? !!loading || !isDirty
                //     : !!loading
                // }
                >
                  Submit
                </Button>
              )}
            </Card.Footer>
          </Form>

          <MaintenanceTypeModal
            show={show}
            handleShow={handleShow}
            handleClose={handleClose}
            vehiclePart={vehiclePart}
            maintenanceType={maintenanceType}
          />
        </>
      )}
    </>
  );
};
