import React, { useState } from "react";
import axios from "axios";
import * as yup from "yup";
import { API_URL } from "../config";
import { useForm } from "react-hook-form";
import Asco_logo from "../images/Asco_logo.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Card,
  Form,
  Badge,
  Button,
  Container,
  InputGroup,
} from "react-bootstrap";
import { LoadingButton } from "./LoadingButtons";
import { alertWarning } from "./CustomAlerts/AlertToast";
import prepareUserDomainAndPermissionsMap from "../hooks/prepareUserDomainAndPermissionsMap";

const schema = yup.object().shape({
  email: yup.string().email("Email is required").required(),
  password: yup.string().min(5).max(15).required("Password is required"),
});

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      email: "",
      password: "",
    },
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({})

  const [networkFail, setNetworkFail] = useState(false);

  const getCurrentUser = async () => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.get(`${API_URL}/user/me`, {
        headers: {
          Authorization: "Bearer" + token,
        },
      });
      

      if (!response?.data?.employee_code) {
        localStorage.removeItem("access_token");
        return response
      }

      setUser(response.data);
      

      if (!!response.data.isControlRoomOfficer || !!response.data.isEscortCommander) {
        navigate("/escort-schedules");
        // navigate("/escort-schedules/calendar_view");
      } else {
        navigate("/dashboard");
      }

      localStorage.setItem("user", JSON.stringify(response.data));

      const userDomainsAndPermissions = prepareUserDomainAndPermissionsMap(
        response.data
      );

      localStorage.setItem(
        "userPermissions",
        JSON.stringify(userDomainsAndPermissions.permissions)
      );

      localStorage.setItem(
        "userDomains",
        JSON.stringify(userDomainsAndPermissions.domains)
      );
    } catch (error) {
      setLoading(false);
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );

      if (error?.code === "ERR_NETWORK") {
        setNetworkFail(true);
      } else {
        setHasError(true);
      }
      // setHasError(true);

      

      // setErrorCode(error.code);
      localStorage.removeItem("access_token");
    }
  };

  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  if (!!(loggedInUser?.id)) {
    return <Navigate to="/dashboard" replace={true} />
  }





  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/auth/login`, data)
      localStorage.setItem("access_token", response.data.token);
      setLoading(true);
      getCurrentUser();
    } catch (error) {
      setLoading(false);

      if (error?.code === "ERR_NETWORK") {
        setNetworkFail(true);
      } else {
        setHasError(true);
      }

      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
    }
  };

  return (
    <>
      <Container className="container-tight py-4">
        <div className="text-center mb-4">
          <Link to="." className="navbar-brand navbar-brand-autodark">
            <img
              src={Asco_logo}
              style={{ width: 36, height: 36 }}
              alt="ASCO Logo"
            />{" "}
          </Link>
          <span className="fw-bold fs-1 "> ASCO UNIFFIED APPLICATION</span>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Card className="w-100 mt-5 mb-5">
            <Card.Body>
              <div
                className="d-flex justify-content-center mb-4"
                data-testid="errorMsg"
                style={{ visibility: hasError ? "visible" : "hidden" }}
              >
                {!!hasError && (
                  <p className="justify-content-end mt-3">
                    <Badge bg="danger">Invalid Login Details</Badge>
                  </p>
                )}

                {!!networkFail && (
                  <p className="justify-content-end mt-3">
                    <Badge bg="danger">Network Failed</Badge>
                  </p>
                )}
              </div>

              <Card.Title className="text-center mb-4"> Login </Card.Title>

              <Form.Group className="mb-4" controlId="formEmail">
                <Form.Label className="required mb-1">Email Address</Form.Label>

                <Form.Control
                  type="email"
                  name="email"
                  placeholder="email"
                  {...register("email")}
                />

                {!!errors.email && (
                  <p className="justify-content-end mt-1">
                    <Badge bg="danger" className="fs-6 fw-normal">
                      {errors.email?.message}
                    </Badge>
                  </p>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label className="required mb-1">Password </Form.Label>
                <InputGroup className="">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    name="password"
                    autoComplete="off"
                    placeholder="Password"
                    {...register("password")}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      className="border-0"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon m-0 "
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="12" r="2" />
                        <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                      </svg>
                    </Button>
                  </InputGroup.Text>
                </InputGroup>

                {!!errors.password && (
                  <p className="justify-content-end mt-1">
                    <Badge bg="danger" className="fs-6 fw-normal">
                      {errors.password?.message}
                    </Badge>
                  </p>
                )}
              </Form.Group>

              <Form.Text className="required-fields mb-1">
                {" "}
                Required Fields
              </Form.Text>
            </Card.Body>

            <Card.Footer className=" d-flex justify-content-center  ">
              {loading ? (
                <LoadingButton />
              ) : (
                <Button
                  data-testid="bs-btn "
                  variant="primary"
                  type="submit"
                  className=""
                  disabled={!!loading || !isDirty}
                >
                  Login
                </Button>
              )}
            </Card.Footer>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default Login;
