import React from "react";
import { RequireAuth } from "../../auth";
import { Route, Routes } from "react-router-dom";
import { Loading } from "../../Components/Loading";
import { Error404 } from "../../Pages/Errors/Error404";
import VendorIndex from "../../Pages/Vendors/index";
import CreateVendor from "../../Pages/Vendors/create";
import ViewVendor from "../../Pages/Vendors/view";
import EditVendor from "../../Pages/Vendors/edit";

export const VendorRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <VendorIndex />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="/create"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateVendor />
              </React.Suspense>
            }
          />
          <Route
            path="view/:vendorId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewVendor />
              </React.Suspense>
            }
          />

          <Route
            path="edit/:vendorId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditVendor />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
};
