import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useNavigate, useParams } from "react-router-dom";
import {
  Col,
  Nav,
  Row,
  Card,
  Button,
  ListGroup,
  Container,
} from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { IncidentCommentForm } from "../../Components/IncidentReports/IncidentCommentForm";
import { HtmlContent } from "../../Components/HtmlContent";
import { format } from "date-fns";
import { ShowMore } from "../../hooks/showMore";

const IncidentReportComment = () => {
  const { get } = useAxios();
  let { reportId } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [incidentReport, setIncidentReport] = useState({});
  const [viewMore, setViewMore] = useState(false);
  const [viewMoreComments, setViewMoreComments] = useState(false);

  const handleMoreIncidentDetails = () => setViewMore(!viewMore)
  const handleMoreComments = () => setViewMoreComments(!viewMoreComments)

  useEffect(() => {
    setFetching(true);
    const getDetailedIncidentReport = async () => {
      try {
        const response = await get(`incidents/${reportId}`);

        setClient(response.data.clients);
        setIncidentReport(response.data);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedIncidentReport();
  }, []);

  return (
    <div className="page-wrapper">
      <Container fluid>
        {!!fetching && <Loading />}

        {!!incidentReport && !fetching && (
          <>
            <div className="page-header d-print-none">
              <Row className="align-items-center">
                <Col>
                  <div className="page-pretitle">INCIDENT REPORT </div>
                </Col>
              </Row>
            </div>

            <div className="page-body">
              <Nav className="justify-content-end">
                <Nav.Item>
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </Nav.Item>
              </Nav>

              <br />

              <Row>
                <Col className="stretch-card">
                  <Card className="mb-5">
                    <Card.Header className="d-flex justify-content-between mt-2 mb-3">
                      <Card.Title> ADD COMMENT </Card.Title>
                    </Card.Header>

                    <Card.Body >
                      <ShowMore className="d-flex justify-content-between mt-2 mb-4" title="Click to see detail" heading={`PREVIOUS COMMENTS (${incidentReport?.comments?.length})`} handleShowMore={handleMoreComments} />

                      {/* <div  className="mb-3"> */}
                      {!!incidentReport?.comments?.length && !!viewMoreComments && (
                        <ListGroup className="list-group-flush mb-4" style={{ height: 250, overflowY: 'auto', backgroundColor: "rgb(211, 211, 211)" }}>
                          {incidentReport.comments.map((comment) => (
                            <ListGroup.Item key={comment.id}>
                              <div className=" h-25">
                                <Card.Text className="fs-3">
                                  {comment?.employee?.first_name} {comment?.employee?.surname}

                                  <span className="ms-3 text-muted">
                                    {format(
                                      new Date(comment?.created_at),
                                      "EEE, MMM d, yyyy",
                                      {
                                        awareOfUnicodeTokens: true,
                                      }
                                    )}

                                  </span>
                                </Card.Text>

                                <HtmlContent>
                                  {comment?.comment || "N/A"}
                                </HtmlContent>
                              </div>
                            </ListGroup.Item>

                          )
                          )}
                        </ListGroup>
                      )}

                      {/* </div> */}

                      <IncidentCommentForm reportId={reportId} />
                    </Card.Body>
                  </Card>
                </Col>

                <Col md={6} className="stretch-card">
                  <Card className="mb-5">
                    <ShowMore className="d-flex justify-content-between mt-2 mb-4" title="click to see detail" heading="VIEW INCIDENT SUMARRY" handleShowMore={handleMoreIncidentDetails} />

                    <Card.Body className="mb-4">
                      <ListGroup className="list-group-flush">
                        <ListGroup.Item>
                          <label className="fw-bolder fs-3">INCIDENT DETAILS</label>
                          <Row className="mt-3 ps-2">
                            <Col className="mb-3" sm={4}>
                              <label className="fw-bold fs-3">Date</label>
                              <Card.Text className="fs-3">
                                {incidentReport.incidentDate || "N/A"}
                              </Card.Text>
                            </Col>

                            <Col sm={4}>
                              <label className="fw-bold fs-3">Time</label>
                              <Card.Text className="fs-3">
                                {incidentReport.incidentTime || "N/A"}
                              </Card.Text>
                            </Col>

                            <Col sm={4}>
                              <Row>
                                <Col className="mb-3" sm={6}>
                                  <label className="fw-bold fs-3"> TYPE</label>
                                  <Card.Text className="fs-3">
                                    {incidentReport.incidentType || "N/A"}{" "}
                                  </Card.Text>
                                </Col>

                                {!!incidentReport.other_type && (
                                  <Col sm={6}>
                                    <label className="fw-bold fs-3">
                                      OTHER INCIDENT TYPE
                                    </label>
                                    <Card.Text className="fs-3">
                                      {incidentReport.other_type || "N/A"}
                                    </Card.Text>
                                  </Col>
                                )}
                              </Row>
                            </Col>
                          </Row>
                        </ListGroup.Item>

                        {!!viewMore && (
                          <>
                            <ListGroup.Item>
                              <label className="fw-bolder fs-3">REPORTER DETAILS</label>
                              <Row className="mt-3 ps-2">
                                <Col className="mb-3" sm={6}>
                                  <label className="fw-bold fs-3">Name</label>
                                  <Card.Text className="fs-3">
                                    {incidentReport.reporterName || "N/A"}
                                  </Card.Text>
                                </Col>

                                <Col sm={6}>
                                  <label className="fw-bold fs-3">Position</label>
                                  <Card.Text className="fs-3">
                                    {incidentReport.position || "N/A"}{" "}
                                  </Card.Text>
                                </Col>
                              </Row>
                            </ListGroup.Item>

                            <ListGroup.Item>
                              <label className="fw-bolder fs-3">CLIENT'S DETAILS</label>
                              <Row className="mt-3 ps-2">
                                <Col className="mb-3" md={12}>
                                  <label className="fw-bold fs-3">Name</label>
                                  <Card.Text className="fs-3">
                                    {client.name || "N/A"}
                                  </Card.Text>
                                </Col>

                                <Col md={12}>
                                  <label className="fw-bold fs-3">Location</label>
                                  <Card.Text className="fs-3">
                                    {incidentReport.client_offices.name || "N/A"}{" "}
                                  </Card.Text>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          </>
                        )}
                      </ListGroup>
                    </Card.Body>

                  </Card>
                </Col>

              </Row>


            </div>
          </>
        )}
      </Container>
    </div>
  );
}

export default IncidentReportComment