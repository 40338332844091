import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { Card, Col, Nav, Row } from "react-bootstrap";
import { permissions } from "../../utils/permissions";
import { Link, useSearchParams } from "react-router-dom";
import ReactPagination from "../../Components/ReactPagination";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { FilteredLayer } from "../../Components/SalaryComplaints/FilteredLayer";
import { ComplaintsDataTable } from "../../Components/SalaryComplaints/ComplaintsDataTable";
import { ComplaintFilterSearch } from "../../Components/SalaryComplaints/ComplaintFilterSearch";
import { StatsComponent } from "../../Components/SalaryComplaints/StatsComponent";
import { PaginationNumber } from "../../utils/PaginationNumber";
import { format } from "date-fns";


const SalaryComplaintsIndex = () => {
    const { get } = useAxios();
    const [fetching, setFetching] = useState(true);
    const [rootCauses, setRootCauses] = useState({});
    const { hasPermission } = usePermissionAndDomain();
    const [officeBranches, setOfficeBranches] = useState({});
    const [salaryComplaints, setSalaryComplaints] = useState([]);
    const [complaintStatuses, setComplaintStatuses] = useState({});
    const [searchPageParams, setSearchPageParams] = useSearchParams();
    const [complaintCategories, setComplaintCategories] = useState({});
    const [salaryComplaintsData, setSalaryComplaintsData] = useState([]);
    const [isFilterSearchClicked, setIsFilterSearchClicked] = useState(true);
    const [salaryComplaintIndexsData, setSalaryComplaintsIndexData] = useState({});

    let pageSize;
    let currentPage = searchPageParams.get("page") || 1;
    let stateDateParams = searchPageParams.get("end") || "";
    let endDateParams = searchPageParams.get("start") || "";
    let statusParams = searchPageParams.get("status") || "";
    let branchParams = searchPageParams.get("branch") || "";
    let searchKeyParams = searchPageParams.get("name") || "";
    let rootCauseParams = searchPageParams.get("root_cause_category") || "";
    let complaintCategoryParams = searchPageParams.get("complaint_category") || "";

    const getSalaryComplaints = async () => {
        setFetching(true);
        try {
            pageSize = Number(pageSize) || 10;
            currentPage = Number(currentPage) || 1;
            const response = await get(
                `salary-complaints?sort=desc&page=${currentPage}&page_size=${pageSize}&status=${statusParams}&branch=${branchParams}&name=${searchKeyParams}&start=${endDateParams}&end=${stateDateParams}&root_cause_category=${rootCauseParams}&complaint_category=${complaintCategoryParams}`
            );

            // console.log("complaintData: ", response.data)

            setRootCauses(response.data.rootCauses)
            setOfficeBranches(response.data.branches)
            setSalaryComplaintsIndexData(response.data)
            setComplaintStatuses(response.data.statuses)
            setSalaryComplaintsData(response.data.complaints)
            setSalaryComplaints(response.data.complaints.data)
            setComplaintCategories(response.data.complaintCategories)
        } catch (error) {
            setFetching(false);
        } finally {
            setFetching(false);
        }
    };

    const pageChangeHandler = (page) => {
        setSearchPageParams(
            {
                ...Object.fromEntries(searchPageParams),
                page,
            },
            { replace: true }
        );
    };

    useEffect(() => {
        setFetching(true);
        const initialiaseSalaryComplaints = async () =>
            await getSalaryComplaints();
        initialiaseSalaryComplaints();
    }, [
        currentPage,
        statusParams,
        branchParams,
        stateDateParams,
        endDateParams,
        searchKeyParams,
        rootCauseParams,
        complaintCategoryParams
    ]);

    const handleFilterSelectSearchChange = (values) => {
        setSearchPageParams(
            {
                ...values,
                page: 1,
            },
            { replace: true }
        );
    };

    return (
        <div className="container fluid">
            {!!fetching ? (
                <Loading />
            ) : (
                <>
                    <div className="d-flex justify-content-between mt-2 mb-5">
                        <Row className="page-title">
                            <Col md="auto">
                                <h2>ALL SALARY COMPLAINTS</h2>
                            </Col>
                        </Row>

                        {hasPermission(permissions.CREATE_SALARY_COMPLAINT) && (
                            <div md="auto">
                                <Link
                                    to="/salary-complaints/create"
                                    className="btn btn-primary"
                                >
                                    Add Complaint
                                </Link>
                            </div>
                        )}
                    </div>

                    <div>
                        {(!(
                            statusParams ||
                            branchParams ||
                            stateDateParams ||
                            endDateParams ||
                            searchKeyParams ||
                            rootCauseParams ||
                            complaintCategoryParams
                        ) && !!salaryComplaints?.length) && (
                                <>
                                    <Card className="mb-5">
                                        <Card.Body>
                                            <div>
                                                <Nav className="fw-bolder fs-4 mb-3">
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            href="#"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                setIsFilterSearchClicked(!isFilterSearchClicked);
                                                            }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="icon"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth="2"
                                                                stroke="currentColor"
                                                                fill="none"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            >
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="6" cy="10" r="2" />
                                                                <line x1="6" y1="4" x2="6" y2="8" />
                                                                <line x1="6" y1="12" x2="6" y2="20" />
                                                                <circle cx="12" cy="16" r="2" />
                                                                <line x1="12" y1="4" x2="12" y2="14" />
                                                                <line x1="12" y1="18" x2="12" y2="20" />
                                                                <circle cx="18" cy="7" r="2" />
                                                                <line x1="18" y1="4" x2="18" y2="5" />
                                                                <line x1="18" y1="9" x2="18" y2="20" />
                                                            </svg>
                                                            Filter
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>

                                            {isFilterSearchClicked && (
                                                <ComplaintFilterSearch
                                                    onhandleFilteredSearchChange={(value) =>
                                                        handleFilterSelectSearchChange(value)
                                                    }
                                                    initialSearchFilterInputs={{
                                                        status: statusParams,
                                                        start: endDateParams,
                                                        end: stateDateParams,
                                                        name: searchKeyParams,
                                                    }}

                                                    salaryComplaints={salaryComplaints}
                                                    complaintStatuses={complaintStatuses}
                                                />
                                            )}
                                        </Card.Body>
                                    </Card>

                                    <div className="mb-5 fs-3 fw-bold">{format(new Date(salaryComplaintIndexsData?.startDate), "MMM d, yyyy", {awareOfUnicodeTokens: true})} - {format(new Date(salaryComplaintIndexsData?.endDate), "MMM d, yyyy", {awareOfUnicodeTokens: true})}
                                    </div>

                                    <StatsComponent salaryComplaintIndexsData={salaryComplaintIndexsData} />

                                    <>
                                        <Row className="text-start ps-3 mb-2 text-muted">
                                            <Col className="data-count">
                                                <PaginationNumber
                                                    total={salaryComplaintsData.total}
                                                    per_page={salaryComplaintsData.per_page}
                                                    current_page={salaryComplaintsData.current_page}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col>
                                                <ComplaintsDataTable
                                                    salaryComplaints={salaryComplaints}
                                                    getSalaryComplaints={getSalaryComplaints}
                                                />

                                            </Col>
                                        </Row>

                                        <div className="d-flex ms-1 align-items-baseline">
                                            <div className="me-5">
                                                <ReactPagination
                                                    links={salaryComplaintsData.links}
                                                    onPageChange={pageChangeHandler}
                                                    current_page={salaryComplaintsData.current_page}
                                                    last_page={salaryComplaintsData.last_page}
                                                />
                                            </div>

                                            <div className="text-muted">
                                                <PaginationNumber
                                                    total={salaryComplaintsData.total}
                                                    per_page={salaryComplaintsData.per_page}
                                                    current_page={salaryComplaintsData.current_page}
                                                />
                                            </div>
                                        </div>
                                    </>
                                </>
                            )}

                        {!!(
                            statusParams ||
                            branchParams ||
                            stateDateParams ||
                            endDateParams ||
                            searchKeyParams ||
                            rootCauseParams ||
                            complaintCategoryParams) && (
                                <FilteredLayer
                                    onhandleFilteredSearchChange={(value) =>
                                        handleFilterSelectSearchChange(value)
                                    }

                                    initialSearchFilterInputs={{
                                        status: statusParams,
                                        end: stateDateParams,
                                        start: endDateParams,
                                        branch: branchParams,
                                        name: searchKeyParams,
                                        root_cause_category: rootCauseParams,
                                        complaint_category: complaintCategoryParams,
                                    }}

                                    rootCauses={rootCauses}
                                    officeBranches={officeBranches}
                                    complaintStatuses={complaintStatuses}
                                    complaintCategories={complaintCategories}

                                    salaryComplaints={salaryComplaints}
                                    getSalaryComplaints={getSalaryComplaints}
                                    salaryComplaintsData={salaryComplaintsData}
                                    pageChangeHandler={pageChangeHandler}
                                    salaryComplaintIndexsData={salaryComplaintIndexsData}

                                    statusParams={statusParams}
                                    branchParams={branchParams}
                                    stateDateParams={stateDateParams}
                                    endDateParams={endDateParams}
                                    searchKeyParams={searchKeyParams}
                                    rootCauseParams={rootCauseParams}
                                    complaintCategoryParams={complaintCategoryParams}
                                />
                            )
                        }
                    </div>
                </>
            )
            }
        </div>
    )
}

export default SalaryComplaintsIndex