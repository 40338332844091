import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { Col, Row, Card, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { RecipientManagementForm } from "../../Components/Notifications/RecipientManagementForm";

const ManageRecipients = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { notificationNeedsId } = useParams();
  const [fetching, setFetching] = useState(true);
  const [hqStaffs, setHqStaffs] = useState({});
  const [notificationNeeds, setNotificationNeeds] = useState({});
  const [notificationDetails, setNotificationDetails] = useState({});
  const [configuredRecipients, setConfiguredRecipients] = useState([]);

  useEffect(() => {
    setFetching(true);
    const getNotificationNeeds = async () => {
      try {
        const response = await get(
          `admin_notification/${notificationNeedsId}/needs`
        );
        setNotificationNeeds(response.data);
        setHqStaffs(response.data.hq_users);
        setNotificationDetails(response.data.adminNotifications);
        setConfiguredRecipients(response.data.adminNotifications.admin_users);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getNotificationNeeds();
  }, []);

  return (
    <>
      {!!fetching && <Loading />}

      {!!notificationNeeds && !fetching && (
        <Card className="container">
          <Card.Header className="d-flex justify-content-between mt-0 ">
            <Card.Title>
              <Row>
                <Col>
                  <h1 className="page-title">Notification Recipients</h1>
                </Col>
              </Row>
            </Card.Title>
            <Row>
              <Col>
                <Button onClick={() => navigate(-1)}>Back</Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <RecipientManagementForm
              hqStaffs={hqStaffs}
              notificationDetails={notificationDetails}
              configuredRecipients={configuredRecipients}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ManageRecipients;
