import React, { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Navigate} from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { LoadingButton } from "../../LoadingButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Badge, Button, Card, Form } from "react-bootstrap";
import { alertSuccess, alertWarning } from "../../CustomAlerts/AlertToast";

const schema = yup.object({
  name: yup
    .string()
    .required("Name of role required")
    .min(2, "Enter at least 2 characters")
    .max(50, "This should not be more 50 characters."),
  permissions: yup.array().when(["is_super_admin"], {
    is: (is_super_admin) => !is_super_admin,
    then: yup.array().min(1, "Select at least one(1) permission"),
  }),
});

export const RolesAndPermissionsForm = ({ editRole, permissions }) => {
  const {
    watch,
    register,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      name: editRole?.name || "",
      is_super_admin: !!Number(editRole?.is_super_admin),
      is_branch_restricted: !!Number(editRole?.is_branch_restricted),
      permissions: !editRole?.permissions?.length
        ? []
        : editRole.permissions.map((permission) => permission.name),
    },
  });

  const { post } = useAxios();
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);

  const showSuperAdminCheck = watch(
    "is_branch_restricted"
  );

  const showBranchRestrictionAndPermissions = watch(
    "is_super_admin"
  );

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const permissionsData = {
        name: data.name,
        permissions: data.permissions,
        is_super_admin: Boolean(data.is_super_admin),
        is_branch_restricted: Boolean(data.is_branch_restricted),
      };

      const endpointPath = !editRole?.id
        ? "roles/store"
        : `roles/${editRole.id}/update`;

      await post(endpointPath, permissionsData);

      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Role successfully saved!", null, 5000);
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 422) {
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error?.response?.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to create role!",
          null,
          5000
        );
      }
    }
  };

  return (
    <>
      {isSaved && (
        <Navigate
          to={!editRole?.id ? "/roles/" : `/roles/view/${editRole?.id}`}
          replace={true}
        />
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        {hasServerError && (
          <Alert variant="danger">
            <p>
              Something went wrong will processing your request. Please try
              again!
            </p>
          </Alert>
        )}
        <Card className="">
          <Card.Body>
            <div className="client-name mb-4">
              <Form.Group controlId="roleName">
                <Form.Label className="required">NAME</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  {...register("name")}
                />

                {!!errors.name && (
                  <p>
                    <Badge bg="danger">{errors.name?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            {showSuperAdminCheck !== "true" && (
              <div className="super-admin-role mb-4">
                <Form.Group controlId="superAdminRole">
                  <Form.Check
                    {...register("is_super_admin")}
                    type="checkbox"
                    name="is_super_admin"
                    id="is_super_admin"
                    label="Super Admin"
                    value={true}
                  />
                </Form.Group>
              </div>
            )}

            {showBranchRestrictionAndPermissions !== "true" && (
              <>
                <div className="branch-manager-role mb-4">
                  <Form.Group controlId="branchManagerRole">
                    <Form.Check
                      {...register("is_branch_restricted")}
                      type="checkbox"
                      value={true}
                      name="is_branch_restricted"
                      id="is_branch_restricted"
                      label="Branch Manager Restricted"
                    />
                  </Form.Group>
                </div>
                
                {/* <div className="branch-manager-role mb-4">
                  <Form.Group controlId="branchManagerRole">
                    <Form.Check
                      {...register("is_director_restricted")}
                      type="checkbox"
                      value={true}
                      name="is_director_restricted"
                      id="is_director_restricted"
                      label="Director"
                    />
                  </Form.Group>
                </div> */}

                <div className="mb-3">
                  <h4 className="border-bottom">Permissions</h4>
                  {Object.keys(permissions).map((domain) => (
                    <div key={domain}>
                      <strong className="d-block text-uppercase">
                        {domain}
                      </strong>
                      {permissions[domain].map((value) => (
                        <div key={value.id}>
                          <Form.Check
                            {...register("permissions")}
                            label={value.name.replace(/[^a-z ]/gi, " ")}
                            key={permissions[domain][value]}
                            value={value.name}
                          />
                        </div>
                      ))}
                    </div>
                  ))}

                  {!!errors.permissions && (
                    <p>
                      <Badge bg="danger">{errors.permissions?.message}</Badge>
                    </p>
                  )}
                </div>
              </>
            )}
          </Card.Body>
        </Card>

        <Card.Footer className=" d-flex justify-content-center  ">
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              variant="success"
              type="submit"
              className=" d-flex justify-content-center mt-3"
              disabled={!!loading || !isDirty}
            >
              Submit
            </Button>
          )}
        </Card.Footer>
      </Form>
    </>
  );
};
