import React, { useState } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Badge,
  Button,
  Container,
} from "react-bootstrap";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import useAxios from "../../hooks/useAxios";
import { Navigate } from "react-router-dom";
import { LoadingButton } from "../LoadingButtons";
import maleAvatar from "../../images/male_avatar.png";
import { yupResolver } from "@hookform/resolvers/yup";
import femaleAvatar from "../../images/female_avatar.jpg";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object({
  signature: yup
    .mixed()
    .test("type", "All uploaded file must be an image.", (value) => {
      if (value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          return (
            value[i].type === "image/jpeg" ||
            value[i].type === "image/png" ||
            value[i].type === "image/jpg"
          );
        }
      } else if (!value.length) {
        return true;
      }
    })
    .notRequired(),
});

const SignatureUploadForm = ({ staff, staffId }) => {
  const {
    setError,
    register,
    resetField,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      signature: "",
    },
  });

  const { postWithFile } = useAxios();
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [signatureUpload, setSignatureUpload] = useState(null);

  const handleSignatureUpload = (event) => {
    setSignatureUpload(event.target.files[0] ?? null);
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await postWithFile(`human_resources/staffs/${staffId}/addSignature`, {
        signature: signatureUpload,
      });
      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Employee signature successfully uploaded!", null, 5000);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 422) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry, something went wrong and your request was unsuccessful!",
          null,
          5000
        );
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
    }
  };

  return (
    <Container className="container-tight py-4 ">
      {isSaved && <Navigate to={`/staffs/view/${staffId}`} replace={true} />}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card className="w-100 mt-5 mb-5 border-radius-rounded">
          <Card.Header>
            <div className="d-flex py-1 align-items-center mt-2 mb-2">
              {staff.gender === "female" ? (
                <span className="avatar avatar-lg me-2 border">
                  <img
                    src={femaleAvatar}
                    width="80"
                    height="78"
                    alt="Female avatar"
                  />
                </span>
              ) : (
                <span className="avatar avatar-lg me-2 border">
                  <img
                    src={maleAvatar}
                    width="80"
                    height="78"
                    alt="Male avatar"
                  />
                </span>
              )}
              <div className="flex-fill">
                <div className="text-uppercase font-weight-medium">
                  {`${staff?.first_name} ${staff?.surname}` || "N/A"}
                </div>
                <div className="text-muted text-uppercase">
                  {staff?.actual_employee_code || "(NO EMPLOYEE CODE FOUND)"}
                </div>
                <div className="text-muted text-uppercase">
                  {staff?.designation.name}
                </div>
              </div>
            </div>
          </Card.Header>

          <Card.Body>
            <Form.Group
              as={Row}
              className="mt-3 mb-3 p-2"
              controlId="signatureUpload"
            >
            
              <Col className="mb-4" md={8}>
                <Form.Group controlId="signature">
                  <Form.Control
                    className="text-dark"
                    {...register("signature")}
                    type="file"
                    onChange={handleSignatureUpload}
                    accept=".jpeg, .png, .jpg"
                  />

                  {!!errors.signature && (
                    <p>
                      <Badge bg="danger">{errors.signature?.message}</Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col md={4}>
                <Button
                  type="reset"
                  variant="danger"
                  onClick={() => resetField("signature")}
                >
                  Remove upload
                </Button>
              </Col>
            </Form.Group>

            <div className=" d-flex justify-content-center mt-3 mb-3  ">
              {loading ? (
                <LoadingButton />
              ) : (
                <Button
                  data-testid="bs-btn "
                  variant="success"
                  type="submit"
                  className=""
                  disabled={!!loading || !isDirty}
                >
                  Submit
                </Button>
              )}
            </div>
          </Card.Body>
        </Card>
      </Form>
    </Container>
  );
};

export default SignatureUploadForm;
