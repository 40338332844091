import React from "react";
import { Link } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { permissions } from "../../../utils/permissions";
import { alertWarning } from "../../CustomAlerts/AlertToast";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import { handleCustomEvent } from "../../../utils/customEventLister";
import { usePermissionAndDomain } from "../../../hooks/usePermissionAndDomain";

export const DisplayedRolesPermissionsTable = ({
  roles,
  getRolesAndPermissions,
}) => {
  const { axiosDelete } = useAxios();
  const { hasPermission } = usePermissionAndDomain();

  const onDeleteClick = (role) => {
    handleCustomEvent("show-confirmation-modal", {
      action: async () => {
        try {
          await axiosDelete(`roles/${role.id}/destroy`);
          getRolesAndPermissions();
        } catch (error) {
          alertWarning("Your request failed!", null, 500);
        }
      },
      question: `Are you sure you want to delete ${role.name}?`,
    });
  };

  return (
    <>
      <Table
        responsive
        hover
        bordered
        className=" table-vcenter table-mobile-md card-table"
      >
        <thead>
          <tr>
            <th>NAME</th>
            <th>PEOPLE</th>
            <th>PERMISSION</th>
            <th>CONTROLS</th>
          </tr>
        </thead>
        <tbody>
          {roles.map((role) => (
            <tr key={role.id}>
              <td data-label="NAME">{role.name}</td>
              <td data-label="PEOPLE">{role.users_count}</td>
              <td data-label="PERMISSION">
                {!Number(role?.is_super_admin) && (
                  <>
                    {Number(role?.is_branch_restricted) > 0 && (
                      <>
                        <span className="badge bg-info mb-2 ms-2 text-uppercase">
                          Branch Restricted
                        </span>
                      </>
                    )}
                    <br />
                    <ul>
                      {role.permissions.slice(0, 3).map((permission) => (
                        <li key={permission.id}>
                          {permission.name.replace(/[^a-z ]/gi, " ")}
                        </li>
                      ))}
                    </ul>
                    {role.permissions.length > 3 && (
                      <p> +{role.permissions.length - 3} more permission(s)</p>
                    )}
                  </>
                )}
                {Number(role?.is_super_admin) > 0 && (
                  <>
                    <span className="badge bg-success ml-2 text-uppercase">
                      Super Admin
                    </span>
                  </>
                )}
              </td>
              <td className="d-flex mt-1">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  {hasPermission(permissions.VIEW_ROLES) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/roles/view/${role.id}`}
                    >
                      View
                    </Dropdown.Item>
                  )}

                  {hasPermission(permissions.EDIT_ROLES) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/roles/edit/${role.id}`}
                    >
                      Edit
                    </Dropdown.Item>
                  )}

                  {hasPermission(permissions.DELETE_ROLES) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      to="#"
                      className="justify-content-center"
                      onClick={() => onDeleteClick(role)}
                    >
                      Delete
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
