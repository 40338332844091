import React, {useState} from 'react'
import * as yup from "yup";
import {
  Form,
  Badge,
  Button,
  Alert,
  FormGroup,
} from "react-bootstrap";
import useAxios from "../../hooks/useAxios.js";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "../LoadingButtons.jsx";
import { yupResolver } from "@hookform/resolvers/yup";
import TinyMCETextEditor from "../TinyMCETextEditor.jsx";
import { Controller, useForm} from "react-hook-form";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast.jsx";

const schema = yup.object({
    comment: yup.string().required("Incident comment is required"),
  });

export const IncidentCommentForm = ({reportId}) => {
    const {
        control,
        setValue,
        setError,
        handleSubmit,
        formState: { errors, isDirty },
      } = useForm({
        mode: "onBlur",
        resolver: yupResolver(schema),
        defaultValues: {
          mode: "onBlur",
          comment: "",
        },
      });

    const navigate = useNavigate();
    const { post } = useAxios();
    const [loading, setLoading] = useState(false);
    const [hasServerError, setHasServerError] = useState(false);
    

    const onSubmit = async (data) => {
        try {
          setLoading(true);
          await post(`incidents/${reportId}/comments/store`, {
            ...data,
          });
    
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          alertSuccess("Comment successfully added!", null, 5000);
          navigate(-1);
        } catch (error) {
          setLoading(false);
          if (error?.response?.status === 422) {
            const handleServerErrors = (errors, setError) => {
              if (errors) {
                const serverValidation = error.response.data.errors;
                return Object.keys(serverValidation).map((key) => {
                  setError(key, {
                    shouldFocus: true,
                    type: "manual",
                    message: serverValidation[key][0],
                  });
                });
              }
            };
            handleServerErrors(error, setError);
            return;
          }
    
          if (error?.response?.status === 500) {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            alertWarning(
              "Something went wrong with the server. Please Try Again!",
              null,
              5000
            );
          } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            alertWarning(
              "Sorry. You are not authorised to comment on an incident report!",
              null,
              5000
            );
          }
        } finally {
          setLoading(false);
        }
      };

    return (
    <>
        <Form onSubmit={handleSubmit(onSubmit)}>
            {hasServerError && (
            <Alert variant="danger">
                <p>
                Something went wrong will processing your request. Please try
                again!
                </p>
            </Alert>
            )}

            <div className="report-comment mb-4 p-3 border">
                <FormGroup controlId="reportComment">
                    <Form.Label className="required">
                        COMMENT
                    </Form.Label>

                    <Controller
                    control={control}
                    name="comment"
                    render={({ field }) => (
                        <TinyMCETextEditor field={field} setValue={setValue} />
                    )}
                    />
                    {!!errors.comment && (
                    <p>
                        <Badge bg="danger">{errors.comment?.message}</Badge>
                    </p>
                    )}
                </FormGroup>
            </div>

            <div className="submit col-md-12 text-center mb-4">
            {loading ? (
                <LoadingButton />
            ) : (
                <Button
                type="submit"
                variant="success"
                disabled={!!loading || !isDirty}
                >
                Submit
                </Button>
            )}
            </div>
        </Form>
        </>
    )
}
