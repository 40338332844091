import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { PurchaseOrderForm } from "../../Components/PurchaseOrder/PurchaseOrderForm";
import { Error404 } from "../Errors/Error404";

const EditPurchaseOrder = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { purchaseOrderId } = useParams();
  const [vendors, setVendors] = useState({});
  const [kitItems, setKitItems] = useState({});
  const [fetching, setFetching] = useState(true);
  const [purchaseOrder, setPurchaseOrder] = useState({});

  useEffect(() => {
    setFetching(true);
    const getDetailedPurchaseOrder = async () => {
      try {
        const response = await get(`purchaseorder/${purchaseOrderId}/edit`);
        setKitItems(response.data.guardKits);
        setVendors(response.data.vendors);
        setPurchaseOrder(response.data.purchaseOrder);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedPurchaseOrder();
  }, []);

  return (
    <>
      {!fetching &&
        purchaseOrder?.status !== "issued" &&
        purchaseOrder?.approval_status !== "initialize" && <Error404 />}

      {!!fetching && <Loading />}

      {!fetching && !!purchaseOrder?.id && (
        <Card className="container">
          <Card.Header className="d-flex justify-content-between mt-0 mb-5">
            <Card.Title>
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">Edit Purchase Order</h1>
                </div>
              </div>
            </Card.Title>

            <div className="row">
              <div className="col-md-12">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </div>
            </div>
          </Card.Header>

          <Card.Body>
            <PurchaseOrderForm
              vendors={vendors}
              guardKitItems={kitItems}
              editPurchaseOrder={purchaseOrder}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default EditPurchaseOrder;
