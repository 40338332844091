import React from "react";
import { Badge } from "react-bootstrap";

export const handleStatusBadge = (maintenanceRecord) => {
  if (maintenanceRecord?.status === "awaiting approval") {
    return (
      <Badge bg="secondary" className="fs-5 mt-2 text-uppercase">
        {maintenanceRecord?.status}
      </Badge>
    );
  } else if (
    maintenanceRecord?.status === "approved" ||
    maintenanceRecord?.status === "approved with adjustment"
  ) {
    return (
      <Badge bg="primary" className="fs-5 mt-2 text-uppercase">
        {maintenanceRecord?.status}
      </Badge>
    );
  } else if (maintenanceRecord?.status === "completed") {
    return (
      <Badge bg="success" className="fs-5 mt-2 text-uppercase">
        {maintenanceRecord?.status}
      </Badge>
    );
  } else if (maintenanceRecord?.status === "rejected") {
    return (
      <Badge bg="danger" className="fs-5 mt-2 text-uppercase">
        {maintenanceRecord?.status}
      </Badge>
    );
  }
};
