import React from "react";
import { Badge } from "react-bootstrap";

export const handleInspectionBage = (maintenanceRecord) => {
  if (
    !!maintenanceRecord?.inspected_by_employee_code &&
    !!Number(maintenanceRecord?.inspection_pass)
  ) {
    return (
      <Badge bg="success" className="fs-5 m-2">
        PASS
      </Badge>
    );
  } else if (
    !!maintenanceRecord?.inspected_by_employee_code &&
    !Number(maintenanceRecord?.inspection_pass)
  ) {
    return (
      <Badge bg="danger" className="fs-5 m-2">
        FAIL
      </Badge>
    );
  } else {
    return "N/A";
  }
};
