import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Card, Col, Row } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { permissions } from "../../utils/permissions";
import { Link, useSearchParams } from "react-router-dom";
import ReactPagination from "../../Components/ReactPagination";
import { alertWarning } from "../../Components/CustomAlerts/AlertToast";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { DisplayedKitsRequestTable } from "../../Components/GuardKitsRequest/DisplayedKitsRequestTable";

const GuardKitsRequestIndex = () => {
  const { get } = useAxios();
  const [fetching, setFetching] = useState(true);
  const [guardKitsRequest, setGuardKitsRequest] = useState([]);
  const [searchPageParams, setSearchPageParams] = useSearchParams({});

  let pageSize;
  const { hasPermission } = usePermissionAndDomain();
  let currentPage = searchPageParams.get("page") || 1;

  const getGuardKitsRequest = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;
      const response = await get(
        `uniformrequest?sort=desc&page=${currentPage}&page_size=${pageSize}`
      );
      setGuardKitsRequest(response.data.uniformRequest);
    } catch (error) {
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    setFetching(true);
    const guardKitsRequest = async () => await getGuardKitsRequest();
    guardKitsRequest();
  }, [currentPage]);

  const pageChangeHandler = (page) => {
    setSearchPageParams(
      {
        ...Object.fromEntries(searchPageParams),
        page,
      },
      { replace: true }
    );
  };

  return (
    <>
      {!!fetching && <Loading />}

      <>
        <Card className="container d-flex justify-content-center ms-3">
          <Card.Header className="d-flex justify-content-between mt-2 mb-2">
            <Card.Title>
              <Row>
                <Col md="auto">
                  <h2 className="page-title">Guard Kit Requests</h2>
                </Col>
              </Row>
            </Card.Title>

            {hasPermission(permissions.MAKE_KIT_REQUEST) && (
              <Row>
                <Col md="auto">
                  <Link
                    to="/guardkits-request/create"
                    className="btn btn-primary"
                  >
                    Request for Kits
                  </Link>
                </Col>
              </Row>
            )}
          </Card.Header>

          <Card.Body>
            {!fetching && !!guardKitsRequest?.data?.length === 0 && (
              <div> No Guardkits Request Found</div>
            )}

            {!!guardKitsRequest?.data?.length && !fetching && (
              <Row>
                <Col className="p-0">
                  <>
                    <DisplayedKitsRequestTable
                      guardKitsRequest={guardKitsRequest.data}
                      getGuardKitsRequest={getGuardKitsRequest}
                    />

                    <br />

                    <ReactPagination
                      links={guardKitsRequest.links}
                      onPageChange={pageChangeHandler}
                      current_page={guardKitsRequest.current_page}
                      last_page={guardKitsRequest.last_page}
                    />
                  </>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      </>
    </>
  );
};

export default GuardKitsRequestIndex;
