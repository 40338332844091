import React, { useEffect, useState } from "react";
import * as yup from "yup";
import Select from "react-select";
import NumberFormat from "react-number-format";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Badge, Button, Modal, Col, Form, Row } from "react-bootstrap";

const schema = yup.object({
  type: yup.string(),
  vehicle_part_id: yup.string(),
  description: yup.string(),
  quantity: yup.string(),
  rate: yup.string(),
  amount: yup.string(),
});

export const MaintenanceTypeModal = ({ vehiclePart, maintenanceType }) => {
  const {
    watch,
    reset,
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      type: "",
      vehicle_part_id: "",
      description: "",
      quantity: "",
      rate: "",
      amount: "",
    },
  });

  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    reset();
    setShow(false);
  };
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [displayPartId, setDisplayPartId] = useState(null);

  const showVehiclePartId = watch("type");

  const vehiclePartOptions = Object.keys(vehiclePart).map((key) => ({
    value: key,
    label: vehiclePart[key],
  }));

  const calculateAmount = () => {
    const quantity = document.querySelector(`[data-quantity-index]`).value;

    const rate = document.querySelector(`[data-rate-index]`).value;

    setValue("amount", (Number(quantity) || 0) * (Number(rate) || 0));
  };

  useEffect(() => {
    window.addEventListener("mi:add", (event) => {
      setDisplayPartId(null);
      handleShow();
      setIsEdit(false);
    });

    window.addEventListener("mi:edit", (event) => {
      setIsEdit(true);
      setEditIndex(event.detail.index);
      handleShow();

      for (const [key, value] of Object.entries(event.detail.maintenanceItem)) {
        if (key === "vehicle_part_id") {
          setDisplayPartId({
            value: value,
            label: vehiclePart[value],
          });
        }

        setValue(key, value ?? "");
      }
    });
  }, []);

  const onSubmit = async (data) => {
    let maintenanceItemsAddReturnedData = data;

    if (isEdit) {
      dispatchEvent(
        new CustomEvent("mi:edit_submit", {
          detail: { maintenanceItemsAddReturnedData, index: editIndex },
        })
      );
    } else {
      dispatchEvent(
        new CustomEvent("mi:add_submit", {
          detail: { ...maintenanceItemsAddReturnedData },
        })
      );
    }

    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="modal modal-blur fade"
    >
      <Modal.Header closeButton>
        <Modal.Title>MAINTENANCE TYPE</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form id="maintenanceTypeModalForm" onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="4" className="required">
              TYPE
            </Form.Label>
            <Col sm="8">
              <Form.Select {...register("type")}>
                <option value="" disabled>
                  Select type
                </option>
                {maintenanceType.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Select>
            </Col>

            {!!errors.type && (
              <p>
                <Badge bg="danger">{errors?.type?.message}</Badge>
              </p>
            )}
          </Form.Group>

          {(showVehiclePartId === "service" ||
            showVehiclePartId === "repair" ||
            showVehiclePartId === "replace" ||
            showVehiclePartId === "purchase") && (
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4" className="required">
                  VEHICLE PART
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="vehicle_part_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        name={field.name}
                        ref={field.ref}
                        className="text-dark"
                        options={vehiclePartOptions}
                        onChange={(vehiclePartOption) => {
                          setDisplayPartId({
                            value: vehiclePartOption?.value || "",
                            label: vehiclePartOption?.label || "",
                          });

                          setValue("vehicle_part_id", vehiclePartOption.value, {
                            shouldValidate: true,
                          });
                        }}
                        value={displayPartId}
                        placeholder={"Select vehicle part"}
                      />
                    )}
                  />
                </Col>

                {!!errors?.vehicle_part_id && (
                  <p>
                    <Badge bg="danger">{errors?.vehicle_part_id?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            )}

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="4">
              DESCRIPTION
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="textarea"
                rows={3}
                {...register("description")}
                onChange={(event) => {
                  setValue("description", event.target.value, {
                    shouldValidate: true,
                  });
                }}
              />
            </Col>

            {!!errors?.description && (
              <p>
                <Badge bg="danger">{errors?.description?.message}</Badge>
              </p>
            )}
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="4" className="required">
              QUANTITY
            </Form.Label>
            <Col sm="8">
              <Controller
                name={"quantity"}
                control={control}
                render={({ field }) => (
                  <NumberFormat
                    {...field}
                    data-quantity-index
                    className="form-control"
                    onChange={(event) => {
                      setValue("quantity", event.target.value, {
                        shouldValidate: true,
                      });
                      calculateAmount(field.value);
                    }}
                  />
                )}
              />
            </Col>

            {!!errors?.quantity && (
              <p>
                <Badge bg="danger">{errors?.quantity?.message}</Badge>
              </p>
            )}
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="4" className="required">
              RATE/UNIT PRICE (&#8358;)
            </Form.Label>
            <Col sm="8">
              <Controller
                name={"rate"}
                control={control}
                render={({ field }) => (
                  <NumberFormat
                    {...field}
                    data-rate-index
                    className="form-control"
                    onChange={(event) => {
                      setValue("rate", event.target.value, {
                        shouldValidate: true,
                      });
                      calculateAmount(field.value);
                    }}
                  />
                )}
              />
            </Col>

            {!!errors?.rate && (
              <p>
                <Badge bg="danger">{errors?.rate?.message}</Badge>
              </p>
            )}
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="4">
              AMOUNT (&#8358;)
            </Form.Label>
            <Col sm="8">
              <Controller
                name={"amount"}
                control={control}
                render={({ field }) => (
                  <NumberFormat
                    {...field}
                    className="form-control"
                    disabled
                    onChange={() => { }}
                  />
                )}
              />
            </Col>
          </Form.Group>

          <div className="d-flex justify-content-center mt-3">
            <Button
              variant="primary"
              type="submit"
              className=" d-flex justify-content-center mt-3 me-3"
              disabled={!isDirty}
            >
              Submit
            </Button>

            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                handleClose();
              }}
              className=" d-flex justify-content-center mt-3"
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
