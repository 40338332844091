import React, { useState } from "react";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import useAxios from "../../hooks/useAxios";
import { Navigate } from "react-router-dom";
import { LoadingButton } from "../LoadingButtons";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import { Alert, Badge, Button, Card, Col, Form, Row } from "react-bootstrap";

const schema = yup.object({
  license_number: yup.string().required("Licence number is required"),
  license_expiry_date: yup.string().required("License expiry date required"),
  lasdri_number: yup.string().required("LASDRI number is required"),
  lasdri_expiry_date: yup.string().required("LASDRI expiry date required"),
  office_location_code: yup.string(),
});

export const DriverForm = ({ editDriver, branchLocations, }) => {
  const {
    register,
    control,
    setError,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      license_number: editDriver?.license_number || "",
      license_expiry_date: editDriver?.license_expiry_date || "",
      lasdri_number: editDriver?.lasdri_number || "",
      lasdri_expiry_date: editDriver?.lasdri_expiry_date || "",
      office_location_code: editDriver?.office_location_code || "",
    },
  });

  const { post } = useAxios();

  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);

  const [licenseExpiryDate, setLicenseExpiryDate] = useState("");
  const [lasdriExpiryDate, setLasdriExpiryDate] = useState("");

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      await post(`user/drivers/${editDriver?.id}/update`, {
        ...data,
        license_expiry_date: format(
          new Date(data?.license_expiry_date),
          "yyyy-MM-dd",
          {
            awareOfUnicodeTokens: true,
          }
        ),

        lasdri_expiry_date: format(
          new Date(data?.lasdri_expiry_date),
          "yyyy-MM-dd",
          {
            awareOfUnicodeTokens: true,
          }
        )
      });

      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Driver successfully saved!", null, 5000);
    } catch (error) {
      setLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
      if (error?.response?.status === 422) {
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            setLoading(false);
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error?.response?.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to edit driver!",
          null,
          5000
        );
      }
    }
  };

  return (
    <>
      {isSaved && (
        <Navigate to={`/drivers/view/${editDriver?.id}`} replace={true} />
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        {hasServerError && (
          <Alert variant="danger">
            <p>
              Something went wrong will processing your request. Please try
              again!
            </p>
          </Alert>
        )}

        <Card>
          <Card.Body>
            <Row>
              <Col className="license-number mb-4" md={6}>
                <Form.Group controlId="licenseNumber">
                  <Form.Label>DRIVER'S LICENSE NUMBER</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Driver's license number"
                    {...register("license_number")}
                  />

                  {!!errors?.license_number && (
                    <p>
                      <Badge bg="danger">
                        {errors?.license_number?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="license-expiry-date mb-4">
                <Form.Group controlId="licenseExpiryDate">
                  <Form.Label className="required">
                    LICENSE EXPIRY DATE
                  </Form.Label>
                  <Controller
                    name="license_expiry_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        isClearable
                        strictParsing
                        showYearDropdown
                        showMonthDropdown
                        closeOnScroll={true}
                        dropdownMode="select"
                        selected={licenseExpiryDate}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        onChange={(date) => {
                          setLicenseExpiryDate(date);
                          setValue("license_expiry_date", date, {
                            shouldValidate: true,
                          });
                        }}
                        placeholderText="Enter license expiry date"
                      />
                    )}
                  />

                  {!!errors?.license_expiry_date && (
                    <p>
                      <Badge bg="danger">
                        {errors?.license_expiry_date?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="lasdri-number mb-4" md={6}>
                <Form.Group controlId="lasdriNumber">
                  <Form.Label>LASDRI NUMBER</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="lasdri number"
                    {...register("lasdri_number")}
                  />

                  {!!errors?.lasdri_number && (
                    <p>
                      <Badge bg="danger">{errors?.lasdri_number?.message}</Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="lasri-expiry-date mb-4">
                <Form.Group controlId="lasriExpiryDate">
                  <Form.Label className="required">
                    LASRI EXPIRY DATE
                  </Form.Label>
                  <Controller
                    name="lasdri_expiry_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        isClearable
                        strictParsing
                        showYearDropdown
                        showMonthDropdown
                        closeOnScroll={true}
                        dropdownMode="select"
                        selected={lasdriExpiryDate}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        onChange={(date) => {
                          setLasdriExpiryDate(date);
                          setValue("lasdri_expiry_date", date, {
                            shouldValidate: true,
                          });
                        }}
                        placeholderText="Enter license expiry date"
                      />
                    )}
                  />

                  {!!errors?.lasdri_expiry_date && (
                    <p>
                      <Badge bg="danger">
                        {errors?.lasdri_expiry_date?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="branch-location mb-2 p-2" md={6}>
                <Form.Group controlId="branchLocation">
                  <Form.Label>BRANCH</Form.Label>
                  <Form.Select {...register("office_location_code")}>
                    <option value=""> Select branch </option>
                    {Object.entries(branchLocations).map(([location, id]) => (
                      <option value={id} key={id}>
                        {`${location}`.replace(/_/g, " ")}
                      </option>
                    ))}
                  </Form.Select>

                  {!!errors?.office_location_code && (
                    <p>
                      <Badge bg="danger">
                        {errors?.office_location_code?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card.Footer className=" d-flex justify-content-center  ">
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              variant="success"
              type="submit"
              className=" d-flex justify-content-center mt-3"
              disabled={!!loading || !isDirty}
            >
              Submit
            </Button>
          )}
        </Card.Footer>
      </Form>
    </>
  );
};
