import React, { useEffect, useState } from "react";

export const useClientData = ({ clients }) => {
  const [clientOptions, setClientOptions] = useState([]);
  const [clientOptionsReady, setClientOptionsReady] = useState(false);
  const [clientLocationsOptions, setClientLocationsOptions] = useState({});

  useEffect(() => {
    const _clientOptions = [];
    const clientLocations = {};
    clients.forEach((client) => {
      const currentClientLocationOptions = [];

      _clientOptions.push({
        value: client.id,
        label: client.name,
      });

      client.locations.forEach((location) => {
        currentClientLocationOptions.push({
          value: location.id,
          label: location.name,
        });
      });

      clientLocations[client.id] = currentClientLocationOptions;
    });

    setClientOptionsReady(true);

    setClientLocationsOptions(clientLocations);
    setClientOptions(_clientOptions);
  }, [clients]);

  return {
    clientOptions,
    clientOptionsReady,
    clientLocationsOptions,
  };
};
