import React from "react";
import { Link } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { permissions } from "../../utils/permissions";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import { Table, Dropdown, DropdownButton, Badge } from "react-bootstrap";
import { handleCustomEvent } from "../../utils/customEventLister";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";

export const DisplayedGuardKitItems = ({ guardKitItems, getGuardKitItems }) => {
  const { axiosDelete } = useAxios();
  const { hasPermission } = usePermissionAndDomain();

  const onDeleteClick = (guardKitItem) => {
    handleCustomEvent("show-confirmation-modal", {
      action: async () => {
        try {
          await axiosDelete(`guardkit/${guardKitItem.id}/destroy`);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          alertSuccess(
            `${guardKitItem.name} successfully deleted!`,
            null,
            5000
          );
          getGuardKitItems();
        } catch (error) {
          alertWarning("Your request failed!", null, 500);
        }
      },
      question: `Are you sure you want to delete ${guardKitItem.name}?`,
    });
  };

  const benchmarkAlert = (guardKitItem) => {
    if (!guardKitItem?.kit_items_stock?.length) {
      return "N/A";
    }

    const stock = guardKitItem.kit_items_stock[0].quantity;
    if (Number(guardKitItem.benchmark) >= Number(stock)) {
      return (
        <Badge bg="danger" className="fs-5 mt-2">
          RESTOCK
        </Badge>
      );
    }
  };

  return (
    <>
      <Table
        hover
        bordered
        responsive
        className=" table-vcenter table-mobile-md card-table"
      >
        <thead>
          <tr>
            <th>NAME</th>
            <th>QUANTITY UNIT</th>
            <th>ALLOCATION QUANTITY</th>
            <th>AVAILABLE QUANTITY</th>
            <th>BENCHMARK QUANTITY</th>
            <th>CONTROLS</th>
          </tr>
        </thead>

        <tbody>
          {guardKitItems.map((guardKitItem) => (
            <tr key={guardKitItem.id}>
              <td data-label="NAME">
                <Link to={`/guardkit/view/${guardKitItem.id}`}>
                  {guardKitItem.name}
                </Link>
              </td>
              <td data-label="QUANTITY UNIT">{guardKitItem.quantity_unit}</td>
              <td data-label="ALLOCATION QUANTITY">
                {guardKitItem.allocation_quantity}
              </td>
              <td data-label="AVAILABLE QUANTITY">
                {`${guardKitItem.kit_items_stock.map(
                  (availableQuantity) => availableQuantity.quantity
                )}` || "NO STOCK RECORD"}{" "}
                {benchmarkAlert(guardKitItem)}
              </td>
              <td data-label="BENCHMARK QUANTITY">
                {guardKitItem.benchmark || "N/A"}
              </td>
              <td className="d-flex mt-1">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  {hasPermission(permissions.VIEW_SINGLE_GUARD_KIT_ITEM) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/guardkit/view/${guardKitItem.id}`}
                    >
                      view
                    </Dropdown.Item>
                  )}

                  {hasPermission(permissions.EDIT_GUARD_KIT_ITEM) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/guardkit/update/${guardKitItem.id}`}
                    >
                      Edit
                    </Dropdown.Item>
                  )}

                  {hasPermission(permissions.DELETE_GUARD_KIT_ITEM) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      to="#"
                      className="justify-content-center"
                      onClick={() => onDeleteClick(guardKitItem)}
                    >
                      Delete
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
