import React, { useMemo, useState } from 'react'
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, FormGroup, Nav, Row} from 'react-bootstrap';
import { GiAlliedStar } from 'react-icons/gi';

export const FilterSidebar = ({
    rootCauses,
    statusStats,
    statusParams,
    officeBranches,
    complaintStatuses,
    complaintCategories,
    salaryComplaintsData,
    initialSearchFilterInputs,
    onhandleFilteredSearchChange,
}) => {
    const [searchFilterInputs, setSearchFilterInputs] = useState({
        status: initialSearchFilterInputs?.status ?? "",
        root_cause_category: initialSearchFilterInputs?.root_cause_category ?? "",
        complaint_category: initialSearchFilterInputs?.complaint_category ?? "",
        branch: initialSearchFilterInputs?.branch ?? "",
        name: initialSearchFilterInputs?.name ?? "",
        start: initialSearchFilterInputs?.start ?? "",
        end: initialSearchFilterInputs?.end ?? "",
    });

    const {
        control,
        setValue,
    } = useForm({
        mode: "onBlur",
        defaultValues: {
            mode: "onBlur",
            start: initialSearchFilterInputs?.start ?? "",
            end: initialSearchFilterInputs?.end ?? "",
        },
    })

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const complaintsByStatus = useMemo(() => {
        const countsByStatus = {}
        for (let stat of statusStats) {
            countsByStatus[stat.payment_status] = stat.number_of_complaints
        }
        return countsByStatus

    }, [statusStats])

    return (
        <Form
            style={{
                zoom: "90%"
            }}
            className="mt-0"
            method="GET"
            onSubmit={(event) => {
            event.preventDefault();
            onhandleFilteredSearchChange(searchFilterInputs);
            }}
        >
            <Row>
                <Nav className="fw-bolder fs-4 mb-2">
                    <Nav.Item>
                        <Nav.Link
                        href="#"
                        >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="6" cy="10" r="2" />
                            <line x1="6" y1="4" x2="6" y2="8" />
                            <line x1="6" y1="12" x2="6" y2="20" />
                            <circle cx="12" cy="16" r="2" />
                            <line x1="12" y1="4" x2="12" y2="14" />
                            <line x1="12" y1="18" x2="12" y2="20" />
                            <circle cx="18" cy="7" r="2" />
                            <line x1="18" y1="4" x2="18" y2="5" />
                            <line x1="18" y1="9" x2="18" y2="20" />
                        </svg>
                        Filter
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </Row>

            <div>
                <div className="mb-4 mx-1" id="concernedEmployee">
                    <Form.Control
                        type="text"
                        autoComplete="off"
                        placeholder="Name/Employee code"
                        defaultValue={searchFilterInputs.name}
                        onChange={(event) =>
                        setSearchFilterInputs({
                            ...searchFilterInputs,
                            name: event.target.value,
                        })
                        }
                    />
                </div>

                <div className="mb-4 mx-1" id="startDate">
                    <FormGroup controlId="startDate">
                        <Controller
                            name="start"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    isClearable
                                    strictParsing
                                    showYearDropdown
                                    showMonthDropdown
                                    autoComplete="off"
                                    maxDate={new Date()}
                                    closeOnScroll={true}
                                    dropdownMode="select"
                                    selected={startDate}
                                    dateFormat="dd-MM-yyyy"
                                    className="form-control"
                                    onChange={(date) => {
                                        setStartDate(date);
                                        setValue("start", date, {
                                            shouldValidate: true,
                                        });
                                        
                                        setSearchFilterInputs({
                                            ...searchFilterInputs,
                                            start: format(date, 'yyyy-MM-dd'),
                                        })
                                    }}
                                    placeholderText="Start Date"
                                />
                            )}
                        />
                    </FormGroup> 
                </div>

                <div className="mb-4 mx-1" id="endDate">
                    <FormGroup controlId="endDate">
                        <Controller
                            name="end"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    isClearable
                                    strictParsing
                                    showYearDropdown
                                    showMonthDropdown
                                    autoComplete="off"
                                    maxDate={new Date()}
                                    closeOnScroll={true}
                                    dropdownMode="select"
                                    selected={endDate}
                                    dateFormat="dd-MM-yyyy"
                                    className="form-control"
                                    onChange={(date) => {
                                        setEndDate(date);
                                        setValue("end", date, {
                                            shouldValidate: true,
                                        });

                                        setSearchFilterInputs({
                                            ...searchFilterInputs,
                                            end: format(date, 'yyyy-MM-dd')
                                        })
                                    }}
                                    placeholderText="End Date"
                                />
                            )}
                        />
                    </FormGroup>
                </div>

                <div className="mb-4 mx-1" id="branch">
                    <Form.Select
                        name="branch"
                        className="form-control"
                        value={searchFilterInputs.branch}
                        onChange={(event) =>
                        setSearchFilterInputs({
                            ...searchFilterInputs,
                            branch: event.target.value,
                        })
                        }
                    >
                        <option value="">All Branch</option>{" "}
                        {Object.entries(officeBranches).map(([branchCode, branchName]) => (
                        <option value={branchCode} key={branchCode}>
                            {branchName}
                        </option>
                        ))}
                    </Form.Select>
                </div>

                <div className="mb-4 mx-1" id="complaintCategory">
                    <Form.Select
                    name="complaint_category"
                    className="form-control"
                    defaultValue={searchFilterInputs.complaint_category}
                    onChange={(event) =>
                        setSearchFilterInputs({
                        ...searchFilterInputs,
                        complaint_category: event.target.value,
                        })
                    }
                    >
                    <option value="">All Complaint Category</option>{" "}
                    {Object.keys(complaintCategories).map((category) => (
                        <option value={category} key={category}>
                        {category}
                        </option>
                    ))}
                    </Form.Select>
                </div>

                <div className="pb-3 mx-1" id="rootCauseCategory">
                    <Form.Select
                        name="root_cause_category"
                        className="form-control"
                        value={searchFilterInputs.root_cause_category}
                        onChange={(event) =>
                        setSearchFilterInputs({
                            ...searchFilterInputs,
                            root_cause_category: event.target.value,
                        })
                        }
                    >
                        <option value="">All Root Cause</option>
                        {Object.keys(rootCauses).map((rootCause) => (
                        <option value={rootCause} key={rootCause}>
                        {rootCause}
                        </option>
                    ))}
                    </Form.Select>
                </div>

                <div className="py-2 mx-1" id="status">
                    <Form.Label>Status</Form.Label>
                    <Form.Check
                        defaultChecked={statusParams === "status"}
                        name="status"
                        type="radio"
                        id={`default-radio-all`}
                        label="All"
                        value=""
                        onChange={(event) => {
                            setSearchFilterInputs({
                                ...searchFilterInputs,
                                status: event.target.value,
                            })
                        }}
                    />
                    {
                        Object.keys(complaintStatuses).map((status, index) => (
                            <div key={status} className="mb-3">
                                <Form.Check
                                    defaultChecked={statusParams === status}
                                    name="status"
                                    type="radio"
                                    id={`default-radio-${index}`}
                                    label={`${status} (${complaintsByStatus?.[status] ?? 0})`}
                                    value={status}
                                    onChange={(event) => {
                                        setSearchFilterInputs({
                                            ...searchFilterInputs,
                                            status: status,
                                        })

                                    }
                                    }
                                />
                            </div>
                        ))
                    }
                </div>
            </div>

            <Button variant="secondary" className="ms-2 fs-4" type="submit" size="sm">
            Filter
            </Button>
        </Form>
    )
}
