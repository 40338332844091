import React, { useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

const TinyMCETextEditor = ({ field, setValue }) => {
  useEffect(() => {
    const handler = (e) => {
      if (
        e.target.closest(
          ".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root"
        ) !== null
      ) {
        e.stopImmediatePropagation();
      }
    };
    document.addEventListener("focusin", handler);
    return () => document.removeEventListener("focusin", handler);
  }, []);

  return (
    <>
      <Editor
        {...field}
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        // onInit={(evt, editor) => (editorRef.current = editor)}

        init={{
          selector: "textarea",
          branding: false,
          toolbar_location: "top",
          height: 300,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "insertdatetime",
            "media",
            "table",
          ],
          toolbar:
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist | " +
            "removeformat ",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
        onChange={() => {}}
        onEditorChange={(a, editor) => {
          setValue(field.name, a);
        }}
      />
    </>
  );
};

export default TinyMCETextEditor;
