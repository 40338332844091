import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { topFiveStats } from "../../../utils/topFiveStats";
import Autocolors from 'chartjs-plugin-autocolors';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';


ChartJS.register(
    Legend,
    Tooltip,
    Autocolors,
    ArcElement,
    DataLabelsPlugin
);


export const BranchPieChart = ({ branchStats }) => {
    const topFiveBranchStats = topFiveStats(branchStats, 'number_of_complaints');
    
    let labels = [];
    let myTotalValue = 0;
    let myDataSet = [];
    let complaintsNumberArray = [];

    topFiveBranchStats.map((data) =>  labels.push(data.branch_name));

    topFiveBranchStats.map((data) =>  complaintsNumberArray.push(data.number_of_complaints));

    for (let i = 0; i < Number(complaintsNumberArray.length); i++) {
        myTotalValue = myTotalValue + i;
    }


    topFiveBranchStats.map((data) => 
        myDataSet.push(
            `${Math.round(Number(data.number_of_complaints / myTotalValue) * 100)}`
        ));

    const chartData = {
        labels: labels,
        datasets: [
        {
            labels: "Top 5 Branches",
            data: labels.map((myLabel, index) => myDataSet[index]),
            hoverOffset: 12,
            borderWidth: 1,
        },
        ],
    };


    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: "Top 5 Branches",
                fontSize: 18,
            },
            tooltip: {
                enabled: true,
                callbacks: {
                label: function (context) {
                    return context.label + ": " + context.parsed + "%";
                },
                },
            },
            autocolors: {
                backgroundColor: 'rgba(155, 109, 945, 0.5)',
                borderColor: 'rgba(255, 99, 132, 1)',
                mode: 'color'
            },
            datalabels: {
                color: 'white',
                labels: {
                    title: {
                        font: {
                            weight: 'lighter',
                            size: 11
                        }
                    },
                },
                formatter: function(value) {
                    return value + "%";
                }
            },
        },
    };

    if (topFiveBranchStats?.length <= 3) {
        return
    }

    return (
        // <div class="chart-container" style={{position: "relative", height: "40vh", width: "30vw"}}></div>
            <Doughnut data={chartData} options={options} />
    )
};
