import React from 'react'
import { TypesStatPieChart } from '../../Components/IncidentReports/IncidentStats/TypesStatPieChart'
import { TopClientsStatBarChart } from '../../Components/IncidentReports/IncidentStats/TopClientsStatBarChart'

export const IncidentStats = ({incidentReportData}) => {
const currentDate = new Date().toISOString().slice(0, 10);
let topClients = [];



if (incidentReportData?.statByClients?.length > 5) {
  topClients = incidentReportData?.statByClients.slice(0, 5);

} 
else {
  topClients = incidentReportData?.statByClients
}
  return (
    <div className="row">
        <div className="col-lg-4">
            <div className="row justify-content-center mb-3">
              <div className="flex-auto">
                <span className="d-block text-primary lead pb-0 font-weight-bold">
                  {!!incidentReportData?.dateTo 
                  ? `From ${incidentReportData?.dateFrom} to ${incidentReportData?.dateTo}`
                  : `From ${incidentReportData?.dateFrom} to ${currentDate}`
                  }
                
                
                </span>
                <span className="d-block mt-n2">Total Incidents: {incidentReportData?.data?.total}</span>

                <span className="d-block mt-n2">Statistics by Incident Type</span>
              </div>
            </div>

            <TypesStatPieChart
            style={{width: "263.2px", display: "block", boxSizing: "border-box", height: "263px"}}
            width="394"
            height="394"
            statByTypes={incidentReportData?.statByTypes}
            />
        </div>

        <div className="col-lg-8">
            <TopClientsStatBarChart
            style={{width: "541.4pageXOffset", display: "blockpageXOffset", boxSizing: "border-boxpageXOffset", height: "270pageXOffset"}}
            width="812"
            height="405"
            topClientsStats={topClients}
            />
        </div>
    </div>
  )
}
