import React, { useEffect } from "react";
import { Button, Col, Container, Nav, Row } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { useNavigate} from "react-router-dom";
import { RequestNeedsForm } from "../../Components/EscortSchedule/RequestNeedsForm";
import { NeedRequestSummary } from "../../Components/EscortSchedule/NeedRequestSummary";
import { useModifyEscortData } from "../../hooks/EscortsDataFetching/useModifyEscortData";

const EditRequestNeeds = () => {
  const { fetching, escortSchedule, fetchData } = useModifyEscortData();

  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      fetchData();
    };

    getData();
  }, []);


  return (
    <div className="page-wrapper">
      <Container fluid>
        {!!fetching && <Loading />}

        {!!escortSchedule?.id && !fetching && (
          <>
            <div className="page-header d-print-none">
              <Row className="align-items-center">
                <Col>
                  <div className="page-pretitle">ESCORT NEEDS </div>
                </Col>
              </Row>
            </div>

            <div className="page-body">
              <Nav className="justify-content-end">
                <Nav.Item>
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </Nav.Item>
              </Nav>

              <br />

              <NeedRequestSummary escortSchedule={escortSchedule} />

              <br />

              <RequestNeedsForm
                editRequestNeeds={escortSchedule}
              />
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default EditRequestNeeds;
