import React, { useEffect, useState } from "react";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  ListGroup,
  Row,
  Table,
} from "react-bootstrap";
import { format } from "date-fns";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { permissions } from "../../utils/permissions";
import ReactPagination from "../../Components/ReactPagination";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";

const ViewGuardKitItem = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { guardKitItemId } = useParams();
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [guardKitItem, setGuardKitItem] = useState({});
  const [kitItemStock, setKitItemStock] = useState({});
  const [inventoryItem, setInventoryItem] = useState({});
  const [searchPageParams, setSearchPageParams] = useSearchParams({});

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;

  const getInventoryItem = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;

      const response =
        await get(`guardkit/${guardKitItemId}?sort=desc&page=${currentPage}&page_size=${pageSize}
        `);
      setInventoryItem(response.data);
      setGuardKitItem(response.data.guard_kit);
      setKitItemStock(response.data.stock_transaction);
    } catch (error) {
      if (error.response.status === 500) {
      }
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    setFetching(true);
    const guardKitItem = async () => await getInventoryItem();
    guardKitItem();
  }, [currentPage]);

  const pageChangeHandler = (page) => {
    setSearchPageParams(
      {
        ...Object.fromEntries(searchPageParams),
        page,
      },
      { replace: true }
    );
  };

  return (
    <>
      {!!fetching && <Loading />}

      {!!inventoryItem && !fetching && (
        <>
          {!!guardKitItem.id && (
            <Card>
              <Card.Header className="d-flex justify-content-between mt-2 mb-5">
                <Card.Title>
                  <Row>
                    <Col>
                      <h1>Guard Kit Item Details</h1>
                    </Col>
                  </Row>
                </Card.Title>

                <Row>
                  <Col className="mb-2">
                    <Button onClick={() => navigate(-1)}>Back</Button>
                  </Col>

                  <Col className="me-4" sm={6}>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="ACTION"
                      className="justify-content-center"
                    >
                      {hasPermission(permissions.EDIT_GUARD_KIT_ITEM) && (
                        <Dropdown.Item
                          as={Link}
                          size="sm"
                          className="justify-content-center"
                          to={`/guardkit/update/${guardKitItemId}`}
                        >
                          Edit
                        </Dropdown.Item>
                      )}

                      {/* <Dropdown.Item
                    as={Link}
                    size="sm"
                    to="#"
                    className="justify-content-center"
                    onClick={() => onDeleteClick(guardKitItem)}
                  >
                    Delete
                  </Dropdown.Item> */}
                    </DropdownButton>
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body>
                <ListGroup className="list-group-flush">
                  <ListGroup.Item>
                    <label className="fw-bolder fs-3">NAME</label>{" "}
                    <Card.Text className="fs-3">
                      {guardKitItem.name || "N/A"}
                    </Card.Text>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <label className="fw-bolder fs-3">QUANTITY UNIT</label>{" "}
                    <Card.Text className="fs-3">
                      {guardKitItem.quantity_unit || "N/A"}
                    </Card.Text>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <label className="fw-bolder fs-3">
                      ALLOCATION QUANTITY
                    </label>{" "}
                    <Card.Text className="fs-3">
                      {guardKitItem.allocation_quantity || "N/A"}{" "}
                    </Card.Text>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <label className="fw-bolder fs-3">
                      QUANTITY AVAILABLE IN STORE
                    </label>{" "}
                    <Card.Text className="fs-3">
                      {`${guardKitItem.kit_items_stock.map(
                        (availableQuantity) => availableQuantity.quantity
                      )}` || "NO STOCK RECORD"}
                    </Card.Text>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <label className="fw-bolder fs-3">
                      BENCHMARK FOR RESTOCKING{" "}
                    </label>{" "}
                    <Card.Text className="fs-3">
                      {guardKitItem.benchmark || "N/A"}{" "}
                    </Card.Text>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>

              <Card.Footer>
                <ListGroup className="list-group-flush">
                  <ListGroup.Item className="d-flex justify-content-end">
                    <Row>
                      <Col className="mb-3">
                        <label className="fw-bolder fs-3">CREATED</label>
                        <Card.Text className="fs-3">
                          {format(
                            new Date(guardKitItem.created_at),
                            "EEE, MMM d, yyyy 'at' HH:mm:ss",
                            {
                              awareOfUnicodeTokens: true,
                            }
                          )}
                        </Card.Text>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item className="d-flex justify-content-end">
                    <Row>
                      <Col>
                        <label className="fw-bolder fs-3">UPDATED</label>{" "}
                        <Card.Text className="fs-3">
                          {" "}
                          {format(
                            new Date(guardKitItem.created_at),
                            "EEE, MMM d, yyyy 'at' HH:mm:ss",
                            {
                              awareOfUnicodeTokens: true,
                            }
                          )}
                        </Card.Text>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Footer>
            </Card>
          )}

          <Card className="mt-4">
            <Card.Header className="d-flex justify-content-between mt-2 mb-2">
              <Card.Title>
                <Row>
                  <Col md="auto">
                    <h2 className="page-title">ITEM STOCK HISTORY</h2>
                  </Col>
                </Row>
              </Card.Title>
            </Card.Header>

            <Card.Body>
              <>
                {!kitItemStock?.data?.length && (
                  <p>
                    NO HISTORY OF DISBURSEMENT/ALLOCATION OR SUPPLY FOR THIS
                    ITEM
                  </p>
                )}
                {!!kitItemStock?.data?.length && (
                  <Row>
                    <Col className="p-0">
                      <>
                        <Table
                          responsive
                          hover
                          bordered
                          className=" table-vcenter table-mobile-md card-table mt-3"
                        >
                          <thead>
                            <tr>
                              <th>MODIFICATION DATE & TIME</th>
                              <th>MODIFICATION TYPE</th>
                              <th>QUANTITY</th>
                            </tr>
                          </thead>

                          <tbody>
                            {kitItemStock.data.map((transaction) => (
                              <tr key={transaction.id}>
                                <td data-label="MODIFICATION DATE">
                                  {format(
                                    new Date(transaction.created_at),
                                    "EEE, MMM d, yyyy 'at' HH:mm:ss",
                                    {
                                      awareOfUnicodeTokens: true,
                                    }
                                  )}
                                </td>
                                <td data-label="MODIFICATION TYPE">
                                  {transaction.type || "N/A"}
                                </td>
                                <td data-label="QUANTITY">
                                  {transaction.quantity || "N/A"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <br />

                        <ReactPagination
                          links={kitItemStock.links}
                          onPageChange={pageChangeHandler}
                          current_page={kitItemStock.current_page}
                          last_page={kitItemStock.last_page}
                        />
                      </>
                    </Col>
                  </Row>
                )}
              </>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default ViewGuardKitItem;
