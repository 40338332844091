import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  BsFillCheckCircleFill,
  BsFillExclamationCircleFill,
  BsFillInfoCircleFill,
  BsFillXCircleFill,
} from "react-icons/bs";

import { Toast, ToastContainer } from "react-bootstrap";
import { handleCustomEvent } from "../../utils/customEventLister";

export const CONTEXT = {
  default: {
    bg: "#fff",
    color: "#000",
    icon: <BsFillInfoCircleFill />,
  },
  success: {
    bg: "#d1e7dd",
    color: "#0a3622",
    icon: <BsFillCheckCircleFill />,
  },
  warning: {
    bg: "#ffe69c",
    color: "#332701",
    icon: <BsFillExclamationCircleFill />,
  },
  danger: {
    bg: "#f1aeb5",
    color: "#58151c",
    icon: <BsFillXCircleFill />,
  },
};

const AlertToast = () => {
  const defaultDelay = 3000;
  const [showAlertToast, setShowAlertToast] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [selectedContext, setSelectedContext] = useState("");
  const [delayAutoHide, setDelayAutoHide] = useState(defaultDelay);
  const toggleShowAlertToast = () => setShowAlertToast(!showAlertToast);

  useEffect(() => {
    document.addEventListener("custom-alert", (event) => {
      setStatus(event?.detail?.status);
      setDelayAutoHide(Number(event?.detail?.delayAutoHide) || defaultDelay);
      setMessage(event?.detail?.message);
      const _selectedContextName = event?.detail?.context || "default";
      setSelectedContext(CONTEXT[_selectedContextName] ?? CONTEXT.default);
      setShowAlertToast(true);
    });
  }, []);

  return (
    <ToastContainer className="p-3 top-0 mt-5 end-0" position={"fixed"}>
      <Toast
        delay={delayAutoHide}
        autohide={true}
        show={showAlertToast}
        onClose={toggleShowAlertToast}
        style={{
          backgroundColor: selectedContext?.bg ?? CONTEXT.default.bg,
        }}
      >
        {" "}
        {!!status && (
          <Toast.Header>
            <span
              style={{
                color: selectedContext?.color ?? CONTEXT.default.color,
              }}
            >
              {selectedContext?.icon ?? CONTEXT.default.icon}
            </span>
            <strong className="ms-2 me-auto">
              {status ?? "Success Alert"}
            </strong>
          </Toast.Header>
        )}
        <div className="d-flex">
          {!status && (
            <span
              className="ms-2 m-auto fs-1"
              style={{
                color: selectedContext?.color ?? CONTEXT.default.color,
              }}
            >
              {" "}
              {selectedContext?.icon ?? CONTEXT.default.icon}
            </span>
          )}
          <Toast.Body>{message ?? " Your Request was successful!"}</Toast.Body>
          {!status && (
            <button
              type="button"
              className="btn-close me-2 m-auto"
              data-bs-dismiss="toast"
              aria-label="Close"
              
            />
          )}
        </div>
      </Toast>
    </ToastContainer>
  );
};

export const alertInfo = (message, status, delayAutoHide) => {
  handleCustomEvent("custom-alert", {
    status: status ?? "",
    delayAutoHide: delayAutoHide ?? 5000,
    message: message,
    context: "default",
  });
};
export const alertSuccess = (message, status, delayAutoHide) => {
  handleCustomEvent("custom-alert", {
    status: status ?? "",
    delayAutoHide: delayAutoHide ?? 5000,
    message: message,
    context: "success",
  });
};

export const alertWarning = (message, status, delayAutoHide) => {
  handleCustomEvent("custom-alert", {
    status: status ?? "",
    delayAutoHide: delayAutoHide ?? 5000,
    message: message,
    context: "warning",
  });
};

export const alertDanger = (message, status, delayAutoHide) => {
  handleCustomEvent("custom-alert", {
    status: status ?? "",
    delayAutoHide: delayAutoHide ?? 5000,
    message: message,
    context: "danger",
  });
};

export default AlertToast;
