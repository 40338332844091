import React from "react";
import { RequireAuth } from "../auth";
import { Route, Routes } from "react-router-dom";
import { Loading } from "./../Components/Loading";
import { Error404 } from "../Pages/Errors/Error404";
import NotificationsConfigIndex from "../Pages/Notifications/notificationsConfigIndex";
import ViewNotification from "../Pages/Notifications/view";
import EditNotification from "../Pages/Notifications/edit";
import CreateNotification from "../Pages/Notifications/create";
import ManageRecipients from "../Pages/Notifications/manageRecipients";
import NotificationsIndex from "../Pages/Notifications";


export const NotificationRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <NotificationsIndex />
              </React.Suspense>
            }
          />

          <Route
            path="/configurations"
            element={
              <React.Suspense fallback={<Loading />}>
                <NotificationsConfigIndex />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="view/:notificationId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewNotification />
              </React.Suspense>
            }
          />
          <Route
            path="edit/:notificationId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditNotification />
              </React.Suspense>
            }
          />
          <Route
            path="/create"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateNotification />
              </React.Suspense>
            }
          />

          <Route
            path="manage_recipients/:notificationNeedsId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ManageRecipients />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
};
