import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Error404 } from "../Errors/Error404";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { VehicleStatusReportForm } from "../../Components/VehiclesStatusReport/VehicleStatusReportForm";

const EditVehicleStatusReport = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { vehicleStatusReportId } = useParams();
  const [vehicles, setVehicles] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [reportedBy, setReportedBy] = useState([]);
  const [officeLocations, setOfficeLocations] = useState([]);
  const [vehicleStatusReport, setVehicleStatusReport] = useState([]);
  const [vehicleStatusParameters, setVehicleStatusParameters] = useState([]);

  useEffect(() => {
    setFetching(true);
    const getVehicleStatusReportEditDetails = async () => {
      try {
        const response = await get(
          `vehicle_status/${vehicleStatusReportId}/edit`
        );
        setVehicleStatusReport(response.data.vehicleStatusReport);
        if (!!Number(response?.data?.vehicleStatusReport?.is_sent)) {
          return;
        }
        setVehicles(response.data.vehicles);
        setReportedBy(response.data.report_by);
        setOfficeLocations(response.data.office_location);
        setVehicleStatusParameters(response.data.vehicle_status_parameters);
        setFetching(false);
      } catch (error) {
        setFetching(false);
        if (error.response.status === 500) {
        }
      }
    };
    getVehicleStatusReportEditDetails();
  }, []);

  return (
    <>
      {!fetching && vehicleStatusReport?.is_sent === 1 && (
        <Navigate to={"/vehicles/status-reports/"} replace={true} />
      )}

      {!fetching && !vehicleStatusReport?.id && <Error404 />}

      <Card className="container">
        {!!fetching ? (
          <Loading />
        ) : (
          <>
            <Card.Header className="d-flex justify-content-between mt-0 mb-4">
              <Card.Title>
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="page-title">Vehicles Status Report </h1>
                  </div>
                </div>
              </Card.Title>

              <div className="row">
                <div className="col-md-12">
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </div>
              </div>
            </Card.Header>

            <Card.Body>
              <VehicleStatusReportForm
                vehicles={vehicles}
                reportedBy={reportedBy}
                officeLocations={officeLocations}
                editVehicleStatusReport={vehicleStatusReport}
                vehicleStatusParameters={vehicleStatusParameters}
              />
            </Card.Body>
          </>
        )}
      </Card>
    </>
  );
};

export default EditVehicleStatusReport;
