import React from 'react'
import { RequireAuth } from "../auth";
import { Loading } from "../Components/Loading";
import { Route, Routes } from "react-router-dom";
import { Error404 } from '../Pages/Errors/Error404';
import ViewWeeklyReport from '../Pages/WeeklyReports/view';
import WeeklyReportIndex from '../Pages/WeeklyReports/Index';
import CreateWeeklyReport from '../Pages/WeeklyReports/create';



const WeeklyReportRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <WeeklyReportIndex />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="/create"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateWeeklyReport />
              </React.Suspense>
            }
          />

          <Route
            path="/view/:weeklyReportId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewWeeklyReport />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
}

export default WeeklyReportRoutes