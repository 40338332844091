import React, { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { PermissionForm } from "../../../Components/RolesPermissions/Permissions/PermissionForm";

const EditPermissionsDomain = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { permissionId } = useParams();
  const [domains, setDomains] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [permission, setPermission] = useState({});

  useEffect(() => {
    setFetching(true);
    const getDetailedPermissionsAndDomain = async () => {
      try {
        const response = await get(`permissions/edit/${permissionId}`);
        setDomains(response.data.domains);
        setPermission(response.data.permissions);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedPermissionsAndDomain();
  }, []);

  return (
    <>
      {!!fetching && <Loading />}

      {!fetching && !!permission?.id && (
        <Card className="container">
          <Card.Header className="d-flex justify-content-between mt-0 mb-5">
            <Card.Title>
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">Edit Permission</h1>
                </div>
              </div>
            </Card.Title>

            <div className="row">
              <div className="col-md-12">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <PermissionForm editPermission={permission} domains={domains} />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default EditPermissionsDomain;
