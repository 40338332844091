import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { Card, Col, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { DriversDataTable } from "../../Components/Drivers/DriversDataTable";

const DriversIndex = () => {
  const { get } = useAxios();
  const [drivers, setDrivers] = useState([]);
  const [searchPageParams] = useSearchParams();
  const [fetching, setFetching] = useState(true);

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;
  let searchKeyParams = searchPageParams.get("search_key") || "";

  const getDrivers = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;
      const response = await get(
        `user/drivers?sort=desc&page=${currentPage}&page_size=${pageSize}&search_key=${searchKeyParams}`
      );

      setDrivers(response.data.drivers);
    } catch (error) {
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    setFetching(true);
    const initialiaseDrivers = async () => await getDrivers();
    initialiaseDrivers();
  }, [currentPage, searchKeyParams]);

  return (
    <>
      {!!fetching && <Loading />}

      <Card className="container d-flex justify-content-center ms-3">
        <Card.Header className="d-flex justify-content-between mt-2 mb-2">
          <Card.Title>
            <Row>
              <Col md="auto">
                <h2 className="page-title">ALL DRIVERS</h2>
              </Col>
            </Row>
          </Card.Title>
        </Card.Header>

        <Card.Body className="mt-4">
          {!fetching && !!drivers?.length === 0 && (
            <div> No Vehicles Found</div>
          )}

          {!fetching && !!drivers?.length && (
            <>
              <Row>
                <Col className="p-0">
                  <DriversDataTable drivers={drivers} />
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default DriversIndex;
