import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Card, Col, Row } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { permissions } from "../../utils/permissions";
import { Link, useSearchParams } from "react-router-dom";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { VendorsDataTable } from "../../Components/Vendors/VendorsDataTable";

const VendorIndex = () => {
  const { get } = useAxios();
  const [vendors, setVendors] = useState([]);
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [searchPageParams, setSearchPageParams] = useSearchParams();

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;

  const pageChangeHandler = (page) => {
    setSearchPageParams(
      {
        ...Object.fromEntries(searchPageParams),
        page,
      },
      { replace: true }
    );
  };

  const getVendors = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;
      const response = await get(
        `vendors?sort=desc&page=${currentPage}&page_size=${pageSize}`
      );
      setVendors(response.data.vendors);
    } catch (error) {
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    setFetching(true);
    const initialiseVendors = async () => await getVendors();
    initialiseVendors();
  }, [currentPage]);

  return (
    <>
      {fetching && <Loading />}

      <br />

      <Card className="container d-flex justify-content-center ms-3">
        <Card.Header className="d-flex justify-content-between mt-2 mb-2">
          <Card.Title>
            <Row>
              <Col md="auto">
                <h2 className="page-title">Vendors</h2>
              </Col>
            </Row>
          </Card.Title>
          {hasPermission(permissions.CREATE_VENDOR) && (
            <Row>
              <Col md="auto">
                <Link to="/vendors/create" className="btn btn-primary">
                  Create Vendor
                </Link>
              </Col>
            </Row>
          )}
        </Card.Header>

        <Card.Body>
          {!fetching && !!vendors?.length === 0 && <div> No Vendors found</div>}

          {!fetching && !!vendors?.length && (
            <Row>
              <Col className="p-0">
                <VendorsDataTable vendors={vendors} getVendors={getVendors} />
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default VendorIndex;
