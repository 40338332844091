import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  Dropdown,
  DropdownButton,
  Alert,
} from "react-bootstrap";
import { format } from "date-fns";
import { SERVER_URL } from "../../config";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { permissions } from "../../utils/permissions";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { HtmlContent } from "../../Components/HtmlContent";
import { Link, useNavigate, useParams } from "react-router-dom";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { handleStatusBadge } from "../../Components/MaintenanceRecords/handleStatusBadge";
import { handleInspectionBage } from "../../Components/MaintenanceRecords/handleInspectionBadge";
import { FaExclamationCircle } from "react-icons/fa";

const ViewVehicleMaintenanceRecord = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { maintenanceRecordId } = useParams();
  const [approver, setApprover] = useState({});
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [maintenanceRecord, setMaintenanceRecord] = useState({});
  const [maintenanceItems, setMaintenanceItems] = useState([]);

  useEffect(() => {
    setFetching(true);
    const getDetailedPurchaseOrder = async () => {
      try {
        const response = await get(`maintenance/${maintenanceRecordId}`);
        setApprover(response.data.maintenance_record.approver);
        setMaintenanceRecord(response.data.maintenance_record);
        setMaintenanceItems(response.data.maintenance_record.maintenance_items);
      } catch (error) {
      } finally {
        setFetching(false);
      }
    };
    getDetailedPurchaseOrder();
  }, []);

  return (
    <>
      {!!fetching && <Loading />}

      <>
        <Card>
          <Card.Header className="d-flex d-print-none justify-content-between mt-2 mb-5">
            <Card.Title>
              <Row className="me-4">
                <Col>
                  <h1>
                    Maintenance Record (Voucher No:{" "}
                    {maintenanceRecord?.request_voucher_number})
                  </h1>
                </Col>
              </Row>
            </Card.Title>

            <Row className="me-4">
              <Col className="mb-2">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </Col>

              {/* <Col className="mb-2" sm={4}>
                  <Button onClick={() => window.print()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />
                      <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />
                      <rect x="7" y="13" width="10" height="8" rx="2" />
                    </svg>
                    Print
                  </Button>
                </Col> 
              */}

              {!(
                maintenanceRecord?.status === "rejected" ||
                maintenanceRecord?.status === "completed"
              ) && (
                  <Col>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="ACTION"
                      className="justify-content-center"
                    >
                      {maintenanceRecord?.status === "awaiting approval" && (
                        <>
                          {hasPermission(permissions.EDIT_MAINTENANCE_RECORDS) && (
                            <Dropdown.Item
                              as={Link}
                              size="sm"
                              className="justify-content-center"
                              to={`/maintenance-records/edit/${maintenanceRecordId}`}
                            >
                              Edit
                            </Dropdown.Item>
                          )}

                          {hasPermission(
                            permissions.APPROVE_MAINTENANCE_RECORDS
                          ) && (
                              <Dropdown.Item
                                as={Link}
                                size="sm"
                                className="justify-content-center"
                                to={`/maintenance-records/approve_reject/${maintenanceRecordId}`}
                              >
                                Approve/Reject
                              </Dropdown.Item>
                            )}
                        </>
                      )}

                      {!maintenanceRecord?.inspected_by_employee_code &&
                        maintenanceRecord?.inspection_pass !== 1 &&
                        (maintenanceRecord?.status === "approved" ||
                          maintenanceRecord?.status ===
                          "approved with adjustment") && (
                          <>
                            {hasPermission(
                              permissions.INSPECT_MAINTENANCE_RECORDS
                            ) && (
                                <Dropdown.Item
                                  as={Link}
                                  size="sm"
                                  className="justify-content-center"
                                  to={`/maintenance-records/inspection/${maintenanceRecordId}`}
                                >
                                  Add Inspection Detail
                                </Dropdown.Item>
                              )}
                          </>
                        )}
                    </DropdownButton>
                  </Col>
                )}
            </Row>
          </Card.Header>
          {!!maintenanceRecord?.id && !fetching && (
            <>
              <Card.Body className="mb-2" id="printableArea">
                {!Number(maintenanceRecord?.already_approved) && !!maintenanceRecord?.approver &&
                  <Alert variant="info" className="mb-5 fs-3">
                    <span>
                      <FaExclamationCircle size={19} />
                    </span>
                    <span className="ms-2">
                      This maintenance record was already approved before entry.
                    </span>
                  </Alert>
                }

                <div className="page-wrapper">
                  <Row className="mb-4 g-0">
                    <Col md={6}>
                      <Table
                        responsive
                        hover
                        bordered
                        className="table table-sm table-bordered m-0"
                      >
                        <tbody>
                          <tr>
                            <th width={45}>VOUCHER NO.</th>
                            <td>
                              {maintenanceRecord?.request_voucher_number ||
                                "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>VEHICLE</th>
                            <td className="text-uppercase">
                              {!!maintenanceRecord?.vehicle
                                ? `${maintenanceRecord?.vehicle?.vehicle_model?.vehicle_make?.name} - ${maintenanceRecord?.vehicle?.vehicle_model?.name}`
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>REPORTED DATE</th>
                            <td>
                              {format(
                                new Date(maintenanceRecord?.reported_date),
                                "d MMM, yyyy",
                                {
                                  awareOfUnicodeTokens: true,
                                }
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>REQUESTED BY</th>
                            <td className="text-uppercase">
                              {!!maintenanceRecord?.requester?.id
                                ? `${maintenanceRecord?.requester?.first_name} ${maintenanceRecord?.requester?.middle_name} ${maintenanceRecord?.requester?.surname}`
                                : "N/A"}
                            </td>
                          </tr>


                          <tr>
                            <th>CREATED BY</th>
                            <td className="text-uppercase">
                              {!!maintenanceRecord?.creator?.id
                                ? `${maintenanceRecord?.creator?.first_name} ${maintenanceRecord?.creator?.surname}`
                                : "N/A"
                              }
                            </td>
                          </tr>
                          <tr>
                            <th>CONFIRMED BY</th>
                            <td>
                              {!!maintenanceRecord?.confirmer1?.id &&
                                !!maintenanceRecord?.confirmer2?.id ? (
                                <ul>
                                  <li className="text-uppercase">
                                    {`${maintenanceRecord?.confirmer1?.first_name} ${maintenanceRecord?.confirmer1?.middle_name} ${maintenanceRecord?.confirmer1?.surname}` ||
                                      "N/A"}
                                  </li>
                                  <li className="text-uppercase">
                                    {`${maintenanceRecord?.confirmer2?.first_name} ${maintenanceRecord?.confirmer2?.middle_name} ${maintenanceRecord?.confirmer1?.surname}` ||
                                      "N/A"}
                                  </li>
                                </ul>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>VENDOR</th>
                            <td>
                              {!!maintenanceRecord?.vendor?.id ? (
                                <>
                                  <span className="text-uppercase">{maintenanceRecord?.vendor.name}</span>
                                  <br />
                                  {maintenanceRecord?.vendor.primary_phone}
                                </>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>ACCOUNT DETAILS</th>
                            <td>
                              {!!maintenanceRecord?.vendor?.bank ? (
                                <>
                                  <span className="text-uppercase">
                                    {`${maintenanceRecord?.vendor?.bank?.name}`}
                                  </span>
                                  <br />
                                  {maintenanceRecord?.vendor?.account_number}
                                </>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>

                    <Col md={6}>
                      <Table
                        responsive
                        hover
                        bordered
                        className="table table-sm table-bordered m-0"
                      >
                        <tbody>
                          <tr>
                            <th width={45}>INVOICE DOCUMENT</th>
                            <td>
                              {!!maintenanceRecord?.invoice_document ? (
                                <a
                                  href={`${SERVER_URL}/${maintenanceRecord?.invoice_document}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <BsFillFileEarmarkPdfFill />
                                  <span>Invoice Document</span>
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>INVOICE DATE</th>
                            <td>
                              {!!maintenanceRecord?.invoice_date
                                ? format(
                                  new Date(maintenanceRecord?.invoice_date),
                                  "d MMM, yyyy",
                                  {
                                    awareOfUnicodeTokens: true,
                                  }
                                )
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>INVOICE NUMBER</th>
                            <td>
                              {!!maintenanceRecord?.invoice_number
                                ? `${maintenanceRecord?.invoice_number}`
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>WARRANTY EXPIRY DATE</th>
                            <td>
                              {!!maintenanceRecord?.warranty_expiry_date
                                ? format(
                                  new Date(
                                    maintenanceRecord?.warranty_expiry_date
                                  ),
                                  "d MMM, yyyy",
                                  {
                                    awareOfUnicodeTokens: true,
                                  }
                                )
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>STATUS</th>
                            <td className="p-2">
                              {handleStatusBadge(maintenanceRecord)}
                            </td>
                          </tr>
                          {!!maintenanceRecord?.rejected_reason && (
                            <tr>
                              <th>REJECTION REASON</th>

                              <td className="p-2">
                                <HtmlContent>
                                  {maintenanceRecord?.rejected_reason}
                                </HtmlContent>
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th>INSPECTION</th>
                            <td>
                              {handleInspectionBage(maintenanceRecord)}

                              {!!maintenanceRecord?.inspected_by_employee_code && (
                                <span>
                                  {format(
                                    new Date(maintenanceRecord?.inspected_date),
                                    "d MMM, yyyy",
                                    {
                                      awareOfUnicodeTokens: true,
                                    }
                                  )}
                                </span>
                              )}
                            </td>
                          </tr>
                          {!!maintenanceRecord?.inspected_by_employee_code && (
                            <tr>
                              <th>INSPECTED BY</th>
                              <td>
                                {`${maintenanceRecord?.inspector?.first_name} ${maintenanceRecord?.inspector?.surname}` ||
                                  "N/A"}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </div>
              </Card.Body>

              <Card.Body className="mb-2">
                <Table
                  responsive
                  hover
                  bordered
                  className=" table-vcenter table-mobile-md card-table mt-2 mb-4 "
                >
                  <thead>
                    <tr>
                      <th>TYPE</th>
                      <th>PART</th>
                      <th>QUANTITY</th>
                      <th>RATE (&#8358;)</th>
                      <th>AMOUNT (&#8358;)</th>
                    </tr>
                  </thead>

                  <tbody>
                    {maintenanceItems.map((maintenanceItem) => (
                      <tr key={maintenanceItem.id}>
                        <td
                          data-label="TYPE"

                        >
                          <span className="text-capitalize fw-bolder">
                            {maintenanceItem?.type}
                          </span>

                          {!!maintenanceItem?.description && (
                            <div className="mt-2 font-italic">
                              ({maintenanceItem?.description})
                            </div>
                          )}

                        </td>

                        <td data-label="PART">
                          {maintenanceItem?.vehicle_part?.name || "N/A"}
                        </td>
                        <td data-label="QUANTITY">
                          {maintenanceItem?.quantity}
                        </td>

                        <td data-label="RATE (&#8358;)">
                          {Number(maintenanceItem?.rate).toLocaleString()}
                        </td>
                        <td data-label="AMOUNT (&#8358;)">
                          {Number(maintenanceItem?.amount).toLocaleString()}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th colSpan={4}>Requested Amount</th>
                      <td>
                        {Number(
                          maintenanceRecord?.request_amount
                        ).toLocaleString() || "N/A"}
                      </td>
                    </tr>
                    {maintenanceRecord?.status !== "awaiting approval" && (
                      <tr>
                        <td
                          data-label="TYPE"
                          className="text-capitalize fw-bolder"
                        >
                          Approved Amount
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {Number(
                            maintenanceRecord?.approved_amount
                          ).toLocaleString() || "N/A"}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="mt-4 border">
                  <span className="text-capitalize fw-bolder me-5 ps-2">
                    APPROVED BY
                  </span>
                  <span className="ms-3">
                    {!!approver
                      ? `${approver?.first_name} ${approver?.surname}`
                      : "N/A"}
                  </span>
                </div>

                <div className="mt-4 border">
                  <span className="text-capitalize fw-bolder me-5 ps-2">
                    APPROVAL DOCUMENT
                  </span>
                  <span className="ms-3">
                    {!!maintenanceRecord?.approval_document_file ? (
                      <a
                        href={`${SERVER_URL}/${maintenanceRecord?.approval_document_file}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {maintenanceRecord?.approval_document_file}
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </span>
                </div>
              </Card.Body>
            </>
          )}
        </Card>
      </>
    </>
  );
};

export default ViewVehicleMaintenanceRecord;
