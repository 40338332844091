import React from "react";
import { Link } from "react-router-dom";
import { FaBell } from "react-icons/fa";
import { FaBellSlash } from "react-icons/fa";

export const NotificationIcon = ({ message }) => {
  
  return (
    <>
        <Link 
          to="/notifications"
          className="nav-link px-3"
          aria-label="Show notifications"
          title="Click to enable notifications"
          >
            <FaBell size={18} />
        
          {/* {Notification.permission === "granted" ? (
            <>
              <FaBell size={18} />
              <span className="badge bg-red"></span>
            </>
          )
          :  <FaBellSlash size={18} />
        } */}
        </Link>
      
      </>
    );
  
};
