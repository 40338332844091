import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useGetEscortData } from "../../hooks/EscortsDataFetching/useGetEscortData";

export const FilterSearch = ({
  onhandleFilteredSearchChange,
  initialSearchFilterInputs,
  escortStatuses,
}) => {
  const { clients, escortTypes, officeLocations, fetchData } =
    useGetEscortData();

  useEffect(() => {
    const getData = async () => {
      fetchData();
    };
    getData();
  }, []);

  const [searchFilterInputs, setSearchFilterInputs] = useState({
    type: initialSearchFilterInputs.type ?? "",
    status: initialSearchFilterInputs.status ?? "",
    client: initialSearchFilterInputs.client ?? "",
    branch: initialSearchFilterInputs.branch ?? "",
    toDate: initialSearchFilterInputs.toDate ?? "",
    fromDate: initialSearchFilterInputs.fromDate ?? "",
    officeLocation: initialSearchFilterInputs.officeLocation ?? "",
  });

  const clientOptions = Object.keys(clients).map((key) => ({
    value: key,
    label: clients[key],
  }));

  return (
    <>
      <Form
        className="border mt-0 mb-4 p-3"
        method="GET"
        onSubmit={(event) => {
          event.preventDefault();
          onhandleFilteredSearchChange(searchFilterInputs);
        }}
      >
        <Row>
          <Col></Col>
        </Row>

        <Row className="mb-3">
          <Col className="py-2 mx-1" sm={4}>
            <Form.Label>CLIENT</Form.Label>
            <Select
              className="text-dark"
              name="client"
              placeholder="Select Client"
              defaultValue={searchFilterInputs.client.label}
              onChange={(event) =>
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  client: event.value,
                })
              }
              options={clientOptions}
            />
          </Col>

          <Col className="py-2 mx-1">
            <Form.Label>BRANCH LOCATION</Form.Label>
            <select
              name="branch"
              className="form-control"
              defaultValue={searchFilterInputs.branch}
              onChange={(event) =>
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  branch: event.target.value,
                })
              }
            >
              <option value="" disabled>
                Select Branch Location
              </option>{" "}
              {Object.keys(officeLocations).map((key) => (
                <option value={key} key={key}>
                  {" "}
                  {officeLocations[key]}
                </option>
              ))}
            </select>
          </Col>

          <Col className="py-2 mx-1">
            <Form.Label>ESCORT TYPE</Form.Label>
            <Form.Select
              name="type"
              className="form-control"
              defaultValue={searchFilterInputs.type}
              onChange={(event) =>
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  type: event.target.value,
                })
              }
            >
              <option value="" disabled>
                Select Escort Type
              </option>{" "}
              {Object.keys(escortTypes).map((key) => (
                <option value={key} key={key}>
                  {" "}
                  {escortTypes[key]}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col className="py-2 mx-1" sm={4}>
            <Form.Label>ESCORT STATUS</Form.Label>
            <Form.Select
              name="status"
              className="form-control"
              defaultValue={searchFilterInputs.status}
              onChange={(event) =>
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  status: event.target.value,
                })
              }
            >
              <option value="" disabled>
                Select Escort Status
              </option>{" "}
              {Object.keys(escortStatuses).map((statusIndex) => (
                <option value={statusIndex} key={statusIndex}>
                  {" "}
                  {escortStatuses[statusIndex]}
                </option>
              ))}
            </Form.Select>
          </Col>

          <Col className="py-2 mx-1">
            <Form.Label>FROM</Form.Label>
            <Form.Control
              type="date"
              name="fromDate"
              className="form-control"
              placeholder="Pick a Date"
              defaultValue={searchFilterInputs.fromDate}
              onChange={(event) =>
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  fromDate: event.target.value,
                })
              }
            />
          </Col>

          <Col className="py-2 mx-1">
            <Form.Label>TO</Form.Label>
            <Form.Control
              type="date"
              name="toDate"
              className="form-control"
              placeholder="Pick a Date"
              defaultValue={searchFilterInputs.toDate}
              onChange={(event) =>
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  toDate: event.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Button variant="secondary" className="me-3" type="submit">
          Filter
        </Button>
      </Form>
    </>
  );
};
