import React from 'react'
import { Card, Nav } from 'react-bootstrap'

export const ShowMore = ({heading, title, handleShowMore}) => {
  return (
    <Nav className="fw-bolder fs-4 mb-3" title={title}>
        <Nav.Item
            onClick={handleShowMore}
        >
            <Nav.Link href="#">
                <Card.Header>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="6" cy="10" r="2" />
                        <line x1="6" y1="4" x2="6" y2="8" />
                        <line x1="6" y1="12" x2="6" y2="20" />
                        <circle cx="12" cy="16" r="2" />
                        <line x1="12" y1="4" x2="12" y2="14" />
                        <line x1="12" y1="18" x2="12" y2="20" />
                        <circle cx="18" cy="7" r="2" />
                        <line x1="18" y1="4" x2="18" y2="5" />
                        <line x1="18" y1="9" x2="18" y2="20" />
                    </svg> 
                    {" "}
                    <Card.Title>{heading}</Card.Title>
                </Card.Header>
        
            </Nav.Link>
        </Nav.Item>
    </Nav>
)
}
