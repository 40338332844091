import React, { useState } from "react";
import * as yup from "yup";
import useAxios from "../../hooks/useAxios";
import { Navigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import TinyMCETextEditor from "../TinyMCETextEditor";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Badge, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object({
  needs: yup.string().required("Escort needs is required"),
  amount: yup.string(),
});

export const RequestNeedsForm = ({ editRequestNeeds, scheduleId }) => {
  const {
    setValue,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      status: "pending",
      needs: editRequestNeeds?.escortscheduleofficerneeds[0]?.needs || "",
      amount: editRequestNeeds?.escortscheduleofficerneeds[0]?.amount || "",
    },
  });

  const { post } = useAxios();
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const endpointPath = !editRequestNeeds?.escortscheduleofficerneeds[0]?.id
        ? `escorts/${scheduleId}/add/officer_need`
        : `escorts/${scheduleId}/update/officer_need`;

      await post(endpointPath, {
        ...data,
      });

      setIsSaved(true);

      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Escort needs request successfully made!", null, 5000);

    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to request schedule needs!",
          null,
          5000
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isSaved && (
        <Navigate
          to={`/escort-schedules/view/${scheduleId}`}
          replace={true}
        />
      )}
      <Form className="ms-4" onSubmit={handleSubmit(onSubmit)}>
        <Form.Label>REQUST FOR NEEDS</Form.Label>

        <Form.Group
          className="escort-requested-needs mb-4"
          controlId="escortRequestedNeeds"
        >
          <Form.Label className="required">NEEDS</Form.Label>
          <Controller
            control={control}
            name="needs"
            render={({ field }) => (
              <TinyMCETextEditor field={field} setValue={setValue} />
            )}
          />

          {!!errors.needs && (
            <p>
              <Badge bg="danger">{errors.needs?.message}</Badge>
            </p>
          )}
        </Form.Group>

        <Form.Group controlId="numerical-monetary-value">
          <Form.Label>
            <span className="form-hint">(N.....................K)</span>
          </Form.Label>
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <NumberFormat {...field} className="form-control" />
            )}
          />

          {!!errors.amount && (
            <p>
              <Badge bg="danger">{errors.amount?.message}</Badge>
            </p>
          )}
        </Form.Group>

        <div className="mt-3">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Button
              type="submit"
              variant="success"
              disabled={
                !!loading ||
                !isDirty 
              }
            >
              Submit
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};
