import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link, useNavigate,} from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { MdFreeCancellation } from "react-icons/md";
import { endOfWeek, format, startOfWeek } from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import colorCodedTypes from "../../Components/EscortSchedule/CalenderView/ColourCodedTypes";
import ScheduleDeTailModal from "../../Components/EscortSchedule/CalenderView/ScheduleDeTailModal";
import EscortTypeColourCode from "../../Components/EscortSchedule/CalenderView/EscortTypeColourCode";
import EscortScheduleDateRange from "../../Components/EscortSchedule/CalenderView/EscortScheduleDateRange";

const EscortSchedulecalendarView = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const localizer = momentLocalizer(moment);
  const [fetching, setFetching] = useState(true);
  const [scheduleDetail, setScheduleDetail] = useState({});
  const [dateFrom, setDateFrom] = useState(startOfWeek(Date.now()));
  const [dateTo, setDateTo] = useState(endOfWeek(Date.now()));
  const [location, setLocation] = useState("");
  const [escortScheduleDetails, setEscortScheduleDetails] = useState([]);
  const [calendarEscortSchedules, setCalendarEscortSchedules] = useState([]);

  const sendFilterParamsRequest = async (dateFrom, dateTo, location) => {
    try {
      setFetching(true);
      setCalendarEscortSchedules([]);
      const response = await get(`escorts/calender?start_date=${format(
        dateFrom,
        "yyyy-MM-dd"
      )}&end_date=${format(dateTo, "yyyy-MM-dd")}
      `);

      const displayedSchedules = response.data.escortSchedules.map(
        (schedule) => ({
          id: schedule.id,
          title: schedule.client.name,
          start: new Date(schedule.escort_date),
          end: new Date(schedule.escort_date),
          scheduleData: schedule,
        })
      );

      setCalendarEscortSchedules(displayedSchedules);
      setEscortScheduleDetails(response.data.escortSchedules);
      setFetching(false);
    } catch (error) {}
  };

  const handleFilterParamsChange = (dateFrom, dateTo, location) => {
    setDateTo(dateTo);
    setDateFrom(dateFrom);
    setLocation(location);
    sendFilterParamsRequest(dateFrom, dateTo, location);
  };

  const handleCalendarEventSelect = (event) => {
    setScheduleDetail(event.scheduleData);
    handleShow();
  };

  useEffect(() => {
    sendFilterParamsRequest(dateFrom, dateTo, location);
  }, []);

  return (
    <Row className="ms-2">
      <Col md={3}>
        <div className="mb-4">
          <div className="col-md-12 mb-2">
            <Button onClick={() => navigate(-1)}>Back</Button>
          </div>
          <Link to="/escort-schedules/create" className="btn btn-primary">
            Create Schedule
          </Link>
        </div>

        <div className="mb-4">
          <EscortScheduleDateRange
            dateTo={dateTo}
            location={location}
            dateFrom={dateFrom}
            handleFilterParamsChange={handleFilterParamsChange}
          />
        </div>

        <div>
          <EscortTypeColourCode />
        </div>
      </Col>

      <Col className="calendar-view " md={9}>
        {!!fetching && <Loading />}

        {!fetching && !calendarEscortSchedules?.length && (
          <div className="d-flex flex-column justify-content-center text-muted align-items-center fs-2 mt-5">
            <MdFreeCancellation
              className="display-1 mb-2"
              style={{ color: "#ccc" }}
            />
            No Escort Schedules Found
          </div>
        )}

        {!fetching && !!calendarEscortSchedules?.length && (
          <>
            <Calendar
              defaultView={Views.AGENDA}
              defaultDate={dateFrom}
              localizer={localizer}
              events={calendarEscortSchedules}
              onSelectEvent={handleCalendarEventSelect}
              titleAccessor={(event) => {
                const config =
                  colorCodedTypes[event?.scheduleData.escort_type] ?? {};
                const icon = config?.icon ?? "";
                return (
                  <>
                    {icon} {event.title}
                  </>
                );
              }}
              components={{
                eventWrapper: ({ event, children }) => {
                  const config =
                    colorCodedTypes[event?.scheduleData.escort_type] ?? {};
                  const colorCode = config?.color ?? "";
                  children.props.style.backgroundColor = colorCode;
                  return <>{children}</>;
                },
              }}
            />

            {!!scheduleDetail.id && (
              <ScheduleDeTailModal
                show={show}
                handleShow={handleShow}
                handleClose={handleClose}
                scheduleDetail={scheduleDetail}
              />
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default EscortSchedulecalendarView;
