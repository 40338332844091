import React, { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { LoadingButton } from "../../LoadingButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Badge, Button, Card, Form } from "react-bootstrap";
import { alertSuccess, alertWarning } from "../../CustomAlerts/AlertToast";

const schema = yup.object({
  domain: yup.string().required("Domain is required"),
  permission: yup.string().required("Permission is required"),
});

export const PermissionForm = ({ editPermission, domains }) => {
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      domain: editPermission?.domain || "",
      permission: editPermission?.name.replace(/[^a-z ]/gi, " ") || "",
    },
  });

  const { post } = useAxios();
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const permissionData = {
        domain: data.domain,
        permission: data.permission,
      };

      const endpointPath = !editPermission?.id
        ? "permissions/store"
        : `permissions/update/${editPermission.id}`;

      await post(endpointPath, permissionData);

      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Permission successfully saved!", null, 5000);
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 422) {
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error?.response?.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to create permission!",
          null,
          5000
        );
      }
    }
  };

  return (
    <>
      {isSaved && <Navigate to={"/permissions/"} replace={true} />}
      <Form onSubmit={handleSubmit(onSubmit)}>
        {hasServerError && (
          <Alert variant="danger">
            <p>
              Something went wrong will processing your request. Please try
              again!
            </p>
          </Alert>
        )}
        <Card className="">
          <Card.Body>
            <div className="mb-4">
              <Form.Group controlId="domain">
                <Form.Label className="required">DOMAIN</Form.Label>
                <Form.Select
                  {...register("domain")}
                  defaultValue={editPermission?.domain || ""}
                >
                  <option disabled>Select one</option>
                  {domains.map((domainName, index) => (
                    <option value={domainName} key={index}>
                      {domainName}
                    </option>
                  ))}
                </Form.Select>

                {!!errors.domain && (
                  <p>
                    <Badge bg="danger">{errors.domain?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <div className="permission-name mb-3">
              <Form.Group controlId="permissionName">
                <Form.Label className="required">PERMISSION</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="permission name"
                  {...register("permission")}
                />

                {!!errors.permission && (
                  <p>
                    <Badge bg="danger">{errors.permission?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>
          </Card.Body>
        </Card>

        <Card.Footer className=" d-flex justify-content-center  ">
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              variant="success"
              type="submit"
              className=" d-flex justify-content-center mt-3"
              disabled={!!loading || !isDirty}
            >
              Submit
            </Button>
          )}
        </Card.Footer>
      </Form>
    </>
  );
};
