import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Col,
  Nav,
  Row,
  Card,
  Table,
  Button,
  ListGroup,
  Container,
  Form,
  Badge,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import useAxios from "../../hooks/useAxios";
import {
  alertSuccess,
  alertWarning,
} from "../../Components/CustomAlerts/AlertToast";
import { Loading } from "../../Components/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "../../Components/LoadingButtons";
import { Navigate, useNavigate, useParams } from "react-router-dom";

const schema = yup.object({
  status: yup.string().required("You have not updated status!"),
  reject_reason: yup.string().max(5000),
});

const KitRequestStatusUpdate = () => {
  const navigate = useNavigate();
  const { get, post } = useAxios();
  const [client, setClient] = useState({});
  let { guardKitsRequestId } = useParams();
  const [kitItems, setKitItems] = useState({});
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [statusOptions, setStatusOptions] = useState([]);
  const [clientLocation, setClientLocation] = useState({});
  const [guardKitsRequest, setGuardKitsRequest] = useState({});

  const {
    watch,
    setError,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      status: "",
      reject_reason: "",
    },
  });

  const selectedStatus = watch("status");

  useEffect(() => {
    setFetching(true);
    const getDetailedGuardKitsRequest = async () => {
      try {
        const response = await get(`uniformrequest/${guardKitsRequestId}/edit`);

        setGuardKitsRequest(response.data);
        setStatusOptions(response.data.statuses);
        setKitItems(response.data.uniformRequest.items);
        setClient(response.data.uniformRequest.clients);
        setClientLocation(response.data.uniformRequest.client_locations);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedGuardKitsRequest();
  }, []);

  const displayedStatuses = [];

  statusOptions.map((status) => {
    if (
      status === "approved" ||
      status === "approve_with_adjustment" ||
      status === "rejected"
    ) {
      displayedStatuses.push(status);
    }
    return displayedStatuses;
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await post(`uniformrequest/${guardKitsRequestId}/editStatus`, {
        ...data,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      navigate(-1);
      alertSuccess("Kit request status successfully updated!", null, 5000);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 422) {
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error.response.status === 500) {
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      }
    }
  };

  return (
    <div className="page-wrapper">
      <Container fluid>
        {!!fetching && <Loading />}

        {!!guardKitsRequest && !fetching && (
          <>
            <div className="page-header d-print-none">
              <Row className="align-items-center">
                <Col>
                  <div className="page-pretitle">GUARD KIT REQUEST STATUS </div>
                </Col>
              </Row>
            </div>

            <div className="page-body">
              <Nav className="justify-content-end">
                <Nav.Item>
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </Nav.Item>
              </Nav>

              <br />

              <>
                <Card className="mb-5">
                  <Card.Header className="d-flex justify-content-between mt-2 mb-4">
                    <Card.Title>REQUEST SUMARRY </Card.Title>
                  </Card.Header>

                  <Card.Body className="mb-4">
                    <ListGroup className="list-group-flush">
                      <ListGroup.Item>
                        <label className="fw-bolder fs-3 ">
                          CLIENT DETAILS
                        </label>

                        <Row className="mb-3">
                          <Col className="mt-3 mb-3" md={6}>
                            <label className="fw-bold fs-3 ">NAME</label>
                            <Card.Text className="fs-3">
                              {client.name || "N/A"}
                            </Card.Text>
                          </Col>

                          <Col className="mt-3" md={6}>
                            <label className="fw-bold fs-3">LOCATION</label>
                            <Card.Text className="fs-3">
                              {clientLocation.name || "N/A"}
                            </Card.Text>
                          </Col>
                        </Row>

                        <Row className="mt-3 mb-3">
                          <Col>
                            <label className="fw-bold fs-3 ">
                              NUMBER OF OPERATIVE
                            </label>
                            <Card.Text className="fs-3">
                              {clientLocation.guards_num || "N/A"}
                            </Card.Text>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col className="mt-3" md={6}>
                            <label className="fw-bold fs-3">
                              LAST KIT ALLOCATION DATE
                            </label>
                            <Card.Text className="fs-3">
                              {clientLocation.last_kit_allocation_date || "N/A"}
                            </Card.Text>
                          </Col>
                          <Col className="mt-3" md={6}>
                            <label className="fw-bold fs-3">
                              NEXT KIT ALLOCATION DATE
                            </label>
                            <Card.Text className="fs-3">
                              {clientLocation.next_kit_allocation_date || "N/A"}
                            </Card.Text>
                          </Col>
                        </Row>
                      </ListGroup.Item>

                      <ListGroup.Item className="mt-4">
                        <label className="fw-bolder fs-3 ">
                          KIT ITEMS REQUEST DETAILS
                        </label>
                        <Table
                          responsive
                          hover
                          bordered
                          className=" table-vcenter table-mobile-md card-table mt-3"
                        >
                          <thead>
                            <tr>
                              <th>NAME</th>
                              <th>QUANTITY REQUESTED</th>
                            </tr>
                          </thead>

                          <tbody>
                            {kitItems.map((kitItem) => (
                              <tr key={kitItem.id}>
                                <td data-label="NAME">
                                  {kitItem.guard_kit?.name || "N/A"}
                                </td>

                                <td data-label="QUANTITY REQUESTED">
                                  {kitItem.quantity}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <label className="fw-bolder fs-3 mt-3">
                          REQUEST STATUS
                        </label>

                        <Row className="mb-3">
                          <Col className="mt-3" md={6}>
                            <label className="fw-bold fs-3">STATUS</label>
                            <Card.Text className="fs-3">
                              {`${guardKitsRequest.uniformRequest.status}`.replace(
                                /\_/g,
                                " "
                              ) || "N/A"}
                            </Card.Text>
                          </Col>

                          {guardKitsRequest.status === "rejected" && (
                            <Col className="mt-3" md={6}>
                              <label className="fw-bold fs-3">REASON</label>
                              <Card.Text className="fs-3">
                                {guardKitsRequest.uniformRequest.reject_reason}
                              </Card.Text>
                            </Col>
                          )}
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>

                <br />

                <Card className="mb-5">
                  <Card.Header className="d-flex justify-content-between mt-2 mb-3">
                    <Card.Title> UPDATE KIT REQUEST STATUS </Card.Title>
                  </Card.Header>

                  <Card.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="requestStatus"
                      >
                        <Form.Label column sm="2">
                          STATUS
                        </Form.Label>
                        <Col sm="10">
                          <Form.Select
                            className="form-control"
                            {...register("status")}
                            onChange={(event) => {
                              setValue("status", event.target.value, {
                                shouldValidate: true,
                              });
                            }}
                          >
                            <option value="" disabled>
                              Select Status
                            </option>
                            {displayedStatuses.map((statusOption, index) => (
                              <option key={index} value={statusOption}>
                                {`${statusOption}`.replace(/_/g, " ")}
                              </option>
                            ))}
                          </Form.Select>
                          {!!errors.status && (
                            <p>
                              <Badge className="mt-1" bg="danger">
                                {errors.status?.message}
                              </Badge>
                            </p>
                          )}
                        </Col>
                      </Form.Group>

                      {selectedStatus === "rejected" && (
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="requestStatus"
                        >
                          <Form.Label column sm="2" className="required">
                            REASON
                          </Form.Label>
                          <Col sm="10">
                            <Form.Text>
                              What is the reason for rejecting the request?
                            </Form.Text>
                            <Form.Control
                              as="textarea"
                              rows={4}
                              placeholder="Reason"
                              {...register("reject_reason")}
                              onChange={(event) => {
                                setValue("reject_reason", event.target.value, {
                                  shouldValidate: true,
                                });
                              }}
                            />
                          </Col>

                          {!!errors.reject_reason && (
                            <p>
                              <Badge className="mt-1" bg="danger">
                                {errors.reject_reason?.message}
                              </Badge>
                            </p>
                          )}
                        </Form.Group>
                      )}

                      {selectedStatus === "approve_with_adjustment" && (
                        <Navigate
                          to={`/guardkits-request/${guardKitsRequestId}/adjusted_approval`}
                          replace={true}
                        />
                      )}

                      {selectedStatus !== "approve_with_adjustment" && (
                        <div className="d-flex justify-content-center mt-3">
                          {loading ? (
                            <LoadingButton />
                          ) : (
                            <Button
                              variant="primary"
                              type="submit"
                              className=" d-flex justify-content-center mt-3"
                              disabled={!!loading || !isDirty}
                            >
                              SUBMIT
                            </Button>
                          )}
                        </div>
                      )}
                    </Form>
                  </Card.Body>
                </Card>
              </>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default KitRequestStatusUpdate;
