import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { DriverForm } from "../../Components/Drivers/DriverForm";

const EditDriver = () => {
  const { get } = useAxios();
  let { driverId } = useParams();
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(true);
  const [editDriver, setEditDriver] = useState({});
  const [branchLocations, setBranchLocations] = useState({});
  
  useEffect(() => {
    setFetching(true);
    const getDetailedDriver = async () => {
      try {
        const response = await get(`user/drivers/${driverId}`);
        setEditDriver(response.data.driver);
        setBranchLocations(response.data.branches);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedDriver();
  }, []);



  return (
    <>
      <Card className="container">
        {!!fetching && <Loading />}

        {!fetching && !!editDriver?.id && (
          <>
            <Card.Header className="d-flex justify-content-between mt-0 mb-5">
              <Card.Title>
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="page-title">Edit Driver Information</h1>
                  </div>
                </div>
              </Card.Title>

              <div className="row">
                <div className="col-md-12">
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </div>
              </div>
            </Card.Header>

            <Card.Body>
              <DriverForm editDriver={editDriver} branchLocations={branchLocations} />
            </Card.Body>
          </>
        )}
      </Card>
    </>
  );
};

export default EditDriver;
