import React, { useState } from "react";
import useAxios from "../useAxios";

export const useGetEscortData = () => {
  const { get } = useAxios();
  const [clients, setClients] = useState({});
  const [fetching, setFetching] = useState(true);
  const [escortTypes, setEscortTypes] = useState({});
  const [officeLocations, setOfficeLocations] = useState({});
  const [failedFetching, setFailedFetching] = useState(true);

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await get(`escorts/create`);
      setClients(response.data.clients);
      setEscortTypes(response.data.escort_type);
      setOfficeLocations(response.data.offices);
    } catch (error) {
      setFailedFetching(true);
      alert("You are not authorised!");
    } finally {
      setFetching(false);
    }
  };

  return {
    clients,
    fetching,
    fetchData,
    escortTypes,
    officeLocations,
    failedFetching,
  };
};
