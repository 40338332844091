import React, { useEffect, useState } from "react"
import useAxios from "../../hooks/useAxios";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { SalaryComplaintForm } from "../../Components/SalaryComplaints/SalaryComplaintForm";

const EditSalaryComplaint = () => {
    const { get } = useAxios();
    const navigate = useNavigate();
    const [banks, setBanks] = useState({});
    let { salaryComplaintId } = useParams();
    const [fetching, setFetching] = useState(true);
    const [rootCauses, seRootCauses] = useState({});
    const [complaintCategories, setComplaintCategories] = useState({});
    const [editSalaryComplaint, setEditSalarayComplaint] = useState({});

    useEffect(() => {
    setFetching(true);
    const getCreateDetails = async () => {
      try {
        const response = await get(`salary-complaints/edit/${salaryComplaintId}`);
        seRootCauses(response.data.root_causes)
        setBanks(response.data.banks)
        setEditSalarayComplaint(response.data.salaryComplaint)
        setComplaintCategories(response.data.complaint_categories)
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getCreateDetails();
  }, []);

    return (
        <Card className="container">
        {!!fetching ? (
          <Loading />
        ) : (
          <>
            <Card.Header className="d-flex justify-content-between mt-0 mb-4">
              <Card.Title>
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="page-title">Salary Complaint</h1>
                  </div>
                </div>
              </Card.Title>

              <div className="row">
                <div className="col-md-12">
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </div>
              </div>
            </Card.Header>

            <Card.Body>
              <SalaryComplaintForm
                bankNames={banks}
                rootCauses={rootCauses}
                complaintCategories={complaintCategories}
                editSalaryComplaint={editSalaryComplaint}
              />
            </Card.Body>
          </>
        )}
      </Card>
    )
}

export default EditSalaryComplaint