import React from 'react'

export const PaginationNumber = ({
    total,
    per_page,
    current_page,
}) => {

    const fromValue = per_page * (current_page - 1) + 1
    const calculatedToValue = per_page * current_page
    let toValue = calculatedToValue > total ? total : calculatedToValue


    return (
        <>{fromValue} - {toValue} of {total} Records</>
    )
}