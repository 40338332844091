let SERVER_URL;

if (/^(localhost)/.test(window.location.host)) {
  // SERVER_URL = "https://unified-v2.ascoapps.com.ng";
  SERVER_URL = "http://unified-v2-test.ascoapps.com.ng";
} else if (/^(test)/.test(window.location.host)) {
  SERVER_URL = "https://unified-v2-test.ascoapps.com.ng";
} else {
  SERVER_URL = "https://unified-v2.ascoapps.com.ng";
}

const API_URL = `${SERVER_URL}/api`;
export { SERVER_URL, API_URL };
