import React, { useState } from "react";
import useAxios from "./useAxios";

export const useGetIncidentCreateData = () => {
  const { get } = useAxios();
  const [clients, setClients] = useState(true);
  const [fetching, setFetching] = useState(true);

  const fetchIncidentClientsData = async () => {
    setFetching(true);
    try {
      const response = await get(`incidents/create`);
      setClients(response.data.clients);
    } catch (error) {
      setFetching(false);
      alert("You are not authorised!");
    } finally {
      setFetching(false);
    }
  };

  return {
    clients,
    fetching,
    fetchIncidentClientsData,
  };
};
