import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { ClientEditForm } from "../../Components/Clients/ClientEditForm";

const EditClient = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { clientId } = useParams();
  const [client, setClient] = useState({});
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    setFetching(true);
    const getDetailedClient = async () => {
      try {
        const response = await get(`clients/${clientId}`);
        setClient(response.data);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedClient();
  }, []);

  return (
    <>
      <Card className="container">
        <Card.Header className="d-flex justify-content-between mt-0 mb-5">
          <Card.Title>
            <div className="row">
              <div className="col-md-12">
                <h1 className="page-title">Edit Client</h1>
              </div>
            </div>
          </Card.Title>

          <div className="row">
            <div className="col-md-12">
              <Button onClick={() => navigate(-1)}>Back</Button>
            </div>
          </div>
        </Card.Header>

        <Card.Body>
          {!!fetching && <Loading />}

          {!fetching && !!client?.id && <ClientEditForm editClient={client} />}
        </Card.Body>
      </Card>
    </>
  );
};

export default EditClient;
