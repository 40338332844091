import React, { useState } from "react";
import * as yup from "yup";
import {
  Card,
  Form,
  Badge,
  Button,
  Container,
  InputGroup,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import useAxios from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "../LoadingButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import maleAvatar from "../../images/male_avatar.png";
import femaleAvatar from "../../images/female_avatar.jpg";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object().shape({
  password: yup.string().min(5).max(15).required("Enter your new password"),
});

const SetPasswordForm = ({ staff, staffId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      password: "",
    },
  });

  const { post } = useAxios();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await post(`human_resources/staffs/${staffId}/password`, data);
      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Password successfully change!", null, 5000);
      navigate(-1);
    } catch (error) {
      setLoading(false);
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
    }
  };

  return (
    <>
      <Container className="container-tight py-4 ">
        {/* {isSaved && <Navigate to={"/"} replace={true} />} */}
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Card className="w-100 mt-5 mb-5 border-radius-rounded">
            <Card.Header>
              <div className="d-flex py-1 align-items-center mt-2 mb-2">
                {staff.gender === "female" ? (
                  <span className="avatar avatar-lg me-2 border">
                    <img
                      src={femaleAvatar}
                      width="80"
                      height="78"
                      alt="Female avatar"
                    />
                  </span>
                ) : (
                  <span className="avatar avatar-lg me-2 border">
                    <img
                      src={maleAvatar}
                      width="80"
                      height="78"
                      alt="Male avatar"
                    />
                  </span>
                )}
                <div className="flex-fill">
                  <div className="text-uppercase font-weight-medium">
                    {`${staff?.first_name} ${staff?.surname}` || "N/A"}
                  </div>
                  <div className="text-muted text-uppercase">
                    {staff?.actual_employee_code || "(NO EMPLOYEE CODE FOUND)"}
                  </div>
                  <div className="text-muted text-uppercase">
                    {staff?.designation.name}
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Form.Group className="mb-3 p-2" controlId="formPassword">
                <Form.Label className="required mb-1">New Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    autoComplete="off"
                    placeholder="Password"
                    {...register("password")}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <Button
                      variant="outline-secondary"
                      id="button-addon1"
                      className="border-0"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon m-0 "
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="12" r="2" />
                        <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                      </svg>
                    </Button>
                  </InputGroup.Text>
                </InputGroup>

                {!!errors.password && (
                  <p className="justify-content-end mt-1">
                    <Badge bg="danger" className="fs-6 fw-normal">
                      {errors.password?.message}
                    </Badge>
                  </p>
                )}
              </Form.Group>

              <div className=" d-flex justify-content-center mt-4 mb-3  ">
                {loading ? (
                  <LoadingButton />
                ) : (
                  <Button
                    data-testid="bs-btn "
                    variant="success"
                    type="submit"
                    className=""
                    disabled={!!loading || !isDirty}
                  >
                    Submit New Password
                  </Button>
                )}
              </div>
            </Card.Body>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default SetPasswordForm;
