import React, { useState } from "react";
import * as yup from "yup";
import Select from "react-select";
import useAxios from "../../hooks/useAxios";
import { Navigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { LoadingButton } from "../LoadingButtons";
import TinyMCETextEditor from "../TinyMCETextEditor";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Col, Row, Button, Form, Badge, Alert} from "react-bootstrap";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object({
    root_cause_category: yup.string(),
    root_cause_comment: yup.string().max(5000),
    payment_status: yup.string().required("You have not updated payment status!"),
  });

export const PaymentStatusForm = ({ rootCauses, statusOptions, salaryComplaint}) => {
    const {
        watch,
        control,
        register,
        setValue,
        handleSubmit,
        formState: { errors, isDirty },
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(schema),
        defaultValues: {
          mode: "onBlur",
          payment_status: "",
          root_cause_category: "",
          root_cause_comment: "",
        },
      });

    const { post } = useAxios();
    let { salaryComplaintId } = useParams();
    const [isSaved, setIsSaved] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasServerError, setHasServerError] = useState(false);

    const selectedStatus = watch("payment_status");

    const rootCauseOptions = Object.keys(rootCauses).map((key) => ({
      value: key,
      label: rootCauses[key],
    }));


    const onSubmit = async (data) => {
        try {
          setLoading(true);
          const endpointPath = `salary-complaints/${salaryComplaintId}/update-status`;
          let postedData = {};
          
          if (data?.payment_status === "paid") {
            postedData = {
              payment_status: data.payment_status,
              root_cause_comment: data?.root_cause_comment || "",
            }
          } else {
            postedData = data
          }

          await post(endpointPath, postedData);
    
          setIsSaved(true);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    
          alertSuccess(
            "Payment status successfully updated!",
            null,
            5000
          );
        }   catch (error) {
          setLoading(false);
          if (error.response.status === 422) {
            const handleServerErrors = (errors, setHasServerError) => {
              if (errors) {
                const serverValidation = error.response.data.error;
                return Object.keys(serverValidation).map((key) => {
                  setHasServerError(key, {
                    shouldFocus: true,
                    type: "manual",
                    message: serverValidation[key][0],
                  });
                });
              }
            };
            handleServerErrors(error, setHasServerError);
            return;
          }
          if (error.response.status === 500) {
            alertWarning(
              "Something went wrong with the server. Please Try Again!",
              null,
              5000
            );
          }
        }
      };
    

    return (
        <>
          {isSaved && (
            <Navigate
              to={`/salary-complaints/view/${salaryComplaintId}`}
              replace={true}
            />
          )}
    
          <Form onSubmit={handleSubmit(onSubmit)}>
            {hasServerError && (
              <Alert variant="danger">
                <p>
                  Something went wrong will processing your request. Please try
                  again!
                </p>
              </Alert>
            )}
            <Form.Group as={Row} className="mb-3" controlId="requestStatus">
              <Form.Label column sm="2" className="required">
                STATUS
              </Form.Label>
              <Col sm="10">
                <Form.Select
                  className="form-control"
                  {...register("payment_status")}
                  onChange={(event) => {
                    setValue("payment_status", event.target.value, {
                      shouldValidate: true,
                    });
                  }}
                >
                  <option value="" disabled>
                    Select Payment Status
                  </option>
                  {Object.keys(statusOptions).map((status) => (
                    <option key={status} value={status}>
                      {statusOptions[status]}
                    </option>
                  ))}
                </Form.Select>
                {!!errors.payment_status && (
                  <p>
                    <Badge className="mt-1" bg="danger">
                      {errors.status?.message}
                    </Badge>
                  </p>
                )}
              </Col>
            </Form.Group>

            {selectedStatus !== "paid"  && (
              <Form.Group as={Row} className="mb-3" controlId="rootCauseCategory">
                <Form.Label column sm="2" className="required">REASON</Form.Label>
                <Col sm="10">
                  <Controller
                    name="root_cause_category"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        name={field.name}
                        ref={field.ref}
                        className="text-dark"
                        options={rootCauseOptions}
                        onChange={(rootCauseOption) => {
                            setValue("root_cause_category", rootCauseOption.value, {
                                shouldValidate: true,
                            });
                        }}
                        // defaultValue={
                        //   !!salaryComplaint?.id
                        //     ? {
                        //       value: salaryComplaint?.root_cause_category,
                        //       label: salaryComplaint?.root_cause_category,
                        //     }
                        //     : null
                        // }
                        placeholder={"Select one"}
                      />
                    )}
                  />

                  {!!errors.root_cause_category && (
                    <p>
                      <Badge bg="danger">{errors.root_cause_category?.message}</Badge>
                    </p>
                  )}
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row} className="mb-3" controlId="rootCauseComment">
              <Form.Label column sm="2" className={selectedStatus !== "paid" ? "required" : ""}>COMMENT</Form.Label>
              <Col sm="10">
                <Controller
                  control={control}
                  name="root_cause_comment"
                  render={({ field }) => (
                    <TinyMCETextEditor field={field} setValue={setValue} />
                  )}
                />

                {!!errors.root_cause_comment && (
                  <p>
                    <Badge bg="danger">{errors.root_cause_comment?.message}</Badge>
                  </p>
                )}
              </Col>
            </Form.Group>
    
            <div className="d-flex justify-content-center mt-3">
              {loading ? (
                <LoadingButton />
              ) : (
                <Button
                  variant="primary"
                  type="submit"
                  className=" d-flex justify-content-center mt-3"
                  disabled={!!loading || !isDirty}
                >
                  SUBMIT
                </Button>
              )}
            </div>
          </Form>
        </>
      );
}
