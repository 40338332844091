import React from "react";
import { RequireAuth } from "../auth";
import { Loading } from "../Components/Loading";
import { Route, Routes } from "react-router-dom";
import { Error404 } from "../Pages/Errors/Error404";
import VehicleStatusReportIndex from "../Pages/VehicleStatusReport/index";
import ViewVehicleStatusReport from "../Pages/VehicleStatusReport/view";
import CreateVehicleStatusReport from "../Pages/VehicleStatusReport/create";
import EditVehicleStatusReport from "../Pages/VehicleStatusReport/edit";


export const VehicleStatusReport = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            vehicleParts
            element={
              <React.Suspense fallback={<Loading />}>
                <VehicleStatusReportIndex />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="/create"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateVehicleStatusReport />
              </React.Suspense>
            }
          />

          <Route
            path="view/:vehicleStatusReportId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewVehicleStatusReport />
              </React.Suspense>
            }
          />

          <Route
            path="edit/:vehicleStatusReportId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditVehicleStatusReport />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
};
