import React, { useState, useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Nav, Row, Button, Container } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { RequestNeedsForm } from "../../Components/EscortSchedule/RequestNeedsForm";
import { NeedRequestSummary } from "../../Components/EscortSchedule/NeedRequestSummary";

const RequestNeeds = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { scheduleId } = useParams();
  const [fetching, setFetching] = useState(true);
  const [escortSchedule, setEscortSchedule] = useState({});

  useEffect(() => {
    setFetching(true);
    const getDetailedEscortSchedule = async () => {
      try {
        const response = await get(`escorts/${scheduleId}/modify`);

        setEscortSchedule(response.data.escortSchedule);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedEscortSchedule();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <Container fluid>
          {!!fetching && <Loading />}

          {!!escortSchedule?.id && !fetching && (
            <>
              <div className="page-header d-print-none">
                <Row className="align-items-center">
                  <Col>
                    <div className="page-pretitle">ESCORT NEEDS </div>
                  </Col>
                </Row>
              </div>

              <div className="page-body">
                <Nav className="justify-content-end">
                  <Nav.Item>
                    <Button onClick={() => navigate(-1)}>Back</Button>
                  </Nav.Item>
                </Nav>

                <br />

                <>
                  <NeedRequestSummary escortSchedule={escortSchedule} />

                  <br />

                  <RequestNeedsForm scheduleId={scheduleId}/>
                </>
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default RequestNeeds;
