import React from 'react'
import { format } from 'date-fns';
import { statusBadge } from './statusBadge';
import { Card, Col, Row, Container } from 'react-bootstrap'
import { BsPersonCircle } from "react-icons/bs";
import { HtmlContent } from '../HtmlContent';

const AuditTrail = ({complaintData, salaryComplaint}) => {
  
  return (
    <Container fluid>
      <Card>
        <Card.Body>
          <Card.Title><h1>History</h1></Card.Title> 

          {complaintData.map((updateData) => (
            <div key={updateData?.id} className='border m-4'>
              <Row  className="p-4 align-content-center">
                <Col className="col-auto mt-3"><BsPersonCircle size={25} /></Col>
                  
                <Col className="col-auto my-2 me-5">
                {!updateData?.attended_by_employee_code 
                  ? <>
                      <div className='mb-2'>Process Owner: {salaryComplaint?.process_owner?.account_name}</div>
                      <div> {salaryComplaint?.process_owner?.actual_employee_code}</div>
                    </>
                  : <>
                      <div className='mb-2'>Attended By: {salaryComplaint?.attended_by?.account_name}</div>
                      <div> {salaryComplaint?.attended_by?.actual_employee_code}</div>
                    </>
                }
                </Col>

                <Col className="col-auto">
                  <div className='mb-2'>{statusBadge(updateData.payment_status)}</div>
                  <div>
                    {format(
                      new Date(updateData?.updated_at),
                      "EEE, MMM d, yyyy 'at' HH:mm:ss",
                      {
                        awareOfUnicodeTokens: true,
                      }
                    )}
                  </div>
                </Col>
              </Row>

              <Row className="px-4 align-content-center">
                {!!updateData?.root_cause_category && (
                  <Col md={6} className="mb-2">Root Cause: {updateData.root_cause_category}</Col>
                )} 

                {!!updateData?.root_cause_comment && (
                  <Col md={6}>
                    Root Cause Comment:{" "}
                    <span className="text-capitalize" style={{display: "inline-block"}}>
                      <HtmlContent >
                        {updateData.root_cause_comment}
                      </HtmlContent>
                    </span>
                  </Col>
                )} 
              </Row>
            </div>

          ))}
        </Card.Body>
      </Card>

    </Container>
  )
}

export default AuditTrail