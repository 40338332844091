import React, { useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Row,
} from "react-bootstrap";
import * as yup from "yup";
import useAxios from "../../hooks/useAxios";
import { LoadingButton } from "../LoadingButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object({
  name: yup.string().required("Client name is required"),
  mobile: yup
    .string()
    .max(15, "your number is invalid")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Client telephone is required"
    )
    .required("Client telephone is required"),
  email: yup
    .string()
    .email("Invalid email")
    .max(255, "Your email too long")
    .required("Client email is required"),
  // operative_count: yup.string().required("Number of Operatives required"),
  locations: yup.array().of(
    yup.object({
      location_name: yup.string().required("Location name is required"),
      guards_num: yup
        .string()
        .required("Expected number of operatives is required"),
    })
  ),
});

export const ClientCreationForm = ({ editClient }) => {
  const {
    control,
    register,
    setError,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      name: "",
      mobile: "",
      email: "",
      // operative_count: editClient?.operatives || "",

      locations: [
        {
          location_name: "",
          guards_num: "",
        },
      ],
    },
  });

  const { post } = useAxios();
  const navigate = useNavigate();
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "locations",
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const clientdata = {
        name: data.name,
        mobile: data.mobile,
        email: data.email,
        locations: data.locations,
      };

      // const endpointPath = !editClient?.id
      //   ? "clients/store"
      //   : `clients/${editClient.id}/update`;

      await post("clients/store", clientdata);

      setIsSaved(true);
      alertSuccess("Client successfully saved!", null, 5000);
      navigate("/clients");
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 422) {
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            setLoading(false);
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error?.response?.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to create client!",
          null,
          5000
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isSaved && <Navigate to={"/clients/"} replace={true} />}
      <Form onSubmit={handleSubmit(onSubmit)}>
        {hasServerError && (
          <Alert variant="danger">
            <p>
              Something went wrong will processing your request. Please try
              again!
            </p>
          </Alert>
        )}
        <Card className="creation-authorization">
          <Card.Body>
            <div className="client-name mb-4">
              <Form.Group controlId="clientName">
                <Form.Label className="required">NAME</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Client Name"
                  {...register("name")}
                />

                {!!errors.name && (
                  <p>
                    <Badge bg="danger">{errors.name?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <div className="client-email mb-4">
              <Form.Group controlId="clientEmail">
                <Form.Label className="required">EMAIL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Client Email"
                  {...register("email")}
                />

                {!!errors.email && (
                  <p>
                    <Badge bg="danger">{errors.email?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <div className="client-mobile mb-4">
              <Form.Group controlId="clientMobile">
                <Form.Label className="required">TELEPHONE</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Client Telephone Number"
                  {...register("mobile")}
                />

                {!!errors.mobile && (
                  <p>
                    <Badge bg="danger">{errors.mobile?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            {/* <div className="operatives-count">
              <Form.Label className="required">NUMBER OF OPERATIVES</Form.Label>
              <Form.Control
                type="number"
                min={1}
                placeholder="Select number of operatives "
                {...register("operative_count")}
              />

              {!!errors.operative_count && (
                <p>
                  <Badge bg="danger">{errors.operative_count?.message}</Badge>
                </p>
              )}
            </div> */}

            <div className="locationDetail mt-4 mb-4 border">
              <Form.Label className="mt-2 p-2">LOCATION DETAILS</Form.Label>
              {fields.map((field, index) => (
                <Row
                  key={field.id}
                  className="d-flex justify-content-between ms-0 me-0 mb-1 p-2 "
                >
                  <Col className="location-name mb-4" md={5}>
                    <FormGroup controlId="location">
                      <Form.Label>NAME</Form.Label>
                      <Form.Control
                        type="text"
                        key={index}
                        placeholder="Enter location name"
                        {...register(`locations.${index}.location_name`, {
                          onChange: (event) => {
                            setValue(
                              `locations.${index}.location_name`,
                              event.target.value,
                              {
                                shouldValidate: true,
                              }
                            );
                          },
                        })}
                      />

                      {!!errors.location_name && (
                        <p>
                          <Badge bg="danger">
                            {errors.locations?.[index].location_name?.message}
                          </Badge>
                        </p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col className="location-name mb-3" md={5}>
                    <FormGroup controlId="location">
                      <Form.Label className="required">
                        EXPECTED OPERATIVES NUMBER
                      </Form.Label>
                      <Form.Control
                        type="number"
                        min={1}
                        placeholder="Select number of operatives "
                        {...register(`locations.${index}.guards_num`, {
                          onChange: (event) => {
                            setValue(
                              `locations.${index}.guards_num`,
                              event.target.value,
                              {
                                shouldValidate: true,
                              }
                            );
                          },
                        })}
                      />

                      {!!errors.guards_num && (
                        <p>
                          <Badge bg="danger">
                            {errors.guards_num?.message}
                          </Badge>
                        </p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col className="align-self-center" md={2}>
                    <Button variant="danger" onClick={() => remove(index)}>
                      Remove
                    </Button>
                  </Col>

                  <hr className="mt-4" />
                </Row>
              ))}
            </div>

            <Row className=" more-location-button mb-4">
              <Col>
                <Button
                  className="me-3"
                  type="button"
                  onClick={() => append({})}
                >
                  Add More Location
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card.Footer className=" d-flex justify-content-center  ">
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              variant="success"
              type="submit"
              className=" d-flex justify-content-center mt-3"
              disabled={!!loading || !isDirty}
            >
              Submit
            </Button>
          )}
        </Card.Footer>
      </Form>
    </>
  );
};
