import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { FaExclamationCircle } from "react-icons/fa";
import { permissions } from "../../utils/permissions";
import { Card, Col, Nav, Row } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import ReactPagination from "../../Components/ReactPagination";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { EmployeeFilterSearch } from "../../Components/Staffs/FilterSearch";
import { DisplayedStaffsTable } from "../../Components/Staffs/DisplayedStaffsTable";

const StaffsIndex = () => {
  const { get } = useAxios();
  const [staffs, setStaffs] = useState({});
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [searchPageParams, setSearchPageParams] = useSearchParams();
  const [isFilterSearchClicked, setIsFilterSearchClicked] = useState(true);

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;

  let clientParams = searchPageParams.get("client") || "";
  let branchParams = searchPageParams.get("branch") || "";
  let searchKeyParams = searchPageParams.get("search_key") || "";
  let departmentParams = searchPageParams.get("department") || "";
  let clientLocationParams = searchPageParams.get("client_location") || "";

  const getStaffs = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;
      const response = await get(
        `human_resources/staffs?sort=desc&page=${currentPage}&page_size=${pageSize}&client=${clientParams}&branch=${branchParams}&search_key=${searchKeyParams}&department=${departmentParams}&client_location=${clientLocationParams}`
      );
      setStaffs(response.data.userData);
    } catch (error) {
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  const pageChangeHandler = (page) => {
    setSearchPageParams(
      {
        ...Object.fromEntries(searchPageParams),
        page,
      },
      { replace: true }
    );
  };

  useEffect(() => {
    setFetching(true);
    const initialiaseStaffs = async () => await getStaffs();
    initialiaseStaffs();
  }, [
    currentPage,
    clientParams,
    branchParams,
    searchKeyParams,
    departmentParams,
    clientLocationParams,
  ]);

  const handleFilterSelectSearchChange = (values) => {
    setSearchPageParams(
      {
        ...values,
        page: 1,
      },
      { replace: true }
    );
  };

  return (
    <>
      {!!fetching ? (
        <Loading />
      ) : (
        <Card className="container d-flex justify-content-center ms-3">
          <Card.Header className="d-flex justify-content-between mt-2 mb-2">
            <Card.Title>
              <Row>
                <Col md="auto">
                  <h2 className="page-title">ALL STAFFS</h2>
                </Col>
              </Row>
            </Card.Title>

              <Row>
                {hasPermission(permissions.ADD_RECRUITE) && (
                  <Col md="auto">
                    <Link to="/staffs/create" className="btn btn-primary">
                      Add Staff
                    </Link>
                  </Col>
                )}
              </Row>
          </Card.Header>

          <Card.Body className="mt-4">
            <Row>
              <Nav className="fw-bolder fs-4 mb-3">
                <Nav.Item>
                  <Nav.Link
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setIsFilterSearchClicked(!isFilterSearchClicked);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="6" cy="10" r="2" />
                      <line x1="6" y1="4" x2="6" y2="8" />
                      <line x1="6" y1="12" x2="6" y2="20" />
                      <circle cx="12" cy="16" r="2" />
                      <line x1="12" y1="4" x2="12" y2="14" />
                      <line x1="12" y1="18" x2="12" y2="20" />
                      <circle cx="18" cy="7" r="2" />
                      <line x1="18" y1="4" x2="18" y2="5" />
                      <line x1="18" y1="9" x2="18" y2="20" />
                    </svg>
                    Filter
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Row>

            <Row className="mb-2">
              <Col>
                {isFilterSearchClicked && (
                  <EmployeeFilterSearch
                    getStaffs={getStaffs}
                    onhandleFilteredSearchChange={(value) =>
                      handleFilterSelectSearchChange(value)
                    }
                    initialSearchFilterInputs={{
                      client: clientParams,
                      branch: branchParams,
                      search_key: searchKeyParams,
                      department: departmentParams,
                      client_location: clientLocationParams,
                    }}
                  />
                )}
              </Col>
            </Row>

            {!!(
              clientParams ||
              branchParams ||
              searchKeyParams ||
              departmentParams ||
              clientLocationParams
            ) &&
              !staffs?.data?.length && (
                <div className="mt-4 p-2 border text-muted text-center">
                  <span>
                    <FaExclamationCircle size={19} />
                  </span>

                  <span className="fs-2 ms-2 ">No Staff Found!</span>
                </div>
              )}

            {(!!(
              clientParams ||
              branchParams ||
              searchKeyParams ||
              departmentParams ||
              clientLocationParams
            ) &&
              !staffs?.data?.length) ||
              (!!staffs?.data?.length && (
                <>
                  <p className="text-end text-muted">
                    Page {staffs.current_page} of {staffs.last_page}
                  </p>

                  <Row>
                    <Col className="p-0">
                      <DisplayedStaffsTable 
                        staffs={staffs.data} 
                        getStaffs={getStaffs}
                        />

                      <br />

                      <ReactPagination
                        links={staffs.links}
                        onPageChange={pageChangeHandler}
                        current_page={staffs.current_page}
                        last_page={staffs.last_page}
                      />
                    </Col>
                  </Row>
                </>
              ))}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default StaffsIndex;
