import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { FaExclamationCircle } from "react-icons/fa";
import { Card, Col, Row} from "react-bootstrap";
import { permissions } from "../../utils/permissions";
import ReactPagination from "../../Components/ReactPagination";
import React, { Link, useSearchParams } from "react-router-dom";
import { FilterSearch } from "../../Components/EscortSchedule/FilterSearch";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { DisplayedSchedules } from "../../Components/EscortSchedule/DisplayedSchedules";
import { ShowMore } from "../../hooks/showMore";

const EscortScheduleIndex = () => {
  const { get } = useAxios();
  const [fetching, setFetching] = useState(true);
  const [escortStatuses, setEscortStatuses] = useState({});
  const [escortSchedules, setEscortSchedules] = useState({});
  const [isFilterClicked, setIsFilterClicked] = useState(true);
  const { hasPermission, isSuperAdmin } = usePermissionAndDomain();
  const [searchPageParams, setSearchPageParams] = useSearchParams({});

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;
  let typeParams = searchPageParams.get("type") || "";
  let clientParams = searchPageParams.get("client") || "";
  let branchParams = searchPageParams.get("branch") || "";
  let statusParams = searchPageParams.get("status") || "";
  let toDateParams = searchPageParams.get("toDate") || "";
  let fromDateParams = searchPageParams.get("fromDate") || "";
  let officeLocationParams = searchPageParams.get("officeLocation") || "";

  const pageChangeHandler = (page) => {
    setSearchPageParams(
      {
        ...Object.fromEntries(searchPageParams),
        page,
      },
      { replace: true }
    );
  };

  const getEscortSchedules = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;
      const response =
        await get(`escorts?sort=desc&type=${typeParams}&status=${statusParams}&page=${currentPage}&page_size=${pageSize}&client=${clientParams}&branch=${branchParams}&toDate=${toDateParams}&fromDate=${fromDateParams}
        `);
      setEscortStatuses(response.data.escort_statuses);
      setEscortSchedules(response.data.escortSchedules);
    } catch (error) {
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    setFetching(true);
    const initialiaseEscortSchedules = async () => await getEscortSchedules();
    initialiaseEscortSchedules();
  }, [
    typeParams,
    statusParams,
    currentPage,
    clientParams,
    branchParams,
    toDateParams,
    fromDateParams,
  ]);

  const handleFilterSelectSearchChange = (values) => {
    setSearchPageParams(
      {
        ...values,
        page: 1,
      },
      { replace: true }
    );
  };

  const handleShowMore = () =>  setIsFilterClicked(!isFilterClicked)

  return (
    <>
      {!!fetching && <Loading />}

      <Card className="container d-flex justify-content-center ms-3">
        <Card.Header className="d-flex justify-content-end mt-2">
          <>
            <Row>
              {hasPermission(permissions.CREATE_AN_ESCORT_SCHEDULE) && (
                <Col className="mb-2" md="auto">
                  <Link
                    to="/escort-schedules/create"
                    className="btn btn-primary"
                  >
                    Create Schedule
                  </Link>
                </Col>
              )}

              {isSuperAdmin() && (
                <Col md="auto">
                  <Link
                    to="/escort-schedules/calendar_view"
                    className="btn btn-primary"
                  >
                    Calendar View
                  </Link>
                </Col>
              )}
            </Row>
          </>
        </Card.Header>

        <Card.Body>
          {!fetching && !!escortSchedules?.data?.length === 0 && (
            <div> No Escort Schedule found</div>
          )}

          {!!escortSchedules && !fetching && (
            <>
              <Row>
                <ShowMore className="d-flex justify-content-between mt-2 mb-4" title="Click to filter" heading="Filter" handleShowMore={handleShowMore}/>
              </Row>

              <Row>
                <Col className="p-0">
                  {isFilterClicked && (
                    <FilterSearch
                      onhandleFilteredSearchChange={(value) =>
                        handleFilterSelectSearchChange(value)
                      }
                      initialSearchFilterInputs={{
                        type: typeParams,
                        status: statusParams,
                        client: clientParams,
                        branch: branchParams,
                        toDate: toDateParams,
                        fromDate: fromDateParams,
                        officeLocation: officeLocationParams,
                      }}
                      escortSchedules={escortSchedules.data}
                      escortStatuses={escortStatuses}
                      // removeFilterInputs={(value) => removeSearchFilterInputs(value)}
                    />
                  )}

                  <br />

                  {!!(
                    typeParams ||
                    statusParams ||
                    clientParams ||
                    branchParams ||
                    toDateParams ||
                    fromDateParams ||
                    officeLocationParams
                  ) &&
                    !escortSchedules?.data?.length && (
                      <div className="mt-4 p-2 border text-muted text-center">
                        <span>
                          <FaExclamationCircle size={19} />
                        </span>

                        <span className="fs-2 ms-2 ">
                          No Escort Schedule found!
                        </span>
                      </div>
                    )}

                  <>
                    <DisplayedSchedules
                      statuses={escortStatuses}
                      escortSchedules={escortSchedules.data}
                      getEscortSchedules={getEscortSchedules}
                    />

                    <br />

                    <ReactPagination
                      links={escortSchedules.links}
                      onPageChange={pageChangeHandler}
                      current_page={escortSchedules.current_page}
                      last_page={escortSchedules.last_page}
                    />
                  </>
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default EscortScheduleIndex;
