import React, { useEffect, useState } from 'react'
import { format } from 'date-fns';
import useAxios from '../../hooks/useAxios';
import { Loading } from '../../Components/Loading';
import { permissions } from '../../utils/permissions';
import { HtmlContent } from '../../Components/HtmlContent';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { Button, Card, Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import AuditTrail from '../../Components/SalaryComplaints/AuditTrail';
import { statusBadge } from '../../Components/SalaryComplaints/statusBadge';

const ViewSalaryComplaint = () => {
    const { get } = useAxios();
    const navigate = useNavigate();
    let { salaryComplaintId } = useParams();
    const [fetching, setFetching] = useState(true);
    const { hasPermission } = usePermissionAndDomain();
    const [salaryComplaint, setSalaryComplaint] = useState({});

    useEffect(() => {
      setFetching(true);
      const getDetailedCoomplaint = async () => {
      try {
        const response = await get(`salary-complaints/show/${salaryComplaintId}`);

        setSalaryComplaint(response.data);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
      };
      getDetailedCoomplaint();
    }, []);

  return (
    <Card>
        <Card.Header className="d-flex justify-content-between mt-2 mb-5">
          <Card.Title>
            <Row>
              <Col>
                <h1>Salary Complaint Details</h1>
              </Col>
            </Row>
          </Card.Title> 
          

          <Row className="ms-1">
            <Col className="mb-2">
              <Button onClick={() => navigate(-1)}>Back</Button>
            </Col>

            {salaryComplaint?.payment_status !== "paid"  && (
              <Col className="me-2">
                <DropdownButton
                    id="dropdown-basic-button"
                    title="ACTION"
                    className="justify-content-center"
                  >    
                    {hasPermission(permissions.EDIT_SALARY_COMPLAINT) && (
                      <Dropdown.Item
                        as={Link}
                        size="sm"
                        className="justify-content-center"
                        to={`/salary-complaints/edit/${salaryComplaintId}`}
                      >
                        Edit
                      </Dropdown.Item>
                    )}

                    {hasPermission(permissions.PROCESS_SALARY_COMPLAINT) && (
                      <Dropdown.Item
                        as={Link}
                        size="sm"
                        className="justify-content-center"
                        to={`/salary-complaints/update-status/${salaryComplaintId}`}
                      >
                        Payment Status
                      </Dropdown.Item>
                    )}
                  </DropdownButton>
              </Col>
            )}

          </Row>
        </Card.Header>

        <Card.Body>
          {!!fetching && <Loading />}

          {!!salaryComplaint?.id && !fetching && (
            <div className="page-wrapper">
              <>
                <Row className="d-flex mb-5">
                  <Col md={3}>
                    <h3 className="pe-5">Staff Concerned</h3>
                  </Col>

                  <Col md={9}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col>
                            <strong>Name</strong>
                            <p className="border-bottom py-2">
                              {`${salaryComplaint?.employee_concerned?.first_name} ${salaryComplaint?.employee_concerned?.middle_name} ${salaryComplaint?.employee_concerned?.surname}` || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Employee Code</strong>
                            <p className="border-bottom py-2">
                              {salaryComplaint?.employee_concerned?.employee_code || "N/A"}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <strong>Bank</strong>
                            <p className="border-bottom py-2">
                              {salaryComplaint?.employee_concerned?.bank_name || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Account Number</strong>
                            <p className="border-bottom py-2">
                              {salaryComplaint?.employee_concerned?.account_number || "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row className="d-flex mb-5">
                  <Col md={3}>
                    <h3 className="pe-5">Complaint Details</h3>
                  </Col>

                  <Col md={9}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col>
                            <strong>Date</strong>
                            <p className="border-bottom py-2">
                            {format(
                                new Date(salaryComplaint?.complaint_date),
                                "d MMM, yyyy",
                                {
                                  awareOfUnicodeTokens: true,
                                }
                              )}
                            </p>
                          </Col>

                          <Col>
                            <strong>Branch</strong>
                            <p className="border-bottom py-2">
                              {salaryComplaint?.employee_concerned?.office_location?.name || "N/A"}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <strong>Payment Status</strong>
                            <p className="border-bottom py-2">
                              {statusBadge(salaryComplaint.payment_status) || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Number of Days Worked</strong>
                            <p className="border-bottom py-2">
                              {salaryComplaint?.number_of_days_worked || "N/A"}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <strong>Complaint</strong>
                            <p className="border-bottom py-2">
                              {salaryComplaint?.complaint_category || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Complaint Comment</strong>
                            <HtmlContent className="border-bottom py-2">
                              {salaryComplaint?.complaint_comment || "N/A"} 
                            </HtmlContent>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <strong>Root Cause</strong>
                            <p className="border-bottom py-2">
                              {salaryComplaint?.root_cause_category || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Root Cause Comment</strong>
                            <HtmlContent className="border-bottom py-2">
                              {salaryComplaint?.root_cause_comment || "N/A"} 
                            </HtmlContent>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                {!!salaryComplaint?.payroll_comment && (
                  <Row className="d-flex mb-5">
                    <Col md={3}>
                      <h3 className="pe-5">Payroll Comment</h3>
                    </Col>

                    <Col md={9}>
                      <Card>
                        <Card.Body>
                          <Row>
                            <Col>
                              <strong>Date</strong>
                              <p className="border-bottom py-2">
                              <HtmlContent className="border-bottom py-2">
                                {salaryComplaint?.root_cause_comment || "N/A"} 
                              </HtmlContent>
                              </p>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                )} 


                <Row className="d-flex mb-5">
                  <Col md={3}>
                    <h3 className="pe-5">Client</h3>
                  </Col>

                  <Col md={9}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col>
                            <strong>Name</strong>
                            <p className="border-bottom py-2">
                              {salaryComplaint?.employee_concerned?.client_location?.client?.name || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Location</strong>
                            <p className="border-bottom py-2">
                              {salaryComplaint?.employee_concerned?.client_location?.name || "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row className="d-flex mb-5">
                  <Col md={3}>
                    <h3 className="pe-5">Process Owner</h3>
                  </Col>

                  <Col md={9}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col>
                            <strong>Name</strong>
                            <p className="border-bottom py-2 text-uppercase">
                              {`${salaryComplaint?.process_owner?.first_name} ${salaryComplaint?.process_owner?.surname}` || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Employee Code</strong>
                            <p className="border-bottom py-2">
                              {salaryComplaint?.process_owner?.employee_code || "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>

              {!!salaryComplaint?.update_records && (
                <AuditTrail 
                  salaryComplaint={salaryComplaint} 
                  complaintData={salaryComplaint.update_records} 
                  />
              )}

            
            </div>
          )}
        </Card.Body>
    </Card>
  )
}

export default ViewSalaryComplaint
