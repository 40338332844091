import React from "react";
import { RequireAuth } from "../../auth";
import { Route, Routes } from "react-router-dom";
import { Loading } from "../../Components/Loading";
import { Error404 } from "../../Pages/Errors/Error404";
import PermissionsDomainIndex from "../../Pages/RolesPermissions/Permissions/index";
import CreatePermissionsDomain from "../../Pages/RolesPermissions/Permissions/create";
import EditPermissionsDomain from "../../Pages/RolesPermissions/Permissions/edit";

export const PermissionsDomainsRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <PermissionsDomainIndex />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="/create"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreatePermissionsDomain />
              </React.Suspense>
            }
          />
          <Route
            path="edit/:permissionId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditPermissionsDomain />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
};
