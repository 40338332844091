import React, { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Navigate} from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { LoadingButton } from "../LoadingButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Card, Alert, Badge, Button } from "react-bootstrap";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object({
  name: yup.string().required("Client name is required"),
  mobile: yup
    .string()
    .max(15, "your number is invalid")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Client telephone is required"
    )
    .required("Client telephone is required"),
  email: yup
    .string()
    .email("Invalid email")
    .max(255, "Your email too long")
    .required("Client email is required"),
  operative_count: yup.string().required("Number of Operatives required"),
});

export const ClientEditForm = ({ editClient }) => {
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      name: editClient?.name || "",
      mobile: editClient?.mobile || "",
      email: editClient?.email || "",
      operative_count: editClient?.operatives || "",
    },
  });

  const { post } = useAxios();
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const clientdata = {
        name: data.name,
        mobile: data.mobile,
        email: data.email,
        operative_count: data.operative_count,
      };

      await post(`clients/${editClient.id}/update`, clientdata);
      setIsSaved(true);
      alertSuccess("Client successfully saved!", null, 5000);
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 422) {
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            setLoading(false);
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error?.response?.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to edit client!",
          null,
          5000
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isSaved && (
        <Navigate to={`/clients/view/${editClient?.id}`} replace={true} />
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        {hasServerError && (
          <Alert variant="danger">
            <p>
              Something went wrong will processing your request. Please try
              again!
            </p>
          </Alert>
        )}
        <Card className="creation-authorization">
          <Card.Body>
            <div className="client-name mb-4">
              <Form.Group controlId="clientName">
                <Form.Label className="required">NAME</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Client Name"
                  {...register("name")}
                />

                {!!errors.name && (
                  <p>
                    <Badge bg="danger">{errors.name?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <div className="client-email mb-4">
              <Form.Group controlId="clientEmail">
                <Form.Label className="required">EMAIL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Client Email"
                  {...register("email")}
                />

                {!!errors.email && (
                  <p>
                    <Badge bg="danger">{errors.email?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <div className="client-mobile mb-4">
              <Form.Group controlId="clientMobile">
                <Form.Label className="required">TELEPHONE</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Client Telephone Number"
                  {...register("mobile")}
                />

                {!!errors.mobile && (
                  <p>
                    <Badge bg="danger">{errors.mobile?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <div className="operatives-count">
              <Form.Label className="required">NUMBER OF OPERATIVES</Form.Label>
              <Form.Control
                type="number"
                min={1}
                placeholder="Select number of operatives "
                {...register("operative_count")}
              />

              {!!errors.operative_count && (
                <p>
                  <Badge bg="danger">{errors.operative_count?.message}</Badge>
                </p>
              )}
            </div>
          </Card.Body>
        </Card>

        <Card.Footer className=" d-flex justify-content-center  ">
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              variant="success"
              type="submit"
              className=" d-flex justify-content-center mt-3"
              disabled={!!loading || !isDirty}
            >
              Submit
            </Button>
          )}
        </Card.Footer>
      </Form>
    </>
  );
};
