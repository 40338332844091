import React from "react";
import { RequireAuth } from "../auth";
import { Loading } from "../Components/Loading";
import { Route, Routes } from "react-router-dom";
import { Error404 } from "../Pages/Errors/Error404";
import ClientsLocations from "../Pages/Clients/clientsLocations";
import ViewClient from "../Pages/Clients/view";
import EditClient from "../Pages/Clients/edit";
import ClientIndex from "../Pages/Clients/index";
import CreateClient from "../Pages/Clients/create";

export const ClientRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <ClientIndex />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="/create"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateClient />
              </React.Suspense>
            }
          />

          <Route
            path="view/:clientId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewClient />
              </React.Suspense>
            }
          />

          <Route
            path="edit/:clientId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditClient />
              </React.Suspense>
            }
          />

          <Route
            path="locations"
            // path="/client_locations"
            element={
              <React.Suspense fallback={<Loading />}>
                <ClientsLocations />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
};
