import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const TopClientsStatBarChart = ({ topClientsStats }) => {

  let labels = [];
  let myDataSet = [];
  const clients = topClientsStats.map((client) => client?.clients )
  const numOfTimes = topClientsStats.map((clientStat) => clientStat?.number_of_times)


  clients.map((client) => {
    labels.push(
      client?.name
    )

  });

  numOfTimes.map((data) => {
    myDataSet.push(`${Number(data)}`);
  });

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Top Incident Locations",
      },
    },
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Number of incidents",
        data: labels.map((myLabel, index) => myDataSet[index]),
        // myDataSet,
        fill: false,
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
        ],
        minBarLength: 2,
        maxBarThickness: 30,
      },
    ],
  };


  return <Bar data={chartData} options={options} />;
};

