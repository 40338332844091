import React from "react";
import { RequireAuth } from "../auth";
import { Loading } from "../Components/Loading";
import { Route, Routes } from "react-router-dom";
import { Error404 } from "../Pages/Errors/Error404";
import VehiclePartsIndex from "../Pages/Vehicles/Parts/index";
import ViewVehiclePart from "../Pages/Vehicles/Parts/view";
import EditVehiclePart from "../Pages/Vehicles/Parts/edit";
import CreateVehiclePart from "../Pages/Vehicles/Parts/create";



export const VehiclePartsRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            vehicleParts
            element={
              <React.Suspense fallback={<Loading />}>
                <VehiclePartsIndex />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="/create"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateVehiclePart />
              </React.Suspense>
            }
          />

          <Route
            path="view/:vehiclePartId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewVehiclePart />
              </React.Suspense>
            }
          />

          <Route
            path="edit/:vehiclePartId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditVehiclePart />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
};
