import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useNavigate} from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { NotificationCreationForm } from "../../Components/Notifications/NotificationCreationForm";

const CreateNotification = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(true);
  const [notificationClass, setNotificationClass] = useState([]);

  useEffect(() => {
    setFetching(true);
    const getCreationNeeds = async () => {
      try {
        const response = await get(`admin_notification/create`);
        setNotificationClass(response.data.classNames);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getCreationNeeds();
  }, []);

  return (
    <>
      {!!fetching && <Loading />}

      {!!notificationClass && !fetching && (
        <Card className="container">
          <Card.Header className="d-flex justify-content-between mt-0 mb-5">
            <Card.Title>
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">Create Notification</h1>
                </div>
              </div>
            </Card.Title>

            <div className="row">
              <div className="col-md-12">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <NotificationCreationForm notificationClass={notificationClass} />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default CreateNotification;
