import React, { useState } from "react";
import useAxios from "../useAxios";
import { useParams } from "react-router-dom";

export const useModifyEscortData = () => {
  const { get } = useAxios();
  let { scheduleId } = useParams();
  const [drivers, setDrivers] = useState({});
  const [officers, setOfficers] = useState({});
  const [vehicles, setVehicles] = useState({});
  const [fetching, setFetching] = useState(true);
  const [escortSchedule, setEscortSchedule] = useState({});

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await get(`escorts/${scheduleId}/modify`);
      setDrivers(response.data.drivers);
      setOfficers(response.data.officers);
      setVehicles(response.data.escort_type);
      setEscortSchedule(response.data.escortSchedule);
    } catch (error) {
      alert("You are not authorised!");
    } finally {
      setFetching(false);
    }
  };

  return {
    drivers,
    officers,
    vehicles,
    fetching,
    fetchData,
    setFetching,
    escortSchedule,
  };
};
