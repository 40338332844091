import React from 'react'

const prepareUserDomainAndPermissionsMap = (userDetails) => {
    const userDomainAndPermissionsMap = {
      domains: {},
      permissions: {},
    };
    const role = undefined === userDetails.roles[0] ? {} : userDetails.roles[0];
    if (role?.permissions?.length) {
      role.permissions.forEach((permission) => {
        userDomainAndPermissionsMap.permissions[permission.name] = true;
        userDomainAndPermissionsMap.domains[permission.domain] = true;
      });
    }
    return userDomainAndPermissionsMap;
  };

export default prepareUserDomainAndPermissionsMap