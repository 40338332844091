import React from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Row} from "react-bootstrap";

const GuarantorDetailsModal = ({ show, handleClose, guarantorDetails }) => {
  return (
    <Modal
      id="guarantorDetailsModal"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      role="dialog"
      aria-hidden="true"
      className="modal fade"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Guarantor's Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center py-4">
        <Row>
          <Col>
            <strong>Name</strong>
            <p className="border-bottom py-2">
              {`${guarantorDetails.first_name} ${guarantorDetails.surname}` ||
                "N/A"}
            </p>
          </Col>

          <Col>
            <strong>Relationship</strong>
            <p className="border-bottom py-2">
              {guarantorDetails.relationship || "N/A"}
            </p>
          </Col>
          <Col>
            <strong>Marital Status</strong>
            <p className="border-bottom py-2">
              {guarantorDetails.marital_status || "N/A"}
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <strong>Phone</strong>
            <p className="border-bottom py-2">
              {guarantorDetails.phone || "N/A"}
            </p>
          </Col>

          <Col>
            <strong>Email</strong>
            <p className="border-bottom py-2">
              {guarantorDetails.email || "N/A"}
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <strong>Occupation</strong>
            <p className="border-bottom py-2">
              {guarantorDetails.occupation || "N/A"}
            </p>
          </Col>

          <Col>
            <strong>Business Nature</strong>
            <p className="border-bottom py-2">
              {guarantorDetails.nature_of_business || "N/A"}
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <strong>Office Address</strong>
            <p className="border-bottom py-2">
              {guarantorDetails.business_address || "N/A"}
            </p>
          </Col>

          <Col>
            <strong>Home Address</strong>
            <p className="border-bottom py-2">
              {guarantorDetails.resident_address || "N/A"}
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <strong>Nationality</strong>
            <p className="border-bottom py-2">
              {guarantorDetails.nationality || "N/A"}
            </p>
          </Col>
          <Col>
            <strong>State of origin</strong>
            <p className="border-bottom py-2">
              {guarantorDetails.state_of_origin || "N/A"}
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <strong>Local govt. of origin</strong>
            <p className="border-bottom py-2">
              {guarantorDetails.local_govt_of_origin || "N/A"}
            </p>
          </Col>

          <Col>
            <strong>Home town address</strong>
            <p className="border-bottom py-2">
              {guarantorDetails.home_town_address || "N/A"}
            </p>
          </Col>
        </Row>

        
      </Modal.Body>
    </Modal>
  );
};

export default GuarantorDetailsModal;
