import React from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";

export const DisplayedNotificationsConfigTable = ({ notifications }) => {
  return (
    <>
      <Table
        hover
        bordered
        responsive
        className=" table-vcenter table-mobile-md card-table"
      >
        <thead>
          <tr>
            <th>TITLE</th>
            <th>NUMBER OF RECIPIENTS</th>
            <th>LAST SENT TO</th>
            <th>CONTROLS</th>
          </tr>
        </thead>

        <tbody>
          {notifications.map((notification) => (
            <tr key={notification.id}>
              <td data-label="TITLE">{notification.title}</td>
              <td data-label="NUMBER OF RECIPIENTS">
                {notification.admin_users_count}
              </td>
              <td data-label="LAST SENT TO">
                {!!notification?.last_sent?.id && (
                  <>
                    {notification?.last_sent?.user.first_name}{" "}
                    {notification?.last_sent?.user.surname} on{" "}
                    {format(
                      new Date(notification?.last_sent?.created_at),
                      "EEE, MMM d, yyyy 'at' HH:mm:ss",
                      {
                        awareOfUnicodeTokens: true,
                      }
                    )}
                  </>
                )}
              </td>

              <td className="d-flex justify-content-center ">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  <Dropdown.Item
                    as={Link}
                    size="sm"
                    className="justify-content-center"
                    to={`/notifications/view/${notification.id}`}
                  >
                    View
                  </Dropdown.Item>

                  <Dropdown.Item
                    as={Link}
                    size="sm"
                    className="justify-content-center"
                    to={`/notifications/edit/${notification.id}`}
                  >
                    Edit
                  </Dropdown.Item>

                  <Dropdown.Item
                    as={Link}
                    size="sm"
                    className="justify-content-center"
                    to={`/notifications/manage_recipients/${notification.id}`}
                  >
                    Manage Recipients
                  </Dropdown.Item>
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
