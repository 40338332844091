import React, { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { useRolesAndPermissionsData } from "../../../hooks/useRolesAndPermissionsData";
import { RolesAndPermissionsForm } from "../../../Components/RolesPermissions/Roles/RolesAndPermissionsForm";

const EditRolesPermissions = () => {
  const { get } = useAxios();
  let { roleId } = useParams();
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(true);
  const [permissions, setPermissions] = useState({});
  const [role, setRole] = useState({});

  useEffect(() => {
    setFetching(true);
    const getDetailedroleAndPermission = async () => {
      try {
        const response = await get(`roles/${roleId}/edit`);
        if (response.status === 202) {
          const permissionsGroup = {};
          Object.values(response.data.permissions).forEach((permission) => {
            if (undefined === permissionsGroup[permission.domain]) {
              permissionsGroup[permission.domain] = [];
            }
            permissionsGroup[permission.domain].push(permission);
          });
          setPermissions(permissionsGroup);
          setRole(response.data.roles);
        } else {
          alert("You are not authorised!");
        }
      } catch (error) {
      } finally {
        setFetching(false);
      }
    };
    getDetailedroleAndPermission();
  }, []);

  return (
    <>
      {!!fetching && <Loading />}

      {!fetching && !!role?.id && (
        <Card className="container">
          <Card.Header className="d-flex justify-content-between mt-0 mb-5">
            <Card.Title>
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">Edit Role</h1>
                </div>
              </div>
            </Card.Title>

            <div className="row">
              <div className="col-md-12">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <RolesAndPermissionsForm
              editRole={role}
              permissions={permissions}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default EditRolesPermissions;
