import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Button, Modal } from "react-bootstrap";

const DeleteModal = ( ) => {
  const { deleted } = useAxios();
  const [vendorId, setVendorId] = useState("");
  const [showModal, setShowModal] = useState(true);

  const [deletedVendor, setDeletedVendor] = useState(""); 

  const handleClose = () => {
    setShowModal(false)
  }
  const handleVendorDelete = async () => {
    try {
      const response = await deleted(`vendors/${vendorId}/destroy`);
      if (response.status === 200) {
        setDeletedVendor(response.data);
      } else {
        alert("You are not authorised!");
      }
    } catch (error) {
    }
  };
  

  useEffect(() => {
    document.addEventListener("show-delete-modal", (event) => {
      setShowModal(true);
      setVendorId(event.detail.id)
    });
    
  }, []);

  return (
    <>
      <Modal
        showModal={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        role="dialog"
        id="modal-danger"
        aria-hidden="true"
        className="modal modal-blur fade"
      >
        <Modal.Header closeButton>
        </Modal.Header>

        <div className="modal-status bg-danger"></div>

        <Modal.Body className="text-center py-4">
          <div className="modal-body text-center py-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon mb-2 text-danger icon-lg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 9v2m0 4v.01" />
              <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
            </svg>
            <h3>Are you sure?</h3>

            <div className="text-muted">
              Do you really want to delete this vendor.
              <br />
              This action cannot be undone.
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            type="submit"
            variant="danger"
            onClick={() => {
              handleVendorDelete();
              handleClose();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
