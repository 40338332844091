import React, { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../Components/Loading";
import { useGetEscortData } from "../../hooks/EscortsDataFetching/useGetEscortData";
import { EscortScheduleForm } from "../../Components/EscortSchedule/EscortScheduleForm";

const CreateEscortSchedule = () => {
  const navigate = useNavigate();

  const { fetching, clients, escortTypes, officeLocations, fetchData } =
    useGetEscortData();

  useEffect(() => {
    const getData = async () => {
      fetchData();
    };
    getData();
  }, []);

  return (
    <>
      {!!fetching ? (
        <Loading />
      ) : (
        <Card className="container">
          <Card.Header className="d-flex justify-content-between mt-0 mb-4">
            <Card.Title>
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">Create an Escort Schedules</h1>
                </div>
              </div>
            </Card.Title>

            <div className="row">
              <div className="col-md-12">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <EscortScheduleForm
              clients={clients}
              escortTypes={escortTypes}
              officeLocations={officeLocations}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default CreateEscortSchedule;
