import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  ListGroup,
  Row,
} from "react-bootstrap";
import { format } from "date-fns";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { permissions } from "../../utils/permissions";
import { HtmlContent } from "../../Components/HtmlContent";
import { Link, useNavigate, useParams } from "react-router-dom";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { VendorWorksTable } from "../../Components/Vendors/VendorWorksTable";
import { FaExclamationCircle } from "react-icons/fa";

const ViewVendor = () => {
  const { get } = useAxios();
  let { vendorId } = useParams();
  const navigate = useNavigate();
  const [vendor, setVendor] = useState({});
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [viewMore, setViewMore] = useState(false)
  const { attestationFile, setAttestationFile } = useState();
  const { maintenanceRecords, setMaintenanceRecords } = useState([]);

  useEffect(() => {
    setFetching(true);
    const getDetailedVendor = async () => {
      try {
        const response = await get(`vendors/${vendorId}`);

        setVendor(response.data);
        setMaintenanceRecords(response.data.maintenance_records);
        setAttestationFile(response.data.evaluationDoc);
      } catch (error) {
      } finally {
        setFetching(false);
      }
    };
    getDetailedVendor();
  }, []);

  return (
    <>
      {!!fetching && <Loading />}

      {!!vendor?.id && !fetching && (
        <>
          <Card>
            <Card.Header className="d-flex justify-content-between mt-2 mb-5">
              <Card.Title>
                <Row>
                  <Col>
                    <h1>Vendor Details</h1>
                  </Col>
                </Row>
              </Card.Title>

              <Row className="ms-1">
                <Col className="mb-2">
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </Col>

                <Col className="me-2">
                  <DropdownButton
                    id="dropdown-basic-button"
                    title="ACTION"
                    className="justify-content-center"
                  >
                    {hasPermission(permissions.EDIT_VENDOR) && (
                      <Dropdown.Item
                        as={Link}
                        size="sm"
                        className="justify-content-center"
                        to={`/vendors/edit/${vendorId}`}
                      >
                        Edit
                      </Dropdown.Item>
                    )}
                  </DropdownButton>
                </Col>
              </Row>
            </Card.Header>

            <Card.Body className="mb-4">
              <div>
                <Card.Text>
                  <span className="fw-bolder fs-1">{vendor.name || "N/A"}</span>

                  <span className="text-muted ms-3">
                    ({vendor.type || "N/A"})
                  </span>

                  <span className="ms-3">{vendor.primary_phone || "N/A"}</span>
                </Card.Text>
              </div>

              <Button
                className="mt-3 mb-3"
                onClick={() => setViewMore(!viewMore)}
              >
                View more
              </Button>

              {!!viewMore && (
                <ListGroup className="list-group-flush">
                  <ListGroup.Item>
                    <label className="fw-bolder fs-3">DESCRIPTION</label>{" "}
                    <HtmlContent>{vendor.description || "N/A"}</HtmlContent>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <label className="fw-bolder fs-3">SPECIALITY</label>{" "}
                    <Card.Text className="fs-3">
                      {vendor.speciality_id || "N/A"}{" "}
                    </Card.Text>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <label className="fw-bolder fs-3">EMAIL</label>{" "}
                    <Card.Text className="fs-3">
                      {vendor.email || "N/A"}
                    </Card.Text>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <label className="fw-bolder fs-3">
                      SECONDARY TELEPHONE
                    </label>{" "}
                    <Card.Text className="fs-3">
                      {vendor.secondary_phone || "N/A"}{" "}
                    </Card.Text>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <label className="fw-bolder fs-3"> ACCOUNT NAME</label>{" "}
                        <Card.Text className="fs-3">
                          {vendor.account_name || "N/A"}
                        </Card.Text>
                      </Col>

                      <Col>
                        <label className="fw-bolder fs-3">ACCOUNT NUMBER</label>{" "}
                        <Card.Text className="fs-3">
                          {vendor.account_number || "N/A"}
                        </Card.Text>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <label className="fw-bolder fs-3">BANK</label>{" "}
                    <Card.Text className="fs-3">
                      {vendor?.bank?.name || "N/A"}{" "}
                    </Card.Text>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <label className="fw-bolder fs-3">OFFICE ADDRESS</label>{" "}
                    <HtmlContent>{vendor.address || "N/A"}</HtmlContent>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <label className="fw-bolder fs-3">WEBSITE</label>{" "}
                    <Card.Text className="fs-3">
                      {vendor.website || "N/A"}
                    </Card.Text>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <label className="fw-bolder fs-3 mb-4">
                      EVALUATION FILE
                    </label>{" "}
                    {/* {uploadedFiles !== null ? (
                    <div>
                      <label className="fw-bold fs-3 mb-4">
                        UPLOADED FILE EVIDENCE
                      </label>

                      <br />

                      {uploadedFiles.map((uploadedFile, index) => (
                        <div key={index}>
                          <label className="fw-bold fs-4 d-flex justify-content-center">
                            {uploadedFile || "N/A"}
                          </label>

                          <Row className="mb-4">
                            {getUploadedFileExtension(
                              uploadedFile
                            ).toLowerCase() === "pdf" ? (
                              <>
                                <Col className="uploaded-file-evidence mb-3 d-flex justify-content-center">
                                  <object
                                    data={`https://unified-v2-test.ascoapps.com.ng/docEvidence/${uploadedFile}`}
                                    type="application/pdf"
                                    width="85%"
                                    height="500"
                                  />

                                  <br />
                                </Col>
                              </>
                            ) : (
                              <Col className="uploaded-file-evidence mb-3 d-flex justify-content-center">
                                <a
                                  target="_blank"
                                  href={`https://unified-v2-test.ascoapps.com.ng/docEvidence/${uploadedFile}`}
                                >
                                  <img
                                    src={`https://unified-v2-test.ascoapps.com.ng/docEvidence/${uploadedFile}`}
                                    alt={uploadedFile}
                                    border={1}
                                    border-radius={4}
                                    padding={5}
                                    width={150}
                                  />
                                </a>
                              </Col>
                            )}
                          </Row>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      <label className="fw-bold fs-3 mb-2">
                        ATTESTATION FILE
                      </label>

                      <p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                          <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                          <path d="M10 12l4 4m0 -4l-4 4" />
                        </svg>
                        No files uploaded
                      </p>
                    </div>
                  )} */}
                    <Card.Text className="fs-3">
                      <Link to="#" download="vendor-attestation file">
                        {vendor.evaluationDoc || "N/A"}
                      </Link>
                    </Card.Text>
                  </ListGroup.Item>
                </ListGroup>
              )}

              {/* <ListGroup.Item className="mt-3">
                <label className="fw-bolder fs-3 ">DISBURSEMENT HISTORY</label>
                {!disbursementHistory?.length && (
                  <p>NO HISTORY OF DISBURSEMENT FOR THIS ITEM</p>
                )}
                {!!disbursementHistory?.length && (
                  <Table
                    responsive
                    hover
                    bordered
                    className=" table-vcenter table-mobile-md card-table mt-3"
                  >
                    <thead>
                      <tr>
                        <th>DISBURSED DATE</th>
                        <th>DISBURSER</th>
                        <th>COLLECTOR</th>
                        <th>ITEMS AND QUANTITY DISBURSED</th>
                      </tr>
                    </thead>

                    <tbody>
                      {disbursementHistory.map((history) => (
                        <tr key={history.id}>
                          <td data-label="DISBURSED DATE">
                            {history.date_created || "N/A"}
                          </td>
                          <td data-label="DISBURSER">
                            {`${history.disbursed_by.first_name} ${history.disbursed_by.surname}` ||
                              "N/A"}
                          </td>
                          <td data-label="COLLECTOR">
                            {`${history.receive_by.first_name} ${history.receive_by.surname}` ||
                              "N/A"}
                          </td>
                          <td data-label="ITEMS AND QUANTITY">
                            <ul>
                              {history.stock_transaction.map(
                                (itemTransaction) => (
                                  <li key={itemTransaction.id}>
                                    {`${itemTransaction.guard_kit.name} (${itemTransaction.quantity})` ||
                                      "N/A"}
                                  </li>
                                )
                              )}
                            </ul>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </ListGroup.Item> */}
            </Card.Body>

            <Card.Footer>
              <ListGroup className="list-group-flush">
                <ListGroup.Item className="d-flex justify-content-end">
                  <Row>
                    <Col className="mb-3">
                      <label className="fw-bolder fs-3">Created at</label>
                      <Card.Text className="fs-3">
                        {/* {vendor.created_at} */}
                        {format(
                          new Date(vendor.created_at),
                          "EEE, MMM d, yyyy 'at' HH:mm:ss",
                          {
                            awareOfUnicodeTokens: true,
                          }
                        )}
                      </Card.Text>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-end">
                  <Row>
                    <Col>
                      <label className="fw-bolder fs-3">Updated at</label>
                      <Card.Text className="fs-3">
                        {format(
                          new Date(vendor.updated_at),
                          "EEE, MMM d, yyyy 'at' HH:mm:ss",
                          {
                            awareOfUnicodeTokens: true,
                          }
                        ) || "N/A"}
                      </Card.Text>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Card.Footer>
          </Card>

          <Card className="mt-5">
            <Card.Header>
              <Card.Title>
                <h1>Maintenance Works</h1>
              </Card.Title>
            </Card.Header>

            <Card.Body>
              {!vendor?.maintenance_records?.length && (
                <div className="mt-4 p-2 border text-muted text-center">
                  <span>
                    <FaExclamationCircle size={19} />
                  </span>

                  <span className="fs-2 ms-2 ">No Maintenance Work Found!</span>
                </div>
              )}

              {!!vendor?.maintenance_records?.length && (
                <VendorWorksTable
                  maintenanceRecords={vendor?.maintenance_records}
                />
              )}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default ViewVendor;
