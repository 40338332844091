import React, { useContext } from "react";
import { AuthContext } from "../auth";

export const usePermissionAndDomain = () => {
  const auth = useContext(AuthContext);

  const hasDomain = (domain) => {
    return isSuperAdmin() || domain in auth.userDomains;
  };

  const hasPermission = (permission) => {
    return isSuperAdmin() || permission in auth.userPermissions;
  };

  const isSuperAdmin = () => {
    return !!Number(auth?.user?.role?.is_super_admin);
  };

  return { hasDomain, hasPermission, isSuperAdmin };
};
