import React, { useState } from "react";
import useAxios from "./useAxios";

export const useRolesAndPermissionsData = (endpoint) => {
  const { get } = useAxios();
  const [fetching, setFetching] = useState(true);
  const [failedFetching, setFailedFetching] = useState(true);
  const [permissions, setPermissions] = useState({});

  const fetchRolesAndPermissionsData = async () => {
    setFetching(true);
    try {
      const response = await get(
        endpoint
      );

      if (response.status === 202) {
        const permissionsGroup = {};
        Object.values(response.data).forEach((permission) => {
          if (undefined === permissionsGroup[permission.domain]) {
            permissionsGroup[permission.domain] = [];
          }
          permissionsGroup[permission.domain].push(permission);
        });
        setPermissions(permissionsGroup);
      } else {
        setFailedFetching(true);
      }
    } catch (error) {
      setFailedFetching(true);
    } finally {
      setFetching(false);
    }
  };

  return {
    fetching,
    failedFetching,
    permissions,
    fetchRolesAndPermissionsData,
  };
};
