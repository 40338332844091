import React from "react";
import { RequireAuth } from "../../auth";
import { Route, Routes } from "react-router-dom";
import { Loading } from "../../Components/Loading";
import { Error404 } from "../../Pages/Errors/Error404";
import RolesPermissionsIndex from "../../Pages/RolesPermissions/Roles/index";
import ViewRolesPermissions from "../../Pages/RolesPermissions/Roles/view";
import CreateRolesPermissions from "../../Pages/RolesPermissions/Roles/create";
import EditRolesPermissions from "../../Pages/RolesPermissions/Roles/edit";

export const RolesPermissionsRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <RolesPermissionsIndex />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="/create"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateRolesPermissions />
              </React.Suspense>
            }
          />

          <Route
            path="view/:roleAndPermissionId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewRolesPermissions />
              </React.Suspense>
            }
          />

          <Route
            path="edit/:roleId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditRolesPermissions />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
};
