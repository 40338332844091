import React, { useState } from "react";
import * as yup from "yup";
import {
  Form,
  Badge,
  Button,
  Alert,
  Row,
  Col,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import useAxios from "../../hooks/useAxios.js";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { LoadingButton } from "../LoadingButtons.jsx";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TinyMCETextEditor from "../TinyMCETextEditor.jsx";
import { useClientData } from "../../hooks/useClientsData.jsx";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast.jsx";

const schema = yup.object({
  client_id: yup.string().required("Client required"),
  client_location_id: yup.string().required("Client location required"),
  incidentDate: yup.string().nullable().required("Date of incident required"),
  incidentTime: yup.string().required("Time of incident required"),
  incidentType: yup.string().required("Incident type required"),
  incidentSummary: yup
    .string()
    .max(5000)
    .required("Conclusion of report required"),
});

export const ShortIncidentReportForm = ({ clients }) => {
  const {
    watch,
    control,
    setValue,
    setError,
    register,
    unregister,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      client_id: "",
      client_location_id: "",
      incidentDate: "",
      incidentTime: "",
      incidentType: "",
      incidentSummary: "",
    },
  });

  const navigate = useNavigate();
  const { post } = useAxios();
  const [loading, setLoading] = useState(false);
  const [incidentType, setIncidentType] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [hasServerError, setHasServerError] = useState(false);

  const showOtherIncidentType = watch("incidentType");

  const [incidentDate, setIncidentDate] = useState("");
  const [incidentTime, setIncidentTime] = useState("");

  const onValueChange = (event) => {
    setIncidentType(event.currentTarget.value);
  };

  const { clientOptions, clientOptionsReady, clientLocationsOptions } =
    useClientData({ clients });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await post(
        `incidents/quick-report-store`,
        {
          ...data,
          incidentDate: format(new Date(data.incidentDate), "yyyy-MM-dd", {
            awareOfUnicodeTokens: true,
          }),
          incidentTime: format(new Date(data.incidentTime), "HH:mm", {
            awareOfUnicodeTokens: true,
          }),
        },
        true
      );

      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Incident report successfully saved!", null, 5000);
      navigate("/incident-reports");
    } catch (error) {
      setLoading(false);
      if (error.response.status === 422) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry, something went wrong and your request was unsuccessful!",
          null,
          5000
        );
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error.response.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to create incident report!",
          null,
          5000
        );
      }
    }
  };

  return (
    <>
      {!!clientOptionsReady && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          {hasServerError && (
            <Alert variant="danger">
              <p>
                Something went wrong will processing your request. Please try
                again!
              </p>
            </Alert>
          )}

          <div className="clientDetail mt-4 mb-4 border">
            <Form.Label className="mt-2 p-2">CLIENT DETAILS</Form.Label>
            <Row className="d-flex justify-content-between ms-0 me-0 mb-1 p-2 ">
              <Col className=" client-name mb-4" md={6}>
                <Form.Group controlId="client ">
                  <Form.Label className="required">NAME</Form.Label>
                  <Controller
                    name="client_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <Select
                          className="text-dark"
                          name={field.name}
                          ref={field.ref}
                          options={clientOptions}
                          onChange={(selectedClientOption) => {
                            setValue("client_id", selectedClientOption.value, {
                              shouldValidate: true,
                            });
                            setValue("client_location_id", "", {
                              shouldValidate: true,
                            });
                            setLocationOptions(
                              clientLocationsOptions[
                                selectedClientOption?.value
                              ] ?? []
                            );
                          }}
                          value={field.label}
                          placeholder="Select Client"
                        />
                      );
                    }}
                  />
                  {!!errors.client_id && (
                    <p>
                      <Badge bg="danger">{errors.client_id?.message}</Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="client-location" md={6}>
                <Form.Group controlId="client-location ">
                  <Form.Label className="required">LOCATION</Form.Label>
                  <Controller
                    name="client_location_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <Select
                          className="text-dark"
                          name={field.name}
                          ref={field.ref}
                          options={locationOptions}
                          onChange={(selectedClientLocationOption) => {
                            setValue(
                              "client_location_id",
                              selectedClientLocationOption.value,
                              {
                                shouldValidate: true,
                              }
                            );
                          }}
                          value={field.label}
                          placeholder="Select client location"
                        />
                      );
                    }}
                  />
                  <Form.Text className="text-danger">
                    Please select a client to see location options.
                  </Form.Text>

                  {!!errors.client_location_id && (
                    <p>
                      <Badge bg="danger">
                        {errors.client_location_id?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>

          <Row className="incident-details ms-0 me-0 mb-4 p-2 border">
            <Col className="incident-date mt-2 mb-3" md={6}>
              <FormGroup controlId="incidentDate">
                <Form.Label className="required">INCIDENT DATE</Form.Label>
                <Controller
                  name="incidentDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      isClearable
                      strictParsing
                      showYearDropdown
                      showMonthDropdown
                      autoComplete="off"
                      maxDate={new Date()}
                      closeOnScroll={true}
                      dropdownMode="select"
                      selected={incidentDate}
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      onChange={(date) => {
                        setIncidentDate(date);
                        setValue("incidentDate", date, {
                          shouldValidate: true,
                        });
                      }}
                      placeholderText="Enter reported date"
                    />
                  )}
                />

                {!!errors.incidentDate && (
                  <p>
                    <Badge bg="danger">{errors.incidentDate?.message}</Badge>
                  </p>
                )}
              </FormGroup>
            </Col>

            <Col className="incident-time mt-2 mb-2" md={6}>
              <FormGroup controlId="incidenTime">
                <Form.Label className="required">INCIDENT TIME</Form.Label>
                <Controller
                  name="incidentTime"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      isClearable
                      showTimeSelect
                      timeIntervals={1}
                      timeCaption="Time"
                      showTimeSelectOnly
                      closeOnScroll={true}
                      dateFormat="HH:mm:ss"
                      selected={incidentTime}
                      className="form-control"
                      onChange={(time) => {
                        setIncidentTime(time);
                        setValue("incidentTime", time);
                      }}
                      placeholderText="Enter incident time"
                    />
                  )}
                />

                {!!errors.incidentTime && (
                  <p>
                    <Badge bg="danger">{errors.incidentTime?.message}</Badge>
                  </p>
                )}
              </FormGroup>
            </Col>
          </Row>

          <div className="incident-type mb-4 p-2 border">
            <FormGroup controlId="incidentType">
              <Form.Label className="required  mt-2">
                INCIDENT TYPE (Select one below)
              </Form.Label>
              {["radio"].map((type) => (
                <div
                  key={`inline-${type}`}
                  className="mb-2"
                  name="incidentType"
                  onChange={onValueChange}
                >
                  <Form.Check
                    inline
                    value="THEFT"
                    label="THEFT"
                    type={type}
                    id={`inline-${type}-THEFT`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="ACCIDENT"
                    label="BURGLARY"
                    type={type}
                    id={`inline-${type}-BURGLARY`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="ROBBERY"
                    label="ROBBERY"
                    type={type}
                    id={`inline-${type}-ROBBERY`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="FIRE"
                    label="FIRE"
                    type={type}
                    id={`inline-${type}-FIRE`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="ACCIDENT"
                    label="ACCIDENT"
                    type={type}
                    id={`inline-${type}-ACCIDENT`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="FLOOD"
                    label="FLOOD"
                    type={type}
                    id={`inline-${type}-FLOOD`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="ASSUALT"
                    label="ASSUALT"
                    type={type}
                    id={`inline-${type}-ASSUALT`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="COLLAPSED FENCE"
                    label="COLLAPSED FENCE"
                    type={type}
                    id={`inline-${type}-COLLAPSED FENCE`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="PROPERTY LOSS"
                    label="PROPERTY LOSS"
                    type={type}
                    id={`inline-${type}-PROPERTY LOSS`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="DEATH"
                    label="DEATH"
                    type={type}
                    id={`inline-${type}-DEATH`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="OTHER"
                    label="OTHER"
                    type={type}
                    id={`inline-${type}-OTHER`}
                    {...register("incidentType")}
                  />
                </div>
              ))}

              {!!errors.incidentType && (
                <p>
                  <Badge bg="danger">{errors.incidentType?.message}</Badge>
                </p>
              )}
            </FormGroup>

            {showOtherIncidentType === "OTHER" && (
              <div className="mb-3">
                <FormGroup>
                  <Form.Label className="required">
                    PLEASE ENTER THE INCIDENT TYPE BELOW (IF OTHER)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    {...register("other_type", { required: true })}
                    onChange={(event) => {
                      setValue("other_type", event.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  />

                  {!!errors.other_type && (
                    <p>
                      <Badge bg="danger">{errors.other_type?.message}</Badge>
                    </p>
                  )}
                </FormGroup>
              </div>
            )}
          </div>

          <div className="report-summary mb-4 p-3 border">
            <FormGroup controlId="reportSummary">
              <Form.Label className="required">SUMMARY</Form.Label>

              <Controller
                control={control}
                name="incidentSummary"
                render={({ field }) => (
                  <TinyMCETextEditor field={field} setValue={setValue} />
                )}
              />
              {!!errors.incidentSummary && (
                <p>
                  <Badge bg="danger">{errors.incidentSummary?.message}</Badge>
                </p>
              )}
            </FormGroup>
          </div>

          <div className="submit col-md-12 text-center mb-4">
            {loading ? (
              <LoadingButton />
            ) : (
              <Button
                type="submit"
                variant="success"
                disabled={!!loading || !isDirty}
              >
                Submit
              </Button>
            )}
          </div>
        </Form>
      )}
    </>
  );
};
