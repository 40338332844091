import React from 'react'
import { format } from 'date-fns';

 const getDateRangeOfWeek = (weekNumber, year) => {
   let firstDayOfWeek = new Date(year, 0, 1 + (weekNumber - 1) * 7);

   let lastDayOfWeek = new Date(firstDayOfWeek);
   lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

   let displayedFirstDay = format(new Date(firstDayOfWeek), "d MMM", {
     awareOfUnicodeTokens: true,
   });

   let displayedLastDay = format(new Date(lastDayOfWeek), "d MMM", {
     awareOfUnicodeTokens: true,
   });

   return `${displayedFirstDay} - ${displayedLastDay}`;
 };



export default getDateRangeOfWeek;