import React, { useState } from "react";
import * as yup from "yup";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import useAxios from "../../hooks/useAxios";
import { Navigate } from "react-router-dom";
import { LoadingButton } from "../LoadingButtons";
import maleAvatar from "../../images/male_avatar.png";
import { yupResolver } from "@hookform/resolvers/yup";
import femaleAvatar from "../../images/female_avatar.jpg";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Row,
} from "react-bootstrap";

const schema = yup.object({
  first_name: yup.string().required("First name is required"),
  middle_name: yup.string().nullable(),
  surname: yup.string().required("Surname is required"),
  designation_id: yup.string().nullable(),
  department_id: yup.string().nullable(),
  employee_code: yup.string().nullable(),
  hrimax_user_id: yup.string().required("HRIMAX ID is required"),
  phone: yup
    .string()
    // .matches(
    //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    // )
    .notRequired(),
  email: yup.string().email().nullable(),
  date_of_birth: yup.string().nullable(),
  gender: yup.string().nullable(),
  marital_status: yup.string().nullable(),
  office_location_code: yup.string().nullable(),
  //   .required("Marital status is required")

  //
  //

  //   client_location: yup.string().required("Office location is required"),
  //   nationality: yup.string().required("Nationality is required"),
  //   residential_address: yup
  //     .string()
  //     .max(5000)
  //     .required("Residential address is required"),
  //   hometown_address: yup
  //     .string()
  //     .max(5000)
  //     .required("Home town address is required"),
});


export const PrimaryDetailsForm = ({
  genders,
  departments,
  designations,
  maritalStatuses,
  branchLocations,
  editPersonalInfo,
}) => {
  const {
    register,
    setValue,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      phone: editPersonalInfo?.phone || "",
      email: editPersonalInfo?.email || "",
      gender: editPersonalInfo?.gender || "",
      surname: editPersonalInfo?.surname || "",
      first_name: editPersonalInfo?.first_name || "",
      middle_name: editPersonalInfo?.middle_name || "",
      date_of_birth: editPersonalInfo?.date_of_birth || "",
      employee_code: editPersonalInfo?.actual_employee_code || "",
      hrimax_user_id: editPersonalInfo?.hrimax_user_id || "",
      department_id: editPersonalInfo?.department_id || "",
      designation_id: editPersonalInfo?.designation_id || "",
      marital_status: editPersonalInfo?.marital_status || "",
      office_location_code: editPersonalInfo?.office_location_code || "",
      can_have_vehicle: !!Number(editPersonalInfo?.can_have_vehicle),
      //   nationality: editPersonalInfo?.nationality || "",
      //   residential_address: editPersonalInfo?.residential_address || "",
      //   hometown_address: editPersonalInfo?.hometown_address || "",
    },
  });

  const { post } = useAxios();
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const today = new Date().toISOString().slice(0, 10);

  const [dateOfBirth, setDateOfBirth] = useState(
    format(new Date(), "yyyy-MM-dd", { awareOfUnicodeTokens: true })
  );

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const endpointPath = !editPersonalInfo?.id
        ? "human_resources/staffs/store"
        : `human_resources/staffs/${editPersonalInfo.id}/update`;

      await post(endpointPath, {
        ...data,
        can_have_vehicle: Boolean(data.can_have_vehicle),
      });
      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Employee details successfully updated!", null, 5000);
    } catch (error) {
      setLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
      const handleServerErrors = (errors, setError) => {
        if (errors) {
          const serverValidation = error.response.data.errors;
          return Object.keys(serverValidation).map((key) => {
            setError(key, {
              shouldFocus: true,
              type: "manual",
              message: serverValidation[key][0],
            });
          });
        }
      };
      handleServerErrors(error, setError);
      return;
    }
  };

  return (
    <>
      {isSaved && (
        <Navigate
          to={
            !editPersonalInfo?.id
              ? "/staffs/"
              : `/staffs/view/${editPersonalInfo?.id}`
          }
          replace={true}
        />
      )}
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Card className="w-100 mt-5 mb-5 border-radius-rounded">
          <Card.Header>
            <div className="d-flex py-1 align-items-center mt-2 mb-2">
              {editPersonalInfo?.gender === "female" ? (
                <span className="avatar avatar-lg me-2 border">
                  <img
                    src={femaleAvatar}
                    width="80"
                    height="78"
                    alt="Female avatar"
                  />
                </span>
              ) : (
                <span className="avatar avatar-lg me-2 border">
                  <img
                    src={maleAvatar}
                    width="80"
                    height="78"
                    alt="Male avatar"
                  />
                </span>
              )}
            </div>
          </Card.Header>

          <Card.Body>
            <Row className="mb-2">
              <Col className="mb-2 p-2">
                <Form.Group controlId="firstName">
                  <Form.Label className="required">FIRST NAME</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First name"
                    {...register("first_name")}
                  />

                  {!!errors?.first_name && (
                    <p className="justify-content-end mt-1">
                      <Badge bg="danger" className="fs-6 fw-normal">
                        {errors?.first_name?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="mb-2 p-2" md={4}>
                <Form.Group controlId="middleName">
                  <Form.Label>MIDDLE NAME</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Middle name"
                    {...register("middle_name")}
                  />

                  {!!errors?.middle_name && (
                    <p className="justify-content-end mt-1">
                      <Badge bg="danger" className="fs-6 fw-normal">
                        {errors?.middle_name?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="mb-2 p-2">
                <Form.Group controlId="surname">
                  <Form.Label className="required">SURNAME</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Surname"
                    {...register("surname")}
                  />

                  {!!errors?.surname && (
                    <p className="justify-content-end mt-1">
                      <Badge bg="danger" className="fs-6 fw-normal">
                        {errors?.surname?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col className="branch-location mb-2 p-2" md={4}>
                <Form.Group controlId="branchLocation">
                  <Form.Label>BRANCH</Form.Label>
                  <Form.Select {...register("office_location_code")}>
                    <option value=""> Select branch </option>
                    {Object.entries(branchLocations).map(([location, id]) => (
                      <option value={id} key={id}>
                        {`${location}`.replace(/_/g, " ")}
                      </option>
                    ))}
                  </Form.Select>

                  {!!errors.office_location_code && (
                    <p>
                      <Badge bg="danger">
                        {errors.office_location_code?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="mb-2 p-2" md={4}>
                <Form.Group controlId="designation">
                  <Form.Label>DESIGNATION</Form.Label>

                  <Form.Select
                    {...register("designation_id")}
                    className="form-control"
                    onChange={(designationOption) => {
                      setValue(
                        "designation_id",
                        designationOption.target.value,
                        {
                          shouldValidate: true,
                        }
                      );
                    }}
                  >
                    <option value="">Select Designation</option>{" "}
                    {Object.entries(designations).map(
                      ([designation, index]) => (
                        <option value={index} key={index}>
                          {" "}
                          {designation}
                        </option>
                      )
                    )}
                  </Form.Select>
                  {!!errors?.designation_id && (
                    <p className="justify-content-end mt-1">
                      <Badge bg="danger" className="fs-6 fw-normal">
                        {errors?.designation_id?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="mb-2 p-2" md={4}>
                <Form.Group controlId="department">
                  <Form.Label>DEPARTMENT</Form.Label>
                  <Form.Select
                    {...register("department_id")}
                    className="form-control"
                    onChange={(departmentOption) => {
                      setValue("department_id", departmentOption.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  >
                    <option value="">Select Department</option>
                    {Object.entries(departments).map(([department, id]) => (
                      <option value={id} key={id}>
                        {`${department}`.replace(/_/g, " ")}
                      </option>
                    ))}
                  </Form.Select>

                  {!!errors?.department_id && (
                    <p className="justify-content-end mt-1">
                      <Badge bg="danger" className="fs-6 fw-normal">
                        {errors?.department_id?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col className="hrimax-user-id mb-2 p-2" md={6}>
                <Form.Group controlId="hrimaxUserId">
                  <Form.Label className="required">HRIMAX USER ID</Form.Label>
                  <Form.Control
                    type="number"
                    min={1}
                    placeholder="HRIMAX user id"
                    {...register(`hrimax_user_id`, {
                    onChange: (event) => {
                        setValue(
                        `hrimax_user_id`,
                        event.target.value,
                        {
                            shouldValidate: true,
                        }
                        );
                    },
                    })}
                  />

                  {!!errors?.hrimax_user_id && (
                    <p className="justify-content-end mt-1">
                      <Badge bg="danger" className="fs-6 fw-normal">
                        {errors?.hrimax_user_id?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
 {/* hrimax_user_id */}
              <Col className="mb-2 p-2">
                <Form.Group controlId="employeeCode">
                  <Form.Label>EMPLOYEE CODE</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Employee code"
                    {...register("employee_code")}
                  />

                  {!!errors?.employee_code && (
                    <p className="justify-content-end mt-1">
                      <Badge bg="danger" className="fs-6 fw-normal">
                        {errors?.employee_code?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col className="mb-2 p-2" md={6}>
                <Form.Group controlId="phone">
                  <Form.Label>PHONE NUMBER</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Primary phone number"
                    {...register("phone")}
                  />

                  {!!errors?.phone && (
                    <p className="justify-content-end mt-1">
                      <Badge bg="danger" className="fs-6 fw-normal">
                        {errors?.phone?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="mb-2 p-2">
                <Form.Group controlId="email">
                  <Form.Label>EMAIL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Email address"
                    {...register("email")}
                  />

                  {!!errors?.email && (
                    <p className="justify-content-end mt-1">
                      <Badge bg="danger" className="fs-6 fw-normal">
                        {errors?.email?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col className="mb-2 p-2" md={6}>
                <Form.Group controlId="gender">
                  <Form.Label>GENDER</Form.Label>
                  <Form.Select
                    name="gender"
                    className="form-control"
                    {...register("gender")}
                    onChange={(genderOption) => {
                      setValue("gender", genderOption.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  >
                    <option value="">Select Gender</option>
                    {Object.entries(genders).map(([genderKey, index]) => (
                      <option value={index} key={index}>
                        {genderKey}
                      </option>
                    ))}
                  </Form.Select>

                  {!!errors?.gender && (
                    <p className="justify-content-end mt-1">
                      <Badge bg="danger" className="fs-6 fw-normal">
                        {errors?.gender?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="mb-2 p-2">
                <Form.Group controlId="dateOfBirth">
                  <Form.Label>D.O.B</Form.Label>
                  <Form.Control
                    type="date"
                    max={today}
                    placeholder="Enter date"
                    {...register("date_of_birth")}
                    value={dateOfBirth}
                    onChange={(event) => setDateOfBirth(event.target.value)}
                  />

                  {!!errors.date_of_birth && (
                    <p>
                      <Badge bg="danger">{errors.date_of_birth?.message}</Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="mb-1 p-2" md={6}>
                <Form.Group controlId="maritalStatus">
                  <Form.Label>MARITAL STATUS</Form.Label>
                  <Form.Select
                    {...register("marital_status")}
                    className="form-control"
                    onChange={(maritalStatusOption) => {
                      setValue(
                        "marital_status",
                        maritalStatusOption.target.value,
                        {
                          shouldValidate: true,
                        }
                      );
                    }}
                  >
                    <option value="">Select Marital Status</option>
                    {Object.entries(maritalStatuses).map(
                      ([statuses, index]) => (
                        <option value={index} key={index}>
                          {statuses}
                        </option>
                      )
                    )}
                  </Form.Select>

                  {!!errors.marital_status && (
                    <p>
                      <Badge bg="danger">
                        {errors.marital_status?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col
                style={{ width: 50, height: 50 }}
                className="mt-4 fs-3 mb-4 d-flex justify-content-center align-items-center"
              >
                <Form.Group controlId="canHaveVehicles">
                  <Form.Check
                    {...register("can_have_vehicle")}
                    type="checkbox"
                    name="can_have_vehicle"
                    id="can_have_vehicle"
                    label="Can have vehicle(s)"
                    value={true}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className=" d-flex justify-content-center mt-4 mb-1  ">
              {loading ? (
                <LoadingButton />
              ) : (
                <Button
                  data-testid="bs-btn "
                  variant="success"
                  type="submit"
                  className=""
                  disabled={!!loading || !isDirty}
                >
                  Submit
                </Button>
              )}
            </div>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};
