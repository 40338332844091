import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { HtmlContent } from "../../Components/HtmlContent";
import { Col, Row, Card, Button, ListGroup } from "react-bootstrap";

const ViewWeeklyReport = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { weeklyReportId } = useParams();
  const [user, setUser] = useState(true);
  const [fetching, setFetching] = useState(true);
  const [officeBranch, setOfficeBranch] = useState(true);
  const [weeklyReport, setWeeklyReport] = useState(true);

  useEffect(() => {
    setFetching(true);
    const getDetailedWeeklyReport = async () => {
      try {
        const response = await get(`weekly_reports/${weeklyReportId}`);
        setUser(response.data.users);
        setWeeklyReport(response.data);
        setOfficeBranch(response.data.office_location);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedWeeklyReport();
  }, []);

  return (
    <>
      {fetching && <Loading />}

      {!!weeklyReport?.id && !fetching && (
        <Card>
          <Card.Header className="d-flex justify-content-between mt-2 mb-5">
            <Card.Title>
              <Row>
                <Col>
                  <h1>Weekly Report for {weeklyReport.date}</h1>
                </Col>
              </Row>
            </Card.Title>

            <Row>
              <Col>
                <Button onClick={() => navigate(-1)}>Back</Button>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body className="mb-4">
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <Row>
                  <Col className="mb-3" md={6}>
                    <label className="fw-bolder fs-3">BRANCH MANAGER</label>
                    <Card.Text className="fs-3">
                      {user.first_name} {user.surname}
                    </Card.Text>
                  </Col>

                  <Col md={6}>
                    <label className="fw-bolder fs-3">EMAIL</label>
                    <Card.Text className="fs-3">
                      {user.email || "N/A"}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3">OFFICE BRANCH</label>
                <Card.Text className="fs-3">
                  {officeBranch.name || "N/A"}
                </Card.Text>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col className="mb-3" sm={4}>
                    <label className="fw-bolder fs-3">MONTH</label>
                    <Card.Text className="fs-3">
                      {weeklyReport.month || "N/A"}{" "}
                    </Card.Text>
                  </Col>

                  <Col className="mb-3" sm={4}>
                    <label className="fw-bolder fs-3">WEEK</label>
                    <Card.Text className="fs-3">
                      {weeklyReport.week || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col sm={4}>
                    <label className="fw-bolder fs-3">DATE</label>
                    <Card.Text className="fs-3">
                      {weeklyReport.date || "N/A"}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3">OPERATION REPORT</label>
                <HtmlContent>
                  {weeklyReport.operation_report || "N/A"}
                </HtmlContent>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col className="mb-3" md={6}>
                    <label className="fw-bolder fs-3">
                      ALL ISSUED EMPLOYMENT LETTERS
                    </label>
                    <Card.Text className="fs-3">
                      {weeklyReport.issues_employment_letter || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col md={6}>
                    <label className="fw-bolder fs-3">REASON (IF NO)</label>
                    <HtmlContent>
                      {weeklyReport.employment_reason || "N/A"}
                    </HtmlContent>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col className="mb-3" md={6}>
                    <label className="fw-bolder fs-3">ALL DULY VERIFIED</label>
                    <Card.Text className="fs-3">
                      {weeklyReport.verified || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col md={6}>
                    <label className="fw-bolder fs-3">REASON (IF NO):</label>
                    <HtmlContent>
                      {weeklyReport.verified_reason || "N/A"}
                    </HtmlContent>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col className="mb-3" md={6}>
                    <label className="fw-bolder fs-3">
                      ALL INPUTED INTO THE PAYROLL
                    </label>
                    <Card.Text className="fs-3">
                      {weeklyReport.in_payroll || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col md={6}>
                    <label className="fw-bolder fs-3">REASON (IF NO):</label>
                    <HtmlContent>
                      {weeklyReport.payroll_reason || "N/A"}
                    </HtmlContent>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3 mb-2">
                  STATE OF BRANCH OFFICE FACILITIES
                </label>
                <HtmlContent>
                  {weeklyReport.branch_office_state || "N/A"}
                </HtmlContent>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3 mb-2">
                  GENERAL WELLBEING OF BRANCH OFFICE PERSONNEL
                </label>
                <HtmlContent>
                  {weeklyReport.branch_office_personnel_well_being || "N/A"}
                </HtmlContent>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3 mb-2">LOGISTICS REPORT</label>
                <HtmlContent>
                  {weeklyReport.logistics_report || "N/A"}
                </HtmlContent>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3 mb-2">
                  CLIENT FEEBACK REPORT
                </label>
                <HtmlContent>
                  {weeklyReport.client_feedback_report || "N/A"}
                </HtmlContent>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3 mb-2">FINANCE REPORT</label>
                <HtmlContent>
                  {weeklyReport.finance_report || "N/A"}
                </HtmlContent>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3 mb-2">DOGS REPORT</label>
                <HtmlContent>{weeklyReport.dogs_report || "N/A"}</HtmlContent>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3 mb-2">
                  PLAN FOR NEXT WEEK
                </label>
                <HtmlContent>
                  {weeklyReport.plan_for_next_week || "N/A"}
                </HtmlContent>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3 mb-2">
                  ACTION TAKEN FROM THE LAST MEETING
                </label>
                <HtmlContent>
                  {weeklyReport.decision_action || "N/A"}
                </HtmlContent>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3">CREATED BY</label>
                <Card.Text className="fs-3">
                  {user.first_name} {user.surname}
                </Card.Text>
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>

          <Card.Footer>
            <ListGroup className="list-group-flush">
              <ListGroup.Item className="d-flex justify-content-end">
                <Row>
                  <Col className="mb-3">
                    <label className="fw-bolder fs-3">Created at</label>
                    <Card.Text className="fs-3">
                      {format(
                        new Date(weeklyReport.created_at),
                        "EEE, MMM d, yyyy 'at' HH:mm:ss",
                        {
                          awareOfUnicodeTokens: true,
                        }
                      )}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card.Footer>
        </Card>
      )}
    </>
  );
};

export default ViewWeeklyReport;
