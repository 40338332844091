import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Col, Form, Row } from "react-bootstrap";

export const MaintenanceFilterSearch = ({
  vendors,
  vehicles,
  vehicleParts,
  maintenanceRecords,
  maintenanceStatuses,
  initialSearchFilterInputs,
  onhandleFilteredSearchChange,
}) => {
  const [searchFilterInputs, setSearchFilterInputs] = useState({
    vendor_id: initialSearchFilterInputs?.vendor_id ?? "",
    vehicle_id: initialSearchFilterInputs?.vehicle_id ?? "",
    fromDate: initialSearchFilterInputs?.fromDate ?? "",
    toDate: initialSearchFilterInputs?.toDate ?? "",
    status: initialSearchFilterInputs?.status ?? "",
    vehicle_part_id: initialSearchFilterInputs?.vehicle_part_id ?? "",
    voucher_number: initialSearchFilterInputs?.voucher_number ?? "",
  });

  const [displayedVendor, setDisplayedVendor] = useState(null);
  const [displayedVehicle, setDisplayedVehicle] = useState(null);
  const [displayedVehiclePart, setDisplayedVehiclePart] = useState(null);

  const [vendorOptions, setVendorOptions] = useState([]);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [vehiclePartsOptions, setVehiclePartsOptions] = useState([]);

  useEffect(() => {
    const _vendorOptions = [];
    const _vehicleOptions = [];
    const _vehiclePartsOptions = [];

    vehicles.forEach((vehicle) => {
      _vehicleOptions.push({
        value: vehicle.id,
        label: `${vehicle?.reg_number} (${vehicle?.vehicle_model?.vehicle_make?.name} - ${vehicle?.vehicle_model?.name} )`,
      });

      if (searchFilterInputs.vehicle_id === vehicle.id) {
        setDisplayedVehicle({
          value: vehicle.id,
          label: `${vehicle?.reg_number} (${vehicle?.vehicle_model?.vehicle_make?.name} - ${vehicle?.vehicle_model?.name} )`,
        });
      }
    });

    Object.keys(vendors).forEach((key) => {
      _vendorOptions.push({
        value: key,
        label: vendors[key],
      });

      if (searchFilterInputs.vendor_id === key) {
        setDisplayedVendor({
          value: key,
          label: vendors[key],
        });
      }
    });

    Object.keys(vehicleParts).forEach((key) => {
      _vehiclePartsOptions.push({
        value: key,
        label: vehicleParts[key],
      });

      if (searchFilterInputs.vehicle_part_id === key) {
        setDisplayedVehiclePart({
          value: key,
          label: vehicleParts[key],
        });
      }
    });


    setVendorOptions(_vendorOptions);
    setVehicleOptions(_vehicleOptions);
    setVehiclePartsOptions(_vehiclePartsOptions);
  }, []);

  return (
    <>
      <Form
        className="border mt-0 mb-4 p-3"
        method="GET"
        onSubmit={(event) => {
          event.preventDefault();
          onhandleFilteredSearchChange(searchFilterInputs);
        }}
      >
        <Row className="mb-3">
          <Col className="py-2 mx-1">
            <Form.Label>VOUCHER NO</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder="Voucher number"
              defaultValue={searchFilterInputs.voucher_number}
              onChange={(event) =>
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  voucher_number: event.target.value,
                })
              }
            />
          </Col>

          <Col className="py-2 mx-1" sm={6}>
            <Form.Label>VENDOR</Form.Label>
            <Select
              className="text-dark"
              name="vendor_id"
              placeholder="Select Vendor"
              defaultValue={searchFilterInputs.vendor_id.label}
              onChange={(event) => {
                setDisplayedVendor({
                  value: event.value,
                  label: event.label,
                });
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  vendor_id: event.value,
                });
              }}
              options={vendorOptions}
              value={displayedVendor}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="py-2 mx-1">
            <Form.Label>VEHICLE PART</Form.Label>
            <Select
              className="text-dark"
              name="vehicle_part_id"
              placeholder="Select Part"
              onChange={(event) => {
                setDisplayedVehiclePart({
                  value: event.value,
                  label: event.label,
                });

                setSearchFilterInputs({
                  ...searchFilterInputs,
                  vehicle_part_id: event.value,
                });
              }}
              value={displayedVehiclePart}
              options={vehiclePartsOptions}
            />
          </Col>

          <Col className="py-2 mx-1" sm={4}>
            <Form.Label>VEHICLE</Form.Label>
            <Select
              className="text-dark"
              name="vehicle_id"
              placeholder="Select Vehicle"
              onChange={(event) => {
                setDisplayedVehicle({
                  value: event.value,
                  label: event.label,
                });
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  vehicle_id: event.value,
                });
              }}
              value={displayedVehicle}
              options={vehicleOptions}
            />
          </Col>

          <Col className="py-2 mx-1">
            <Form.Label>MANTENANCE STATUS</Form.Label>
            <Form.Select
              name="status"
              className="form-control"
              defaultValue={searchFilterInputs.status}
              onChange={(event) =>
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  status: event.target.value,
                })
              }
            >
              <option value="">Select Status</option>{" "}
              {maintenanceStatuses.map((statusIndex) => (
                <option value={statusIndex} key={statusIndex}>
                  {statusIndex}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>

        <Row>
          <Col className="py-2 mx-1">
            <Form.Label>FROM</Form.Label>
            <Form.Control
              type="date"
              name="fromDate"
              className="form-control"
              placeholder="Pick a Date"
              defaultValue={searchFilterInputs.fromDate}
              onChange={(event) =>
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  fromDate: event.target.value,
                })
              }
            />
          </Col>

          <Col className="py-2 mx-1" sm={6}>
            <Form.Label>TO</Form.Label>
            <Form.Control
              type="date"
              name="toDate"
              className="form-control"
              placeholder="Pick a Date"
              defaultValue={searchFilterInputs.toDate}
              onChange={(event) =>
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  toDate: event.target.value,
                })
              }
            />
          </Col>
        </Row>
        <Button variant="secondary" className="me-3" type="submit">
          Filter
        </Button>
      </Form>
    </>
  );
};
