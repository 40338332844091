import React from "react";
import { RequireAuth } from "../auth";
import { Loading } from "../Components/Loading";
import { Route, Routes } from "react-router-dom";
import { Error404 } from "../Pages/Errors/Error404";
import ViewDriver from "../Pages/Drivers/view";
import EditDriver from "../Pages/Drivers/edit";
import DriversIndex from "../Pages/Drivers/index";

export const DriverRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <DriversIndex />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="view/:driverId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewDriver />
              </React.Suspense>
            }
          />

          <Route
            path="edit/:driverId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditDriver />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
};
