import React from "react";
import { UserInfo } from "./UserInfo";
import { Link } from "react-router-dom";
import { Theme } from "../Components/Theme";
import AscoLogo from "../images/Asco_logo.png";
import { permissions } from "../utils/permissions";
import AlertToast from "../Components/CustomAlerts/AlertToast";
import ConfirmationModal from "../Components/Modals/ConfirmationModal";
import { usePermissionAndDomain } from "../hooks/usePermissionAndDomain";
import { NotificationIcon } from "./NotificationIcon";

export const HorizontalLayout = ({ children }) => {
  const { hasPermission } = usePermissionAndDomain();
  const user = JSON.parse(localStorage.getItem("user"));
  
  return (
    <>
      <div className="wrapper">
        <header className="navbar navbar-expand-md navbar-light d-print-none">
          <div className="container-xl">
            <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
              <Link to=".">
                <img
                  src={AscoLogo}
                  width="110"
                  height="32"
                  alt="ASCO Logo"
                  className="navbar-brand-image"
                />
              </Link>
            </h1>

            <div>
              <ul className="navbar-nav flex-row">
                {(hasPermission(permissions.VIEW_ALL_ESCORT_SCHEDULES) ||
                  hasPermission(permissions.CREATE_AN_ESCORT_SCHEDULE)) && (
                  <li className="escort-schedules nav-item dropdown pe-2">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="/escort-schedules"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="true"
                      role="button"
                      aria-expanded="false"
                    >
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <polyline points="9 11 12 14 20 6" />
                          <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" />
                        </svg>
                      </span>
                      <span className="nav-link-title">Escort Schedules</span>
                    </Link>

                    <div className="dropdown-menu">
                      <div className="dropdown-menu-columns">
                        <div className="dropdown-menu-column">
                          {hasPermission(
                            permissions.VIEW_ALL_ESCORT_SCHEDULES
                          ) && (
                            <Link
                              className="dropdown-item"
                              to="/escort-schedules"
                            >
                              View All
                            </Link>
                          )}

                          {hasPermission(
                            permissions.CREATE_AN_ESCORT_SCHEDULE
                          ) && (
                            <Link
                              className="dropdown-item"
                              to="/escort-schedules/create"
                            >
                              Create New
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                )}

                {(hasPermission(permissions.ADD_CLIENT) ||
                  hasPermission(permissions.VIEW_ANY_CLIENT)) && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="/clients"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="true"
                      role="button"
                      aria-expanded="false"
                    >
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <circle cx="9" cy="7" r="4" />
                          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                          <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                        </svg>
                      </span>
                      <span className="nav-link-title">Clients</span>
                    </Link>

                    <div className="dropdown-menu">
                      <div className="dropdown-menu-columns">
                        <div className="dropdown-menu-column">
                          {hasPermission(permissions.VIEW_ANY_CLIENT) && (
                            <Link className="dropdown-item" to="/clients">
                              View All
                            </Link>
                          )}

                          {hasPermission(permissions.ADD_CLIENT) && (
                            <Link
                              className="dropdown-item"
                              to="/clients/create"
                            >
                              Create New
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>

            <div className="navbar-nav flex-row order-md-last">
              <Theme />

              {!!user?.admin_notifications?.length && (
                <NotificationIcon />
              )}

              <UserInfo />
            </div>
          </div>
        </header>

        <div className="page-wrapper">
          <div className="container-xl">
            <div className="page-header d-print-none">
              <div className="row align-items-center">
                <div className="page-body">{children}</div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer footer-transparent d-print-none">
          <div className="container-fluid">
            <div className="row text-center align-items-center flex-row-reverse">
              <div className="col-12 col-lg-auto mt-3 mt-lg-0">
                <ul className="list-inline list-inline-dots mb-0">
                  <li className="list-inline-item">
                    Copyright &copy; 2023
                    <a href="#" className="link-secondary">
                      UNIFIED
                    </a>
                    . All rights reserved.
                  </li>
                  <li className="list-inline-item">
                    <a href="#" className="link-secondary" rel="noopener">
                      v1.6.0
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>

      <AlertToast />
      <ConfirmationModal />
    </>
  );
};
