import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { MaintenanceRecordForm } from "../../Components/MaintenanceRecords/MaintenanceRecordForm";

const CreateMaintenanceRecord = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(true);
  const [vendors, setVendors] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [approvedBy, setApprovedBy] = useState({});
  const [confirmedBy, setConfirmedBy] = useState({});
  const [vehiclePart, setVehiclePart] = useState({});
  const [maintenanceType, setMaintenanceType] = useState([]);
  const [employeeWithVehicle, setEmployeeWithVehicle] = useState([]);

  useEffect(() => {
    setFetching(true);
    const getCreateDetails = async () => {
      try {
        const response = await get(`maintenance/create`);
        setVendors(response.data.vendors);
        setVehicles(response.data.vehicle);
        setApprovedBy(response.data.approve_by);
        setConfirmedBy(response.data.confirmed_by);
        setVehiclePart(response.data.vehicle_part);
        setMaintenanceType(response.data.maintenance_type);
        setEmployeeWithVehicle(response.data.employee_with_vehicles);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getCreateDetails();
  }, []);

  return (
    <>
      <Card className="container">
        {!!fetching ? (
          <Loading />
        ) : (
          <>
            <Card.Header className="d-flex justify-content-between mt-0 mb-4">
              <Card.Title>
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="page-title">Maintenance Record</h1>
                  </div>
                </div>
              </Card.Title>

              <div className="row">
                <div className="col-md-12">
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </div>
              </div>
            </Card.Header>

            <Card.Body>
              <MaintenanceRecordForm
                vendors={vendors}
                vehicles={vehicles}
                approvedBy={approvedBy}
                confirmedBy={confirmedBy}
                vehiclePart={vehiclePart}
                maintenanceType={maintenanceType}
                employeeWithVehicle={employeeWithVehicle}
              />
            </Card.Body>
          </>
        )}
      </Card>
    </>
  );
};

export default CreateMaintenanceRecord;
