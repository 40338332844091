import React, { useState } from "react";
import * as yup from "yup";
import Select from "react-select";
import useAxios from "../../hooks/useAxios";
import { Navigate } from "react-router-dom";
import { LoadingButton } from "../LoadingButtons";
import TinyMCETextEditor from "../TinyMCETextEditor";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import { Alert, Badge, Button, Card, Col, Form, Row } from "react-bootstrap";

const schema = yup.object({
  name: yup.string().required("Name is required"),
  website: yup.string(),
  type: yup.string().required("Vendor type is required"),
  bank_id: yup.string().required("Bank name is required"),
  description: yup.string().max(5000),
  vendor_speciality_id: yup.string(),
  primary_phone: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Primary telephone number is required"
    )
    .required("Primary telephone number is required"),
  secondary_phone: yup.string(),
  account_name: yup.string().required("Account name type is required"),
  account_number: yup.string().required("Account number is required"),
  email: yup.string().email(),
  address: yup.string().max(5000).required("Office address is required"),
  evaluationDoc: yup
    .mixed()
    .test("type", "Evaluation file must be pdf or image.", (value) => {
      if (value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          return (
            value[i].type === "application/pdf" ||
            value[i].type === "image/jpeg" ||
            value[i].type === "image/png" ||
            value[i].type === "image/jpg"
          );
        }
      } else if (!value.length) {
        return true;
      }
    })
    .notRequired(),
});

export const VendorForm = ({ editVendor, bankNames, vendorSpecialities }) => {
  const {
    register,
    setError,
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      name: editVendor?.name || "",
      website: editVendor?.website || "",
      type: editVendor?.type || "",
      bank_id: editVendor?.bank?.id || "",
      vendor_speciality_id: editVendor?.vandor_speciality_id || "",
      description: editVendor?.description || "",
      primary_phone: editVendor?.primary_phone || "",
      secondary_phone: editVendor?.secondary_phone || "",
      account_name: editVendor?.account_name || "",
      account_number: editVendor?.account_number || "",
      email: editVendor?.email || "",
      address: editVendor?.address || "",
      evaluationDoc: editVendor?.evaluationDoc || "",
    },
  });

  const { postWithFile } = useAxios();

  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);
  const [attestationFile, setAttestationFile] = useState(null);

  const handleEvaluationDocUpload = (event) => {
    setAttestationFile(event.target.files[0] ?? null);
  };

  const showFileInput = watch("status");

  const bankOptions = Object.keys(bankNames).map((key) => ({
    value: key,
    label: bankNames[key],
  }));

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const endpointPath = !editVendor?.id
        ? "vendors/store"
        : `vendors/${editVendor.id}/update`;

      await postWithFile(endpointPath, {
        ...data,
        evaluationDoc: attestationFile,
      }, false);

      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Vendor successfully saved!", null, 5000);
    } catch (error) {
      setLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
      if (error?.response?.status === 422) {
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            setLoading(false);
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error?.response?.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to create vendor!",
          null,
          5000
        );
      }
    }
  };

  return (
    <>
      {isSaved && (
        <Navigate
          to={!editVendor?.id ? "/vendors/" : `/vendors/view/${editVendor?.id}`}
          replace={true}
        />
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        {hasServerError && (
          <Alert variant="danger">
            <p>
              Something went wrong will processing your request. Please try
              again!
            </p>
          </Alert>
        )}
        <Card className="creation-authorization">
          <Card.Body>
            <div className="vendor-name mb-4">
              <Form.Group controlId="vendorName">
                <Form.Label className="required">NAME</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  {...register("name")}
                />

                {!!errors.name && (
                  <p>
                    <Badge bg="danger">{errors.name?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <div className="vendor-description mb-4">
              <Form.Group controlId="vendorDescription">
                <Form.Label>DESCRIPTION</Form.Label>
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <TinyMCETextEditor field={field} setValue={setValue} />
                  )}
                />

                {!!errors.description && (
                  <p>
                    <Badge bg="danger">{errors.description?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <div className="vendor-email mb-4">
              <Form.Group controlId="vendorEmail">
                <Form.Label>EMAIL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Email"
                  {...register("email")}
                />

                {!!errors.email && (
                  <p>
                    <Badge bg="danger">{errors.email?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <div className="organisation-type mb-4">
              <Form.Group controlId="type">
                <Form.Label className="required">TYPE</Form.Label>
                <Form.Select {...register("type")}>
                  <option value="" disabled>
                    -- Select Type --
                  </option>
                  <option value="organization">Organization</option>
                  <option value="individual">Individiual</option>
                </Form.Select>

                {!!errors.type && (
                  <p>
                    <Badge bg="danger">{errors.type?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <div className="website mb-4">
              <Form.Group controlId="website">
                <Form.Label>WEBSITE</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Website address"
                  {...register("website")}
                />

                {!!errors.website && (
                  <p>
                    <Badge bg="danger">{errors.website?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <Row>
              <Col className="vendor-primary-phone mb-4">
                <Form.Group controlId="vendorPrimaryPhone">
                  <Form.Label className="required">
                    PRIMARY TELEPHONE
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Primary Telephone"
                    {...register("primary_phone")}
                  />

                  {!!errors.primary_phone && (
                    <p>
                      <Badge bg="danger">{errors.primary_phone?.message}</Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="vendor-secondary-phone mb-4">
                <Form.Group controlId="vendorPrimaryPhone">
                  <Form.Label>SECONDARY TELEPHONE</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Secondary Telephone"
                    {...register("secondary_phone")}
                  />

                  {!!errors.secondary_phone && (
                    <p>
                      <Badge bg="danger">
                        {errors.secondary_phone?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="bank-account-details">
              <Col className="account-name mb-4">
                <Form.Group controlId="accountName">
                  <Form.Label className="required">ACCOUNT NAME</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name of Account "
                    {...register("account_name")}
                  />

                  {!!errors.account_name && (
                    <p>
                      <Badge bg="danger">{errors.account_name?.message}</Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="account-number mb-4">
                <Form.Group controlId="accountNumber">
                  <Form.Label className="required">ACCOUNT NUMBER</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Account number"
                    {...register("account_number")}
                  />

                  {!!errors.account_number && (
                    <p>
                      <Badge bg="danger">
                        {errors.account_number?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="bank-speciality-details">
              <Col className="bank-name mb-4">
                <Form.Group controlId="bankName">
                  <Form.Label className="required">BANK NAME</Form.Label>
                  <Controller
                    name="bank_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        name={field.name}
                        ref={field.ref}
                        className="text-dark"
                        options={bankOptions}
                        onChange={(option) => {
                          setValue("bank_id", option.value, {
                            shouldValidate: true,
                          });
                        }}
                        // value={field.label}
                        defaultValue={{
                          value: editVendor?.bank?.id || "",
                          label: editVendor?.bank?.name || "",
                        }}
                        placeholder="Select Bank"
                      />
                    )}
                  />

                  {!!errors.bank_id && (
                    <p>
                      <Badge bg="danger">{errors.bank_id?.message}</Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="vendor-speciality mb-4">
                <Form.Group controlId="accountNumber">
                  <Form.Label>SPECIALITY</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Vendor Speciality"
                    {...register("vendor_speciality_id")}
                  />

                  {!!errors.vendor_speciality_id && (
                    <p>
                      <Badge bg="danger">
                        {errors.vendor_speciality_id?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <div className="vendor-office-address mb-4">
              <Form.Group controlId="vendorOfficeAddress">
                <Form.Label className="required">OFFICE ADDRESS</Form.Label>
                <Controller
                  control={control}
                  name="address"
                  render={({ field }) => (
                    <TinyMCETextEditor field={field} setValue={setValue} />
                  )}
                />
                {!!errors.address && (
                  <p>
                    <Badge bg="danger">{errors.address?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <div>
              <Form.Group controlId="formFile" className="mt-3 p-3 border">
                <Form.Label>EVALUATION FORM</Form.Label>
                {!!isFilePicked ? (
                  <div>
                    <p>Filename: {attestationFile.name}</p>
                  </div>
                ) : (
                  <Form.Text className="text-danger">
                    Please upload Vendor Attestation File.
                  </Form.Text>
                )}

                <Form.Control
                  className="text-dark"
                  {...register("evaluationDoc")}
                  type="file"
                  onChange={handleEvaluationDocUpload}
                  accept=".pdf, .jpeg, .png, .jpg"
                />

                {!!errors.evaluationDoc && (
                  <p>
                    <Badge bg="danger">{errors.evaluationDoc?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>
          </Card.Body>
        </Card>

        <Card.Footer className=" d-flex justify-content-center  ">
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              variant="success"
              type="submit"
              className=" d-flex justify-content-center mt-3"
              disabled={!!loading || !isDirty}
            >
              Submit
            </Button>
          )}
        </Card.Footer>
      </Form>
    </>
  );
};
