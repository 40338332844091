import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { permissions } from "../../utils/permissions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";

const ViewVehicle = () => {
  const { get } = useAxios();
  let { vehicleId } = useParams();
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [vehicleDetails, setVehicleDetails] = useState({});

  useEffect(() => {
    setFetching(true);
    const getDetailedVehicle = async () => {
      try {
        const response = await get(`vehicles/${vehicleId}`);
        setVehicleDetails(response.data.vehicle);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedVehicle();
  }, []);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between mt-2 mb-5">
        <Card.Title>
          <Row>
            <Col>
              <h1>Vehicle Details</h1>
            </Col>
          </Row>
        </Card.Title>

        <Row className="ms-1">
          <Col className="mb-2">
            <Button onClick={() => navigate(-1)}>Back</Button>
          </Col>

          <Col className="me-2">
            <DropdownButton
              id="dropdown-basic-button"
              title="ACTION"
              className="justify-content-center"
            >
              {hasPermission(permissions.EDIT_VEHICLE) && (
                <Dropdown.Item
                  as={Link}
                  size="sm"
                  className="justify-content-center"
                  to={`/vehicles/edit/${vehicleId}`}
                >
                  Edit
                </Dropdown.Item>
              )}
            </DropdownButton>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body>
        {!!fetching && <Loading />}

        {!fetching && !!vehicleDetails?.id && (
          <div className="page-wrapper">
            <Row className="d-flex mb-5">
              <Col md={3}>
                <h3 className="pe-5">General Details</h3>
              </Col>

              <Col md={9}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <strong>Make</strong>
                        <p className="border-bottom py-2">
                          {vehicleDetails?.vehicle_model?.vehicle_make?.name ||
                            "N/A"}
                        </p>
                      </Col>

                      <Col>
                        <strong>Model</strong>
                        <p className="border-bottom py-2">
                          {vehicleDetails?.vehicle_model?.name || "N/A"}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <strong>Usage Type</strong>
                        <p className="border-bottom py-2">
                          {vehicleDetails?.usage_type || "N/A"}
                        </p>
                      </Col>

                      <Col>
                        <strong>Status</strong>
                        <p className="border-bottom py-2">
                          {vehicleDetails?.status || "N/A"}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <strong>Location</strong>
                        <p className="border-bottom py-2">
                          {vehicleDetails?.office_location?.name || "N/A"}
                        </p>
                      </Col>

                      <Col>
                        <strong>Purchase Date</strong>
                        <p className="border-bottom py-2">
                          {vehicleDetails?.purchase_date || "N/A"}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <strong>Last Service Date</strong>
                        <p className="border-bottom py-2">
                          {vehicleDetails?.last_service_date || "N/A"}
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="d-flex mb-5">
              <Col md={3}>
                <h3 className="pe-5">Registration</h3>
              </Col>

              <Col md={9}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <strong>Reg. Number</strong>
                        <p className="border-bottom py-2">
                          {vehicleDetails?.reg_number || "N/A"}
                        </p>
                      </Col>

                      <Col>
                        <strong>Date Registered</strong>
                        <p className="border-bottom py-2">
                          {vehicleDetails?.license_reg_date || "N/A"}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <strong>Expiry Date</strong>
                        <p className="border-bottom py-2">
                          {vehicleDetails?.license_expiry_date || "N/A"}
                        </p>
                      </Col>

                      <Col>
                        <strong>Worthiness Expiry Date</strong>
                        <p className="border-bottom py-2">
                          {vehicleDetails?.worthiness_expiry_date || "N/A"}
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="d-flex mb-5">
              <Col md={3}>
                <h3 className="pe-5">Insurance</h3>
              </Col>

              <Col md={9}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <strong>Company</strong>
                        <p className="border-bottom py-2">
                          {vehicleDetails?.reg_number || "N/A"}
                        </p>
                      </Col>

                      <Col>
                        <strong>Type</strong>
                        <p className="border-bottom py-2">
                          {vehicleDetails?.insurance_type || "N/A"}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <strong>Expiry Date</strong>
                        <p className="border-bottom py-2">
                          {vehicleDetails?.insurance_expiry_date || "N/A"}
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="d-flex mb-5">
              <Col md={3}>
                <h3 className="pe-5">Assigned Drivers</h3>
              </Col>

              <Col md={9}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <strong>Primary</strong>
                        <p className="border-bottom py-2">
                          {!!vehicleDetails?.primary_driver
                            ? `${vehicleDetails?.primary_driver?.first_name} ${vehicleDetails?.primary_driver?.surname}`
                            : "NO PRIMARY DRIVER"}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <strong>Secondary</strong>
                        <p className="border-bottom py-2">
                          {!!vehicleDetails?.secondary_driver
                            ? `${vehicleDetails?.primary_driver?.first_name} ${vehicleDetails?.primary_driver?.surname}`
                            : "NO SECONDARY DRIVER"}
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ViewVehicle;
