import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Table,
  Alert,
  Badge,
  Button,
} from "react-bootstrap";
import * as yup from "yup";
import Select from "react-select";
import { Navigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { LoadingButton } from "../LoadingButtons";
import "react-datepicker/dist/react-datepicker.css";
import { FaExclamationCircle } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";
import weekRangeOptions from "../../utils/weekRangeOptions";
import { StatusParametersModal } from "./StatusParametersModal";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import { VehicleStatusCommentModal } from "./VehicleStatusCommentModal";

const schema = yup.object({
  week_number: yup.string().required("Invoice number is required"),
  office_location: yup.string().required("Date of invoice required"),
  reported_by_employee_code: yup.string().required("This field is required"),
  conditions: yup.array().of(
    yup.object({
      vehicle: yup.string(),
      tyre: yup.string(),
      engine: yup.string(),
      battery: yup.string(),
      body: yup.string(),
      gear_grear_oil: yup.string(),
      fire_extinguisher: yup.string(),
      jack_and_spanner: yup.string(),
      spare_tire: yup.string(),
      side_mirrors: yup.string(),
      horn: yup.string(),
      wiper: yup.string(),
      all_lights: yup.string(),
      vehicle_pappers: yup.string(),
      c_caution: yup.string(),
      comment: yup.string(),
    })
  ),
});

export const VehicleStatusReportForm = ({
  vehicles,
  reportedBy,
  officeLocations,
  vehicleStatusParameters,
  editVehicleStatusReport,
}) => {
  const {
    watch,
    control,
    setError,
    setValue,
    handleSubmit,
    formState: { errors, isAllValid },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      week_number: editVehicleStatusReport?.week_number || "",
      office_location: editVehicleStatusReport?.office_location_id || "",
      reported_by_employee_code:
        editVehicleStatusReport?.reported_by_employee_code || "",
      conditions: [],
    },
  });

  const { post } = useAxios();

  const [isSent, setIsSent] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);

  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [allFieldsHaveValue, setAllFieldsHaveValue] = useState(false);
  const [officeLocationOptions, setOfficeLocationOptions] = useState([]);
  const [selectionOptionsReady, setSelectedOptionsReady] = useState(false);
  const [selectedReporterValue, setSelectedReporterValue] = useState(null);
  const [locationReporterOptions, setLocationReporterOptions] = useState([]);

  const [conditionsParamsArray, setConditionsParamsArray] = useState([]);

  const conditionsParameters = {
    tyre: "",
    engine: "",
    battery: "",
    body: "",
    gear_grear_oil: "",
    fire_extinguisher: "",
    jack_and_spanner: "",
    spare_tire: "",
    side_mirrors: "",
    horn: "",
    wiper: "",
    all_lights: "",
    vehicle_pappers: "",
    c_caution: "",
    comment: "",
  };

  const conditionNames = {
    "Tyre Condition": "tyre",
    "Engine Condition": "engine",
    Battery: "battery",
    Body: "body",
    "Gear/Gear Oil": "gear_grear_oil",
    "Fire Extinguisher": "fire_extinguisher",
    "Jack & Spanner": "jack_and_spanner",
    "Spare Tire": "spare_tire",
    "Side Mirrors": "side_mirrors",
    Horn: "horn",
    Wiper: "wiper",
    "All Lights": "all_lights",
    "Vehicle Papers": "vehicle_pappers",
    "C-caution": "c_caution",
    Comment: "comment",
  };

  const { fields } = useFieldArray({
    control,
    name: "conditions",
  });

  const officeLocationOptionSelected = watch("office_location");

  useEffect(() => {
    const _officeLocationOptions = [];

    officeLocations.forEach((location) => {
      _officeLocationOptions.push({
        value: location.id,
        label: location.name,
      });
    });

    if (editVehicleStatusReport?.id) {
      const _conditions = [];
      editVehicleStatusReport.vehicle_statuses.forEach((vehicleStatuse) => {
        let statusJson = JSON.parse(vehicleStatuse.status_json);
        _conditions.push({
          vehicle: vehicleStatuse.vehicle.id,
          branch_id: vehicleStatuse.vehicle.office_location_id,
          reg_number: vehicleStatuse.vehicle.reg_number,
          label: `${vehicleStatuse.vehicle.vehicle_model.vehicle_make.name}${vehicleStatuse.vehicle.vehicle_model.name}`,
          tyre: statusJson["Tyre Condition"] ?? "",
          engine: statusJson["Engine Condition"] ?? "",
          battery: statusJson["Battery"] ?? "",
          body: statusJson["Body"] ?? "",
          gear_grear_oil: statusJson["Gear Grear Oil"] ?? "",
          fire_extinguisher: statusJson["Fire Extinguisher"] ?? "",
          jack_and_spanner: statusJson["Jack & Spanner"] ?? "",
          spare_tire: statusJson["Spare Tire"] ?? "",
          side_mirrors: statusJson["Side Mirrors"] ?? "",
          horn: statusJson["Horn"] ?? "",
          wiper: statusJson["Wiper"] ?? "",
          all_lights: statusJson["All Lights"] ?? "",
          vehicle_pappers: statusJson["Vehicle Papers"] ?? "",
          c_caution: statusJson["C-caution"] ?? "",
          comment: vehicleStatuse["comment"] ?? "",
        });
      });

      setValue("conditions", _conditions);

      setConditionsParamsArray(_conditions);
    }

    setOfficeLocationOptions(_officeLocationOptions);
    setSelectedOptionsReady(true);
  }, []);

  const onHandleVehiclesAndReportersOptions = (
    selectedOfficeLocationOption
  ) => {
    const _vehicleStatusReporters = [];

    reportedBy.forEach((reporter) => {
      if (
        selectedOfficeLocationOption?.value ===
        Number(reporter?.office_location_id)
      ) {
        _vehicleStatusReporters.push({
          value: reporter.employee_code,
          branch_id: reporter.office_location_id,
          label: `${reporter.first_name} ${reporter.surname}`,
        });
      }
    });
    setLocationReporterOptions(_vehicleStatusReporters);

    prepareVehiclesData(selectedOfficeLocationOption?.value);
  };

  const prepareVehiclesData = (selectedOfficeLocationId) => {
    const _vehicleOptions = [];
    vehicles.forEach((vehicle) => {
      if (selectedOfficeLocationId === Number(vehicle?.office_location_id)) {
        _vehicleOptions.push({
          vehicle: vehicle.id,
          branch_id: vehicle.office_location_id,
          reg_number: vehicle.reg_number,
          label: `${vehicle.vehicle_model.vehicle_make.name}${vehicle.vehicle_model.name}`,
          ...conditionsParameters,
        });
      }
    });

    setValue("conditions", _vehicleOptions);

    setVehicleOptions(_vehicleOptions);
  };

  const calculatedWeekRangeOptions = weekRangeOptions();

  const filteredWeekRangeOptions = calculatedWeekRangeOptions.map(
    (weekDetail, index) => ({
      weekNumber: index + 1,
      value: index + 1,
      label: weekDetail,
    })
  );

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const endpointPath = !editVehicleStatusReport?.id
        ? "vehicle_status/store"
        : `vehicle_status/${editVehicleStatusReport?.id}/update`;

      const submitData = { ...data, is_sent: isSent };

      let conditionsObject;

      submitData.conditions.map((condition) => {
        conditionsObject = Object.values(condition).every(
          (statusValue) => statusValue !== ""
        );
      });

      setAllFieldsHaveValue(conditionsObject);

      if (!conditionsObject && !!submitData.is_sent) {
        setLoading(false);
        setIsSaved(false);
        return;
      }

      await post(endpointPath, { ...data, is_sent: isSent });

      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

      alertSuccess("Vehicle status report successfully saved!", null, 5000);
    } catch (error) {
      setLoading(false);
      setIsSaved(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
      if (error?.response?.status === 422) {
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            setLoading(false);
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error?.response?.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to create vehicle status report!",
          null,
          5000
        );
      }
    }
  };

  return (
    <>
      {isSaved && <Navigate to={"/vehicles/status-reports/"} replace={true} />}
      {!!selectionOptionsReady && (
        <>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {hasServerError && (
              <Alert variant="danger">
                <p>
                  Something went wrong will processing your request. Please try
                  again!
                </p>
              </Alert>
            )}

            <Card>
              <Card.Body>
                <Row className="mt-2 mb-4">
                  <Col className="office_location-id mb-3">
                    <Form.Group>
                      <Form.Label className="required">LOCATION</Form.Label>
                      <Controller
                        name="office_location"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            name={field.name}
                            ref={field.ref}
                            options={officeLocationOptions}
                            onChange={(selectedOfficeLocationOption) => {
                              setValue(
                                "office_location",
                                selectedOfficeLocationOption.value,
                                {
                                  shouldValidate: true,
                                }
                              );

                              setSelectedReporterValue(null);

                              onHandleVehiclesAndReportersOptions(
                                selectedOfficeLocationOption
                              );

                              setValue("reported_by_employee_code", "", {
                                shouldValidate: true,
                              });
                            }}
                            defaultValue={
                              !!editVehicleStatusReport?.id
                                ? {
                                    value:
                                      editVehicleStatusReport?.office_location
                                        ?.id,
                                    label: `${editVehicleStatusReport?.office_location?.name}`,
                                  }
                                : null
                            }
                            placeholder={"Select branch"}
                          />
                        )}
                      />

                      {!!errors.office_location && (
                        <p>
                          <Badge bg="danger">
                            {errors.office_location?.message}
                          </Badge>
                        </p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col className="reported-by-employee-code mb-3" md={4}>
                    <Form.Group>
                      <Form.Label className="required">REPORTED BY</Form.Label>
                      <Controller
                        name="reported_by_employee_code"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            name={field.name}
                            ref={field.ref}
                            className="text-dark"
                            options={locationReporterOptions}
                            onChange={(selectedReporterOption) => {
                              setValue(
                                "reported_by_employee_code",
                                selectedReporterOption.value,
                                {
                                  shouldValidate: true,
                                }
                              );
                              setSelectedReporterValue({
                                value: selectedReporterOption.value,
                                label: selectedReporterOption.label,
                              });
                            }}
                            value={
                              !!editVehicleStatusReport?.id
                                ? {
                                    value:
                                      editVehicleStatusReport?.reported_by
                                        ?.employee_code,
                                    label: `${editVehicleStatusReport?.reported_by?.first_name} ${editVehicleStatusReport?.reported_by?.surname}`,
                                  }
                                : selectedReporterValue
                            }
                            placeholder={"Select reporter"}
                          />
                        )}
                      />

                      {!!errors.reported_by_employee_code && (
                        <p>
                          <Badge bg="danger">
                            {errors.reported_by_employee_code?.message}
                          </Badge>
                        </p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col className="week-number mb-3">
                    <Form.Group>
                      <Form.Label className="required">WEEK</Form.Label>
                      <Controller
                        name="week_number"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            name={field.name}
                            ref={field.ref}
                            className="text-dark"
                            options={filteredWeekRangeOptions}
                            onChange={(selectedWeekOption) => {
                              setValue(
                                "week_number",
                                selectedWeekOption.weekNumber,
                                {
                                  shouldValidate: true,
                                }
                              );
                            }}
                            defaultValue={
                              !!editVehicleStatusReport?.week_number
                                ? filteredWeekRangeOptions[
                                    editVehicleStatusReport?.week_number - 1
                                  ]
                                : null
                            }
                            placeholder={"Select one"}
                          />
                        )}
                      />

                      {!!errors?.week_number && (
                        <p>
                          <Badge bg="danger">
                            {errors?.week_number?.message}
                          </Badge>
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                {officeLocationOptionSelected == "" &&
                  !editVehicleStatusReport?.id && (
                    <div className="mt-4 p-2 border text-muted text-center">
                      <span>
                        <FaExclamationCircle size={19} />
                      </span>

                      <span className="fs-2 ms-2 ">Select a location!</span>
                    </div>
                  )}

                {!fields?.length && officeLocationOptionSelected !== "" && (
                  <div className="mt-4 p-2 border text-muted text-center">
                    <span>
                      <FaExclamationCircle size={19} />
                    </span>

                    <span className="fs-2 ms-2 ">No vehicle found!</span>
                  </div>
                )}

                {!allFieldsHaveValue && !!isSent && (
                  <Alert variant="danger">
                    <p>
                      In other to send, you have to enter all values for vehicle
                      conditions!
                    </p>
                  </Alert>
                )}

                {!!fields?.length && (
                  <Table
                    responsive
                    hover
                    bordered
                    className="table table-sm table-bordered m-0"
                  >
                    <thead>
                      <tr className="vertical-header">
                        <th data-no-rotate>VEHICLE</th>

                        {vehicleStatusParameters.map(
                          (statusParameter, index) => (
                            <th key={index}>{statusParameter.name}</th>
                          )
                        )}

                        <th data-no-rotate>COMMENT</th>
                      </tr>
                    </thead>

                    <tbody>
                      {fields.map((field, vehicleIndex) => (
                        <tr key={vehicleIndex}>
                          <td>
                            <span className="text-capitalize">
                              {field?.label}
                            </span>
                            <br />
                            <span>{field?.reg_number}</span>
                          </td>

                          {vehicleStatusParameters.map(
                            (vehicleStatusParameter, paramIndex) => (
                              <td
                                key={vehicleStatusParameter.name}
                                title="Click to change"
                                data-vehicle={vehicleIndex}
                                data-parameter={paramIndex}
                                onClick={(event) => {
                                  const handleConditionValue = (value) => {
                                    setValue(
                                      `conditions.${vehicleIndex}.${
                                        conditionNames[
                                          vehicleStatusParameter.name
                                        ]
                                      }`,
                                      value
                                    );

                                    document.querySelector(
                                      `[data-parameter='${paramIndex}'][data-vehicle='${vehicleIndex}']`
                                    ).innerText = value;
                                  };

                                  dispatchEvent(
                                    new CustomEvent("statusReportParams:add", {
                                      detail: {
                                        handleConditionValue,
                                        paramIndex,
                                        field,
                                        vehicleStatusParameter,
                                      },
                                    })
                                  );
                                }}
                              >
                                {
                                  field[
                                    conditionNames[vehicleStatusParameter.name]
                                  ]
                                }
                              </td>
                            )
                          )}

                          <td
                            title="Click to change"
                            data-vehicle={vehicleIndex}
                            onClick={(event) => {
                              const handleCommentText = (value) => {
                                setValue(
                                  `conditions.${vehicleIndex}.comment`,
                                  value
                                );

                                document.querySelector(
                                  `[data-vehicle='${vehicleIndex}']:not([data-parameter])`
                                ).innerText = value;
                              };
                              dispatchEvent(
                                new CustomEvent("statusReportComment:add", {
                                  detail: {
                                    handleCommentText,
                                    field,
                                  },
                                })
                              );
                            }}
                          >
                            {field?.comment}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Card.Body>
            </Card>

            <Card.Footer className=" d-flex justify-content-center  ">
              {loading ? (
                <LoadingButton />
              ) : (
                <Row>
                  <Col className="me-4">
                    <Button
                      variant="primary"
                      type="submit"
                      data-submit="save"
                      className=" d-flex justify-content-center mt-3"
                      disabled={!!loading}
                      // disabled={!!loading || !isDirty}
                      onClick={(event) => {
                        setIsSent(false);
                        setIsEditable(true);
                      }}
                    >
                      Save as Draft
                    </Button>
                  </Col>

                  <Col>
                    <Button
                      variant="success"
                      type="submit"
                      data-submit="submit"
                      className=" d-flex justify-content-center mt-3"
                      disabled={!!loading || !!isAllValid}
                      onClick={(event) => {
                        setIsSent(true);
                        setIsEditable(false);
                        // handleStatusAlert();
                      }}
                    >
                      Send
                    </Button>
                  </Col>
                </Row>
              )}
            </Card.Footer>
          </Form>

          <StatusParametersModal />
          <VehicleStatusCommentModal />
        </>
      )}
    </>
  );
};
