import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../auth";

export const UserInfo = () => {
  const auth = useContext(AuthContext);

  const handleLogout = (event) => {
    event.preventDefault();
    auth.logout();
  };

  return (
    <div className="nav-item dropdown">
      <a
        href="#"
        className="nav-link d-flex lh-1 text-reset px-0"
        data-bs-toggle="dropdown"
        aria-label="Open user menu"
      >
        <span className="avatar avatar-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="7" r="4" />
            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
          </svg>
        </span>

        <div className="d-none d-xl-block ps-2">
          <div>{`${auth.user.first_name} ${auth.user.surname}`}</div>
          <div className="mt-1 small text-muted">{`${auth?.user?.designation?.name}`}</div>
        </div>
      </a>

      <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
        <Link to="/settings/security" className="dropdown-item">
          Change Password
        </Link>
        <Link to="/settings/profile" className="dropdown-item">
          Profile
        </Link>
        <a href="#" className="dropdown-item" onClick={handleLogout}>
          Logout
        </a>
      </div>
    </div>
  );
};
