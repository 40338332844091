import React from "react";
import { RequireAuth } from "../auth";
import { Loading } from "../Components/Loading";
import { Route, Routes } from "react-router-dom";
import { Error404 } from "../Pages/Errors/Error404";
import EscortScheduleIndex from "../Pages/EscortSchedules/index";
import CreateEscortSchedule from "../Pages/EscortSchedules/create";
import EscortSchedulecalendarView from "../Pages/EscortSchedules/calendarView";
import ViewEscortSchedule from "../Pages/EscortSchedules/view";
import Client from "../Pages/Clients/create";
import AddDriver from "../Components/EscortSchedule/AddDriver";
import AddVehicle from "../Components/EscortSchedule/AddVehicle";
import AddOfficer from "../Components/EscortSchedule/AddOfficer";
import RequestNeeds from "../Pages/EscortSchedules/RequestNeeds";
import EditRequestNeeds from "../Pages/EscortSchedules/editRequestNeeds";


const EscortScheduleRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <EscortScheduleIndex />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="/create"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateEscortSchedule />
              </React.Suspense>
            }
          />

          <Route
            path="/calendar_view"
            element={
              <React.Suspense fallback={<Loading />}>
                <EscortSchedulecalendarView />
              </React.Suspense>
            }
          />

          <Route
            path="view/:scheduleId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewEscortSchedule />
              </React.Suspense>
            }
          />
          <Route
            path="/addClient"
            element={
              <React.Suspense fallback={<Loading />}>
                <Client />
              </React.Suspense>
            }
          />

          <Route
            path="/addDrivers/:scheduleId"
            element={
              <React.Suspense fallback={<Loading />}>
                <AddDriver />
              </React.Suspense>
            }
          />

          <Route
            path="/addVehicles/:scheduleId"
            element={
              <React.Suspense fallback={<Loading />}>
                <AddVehicle />
              </React.Suspense>
            }
          />

          <Route
            path="/addOfficers/:scheduleId"
            element={
              <React.Suspense fallback={<Loading />}>
                <AddOfficer />
              </React.Suspense>
            }
          />
          <Route
            path="/add/officer_need/:scheduleId"
            element={
              <React.Suspense fallback={<Loading />}>
                <RequestNeeds />
              </React.Suspense>
            }
          />
          <Route
            path="/edit/officer_need/:scheduleId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditRequestNeeds />
              </React.Suspense>
            }
          />

          {/* <Route
            path="/approve_reject/:scheduleId"
            element={
              <React.Suspense fallback={<Loading />}>
                <RequestNeedStatusUpdate />
              </React.Suspense>
            }
          /> */}
        </Route>
      </Routes>
    </RequireAuth>
  );
};

export default EscortScheduleRoutes;
