import React, { useState } from "react";
import { Link } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { permissions } from "../../utils/permissions";
import DisregardOrderModal from "./DisregardOrderModal.jsx";
import { handleCustomEvent } from "../../utils/customEventLister";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import { Badge, Dropdown, DropdownButton, Table } from "react-bootstrap";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";

export const DisplayedPurchaseOrderTable = ({
  purchaseOrders,
  getPurchaseOrders,
}) => {
  const { post, get } = useAxios();
  const { hasPermission, isSuperAdmin } = usePermissionAndDomain();

  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [disregardOrder, setDiregardOrder] = useState({});

  const onDisregardOrder = (event, purchaseOrder) => {
    event.preventDefault();
    setDiregardOrder(purchaseOrder);
    handleShow();
  };

  const onCloseOrderClick = (purchaseOrder) => {
    handleCustomEvent("show-confirmation-modal", {
      action: async () => {
        try {
          await post(`purchaseorder/${purchaseOrder.id}/change_status`, {
            status: "closed_order",
          });
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          alertSuccess(
            `Purchase order with PO number ${purchaseOrder.purchase_order_number} successfully closed!`,
            null,
            5000
          );
          getPurchaseOrders();
        } catch (error) {
          alertWarning("Your request failed!", null, 500);
        }
      },
      question: `Are you sure you want to close purchase order with PO number ${purchaseOrder.purchase_order_number}?`,
    });
  };

  const onApproveClick = (purchaseOrder) => {
    handleCustomEvent("show-confirmation-modal", {
      action: async () => {
        try {
          await get(`purchaseorder/${purchaseOrder.id}/approve`);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          alertSuccess(
            `Purchase order with PO number ${purchaseOrder.purchase_order_number} successfully approved!`,
            null,
            5000
          );
          getPurchaseOrders();
        } catch (error) {
          alertWarning("Your request failed!", null, 500);
        }
      },
      question: `Are you sure you want to approve purchase order PO number ${purchaseOrder.purchase_order_number}?`,
    });
  };

  const onRejectClick = (purchaseOrder) => {
    handleCustomEvent("show-confirmation-modal", {
      action: async () => {
        try {
          await get(`purchaseorder/${purchaseOrder.id}/disapprove`);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          alertSuccess(
            `Purchase order with PO number ${purchaseOrder.purchase_order_number} rejected!`,
            null,
            5000
          );
          getPurchaseOrders();
        } catch (error) {
          alertWarning("Your request failed!", null, 500);
        }
      },
      question: `Are you sure you want to reject purchase order PO number ${purchaseOrder.purchase_order_number}?`,
    });
  };

  const paymentAlert = (purchaseOrder) => {
    if (!purchaseOrder?.payment_count_down) {
      return "N/A";
    }

    const dueDate = purchaseOrder.payment_count_down;

    const poStatus = purchaseOrder.status;

    while (poStatus !== "closed_order") {
      if (dueDate === null) {
        return (
          <Badge bg="secondary" className="fs-5 mt-2">
            {"N/A"}
          </Badge>
        );
      } else if (dueDate > "10") {
        return (
          <Badge bg="success" className="fs-5 mt-2">
            {`IN ${dueDate} DAYS` || "N/A"}
          </Badge>
        );
      } else if (dueDate > "0" && "0" <= "10") {
        return (
          <Badge bg="warning" className="fs-5 mt-2">
            {`IN ${dueDate} DAYS OVERDUE` || "N/A"}
          </Badge>
        );
      } else if (dueDate < "0") {
        return (
          <Badge bg="danger" className="fs-5 mt-2">
            {`IN ${dueDate} DAYS OVERDUE` || "N/A"}
          </Badge>
        );
      }
    }
    return (
      <Badge bg="secondary" className="fs-5 mt-2">
        {"N/A"}
      </Badge>
    );
  };

  return (
    <>
      <Table
        responsive
        hover
        bordered
        className=" table-vcenter table-mobile-md card-table"
      >
        <thead>
          <tr>
            <th>PURCHASE ORDER NUMBER</th>
            <th>VENDOR</th>
            <th>KIT ITEM(S)</th>
            <th>TOTAL</th>
            <th>ORDER STATUS</th>
            <th>APPROVAL STATUS</th>
            <th>PAYMENT DUE</th>
            <th>CONTROLS</th>
          </tr>
        </thead>

        <tbody>
          {purchaseOrders.map((purchaseOrder) => (
            <tr key={purchaseOrder.id}>
              <td data-label="PURCHASE ORDER NUMBER">
                {purchaseOrder.purchase_order_number}
              </td>
              <td data-label="VENDOR">{purchaseOrder.vendors.name}</td>
              <td data-label="KIT ITEM(S)">
                <ul>
                  {purchaseOrder.items.map((item) => (
                    <li key={item.id}>{item.kit_item.name}</li>
                  ))}
                </ul>
                {purchaseOrder.items.length > 3 && (
                  <p> +{purchaseOrder.items.length - 3} more items</p>
                )}
              </td>
              <td data-label="TOTAL">
                &#8358; {Number(purchaseOrder.total).toLocaleString()}
              </td>
              <td data-label="ORDER STATUS">
                {" "}
                {`${purchaseOrder.status}`.replace(/_/g, " ")}
              </td>
              <td data-label="APPROVAL STATUS">
                {" "}
                {purchaseOrder.approval_status}
              </td>
              <td data-label="PAYMENT DUE">{paymentAlert(purchaseOrder)}</td>
              <td className="d-flex mt-3">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  {hasPermission(permissions.VIEW_SINGLE_PURCHASE_ORDER) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/purchase-order/view/${purchaseOrder.id}`}
                    >
                      View
                    </Dropdown.Item>
                  )}

                  {!!(
                    purchaseOrder?.status === "issued" &&
                    purchaseOrder?.approval_status === "initialize"
                  ) && (
                    <>
                      {hasPermission(permissions.EDIT_PURCHASE_ORDER) && (
                        <Dropdown.Item
                          as={Link}
                          size="sm"
                          className="justify-content-center"
                          to={`/purchase-order/edit/${purchaseOrder.id}`}
                        >
                          Edit
                        </Dropdown.Item>
                      )}

                      <>
                        {purchaseOrder?.approval_status === "initialize" && (
                          <>
                            {hasPermission(
                              permissions.APPROVAL_OF_PURCHASE_ORDER
                            ) && (
                              <>
                                <Dropdown.Item
                                  as={Link}
                                  size="sm"
                                  to={"#"}
                                  className="justify-content-center"
                                  onClick={() => onApproveClick(purchaseOrder)}
                                >
                                  Approve
                                </Dropdown.Item>

                                <Dropdown.Item
                                  as={Link}
                                  size="sm"
                                  to={"#"}
                                  className="justify-content-center"
                                  onClick={() => onRejectClick(purchaseOrder)}
                                >
                                  Reject
                                </Dropdown.Item>
                              </>
                            )}
                          </>
                        )}
                      </>
                    </>
                  )}

                  {hasPermission(permissions.RECORD_PURCHASE_ORDER_SUPPLY) && (
                    <>
                      {(purchaseOrder?.status === "issued" ||
                        purchaseOrder?.status === "partial_delivery") &&
                        purchaseOrder?.approval_status === "approved" && (
                          <Dropdown.Item
                            as={Link}
                            size="sm"
                            className="justify-content-center"
                            to={`/purchase-order/supply_record/${purchaseOrder.id}`}
                          >
                            Supply Record
                          </Dropdown.Item>
                        )}
                    </>
                  )}

                  {hasPermission(permissions.CLOSE_PURCHASE_ORDER) && (
                    <>
                      {(purchaseOrder?.status === "disregard" ||
                        purchaseOrder?.status === "delivery_complete") && (
                        <Dropdown.Item
                          as={Link}
                          size="sm"
                          to={`#`}
                          className="justify-content-center"
                          onClick={() => onCloseOrderClick(purchaseOrder)}
                        >
                          Close Order
                        </Dropdown.Item>
                      )}
                    </>
                  )}

                  {isSuperAdmin() && (
                    <>
                      {(purchaseOrder?.status === "issued" ||
                        purchaseOrder?.status === "partial_delivery") &&
                        purchaseOrder?.approval_status === "approved" && (
                          <Dropdown.Item
                            as={Link}
                            size="sm"
                            to={`#`}
                            className="justify-content-center"
                            onClick={(event) =>
                              onDisregardOrder(event, purchaseOrder)
                            }
                          >
                            Disregard Order
                          </Dropdown.Item>
                        )}
                    </>
                  )}
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <DisregardOrderModal
        disregardOrder={disregardOrder}
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        onDisregardOrderSuccess={() => {
          getPurchaseOrders();
          handleClose();
        }}
      />
    </>
  );
};
