import React, { useState } from "react";
import * as yup from "yup";
import useAxios from "../../hooks/useAxios";
import { LoadingButton } from "../LoadingButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Navigate, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { Row, Col, Form, Badge, Button } from "react-bootstrap";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object({
  status: yup.string(),
  client_id: yup.string(),
  client_location_id: yup.string(),
  items: yup.array().of(
    yup.object({
      item_id: yup.string(),
      quantity: yup.string().required("Quantity is required"),
    })
  ),
});

const ApprovalWithAdjustmentForm = ({
  client,
  status,
  kitItems,
  clientLocation,
}) => {
  const { post } = useAxios();
  let { guardKitsRequestId } = useParams();
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const {
    control,
    setValue,
    register,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      status: status,
      client_id: client?.name,
      client_location_id: clientLocation?.name,
      items: kitItems,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "items",
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const itemsArray = data.items;
      const itemsData = itemsArray.map((itemProperty) => {
        return {
          item_id: itemProperty.kit_item_id,
          approved_quantity: itemProperty.quantity,
        };
      });

      const guardKitsRequestData = {
        items: itemsData,
        status: "approve_with_adjustment",
      };

      await post(
        `uniformrequest/${guardKitsRequestId}/editStatus`,
        guardKitsRequestData
      );

      setIsSaved(true);

      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Guard kit request successfully approved!", null, 5000);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 422) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry, something went wrong and your request was unsuccessful!",
          null,
          5000
        );
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error.response.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to approve guard kit request!",
          null,
          5000
        );
      }
    }
  };

  return (
    <>
      {isSaved && (
        <Navigate
          to={`/guardkits-request/view/${guardKitsRequestId}`}
          replace={true}
        />
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="clientDetail mt-4 mb-4 border">
          <Form.Label className="mt-2 p-2">CLIENT DETAILS</Form.Label>
          <Row className="d-flex justify-content-between ms-0 me-0 mb-2 p-2 ">
            <Col className="mb-3" md={6}>
              <Form.Group controlId="client ">
                <Form.Label>NAME</Form.Label>
                <Form.Control
                  name="client_id"
                  disabled
                  type="text"
                  defaultValue={client?.name}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="client-location ">
                <Form.Label>LOCATION</Form.Label>
                <Form.Control
                  name="client_location_id"
                  disabled
                  type="text"
                  defaultValue={clientLocation?.name}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div className="kitItemDetail mt-4 mb-4 border">
          <Form.Label className="mt-2 p-2">GUARD KIT ITEM DETAILS</Form.Label>
          {fields.map((field, index) => (
            <Row
              key={field.id}
              className="d-flex justify-content-between ms-0 me-0 mb-1 p-2 "
            >
              <Col className="kit-item mb-3" md={9}>
                <Form.Group controlId="kitItem ">
                  <Form.Label>KIT ITEM (Select one below)</Form.Label>
                  <Form.Control
                    name={`items.${index}.item_id`}
                    disabled
                    type="text"
                    defaultValue={`${field?.guard_kit?.name}`}
                  />
                </Form.Group>
              </Col>

              <Col className="kit-item-quantity mb-3" md={3}>
                <Form.Label className="required">QUANTITY</Form.Label>
                <Form.Control
                  key={index}
                  type="number"
                  min={1}
                  placeholder="Enter quantity needed"
                  {...register(`items.${index}.quantity`)}
                  onChange={(event) => {
                    setValue(`items.${index}.quantity`, event.target.value, {
                      shouldValidate: true,
                    });
                  }}
                />

                {!!errors.quantity && (
                  <p>
                    <Badge bg="danger">
                      {errors.items?.[index].quantity?.message}
                    </Badge>
                  </p>
                )}
              </Col>

              <hr className="mt-4" />
            </Row>
          ))}
        </div>

        <div className="submit col-md-12 text-center mb-3">
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              type="submit"
              variant="success"
              disabled={!!loading || !isDirty}
            >
              Submit
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default ApprovalWithAdjustmentForm;
