import React from "react";
import { Link } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { alertWarning } from "../../CustomAlerts/AlertToast";
import {
  Button,
} from "react-bootstrap";
import { handleCustomEvent } from "../../../utils/customEventLister";
import { usePermissionAndDomain } from "../../../hooks/usePermissionAndDomain";

export const DisplayedPermissionsAndDomainsTable = ({
  permissions,
  getPermissionsAndDomains,
}) => {
  const { axiosDelete } = useAxios();

  const onDeleteClick = (permission) => {
    handleCustomEvent("show-confirmation-modal", {
      action: async () => {
        try {
          await axiosDelete(`permissions/delete/${permission.id}`);
          getPermissionsAndDomains();
        } catch (error) {
          alertWarning("Your request failed!", null, 500);
        }
      },
      question: `Are you sure you want to delete the permission: "${permission.name.replace(
        /[^a-z ]/gi,
        " "
      )}"?`,
    });
  };

  return (
    <>
      {Object.keys(permissions).map((domain) => (
        <div key={domain} className="mb-4">
          <strong className="d-block text-uppercase">{domain}</strong>
          <div className="lh-lg">
            {permissions[domain].map((permission) => (
              <span
                key={permission.id}
                className="badge me-2 px-2"
                style={{ backgroundColor: "#caeefaba" }}
              >
                <Link
                  to={`/permissions/edit/${permission.id}`}
                  className="me-2 fs-3 fw-normal"
                  title="click to edit"
                >
                  {permission.name.replace(/[^a-z ]/gi, " ")}
                </Link>{" "}
                {!permission?.roles?.length && (
                  <Button
                    size="sm"
                    variant="primary"
                    title="click to delete"
                    className="rounded-circle px-1 py-0"
                    onClick={() => onDeleteClick(permission)}
                  >
                    &times;
                  </Button>
                )}
              </span>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};
