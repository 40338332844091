import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Card, Col, Row } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { permissions } from "../../utils/permissions";
import { Link, useSearchParams } from "react-router-dom";
import ReactPagination from "../../Components/ReactPagination";
import { alertWarning } from "../../Components/CustomAlerts/AlertToast";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { DisplayedGuardKitItems } from "../../Components/GuardKitItems/DisplayedGuardKitItems";

const GuardKitItemsIndex = () => {
  const { get } = useAxios();
  const [fetching, setFetching] = useState(true);
  const [guardKitItems, setGuardKitItems] = useState([]);
  const [searchPageParams, setSearchPageParams] = useSearchParams({});
  const { hasPermission} = usePermissionAndDomain();

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;

  const getGuardKitItems = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;

      const response = await get(`guardkit?sort=desc&page=${currentPage}&page_size=${pageSize}
      `
      );
      setGuardKitItems(response.data.guardKit);
    } catch (error) {
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    setFetching(true);
    const guardKits = async () => await getGuardKitItems();
    guardKits();
  }, [currentPage]);

  const pageChangeHandler = (page) => {
    setSearchPageParams(
      {
        ...Object.fromEntries(searchPageParams),
        page,
      },
      { replace: true }
    );
  };

  return (
    <>
      {!!fetching && <Loading />}

      <Card className="container d-flex justify-content-center ms-3">
        <Card.Header className="d-flex justify-content-between mt-2 mb-2">
          <Card.Title>
            <Row>
              <Col md="auto">
                <h2 className="page-title">Guard Kit Items</h2>
              </Col>
            </Row>
          </Card.Title>

          {hasPermission(permissions.CREATE_GUARD_KIT_ITEM) && (
            <Row>
              <Col md="auto">
                <Link to="/guardkit/create" className="btn btn-primary">
                  Create Kit Item
                </Link>
              </Col>
            </Row>
          )}
        </Card.Header>

        <Card.Body>
          {!fetching && !!guardKitItems?.data?.length === 0 && (
            <div> No Guard Kit Items Found</div>
          )}

          {!fetching && !!guardKitItems?.data?.length && (
            <Row>
              <Col className="p-0">
                <>
                  <DisplayedGuardKitItems
                    guardKitItems={guardKitItems.data}
                    getGuardKitItems={getGuardKitItems}
                  />

                  <br />

                  <ReactPagination
                    links={guardKitItems.links}
                    onPageChange={pageChangeHandler}
                    current_page={guardKitItems.current_page}
                    last_page={guardKitItems.last_page}
                  />
                </>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default GuardKitItemsIndex;
