import React, { useState } from "react";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DataTable } from "../DisplayTable/DataTable";
import { Button} from "react-bootstrap";
import { PageCount } from "../DisplayTable/PageCount";

export const ClientsLocationsDataTable = ({ clientsLocations }) => {
  const columns = [
    {
      id: "name",
      header: "CLIENT LOCATION",
      cell: ({ row }) => {
        const clientsLocation = row.original;
        return (
          <>
            {!!clientsLocation?.name 
            ? `${clientsLocation?.name}`
            : "N/A"}
          </>
        );
      },
    },

    {
      id: "guards_num",
      header: "NUMBER OF GUARDS",
      cell: ({ row }) => {
        const clientsLocation = row.original;
        return (
          <>
            {!!clientsLocation?.guards_num 
            ? `${clientsLocation?.guards_num}`
            : "N/A"}
          </>
        );
      },
    },

    {
      id: "kit_allocation_date",
      header: "KIT ALLOCATION DATE",
      cell: ({ row }) => {
        const clientsLocation = row.original;
        return (
          <>
            {!!clientsLocation?.kit_allocation_date 
            ? `${clientsLocation?.kit_allocation_date}`
            : "N/A"}
          </>
        );
      },
    },

    {
      id: "last_kit_allocation_date",
      header: "LAST KIT ALLOCATION DATE",
      cell: ({ row }) => {
        const clientsLocation = row.original;
        return (
          <>
            {!!clientsLocation?.last_kit_allocation_date 
            ? `${clientsLocation?.last_kit_allocation_date}`
            : "N/A"}
          </>
        );
      },
    },

    {
      id: "next_kit_allocation_date",
      header: "NEXT KIT ALLOCATION DATE",
      cell: ({ row }) => {
        const clientsLocation = row.original;
        return (
          <>
            {!!clientsLocation?.next_kit_allocation_date 
            ? `${clientsLocation?.next_kit_allocation_date}`
            : "N/A"}
          </>
        );
      },
    },
  ];

  const data = clientsLocations;

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters,
      globalFilter,
    },
  });

  return (
    <div className="rounded-md border">
      <div>
        <div className="flex items-center py-4 ms-4 me-4 ">
          <input
            placeholder="Filter input..."
            value={globalFilter ?? ""}
            onChange={(event) => {
              return setGlobalFilter(event.target.value);
            }}
            className="max-w-sm form-control"
          />
        </div>
      </div>

      <DataTable data={data} columns={columns} table={table} />

      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            table.previousPage();
          }}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            table.nextPage();
          }}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>

        <PageCount table={table} />
      </div>
    </div>
  );
};
