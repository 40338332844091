import React, { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import useAxios from "../../hooks/useAxios";
import { LoadingButton } from "../LoadingButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Badge, Form, Button, Modal, Row, Col } from "react-bootstrap";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object({
  status: yup.string(),
  change_reason: yup.string().max(5000).required("Why the status change?"),
  confirmation_response: yup
    .string()
    .max(5000)
    .required("Why the status change?"),
});

const DisregardOrderModal = ({
  show,
  disregardOrder,
  handleClose,
  onDisregardOrderSuccess,
}) => {
  const {
    watch,
    register,
    setValue,
    unregister,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      status: "disregard_order",
      change_reason: "",
      confirmation_response: "",
    },
  });

  const { post } = useAxios();
  const [loading, setLoading] = useState(false);

  const showDisregardOrderReason = watch("confirmation_response");

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const disregardOrderData = {
        change_reason: data.change_reason,
        status: "disregard_order",
      };
      const response = await post(
        `purchaseorder/${disregardOrder.id}/change_status`,
        disregardOrderData
      );

      if (undefined !== onDisregardOrderSuccess) {
        onDisregardOrderSuccess(response.data);
      }
      handleClose();
      alertSuccess("Purchase order successfully closed!", null, 5000);
    } catch (error) {
      setLoading(false);
      alertWarning(
        "Something went wrong with the server. Please Try Again!",
        null,
        5000
      );
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        id="modal-danger"
        className="modal modal-blur fade"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <div className="text-center py-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon mb-2 text-danger icon-lg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 9v2m0 4v.01" />
              <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
            </svg>
            {/* <h3>Are you sure?</h3> */}
          </div>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="text-muted mt-4 p-3 border">
              <Form.Group className="text-center mt-3">
                Are you sure you want to disregard purchase order with PO number{" "}
                {disregardOrder.purchase_order_number}?
                <Row className="mt-4 mb-2">
                  {["radio"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Col>
                        <Form.Check
                          inline
                          label="YES"
                          value="yes"
                          type={type}
                          id={`inline-${type}-YES`}
                          onClick={() => unregister("change_reason")}
                          {...register("confirmation_response")}
                        />
                      </Col>

                      <Col>
                        <Form.Check
                          inline
                          label="NO"
                          value="no"
                          type={type}
                          id={`inline-${type}-NO`}
                          {...register("confirmation_response")}
                          onClick={() => handleClose()}
                        />
                      </Col>
                    </div>
                  ))}
                </Row>
              </Form.Group>

              {showDisregardOrderReason === "yes" && (
                <Form.Group className="mb-3">
                  <Form.Label className="required"> REASON</Form.Label>
                  <Form.Text>
                    What is the reason for closing this purchase order
                  </Form.Text>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Reason"
                    {...register("change_reason")}
                    onChange={(event) => {
                      setValue("change_reason", event.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  />

                  {!!errors.change_reason && (
                    <p>
                      <Badge className="mt-1" bg="danger">
                        {errors.change_reason?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              )}
            </div>

            {showDisregardOrderReason === "yes" && (
              <div className="d-flex justify-content-center mt-3">
                {loading ? (
                  <LoadingButton />
                ) : (
                  <Button
                    variant="primary"
                    type="submit"
                    className=" d-flex justify-content-center mt-3"
                    disabled={!!loading || !isDirty}
                  >
                    Disregard Order
                  </Button>
                )}
              </div>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DisregardOrderModal;
