import { format } from "date-fns";
import React from "react";
import { ListGroup } from "react-bootstrap";

export const NotificationsListingTable = ({notifications, selectedNotification, setSelectedNotification}) => {
    const activeItemClicked = (notification) => {
      setSelectedNotification(notification)
    }

    return (
          <ListGroup variant="flush" style={{ height: 350, overflowY: 'auto'}} >
            {notifications.map((notification) => (
              <ListGroup.Item 
                key={notification.id} 
                // className="d-flex justify-content-between align-items-start m-0 ps-0 pe-0" 
                action 
                className={selectedNotification?.id === notification?.id ? "d-flex justify-content-between align-items-start m-0 ps-0 pe-0 bg-light" : "d-flex justify-content-between align-items-start m-0 ps-0 pe-0" }
                onClick={(event) =>
                  activeItemClicked(notification)
              }>
                <div title={notification?.subject}>
                  <div className="fw-bold text-nowrap d-inline-block text-truncate" style={{width: 200}}>{notification?.subject}</div>
                  <p className="text-muted">
                    {format(
                        new Date(notification?.created_at),
                        "dd-MM-yyyy HH:mm",
                        {
                          awareOfUnicodeTokens: true,
                        }
                    )}

                  </p>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>

           

        
      );
}

