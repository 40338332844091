import React, { useState } from "react";
import * as yup from "yup";
import { Navigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { LoadingButton } from "../LoadingButtons";
import TinyMCETextEditor from "../TinyMCETextEditor";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, Form, Badge, Button } from "react-bootstrap";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object({
  class: yup.string().required("Class is required"),
  title: yup.string().required("Description is required"),
  description: yup.string().max(5000),
});

export const NotificationCreationForm = ({
  notificationClass,
  editNotification,
}) => {
  const { post } = useAxios();
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const {
    register,
    setError,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      class: editNotification?.class || "",
      title: editNotification?.title || "",
      description: editNotification?.description || "",
    },
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const notificationData = !editNotification?.id
        ? data
        : {
            title: data.title,
            description: data.description,
          };

      const endpointPath = !editNotification?.id
        ? "admin_notification/store"
        : `admin_notification/${editNotification.id}/update`;

      await post(endpointPath, notificationData);

      setIsSaved(true);

      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Notification successfully saved!", null, 5000);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 422) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry, something went wrong and your request was unsuccessful!",
          null,
          5000
        );
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error.response.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to create Notification!",
          null,
          5000
        );
      }
    }
  };

  const formatTitle = (notificationClass) => {
    // get the last index of the forward slash
    const lastIndexOfSlash = notificationClass.lastIndexOf("\\");
    //get substring
    return notificationClass
      .substring(lastIndexOfSlash + 1)
      .replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  return (
    <>
      {isSaved && (
        <Navigate
          to={
            !editNotification?.id
              ? "/notifications"
              : `/notifications/view/${editNotification?.id}`
          }
          replace={true}
        />
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row} className="mt-3 mb-3 " controlId="formPassword">
          <Form.Label className="required">CLASS</Form.Label>
          <Col>
            <Form.Select
              className="form-control"
              {...register("class")}
              defaultValue={editNotification?.class || ""}
            >
              <option value="">Select Notification Class</option>
              {notificationClass.map((notification) => (
                <option key={notification} value={notification}>
                  {formatTitle(notification)}
                </option>
              ))}
            </Form.Select>

            {!!errors.class && (
              <p className="justify-content-end mt-1">
                <Badge bg="danger" className="fs-6 fw-normal">
                  {errors.class?.message}
                </Badge>
              </p>
            )}
          </Col>
        </Form.Group>

        <Form.Group className="notification-title mb-4" controlId="title">
          <Form.Label className="required">TITLE</Form.Label>
          <Form.Control
            type="text"
            placeholder="Notification Title"
            {...register("title")}
            defaultValue={editNotification?.title}
          />

          {!!errors.title && (
            <p>
              <Badge bg="danger">{errors.title?.message}</Badge>
            </p>
          )}
        </Form.Group>

        <Form.Group controlId="notificationDescription">
          <Form.Label>DESCRIPTION</Form.Label>
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <TinyMCETextEditor field={field} setValue={setValue} />
            )}
            defaultValue={editNotification?.description}
          />

          {!!errors.description && (
            <p>
              <Badge bg="danger">{errors.description?.message}</Badge>
            </p>
          )}
        </Form.Group>

        <div className=" d-flex justify-content-center mt-3 mb-3  ">
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              data-testid="bs-btn "
              variant="success"
              type="submit"
              className=""
              disabled={!!loading || !isDirty}
            >
              Submit
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};
