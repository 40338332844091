import React, { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import useAxios from "../../hooks/useAxios";
import { LoadingButton } from "../LoadingButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Badge, Form, Button, Modal } from "react-bootstrap";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object({
  status: yup.string().required("You have not updated status!"),
  file: yup
    .mixed()
    .notRequired()
    .test("type    ", "All uploaded file must be pdf or image.", (value) => {
      if (value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          return (
            value[i].type === "application/pdf" ||
            value[i].type === "image/jpeg" ||
            value[i].type === "image/png" ||
            value[i].type === "image/jpg"
          );
        }
      } else if (!value.length) {
        return true;
      }
    })
    .notRequired(),
  change_reason: yup.string().max(5000).required("Why the status change?"),
});

const StatusModal = ({
  show,
  statuses,
  handleClose,
  onUpdateSuccess,
  scheduleStatusChange,
}) => {
  const {
    setError,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      status: "",
      file: "",
      change_reason: "",
    },
  });

  const { post } = useAxios();
  const [loading, setLoading] = useState(false);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [clientRemarkFile, setClientRemarkFile] = useState(null);

  const handleClientRemarkFileChange = (event) => {
    setClientRemarkFile(event.currentTarget.files[0]);
    setIsFilePicked(true);
  };


  const onSubmit = async (data) => {
    try {
      const response = await post(
        `escorts/editStatus/${scheduleStatusChange.id}`,
        {
          ...data,
          file: clientRemarkFile,
        }
      );
      setLoading(true);
      if (undefined !== onUpdateSuccess) {
        onUpdateSuccess(response.data);
      }
      handleClose();
      alertSuccess("Schedule status successfully updated!", null, 5000);
    } catch (error) {
      setLoading(false);
      setError("file", {
        type: "custom",
        shouldFocus: true,
        message: "Please upload the client remark file",
      });
      if (error.response.status === 422) {
      }
      if (error.response.status === 500) {
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="modal modal-blur fade"
      >
        <Modal.Header closeButton>
          <Modal.Title>UPDATE ESCORT STATUS</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <Form.Select {...register("status")}>
                <option value="">-- Select Status --</option>
                {Object.keys(statuses).map((key) => (
                  <option key={key} value={key}>
                    {statuses[key]}
                  </option>
                ))}
              </Form.Select>

              {!!errors.status && (
                <p>
                  <Badge className="mt-1" bg="danger">
                    {errors.status?.message}
                  </Badge>
                </p>
              )}
            </Form.Group>

            {/* {showFileInput === "completed" && (
              <>
                <Form.Group controlId="formFile" className="mt-4 p-3 border">
                  <Form.Label className="required">CLIENT REMARK</Form.Label>
                  {!!isFilePicked ? (
                    <div>
                      <p>Filename: {clientRemarkFile.name}</p>
                    </div>
                  ) : (
                    <Form.Text>Please upload Client Remark Document.</Form.Text>
                  )}
                  <Form.Control
                    className="text-dark"
                    {...register("file")}
                    type="file"
                    onChange={handleClientRemarkFileChange}
                  />

                  {!!errors.file && (
                    <p>
                      <Badge className="mt-1" bg="danger">
                        {errors.file?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </>
            )} */}

            {/* {(showFileInput === "disregard" ||
              showFileInput === "suspended") && (
              <>
                <Form.Group controlId="formFile" className="mt-4 p-3 border">
                  <Form.Label className="required">CLIENT REQUEST PROOF</Form.Label>
                  {!!isFilePicked ? (
                    <div>
                      <p>Filename: {clientRemarkFile.name}</p>
                    </div>
                  ) : (
                    <Form.Text>Please upload Client Remark Document.</Form.Text>
                  )}
                  <Form.Control
                    className="text-dark"
                    {...register("file")}
                    type="file"
                    onChange={handleClientRemarkFileChange}
                  />

                  {!!errors.file && (
                    <p>
                      <Badge className="mt-1" bg="danger">
                        {errors.file?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </>
            )} */}

            <Form.Group className="mt-4 p-3 border">
              <Form.Label className="required"> REASON</Form.Label>
              <Form.Text>
                What is the reason for updating this escort schedule
              </Form.Text>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Reason"
                {...register("change_reason")}
                onChange={(event) => {
                  setValue("change_reason", event.target.value, {
                    shouldValidate: true,
                  });
                }}
              />

              {!!errors.change_reason && (
                <p>
                  <Badge className="mt-1" bg="danger">
                    {errors.change_reason?.message}
                  </Badge>
                </p>
              )}
            </Form.Group>

            <div className="d-flex justify-content-center mt-3">
              {loading ? (
                <LoadingButton />
              ) : (
                <Button
                  variant="primary"
                  type="submit"
                  className=" d-flex justify-content-center mt-3"
                  disabled={!!loading || !isDirty}
                >
                  Update
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StatusModal;
