import React from 'react';
import { FiDownload } from "react-icons/fi";
import { FilterSidebar } from './FilterSidebar';
import ReactPagination from '../ReactPagination';
import { Card, Col, Row,} from 'react-bootstrap';
import { StatsComponent } from './StatsComponent';
import { ComplaintsDataTable } from './ComplaintsDataTable';
import { FaExclamationCircle } from 'react-icons/fa';
import { format } from 'date-fns';

export const FilteredLayer = ({
    rootCauses,
    officeBranches,
    complaintStatuses,
    complaintCategories,

    salaryComplaints,
    pageChangeHandler,
    getSalaryComplaints,
    salaryComplaintsData,

    initialSearchFilterInputs,
    salaryComplaintIndexsData,
    onhandleFilteredSearchChange,

    statusParams,
    branchParams,
    stateDateParams,
    endDateParams,
    searchKeyParams,
    rootCauseParams,
    complaintCategoryParams
    
}) => {

    return (
        <div className="row">
            <div className="col col-sm-5 col-md-3" id="sidebar-wrapper"> 
                <Card>
                    <Card.Body>
                        <FilterSidebar
                            rootCauses={rootCauses}
                            statusParams={statusParams}
                            statusStats={salaryComplaintIndexsData.statusStats}
                            officeBranches={officeBranches}
                            complaintStatuses={complaintStatuses}
                            complaintCategories={complaintCategories}
                            salaryComplaintsData={salaryComplaintsData}
                            initialSearchFilterInputs={initialSearchFilterInputs}

                            onhandleFilteredSearchChange={onhandleFilteredSearchChange}
                        />
                    </Card.Body>
                </Card>
            </div>

            <div className="col col-sm-7 col-md-9" id="page-content-wrapper">
                { !salaryComplaints?.length && (
                    <div className="mt-5 p-2 text-muted text-center">
                        <span>
                            <FaExclamationCircle size={19} />
                        </span>

                        <span className="fs-2 ms-2 ">
                            No Salary Complaint Found!
                        </span>
                    </div>
                )}

                {(!!(
                    statusParams ||
                    branchParams ||
                    stateDateParams ||
                    endDateParams ||
                    searchKeyParams ||
                    rootCauseParams ||
                    complaintCategoryParams
                ) && !!salaryComplaints?.length) && (
                    <>
                        <div className="mb-5 fs-3 fw-bold">{format(new Date(salaryComplaintIndexsData?.startDate), "MMM d, yyyy", {awareOfUnicodeTokens: true})} - {format(new Date(salaryComplaintIndexsData?.endDate), "MMM d, yyyy", {awareOfUnicodeTokens: true})}
                        </div>

                        <StatsComponent salaryComplaintIndexsData={salaryComplaintIndexsData}/>
                        
                        <>
                            <p className="text-start ps-3 text-muted">Page {salaryComplaintsData.current_page} of {salaryComplaintsData.last_page}</p>

                            <Row>
                                <Col>
                                    <ComplaintsDataTable
                                    salaryComplaints={salaryComplaints}
                                    getSalaryComplaints={getSalaryComplaints}
                                    />

                                    <br />

                                    <ReactPagination
                                    links={salaryComplaintsData.links}
                                    onPageChange={pageChangeHandler}
                                    current_page={salaryComplaintsData.current_page}
                                    last_page={salaryComplaintsData.last_page}
                                    />
                                </Col>
                            </Row>
                        </>
                    </>
                )}
            </div> 
        </div>
    )
}
