import React from "react";
import { AiFillCar } from "react-icons/ai";
import { GiReceiveMoney } from "react-icons/gi";
import { FaPlaneArrival } from "react-icons/fa";
import { BsFillPinMapFill } from "react-icons/bs";
import { FaPlaneDeparture } from "react-icons/fa";

const colorCodedTypes = {
  "Pickup/Dropoff": {
    icon: <AiFillCar />,
    color: "#1aa179",
  },

  "Pin Down": {
    icon: <BsFillPinMapFill />,
    color: "#087990",
  },

  "Airport Dropoff": {
    icon: <FaPlaneDeparture />,
    color: "#052c65",
  },

  "Airport Pickup": {
    icon: <FaPlaneArrival />,
    color: "#997404",
  },

  "Trade Visit": {
    icon: <GiReceiveMoney />,
    color: "#b02a37",
  },
};

export default colorCodedTypes;
