import React from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Card} from "react-bootstrap";

const ScheduleDeTailModal = ({ show, scheduleDetail, handleClose }) => {

  return (
    <Modal
      id="scheduleDeTailModal"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      role="dialog"
      aria-hidden="true"
      className="modal fade"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Schedule Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center py-4">
        <Row className="mb-3">
          <Col>
            <label className="fw-bolder fs-3">CLIENT</label>
          </Col>
          <Col>
            <Card.Text className="fs-3">
              {scheduleDetail?.client?.name}
            </Card.Text>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <label className="fw-bolder fs-3">ESCORT DATE</label>
          </Col>
          <Col>
            <Card.Text className="fs-3">{scheduleDetail.escort_date}</Card.Text>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <label className="fw-bolder fs-3">ESCORT TIME</label>
          </Col>
          <Col>{scheduleDetail.escort_time}</Col>
          <Card.Text className="fs-3"></Card.Text>
        </Row>

        <Row className="mb-3">
          <Col>
            <label className="fw-bolder fs-3">PICKUP POINT</label>
          </Col>
          <Col>
            <Card.Text className="fs-3">
              {scheduleDetail.pickup_point}
            </Card.Text>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <label className="fw-bolder fs-3">DESTINATION</label>
          </Col>
          <Col>
            <Card.Text className="fs-3">{scheduleDetail.destination}</Card.Text>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <label className="fw-bolder fs-3">ESCORT TYPE</label>
          </Col>
          <Col>
            <Card.Text className="fs-3">{scheduleDetail.escort_type}</Card.Text>
          </Col>
        </Row>

        <Row>
          <Col>
            <label className="fw-bolder fs-3">ESCORT STATUS</label>
          </Col>
          <Col>
            <Card.Text className="fs-3">
              {scheduleDetail.escort_status}
            </Card.Text>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ScheduleDeTailModal;
