import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

export const IncidentFilterSearch = ({
  onhandleFilteredSearchChange,
  initialSearchFilterInputs,
  types,
}) => {

  const [searchFilterInputs, setSearchFilterInputs] = useState({
    type: initialSearchFilterInputs.type ?? "",
    to: initialSearchFilterInputs.to ?? "",
    from: initialSearchFilterInputs.from ?? "",
  });



  return (
    <>
      <Form
        className="border mt-0 mb-4 p-3"
        method="GET"
        onSubmit={(event) => {
          event.preventDefault();
          onhandleFilteredSearchChange(searchFilterInputs);
        }}
      >
        <Row>
          <Col></Col>
        </Row>

        <Row className="mb-3">
          <Col className="py-2 mx-1">
            <Form.Label>TYPE</Form.Label>
            <Form.Select
              name="type"
              className="form-control"
              defaultValue={searchFilterInputs.type}
              onChange={(event) =>
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  type: event.target.value,
                })
              }
            >
              <option value="" disabled>
                Select Type
              </option>
              {types.map((incidentType) => (

                <option value={incidentType} key={incidentType}>
                    {incidentType}
                </option>
              )
              )}
            </Form.Select>
          </Col>
          
          <Col className="py-2 mx-1">
            <Form.Label>FROM</Form.Label>
            <Form.Control
              type="date"
              name="from"
              className="form-control"
              placeholder="Pick a Date"
              defaultValue={searchFilterInputs.from}
              onChange={(event) =>
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  from: event.target.value,
                })
              }
            />
          </Col>

          <Col className="py-2 mx-1">
            <Form.Label>TO</Form.Label>
            <Form.Control
              type="date"
              name="to"
              className="form-control"
              placeholder="Pick a Date"
              defaultValue={searchFilterInputs.to}
              onChange={(event) =>
                setSearchFilterInputs({
                  ...searchFilterInputs,
                  to: event.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Button variant="secondary" className="me-3" type="submit">
          Filter
        </Button>
      </Form>
    </>
  );
};
