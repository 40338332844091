import React, { useState } from 'react'
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, FormGroup, Row } from 'react-bootstrap';

export const ComplaintFilterSearch = ({
    complaintStatuses,
    initialSearchFilterInputs,
    onhandleFilteredSearchChange,
}) => {
    const [searchFilterInputs, setSearchFilterInputs] = useState({
        status: initialSearchFilterInputs?.status ?? "",
        name: initialSearchFilterInputs?.name ?? "",
        start: initialSearchFilterInputs?.start ?? "",
        end: initialSearchFilterInputs?.end ?? "",
    });

    const {
        control,
        setValue,
    } = useForm({
        mode: "onBlur",
        defaultValues: {
            mode: "onBlur",
            start: initialSearchFilterInputs?.start ?? "",
            end: initialSearchFilterInputs?.end ?? "",
        },
    })

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());


    return (
        <>
            <Form
                style={{
                    zoom: "90%"
                }}
                className="mt-0 p-3"
                method="GET"
                onSubmit={(event) => {
                event.preventDefault();
                onhandleFilteredSearchChange(searchFilterInputs);
                }}
            >
                <Row className="mb-3">
                    <Col className="py-2 mx-1" sm={6} md={3}>
                        <Form.Control
                            type="text"
                            autoComplete="off"
                            placeholder="Enter Employee/Name Code"
                            defaultValue={searchFilterInputs.name}
                            onChange={(event) =>
                            setSearchFilterInputs({
                                ...searchFilterInputs,
                                name: event.target.value,
                            })
                            }
                        />
                    </Col>

                    <Col className="py-2 mx-1">
                        <Form.Select
                        name="status"
                        className="form-control"
                        defaultValue={searchFilterInputs.status}
                        onChange={(event) =>
                            setSearchFilterInputs({
                            ...searchFilterInputs,
                            status: event.target.value,
                            })
                        }
                        >
                        <option value="">All Status</option>{" "}
                        {Object.keys(complaintStatuses).map((statusIndex) => (
                            <option value={statusIndex} key={statusIndex}>
                            {statusIndex}
                            </option>
                        ))}
                        </Form.Select>
                    </Col>

                    <Col className="py-2 mx-1" sm={6} md={3}>
                        <FormGroup controlId="startDate">
                            <Controller
                                name="start"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        isClearable
                                        strictParsing
                                        showYearDropdown
                                        showMonthDropdown
                                        autoComplete="off"
                                        maxDate={new Date()}
                                        closeOnScroll={true}
                                        dropdownMode="select"
                                        selected={startDate}
                                        dateFormat="dd-MM-yyyy"
                                        className="form-control"
                                        onChange={(date) => {
                                            setValue("start", date, {
                                                shouldValidate: true,
                                            });
                                            setStartDate(date);

                                            setSearchFilterInputs({
                                                ...searchFilterInputs,
                                                start: format(date, 'yyyy-MM-dd'),
                                            })
                                        }}
                                        placeholderText="Start Date"
                                    />
                                )}
                            />
                        </FormGroup>
                    </Col>

                    <Col className="py-2 mx-1" >
                        <FormGroup controlId="endDate">
                            <Controller
                                name="end"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        isClearable
                                        strictParsing
                                        showYearDropdown
                                        showMonthDropdown
                                        autoComplete="off"
                                        maxDate={new Date()}
                                        closeOnScroll={true}
                                        dropdownMode="select"
                                        selected={endDate}
                                        dateFormat="dd-MM-yyyy"
                                        className="form-control"
                                        onChange={(date) => {
                                            setValue("end", date, {
                                                shouldValidate: true,
                                            });
                                            setEndDate(date);

                                            setSearchFilterInputs({
                                                ...searchFilterInputs,
                                                end: format(date, 'yyyy-MM-dd')
                                            })
                                        }}
                                        placeholderText="End Date"
                                    />
                                )}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Button variant="secondary" type="submit" size="sm" className="ms-2 fs-4">Filter</Button>
            </Form>
        </>
    )
}
