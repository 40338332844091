import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../Components/Loading";
import { PurchaseOrderForm } from "../../Components/PurchaseOrder/PurchaseOrderForm";

const CreatePurchaseOrder = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  const [vendors, setVendors] = useState({});
  const [fetching, setFetching] = useState(true);
  const [guardKitItems, setGuardKitItems] = useState({});

  useEffect(() => {
    setFetching(true);
    const getPurchaseOrderDetails = async () => {
      try {
        const response = await get(`purchaseorder/create`);
        setVendors(response.data.vendors);
        setGuardKitItems(response.data.items);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getPurchaseOrderDetails();
  }, []);

  return (
    <>
      {!!fetching ? (
        <Loading />
      ) : (
        <Card className="container">
          <Card.Header className="d-flex justify-content-between mt-0 mb-5">
            <Card.Title>
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">Create Purchase Order</h1>
                </div>
              </div>
            </Card.Title>

            <div className="row">
              <div className="col-md-12">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <PurchaseOrderForm
              guardKitItems={guardKitItems}
              vendors={vendors}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default CreatePurchaseOrder;
