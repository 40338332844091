import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../Components/Loading";
import { useGetIncidentCreateData } from "../../hooks/useGetIncidentCreateData";
import { ShortIncidentReportForm } from "../../Components/IncidentReports/ShortIncidentReportForm";

const CreateShortIncidentReport = () => {

    const navigate = useNavigate();

    const { clients, fetching, fetchIncidentClientsData } =
      useGetIncidentCreateData();

    useEffect(() => {
      const getClientsDetail = async () => {
        fetchIncidentClientsData();
      };

      getClientsDetail();
    }, []);

  return (
    <>
      {fetching ? (
        <Loading />
      ) : (
        <Card className="container">
          <Card.Header className="d-flex justify-content-between mt-0 mb-5">
            <Card.Title>
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">Create Incident Report</h1>
                </div>
              </div>
            </Card.Title>

            <div className="row">
              <div className="col-md-12">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <ShortIncidentReportForm clients={clients} />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default CreateShortIncidentReport;
