import React from "react";
import { RequireAuth } from "../auth";
import { Loading } from "../Components/Loading";
import { Route, Routes } from "react-router-dom";
import { Error404 } from "../Pages/Errors/Error404";
import EditIncidentReport from "../Pages/IncidentReports/edit";
import IncidentReportView from "../Pages/IncidentReports/view";
import IncidentReportIndex from "../Pages/IncidentReports/Index";
import CreateFullIncidentReport from "../Pages/IncidentReports/createFull";
import CreateShortIncidentReport from "../Pages/IncidentReports/createShort";
import IncidentReportComment from "../Pages/IncidentReports/addComment";



const IncidentReportRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <IncidentReportIndex />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="/create-full-report"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateFullIncidentReport />
              </React.Suspense>
            }
          />

          <Route
            path="/create-short-report"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateShortIncidentReport />
              </React.Suspense>
            }
          />

          <Route
            path="/view/:reportId"
            element={
              <React.Suspense fallback={<Loading />}>
                <IncidentReportView />
              </React.Suspense>
            }
          />

          <Route
            path="/edit/:reportId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditIncidentReport />
              </React.Suspense>
            }
          />

          <Route
            path="/add-comment/:reportId"
            element={
              <React.Suspense fallback={<Loading />}>
                <IncidentReportComment />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
};

export default IncidentReportRoutes;
