import React, { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { LoadingButton } from "../LoadingButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Badge, Button, Card, Form } from "react-bootstrap";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object({
  name: yup.string().required("Client name is required"),
  quantity_unit: yup.string(),
  allocation_quantity: yup.string(),
});

export const GuardKitItemForm = ({ editGuardKitItem }) => {
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      name: editGuardKitItem?.name || "",
      quantity_unit: editGuardKitItem?.quantity_unit || "",
      benchmark_quantity: editGuardKitItem?.benchmark || "",
      allocation_quantity: editGuardKitItem?.allocation_quantity || "",
    },
  });

  const { post } = useAxios();
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const guardKitItemData = {
        name: data.name,
        quantity_unit: data.quantity_unit,
        allocation_quantity: data.allocation_quantity,
        benchmark: data.benchmark_quantity,
      };

      const endpointPath = !editGuardKitItem?.id
        ? `guardkit/store`
        : `guardkit/${editGuardKitItem.id}/update`;

      await post(endpointPath, guardKitItemData);

      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Guard Kit Item has been successfully saved!", null, 5000);
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 422) {
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error?.response?.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to create guard kit!",
          null,
          5000
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isSaved && <Navigate to={"/guardkit"} replace={true} />}
      <Form onSubmit={handleSubmit(onSubmit)}>
        {hasServerError && (
          <Alert variant="danger">
            <p>
              Something went wrong will processing your request. Please try
              again!
            </p>
          </Alert>
        )}
        <Card className="creation-authorization">
          <Card.Body>
            <div className="client-name mb-4">
              <Form.Group controlId="clientName">
                <Form.Label className="required">NAME</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  {...register("name")}
                />

                {!!errors.name && (
                  <p>
                    <Badge bg="danger">{errors.name?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <div className="quantityUnit mb-4">
              <Form.Group controlId="quantity_unit">
                <Form.Label className="required">
                  UNIT FOR QUANTIFYING
                </Form.Label>
                <Form.Text>This can be pair, unit, set, etc.</Form.Text>
                <Form.Control
                  type="text"
                  placeholder="Unit"
                  {...register("quantity_unit")}
                />
                {!!errors.quantity_unit && (
                  <p>
                    <Badge bg="danger">{errors.quantity_unit?.message}</Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <div className="quantityAllocation mb-4">
              <Form.Group controlId="allocation_quantity">
                <Form.Label className="required">QUANTITY PER GUARD</Form.Label>
                <Form.Text>
                  Number of this kit item that should be allocated per guard,
                  quantified in the unit stated above.
                </Form.Text>
                <Form.Control
                  type="text"
                  placeholder="Quantity per guard"
                  {...register("allocation_quantity")}
                />
                {!!errors.allocation_quantity && (
                  <p>
                    <Badge bg="danger">
                      {errors.allocation_quantity?.message}
                    </Badge>
                  </p>
                )}
              </Form.Group>
            </div>

            <div className="benchmarkQuantity">
              <Form.Group controlId="restock_quantity">
                <Form.Label className="required">LOW STOCK QUANTITY</Form.Label>
                <Form.Text className="text-danger">
                  The quantity this items reaches in the inventory that requires
                  immediate restock
                </Form.Text>
                <Form.Control
                  type="text"
                  placeholder="Bench-mark for restocking  item"
                  {...register("benchmark_quantity")}
                />
                {!!errors.benchmark_quantity && (
                  <p>
                    <Badge bg="danger">
                      {errors.benchmark_quantity?.message}
                    </Badge>
                  </p>
                )}
              </Form.Group>
            </div>
          </Card.Body>
        </Card>

        <Card.Footer className=" d-flex justify-content-center  ">
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              variant="success"
              type="submit"
              className=" d-flex justify-content-center mt-3"
              disabled={!!loading || !isDirty}
            >
              Submit
            </Button>
          )}
        </Card.Footer>
      </Form>
    </>
  );
};
