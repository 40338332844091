import React, {useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  Dropdown,
  ListGroup,
  DropdownButton,
} from "react-bootstrap";
import { format } from "date-fns";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { Link, useNavigate, useParams } from "react-router-dom";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { permissions } from "../../utils/permissions";

const ViewGuardKitsRequest = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { guardKitsRequestId } = useParams();
  const [client, setClient] = useState({});
  const [kitItems, setKitItems] = useState({});
  const [createdBy, setCreatedBy] = useState({});
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [clientLocation, setClientLocation] = useState({});
  const [guardKitsRequest, setGuardKitsRequest] = useState({});
  const [disbursementHistory, setDisbursementHistory] = useState({});

  useEffect(() => {
    setFetching(true);
    const getDetailedGuardKitsRequest = async () => {
      try {
        const response = await get(`uniformrequest/${guardKitsRequestId}`);
        setKitItems(response.data.items);
        setClient(response.data.clients);
        setGuardKitsRequest(response.data);
        setCreatedBy(response.data.created_by);
        setClientLocation(response.data.client_locations);
        setDisbursementHistory(response.data.kit_disbursement);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };

    getDetailedGuardKitsRequest();
  }, []);

  return (
    <>
      {!!fetching && <Loading />}

      {!!guardKitsRequest?.id && !fetching && (
        <Card>
          <Card.Header className="d-flex justify-content-between mt-2 mb-5">
            <Card.Title>
              <Row>
                <Col>
                  <h1>Kits Request Details</h1>
                </Col>
              </Row>
            </Card.Title>

            <Row>
              <Col className="mb-2">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </Col>

              <Col className="me-4">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  {hasPermission(permissions.EDIT_KIT_REQUEST) &&
                    guardKitsRequest?.status === "awaiting_approval" && (
                      <Dropdown.Item
                        as={Link}
                        size="sm"
                        className="justify-content-center"
                        to={`/guardkits-request/edit/${guardKitsRequest.id}`}
                      >
                        Edit
                      </Dropdown.Item>
                    )}

                  {hasPermission(permissions.APPROVAL_OF_KIT_REQUEST) &&
                    guardKitsRequest?.status === "awaiting_approval" && (
                      <Dropdown.Item
                        as={Link}
                        size="sm"
                        className="justify-content-center"
                        to={`/guardkits-request/status_update/${guardKitsRequest.id}`}
                      >
                        Approve/Reject
                      </Dropdown.Item>
                    )}

                  {hasPermission(permissions.DISBURSE_KIT_ITEMS) &&
                    (guardKitsRequest?.status === "approve_with_adjustment" ||
                      guardKitsRequest?.status === "approved" ||
                      guardKitsRequest?.status === "partially_disbursed") && (
                      <Dropdown.Item
                        as={Link}
                        to={`/guardkits-request/disburse/${guardKitsRequest.id}`}
                        size="sm"
                        className="justify-content-center"
                      >
                        Disburse
                      </Dropdown.Item>
                    )}

                  {guardKitsRequest?.status === "completely_disbursed" && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      to={`#`}
                      className="justify-content-center"
                      // onClick={() => onCompletedClick(kitsRequest)}
                    >
                      Received
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body className="mb-4">
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <label className="fw-bolder fs-3 ">CLIENT DETAILS</label>

                <Row className="mb-3">
                  <Col className="mt-3 mb-3" md={6}>
                    <label className="fw-bold fs-3">NAME</label>
                    <Card.Text className="fs-3">
                      {client.name || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col className="mt-3" md={6}>
                    <label className="fw-bold fs-3">LOCATION</label>
                    <Card.Text className="fs-3">
                      {clientLocation.name || "N/A"}
                    </Card.Text>
                  </Col>
                </Row>

                <Row className="mt-3 mb-3">
                  <Col>
                    <label className="fw-bold fs-3 ">NUMBER OF OPERATIVE</label>
                    <Card.Text className="fs-3">
                      {clientLocation.guards_num || "N/A"}
                    </Card.Text>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col className="mt-3" md={6}>
                    <label className="fw-bold fs-3">
                      LAST KIT ALLOCATION DATE
                    </label>
                    <Card.Text className="fs-3">
                      {clientLocation.last_kit_allocation_date || "N/A"}
                    </Card.Text>
                  </Col>
                  <Col className="mt-3" md={6}>
                    <label className="fw-bold fs-3">
                      NEXT KIT ALLOCATION DATE
                    </label>
                    <Card.Text className="fs-3">
                      {clientLocation.next_kit_allocation_date || "N/A"}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3">REQUEST WAS CREATED BY</label>
                <Card.Text className="fs-3">
                  {`${createdBy.first_name} ${createdBy.surname}`}
                </Card.Text>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3 mt-3">REQUEST STATUS</label>

                <Row className="mb-3">
                  <Col className="mt-3" md={6}>
                    <label className="fw-bold fs-3">STATUS</label>
                    <Card.Text className="fs-3">
                      {`${guardKitsRequest.status}`.replace(/_/g, " ") ||
                        "N/A"}
                    </Card.Text>
                  </Col>

                  {guardKitsRequest.status === "rejected" && (
                    <Col className="mt-3" md={6}>
                      <label className="fw-bold fs-3">REASON</label>
                      <Card.Text className="fs-3">
                        {guardKitsRequest.reject_reason}
                      </Card.Text>
                    </Col>
                  )}
                </Row>
              </ListGroup.Item>

              <ListGroup.Item className="mt-3">
                <label className="fw-bolder fs-3 ">
                  GUARD KIT ITEMS DETAILS
                </label>
                <Table
                  responsive
                  hover
                  bordered
                  className=" table-vcenter table-mobile-md card-table mt-3"
                >
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>QUANTITY REQUESTED</th>
                      <th>QUANTITY APPROVED</th>
                      <th>QUANTITY DISBURSED</th>
                    </tr>
                  </thead>

                  <tbody>
                    {kitItems.map((kitItem) => (
                      <tr key={kitItem.id}>
                        <td data-label="NAME">
                          {kitItem.guard_kit?.name || "N/A"}
                        </td>

                        <td data-label="QUANTITY REQUESTED">
                          {kitItem.quantity}
                        </td>
                        <td data-label="QUANTITY APPROVED">
                          {kitItem.approved_quantity || "N/A"}
                        </td>
                        <td data-label="QUANTITY DISBURSED">
                          {kitItem.quantity_disbursed || "N/A"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ListGroup.Item>

              <ListGroup.Item className="mt-3">
                <label className="fw-bolder fs-3 ">DISBURSEMENT HISTORY</label>
                {!disbursementHistory?.length && (
                  <p>NO HISTORY OF DISBURSEMENT FOR THIS ITEM</p>
                )}
                {!!disbursementHistory?.length && (
                  <Table
                    responsive
                    hover
                    bordered
                    className=" table-vcenter table-mobile-md card-table mt-3"
                  >
                    <thead>
                      <tr>
                        <th>DISBURSED DATE</th>
                        <th>DISBURSER</th>
                        <th>COLLECTOR</th>
                        <th>ITEMS AND QUANTITY DISBURSED</th>
                      </tr>
                    </thead>

                    <tbody>
                      {disbursementHistory.map((history) => (
                        <tr key={history.id}>
                          <td data-label="DISBURSED DATE">
                            {history.date_created || "N/A"}
                          </td>
                          <td data-label="DISBURSER">
                            {`${history.disbursed_by.first_name} ${history.disbursed_by.surname}` ||
                              "N/A"}
                          </td>
                          <td data-label="COLLECTOR">
                            {`${history.receive_by.first_name} ${history.receive_by.surname}` ||
                              "N/A"}
                          </td>
                          <td data-label="ITEMS AND QUANTITY">
                            <ul>
                              {history.stock_transaction.map(
                                (itemTransaction) => (
                                  <li key={itemTransaction.id}>
                                    {`${itemTransaction.guard_kit.name} (${itemTransaction.quantity})` ||
                                      "N/A"}
                                  </li>
                                )
                              )}
                            </ul>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>

          <Card.Footer>
            <ListGroup className="list-group-flush">
              <ListGroup.Item className="d-flex justify-content-end">
                <Row>
                  <Col className="mb-3">
                    <label className="fw-bolder fs-3">CREATED</label>
                    <Card.Text className="fs-3">
                      {format(
                        new Date(guardKitsRequest.created_at),
                        "EEE, MMM d, yyyy 'at' HH:mm:ss",
                        {
                          awareOfUnicodeTokens: true,
                        }
                      ) || "N/A"}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item className="d-flex justify-content-end">
                <Row>
                  <Col>
                    <label className="fw-bolder fs-3">LAST UPDATED</label>
                    <Card.Text className="fs-3">
                      {format(
                        new Date(guardKitsRequest.updated_at),
                        "EEE, MMM d, yyyy 'at' HH:mm:ss",
                        {
                          awareOfUnicodeTokens: true,
                        }
                      ) || "N/A"}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card.Footer>
        </Card>
      )}
    </>
  );
};

export default ViewGuardKitsRequest;
