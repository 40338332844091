import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { VehiclePartForm } from "../../../Components/Vehicles/Parts/VehiclePartForm ";

const CreateVehiclePart = () => {
  const navigate = useNavigate();

  return (
    <>
      <Card className="container">
        <Card.Header className="d-flex justify-content-between mt-0 mb-5">
          <Card.Title>
            <div className="row">
              <div className="col-md-12">
                <h1 className="page-title">New Vehicle Part</h1>
              </div>
            </div>
          </Card.Title>

          <div className="row">
            <div className="col-md-12">
              <Button onClick={() => navigate(-1)}>Back</Button>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <VehiclePartForm />
        </Card.Body>
      </Card>
    </>
  );
};

export default CreateVehiclePart;
