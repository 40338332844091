import React, { useState } from "react";
import { Link } from "react-router-dom";
import { permissions } from "../../utils/permissions";
import StatusModal from "../Modals/StatusModal";
import DeleteModal from "../CustomAlerts/DeleteModal";
import { Table, Dropdown, DropdownButton } from "react-bootstrap";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";

export const DisplayedSchedules = ({
  escortSchedules,
  statuses,
  getEscortSchedules,
}) => {
  const { hasPermission } = usePermissionAndDomain();

  const [show, setShow] = useState(false);
  const [scheduleStatusChange, setScheduleStatusChange] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onStatusChangeClick = (event, schedule) => {
    event.preventDefault();
    handleShow();
    setScheduleStatusChange(schedule);
  };

  const needRequestEndpoint = (escortSchedule) => {
    return !escortSchedule?.escortscheduleofficerneeds?.length
      ? `/escort-schedules/add/officer_need/${escortSchedule.id}`
      : `/escort-schedules/edit/officer_need/${escortSchedule.id}`;
  };

  return (
    <>
      <Table
        responsive
        hover
        bordered
        className=" table-vcenter table-mobile-md card-table"
      >
        <thead>
          <tr>
            <th>CLIENT NAME</th>
            <th>PICKUP POINT</th>
            <th>ESCORT DATE</th>
            <th>ESCORT TIME</th>
            <th>DESTINATION</th>
            <th>ESCORT TYPE</th>
            <th>REQUEST DATE</th>
            <th>ESCORT STATUS</th>
            <th>CONTROLS</th>
          </tr>
        </thead>

        <tbody>
          {escortSchedules.map((escortSchedule) => (
            <tr key={escortSchedule?.id}>
              <td data-label="CLIENT NAME">{escortSchedule?.client?.name}</td>
              <td data-label="PICKUP POINT">{escortSchedule?.pickup_point}</td>
              <td data-label="ESCORT DATE" className="bg-success">
                {escortSchedule?.escort_date}
              </td>
              <td data-label="ESCORT TIME">{escortSchedule?.escort_time}</td>
              <td data-label="DESTINATION">{escortSchedule?.destination}</td>
              <td data-label="ESCORT TYPE">{escortSchedule?.escort_type}</td>
              <td data-label="REQUEST DATE">{escortSchedule?.request_date}</td>
              <td data-label="ESCORT STATUS">
                {escortSchedule?.escort_status}
              </td>
              <td className="d-flex mt-1">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  {hasPermission(permissions.VIEW_ALL_ESCORT_SCHEDULES) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/escort-schedules/view/${escortSchedule.id}`}
                    >
                      View
                    </Dropdown.Item>
                  )}
                  {hasPermission(permissions.UPDTAE_AN_ESCORT_STATUS) && (
                    <>
                      <Dropdown.Item
                        as={Link}
                        size="sm"
                        to="#"
                        className="justify-content-center"
                        onClick={(event) =>
                          onStatusChangeClick(event, escortSchedule)
                        }
                      >
                        Update Status
                      </Dropdown.Item>
                    </>
                  )}
                  {hasPermission(
                    permissions.ASSIGN_DRIVER_TO_AN_ESCORT_SCHEDULE
                  ) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/escort-schedules/addDrivers/${escortSchedule.id}`}
                    >
                      {!!escortSchedule?.drivers?.length
                        ? "Update Driver"
                        : "Assign Driver"}
                    </Dropdown.Item>
                  )}
                  {hasPermission(
                    permissions.ASSIGN_VEHICLE_TO_AN_ESCORT_SCHEDULE
                  ) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/escort-schedules/addVehicles/${escortSchedule.id}`}
                    >
                      {!!escortSchedule?.drivers?.length
                        ? "Update Vehicle"
                        : "Assign Vehicle"}
                    </Dropdown.Item>
                  )}
                  {hasPermission(
                    permissions.ASSIGN_OFFICERS_TO_AN_ESCORT_SCHEDULE
                  ) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/escort-schedules/addOfficers/${escortSchedule.id}`}
                    >
                      {!!Object.keys(escortSchedule.officers)?.length
                        ? "Update Officer"
                        : "Assign Officer"}
                    </Dropdown.Item>
                  )}

                  {hasPermission(
                    permissions.MAKE_REQUEST_FOR_ESCORT_SCHEDULE_NEEDS
                  ) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={needRequestEndpoint(escortSchedule)}
                    >
                      {!escortSchedule?.escortscheduleofficerneeds?.length
                        ? "Needs Request"
                        : "Edit Needs Request"}
                    </Dropdown.Item>
                  )}

                  {/* {hasPermission(
                    permissions.APPROVE_AN_ESCORT_SCHEDULE_NEED_REQUEST
                  ) 
                      <Dropdown.Item
                        as={Link}
                        size="sm"
                        className="justify-content-center"
                        to={`/escort-schedules/approve_reject/${escortSchedule.id}`}
                      >
                        Approve/Reject Needs Request
                      </Dropdown.Item>
                    } */}
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <DeleteModal />

      <StatusModal
        scheduleStatusChange={scheduleStatusChange}
        statuses={statuses}
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        onUpdateSuccess={() => {
          handleClose();
          window.location.reload();
        }}
      />
    </>
  );
};
