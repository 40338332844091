import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Col, Row } from "react-bootstrap";

const ConfirmationModal = () => {
  const [show, setShow] = useState(false);
  const [question, setQuestion] = useState("");
  const [yesListener, setYesListener] = useState({
  });

  useEffect(() => {

    document.addEventListener("show-confirmation-modal", (event) => {
      if (event?.detail?.action) {
        setYesListener({
          action: () => event.detail.action(),
        });
      }

      if (event?.detail?.question) {
        setQuestion(event.detail.question);
      }

      setShow(true);
    });
  }, []);

  return (
    <Modal
      id="confirmationModal"
      show={show}
      onHide={() => {
        setShow(false);
      }}
      backdrop="static"
      keyboard={false}
      role="dialog"
      aria-hidden="true"
      className="modal modal-blur fade"
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirmation Required</Modal.Title>
      </Modal.Header>

      <Modal.Body className="text-center py-4">
        {question}
        <Row className="mt-4">
          <Col>
            <button
              className="btn"
              onClick={() => {
                setShow(false);
                yesListener.action();
              }}
            >
              Yes
            </button>
          </Col>
          <Col>
            <Button
              id="confirmationModalYesButton"
              onClick={() => setShow(false)}
            >
              No
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
