import React from 'react'
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { useEffect, useState } from "react";
import {useParams, useNavigate } from "react-router-dom";
import { Error404 } from '../Errors/Error404';
import prepareUserDomainAndPermissionsMap from '../../hooks/prepareUserDomainAndPermissionsMap';

const ViewFromMail = () => {
  const [fetching, setFetching] = useState(true);
  const [showError, setShowError] = useState(false);
  const [fromMailData, setFromMailData] = useState();
  const navigate = useNavigate()
  const { get } = useAxios();
  let {loginToken} = useParams();

  const notificationTypes = {
    "newIncident": "NOTIFICATION_TYPE_NEW_INCIDENT",
  }
  


  useEffect(() => {
    const getIncidentReport = async () => {
      try {
        if (!loginToken) {
          setShowError(false)
          return 
        }

        const response = await get(
          `verify-mail-token/${loginToken}`
        );

        if (!!response.data.user && !!response.data.token && !!response.data.type ) {
          sessionStorage.setItem("access_token", response.data.token);

          sessionStorage.setItem("user", JSON.stringify(response.data.user));
  
          const userDomainsAndPermissions = prepareUserDomainAndPermissionsMap(
            response?.data?.user
          );
  
          sessionStorage.setItem(
            "userPermissions",
            JSON.stringify(userDomainsAndPermissions.permissions)
          );
  
          sessionStorage.setItem(
            "userDomains",
            JSON.stringify(userDomainsAndPermissions.domains)
          );

          
          if (response.data.type === notificationTypes.newIncident) {
            return navigate(`/incident-reports/view/${response.data.incident_id}`)
          }

        }


        setFromMailData(response)

        
      } catch (error) {
          setShowError(true)
          setFetching(false);
          sessionStorage.removeItem(loginToken);
      } finally {
        setFetching(false);
      }
    };
    getIncidentReport();
  }, [loginToken]);


  return (
    <>
      {!!fetching && <Loading />}

      {!fetching && !!showError && (
        <Error404 />
      )}
    </>
  )
}

export default ViewFromMail