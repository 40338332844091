import React from "react";
import { RequireAuth } from "../auth";
import { Loading } from "../Components/Loading";
import { Route, Routes } from "react-router-dom";
import { Error404 } from "../Pages/Errors/Error404";
import VehicleIndex from "../Pages/Vehicles/index";
import ViewVehicle from "../Pages/Vehicles/view";
import EditVehicle from "../Pages/Vehicles/edit";
import VehicleTable from "../Pages/Vehicles/table";
import CreateVehicle from "../Pages/Vehicles/create";



export const VehicleRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <VehicleIndex />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="view/:vehicleId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewVehicle />
              </React.Suspense>
            }
          />

          <Route
            path="/table"
            element={
              <React.Suspense fallback={<Loading />}>
                <VehicleTable />
              </React.Suspense>
            }
          />

          <Route
            path="/create"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateVehicle />
              </React.Suspense>
            }
          />

          <Route
            path="edit/:vehicleId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditVehicle />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
};
