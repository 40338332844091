import React, { useState } from "react";
import * as yup from "yup";
import { format } from "date-fns";
import Select from "react-select";
import useAxios from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "../LoadingButtons";
import TinyMCETextEditor from "../TinyMCETextEditor";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import { Badge, Button, Col, Form, FormGroup, Row } from "react-bootstrap";

const schema = yup.object({
  month: yup.string().required("Month is required"),
  week: yup.string().required("Week is required"),
  date: yup.string().nullable().required("Date is required"),
  operation_report: yup
    .string()
    .max(10000, "Operation report can not be more than 10000 characters")
    .required("Operation report is required"),
  personnel_welfare: yup
    .string()
    .max(5000)
    .required("Personnel welfare is required"),
  health_safety: yup
    .string()
    .max(5000)
    .required("Health and safety is required"),
  issues_employment_letter: yup.string().required("Please select one"),
  employment_reason: yup.string().max(5000),
  verified: yup.string().required("Please select one"),
  verified_reason: yup.string().max(5000),
  in_payroll: yup.string().required("Please select one"),
  payroll_reason: yup.string().max(5000),
  branch_office_state: yup
    .string()
    .max(5000)
    .required("Branch office status is required"),
  branch_office_personnel_well_being: yup
    .string()
    .max(5000)
    .required("Branch office personnel wellbeing is required"),
  logistics_report: yup
    .string()
    .max(5000)
    .required("Logistics reportis required"),
  client_feedback_report: yup
    .string()
    .max(5000)
    .required("Client feedback report is required"),
  finance_report: yup.string().max(5000).required("Finance report is required"),
  dogs_report: yup.string().max(5000).required("Dogs report is required"),
  plan_for_next_week: yup
    .string()
    .max(5000)
    .required("Plan for next week is required"),
  decision_action: yup
    .string()
    .max(5000)
    .required("Decision action is required"),
});

export const WeeklyReportForm = ({ months, weeks }) => {
  const {
    watch,
    control,
    register,
    setValue,
    unregister,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      month: "",
      week: "",
      date: "",
      decision_action: "",
      finance_report: "",
      health_safety: "",
      in_payroll: "",
      issues_employment_letter: "",
      operation_report: "",
      personnel_welfare: "",
      employment_reason: "",
      verified: "",
      payroll_reason: "",
      branch_office_state: "",
      branch_office_personnel_well_being: "",
      logistics_report: "",
      client_feedback_report: "",
      dogs_report: "",
      plan_for_next_week: "",
      verified_reason: "",
    },
  });

  const { post } = useAxios();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const today = new Date().toISOString().slice(0, 10);

  const showVerifiedReason = watch("verified");
  const showPayrollReason = watch("in_payroll");
  const showEmploymentReason = watch("issues_employment_letter");

  const [date, setDate] = useState(
    format(new Date(), "yyyy-MM-dd", { awareOfUnicodeTokens: true })
  );

  const monthOptions = months.map((month) => ({
    value: month,
    label: month,
  }));

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await post(`weekly_reports/store`, {
        ...data,
      });

      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Weekly report successfully saved!", null, 5000);

      navigate("/weekly-reports");
    } catch (error) {
      setLoading(false);

      if (error?.response?.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to create weekly report!",
          null,
          5000
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="py-1 mb-3">
        <Col className="mb-3" md={4}>
          <FormGroup>
            <Form.Label className="required">MONTH</Form.Label>
            <Controller
              name="month"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  className="text-dark"
                  options={monthOptions}
                  onChange={(event) => {
                    setValue("month", event.value, {
                      shouldValidate: true,
                    });
                  }}
                  value={field.label}
                  placeholder="Select month"
                />
              )}
            />

            {!!errors.month && (
              <p>
                <Badge bg="danger">{errors.month?.message}</Badge>
              </p>
            )}
          </FormGroup>
        </Col>

        <Col className="mb-3" md={4}>
          <FormGroup>
            <Form.Label className="required">WEEK</Form.Label>
            <Form.Select {...register("week")}>
              <option value="" disabled>
                Select Week
              </option>
              {weeks.map((week) => (
                <option key={week} value={week}>
                  {week}
                </option>
              ))}
            </Form.Select>

            {!!errors.week && (
              <p>
                <Badge bg="danger">{errors.week?.message}</Badge>
              </p>
            )}
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Form.Label className="required">DATE</Form.Label>
            <Form.Control
              type="date"
              max={today}
              placeholder="Enter date"
              {...register("date")}
              value={date}
              onChange={(event) => setDate(event.target.value)}
            />

            {!!errors.date && (
              <p>
                <Badge bg="danger">{errors.date?.message}</Badge>
              </p>
            )}
          </FormGroup>
        </Col>
      </Row>

      <div className="mb-4">
        <FormGroup>
          <Form.Label className="required">OPERATION REPORT</Form.Label>
          <Controller
            control={control}
            name="operation_report"
            render={({ field }) => (
              <TinyMCETextEditor field={field} setValue={setValue} />
            )}
          />

          {!!errors.operation_report && (
            <p>
              <Badge bg="danger">{errors.operation_report?.message}</Badge>
            </p>
          )}
        </FormGroup>
      </div>

      <Row className="mb-4">
        <Col className="mb-3" md={6}>
          <FormGroup>
            <Form.Label className="required">PERSONNEL WELFARE</Form.Label>
            <Controller
              control={control}
              name="personnel_welfare"
              render={({ field }) => (
                <TinyMCETextEditor field={field} setValue={setValue} />
              )}
            />

            {!!errors.personnel_welfare && (
              <p>
                <Badge bg="danger">{errors.personnel_welfare?.message}</Badge>
              </p>
            )}
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Form.Label className="required">HEALTH &amp; SAFETY</Form.Label>
            <Controller
              control={control}
              name="health_safety"
              render={({ field }) => (
                <TinyMCETextEditor field={field} setValue={setValue} />
              )}
            />

            {!!errors.health_safety && (
              <p>
                <Badge bg="danger">{errors.health_safety?.message}</Badge>
              </p>
            )}
          </FormGroup>
        </Col>
      </Row>

      <div className="mb-4 p-2 border">
        <h3>EMPLOYMENT STATUS OF THE WEEK</h3>

        <div>
          <Row>
            <Col className="mb-3" md={8}>
              ALL ISSUED EMPLOYMENT LETTERS
            </Col>

            <Col md={4}>
              <FormGroup>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="YES"
                      value="yes"
                      type={type}
                      className={`inline-${type}-YES`}
                      onClick={() => unregister("employment_reason")}
                      {...register("issues_employment_letter")}
                    />

                    <Form.Check
                      inline
                      label="NO"
                      value="no"
                      type={type}
                      className={`inline-${type}-NO`}
                      {...register("issues_employment_letter")}
                    />
                  </div>
                ))}

                {!!errors.issues_employment_letter && (
                  <p>
                    <Badge bg="danger">
                      {errors.issues_employment_letter?.message}
                    </Badge>
                  </p>
                )}
              </FormGroup>
            </Col>
          </Row>

          {showEmploymentReason === "no" && (
            <div className="mb-4">
              <FormGroup>
                <Form.Label className="required">REASON (IF NO)</Form.Label>
                <Controller
                  control={control}
                  name="employment_reason"
                  render={({ field }) => (
                    <TinyMCETextEditor field={field} setValue={setValue} />
                  )}
                />

                {!!errors.employment_reason && (
                  <p>
                    <Badge bg="danger">
                      {errors.employment_reason?.message}
                    </Badge>
                  </p>
                )}
              </FormGroup>
            </div>
          )}
        </div>

        <div>
          <Row>
            <Col className="mb-3" md={8}>
              ALL DULY VERIFIED
            </Col>

            <Col md={4}>
              <FormGroup>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="YES"
                      value="yes"
                      type={type}
                      className={`inline-${type}-YES`}
                      onClick={() => unregister("verified_reason")}
                      {...register("verified")}
                    />

                    <Form.Check
                      inline
                      label="NO"
                      value="no"
                      type={type}
                      className={`inline-${type}-NO`}
                      {...register("verified")}
                    />
                  </div>
                ))}

                {!!errors.verified && (
                  <p>
                    <Badge bg="danger">{errors.verified?.message}</Badge>
                  </p>
                )}
              </FormGroup>
            </Col>
          </Row>

          {showVerifiedReason === "no" && (
            <div className="mb-4">
              <FormGroup>
                <Form.Label className="required">REASON (IF NO)</Form.Label>
                <Controller
                  control={control}
                  name="verified_reason"
                  render={({ field }) => (
                    <TinyMCETextEditor field={field} setValue={setValue} />
                  )}
                />

                {!!errors.verified_reason && (
                  <p>
                    <Badge bg="danger">{errors.verified_reason?.message}</Badge>
                  </p>
                )}
              </FormGroup>
            </div>
          )}
        </div>

        <div>
          <Row>
            <Col className="mb-3" md={8}>
              ALL INPUTED INTO THE PAYROLL
            </Col>

            <Col md={4}>
              <FormGroup>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="YES"
                      value="yes"
                      type={type}
                      className={`inline-${type}-YES`}
                      onClick={() => unregister("payroll_reason")}
                      {...register("in_payroll")}
                    />

                    <Form.Check
                      inline
                      label="NO"
                      value="no"
                      type={type}
                      className={`inline-${type}-NO`}
                      {...register("in_payroll")}
                    />
                  </div>
                ))}

                {!!errors.in_payroll && (
                  <p>
                    <Badge bg="danger">{errors.in_payroll?.message}</Badge>
                  </p>
                )}
              </FormGroup>
            </Col>
          </Row>

          {showPayrollReason === "no" && (
            <div>
              <FormGroup>
                <Form.Label className="required">REASON (IF NO)</Form.Label>
                <Controller
                  control={control}
                  name="payroll_reason"
                  render={({ field }) => (
                    <TinyMCETextEditor field={field} setValue={setValue} />
                  )}
                />

                {!!errors.payroll_reason && (
                  <p>
                    <Badge bg="danger">{errors.payroll_reason?.message}</Badge>
                  </p>
                )}
              </FormGroup>
            </div>
          )}
        </div>
      </div>

      <Row className="mb-4">
        <Col className="mb-3" md={6}>
          <FormGroup>
            <Form.Label className="required">
              STATE OF BRANCH OFFICE FACILITIES
            </Form.Label>
            <Controller
              control={control}
              name="branch_office_state"
              render={({ field }) => (
                <TinyMCETextEditor field={field} setValue={setValue} />
              )}
            />

            {!!errors.branch_office_state && (
              <p>
                <Badge bg="danger">{errors.branch_office_state?.message}</Badge>
              </p>
            )}
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Form.Label className="required">
              WELL BEING OF BRANCH OFFICE PERSONNEL
            </Form.Label>
            <Controller
              control={control}
              name="branch_office_personnel_well_being"
              render={({ field }) => (
                <TinyMCETextEditor field={field} setValue={setValue} />
              )}
            />

            {!!errors.branch_office_personnel_well_being && (
              <p>
                <Badge bg="danger">
                  {errors.branch_office_personnel_well_being?.message}
                </Badge>
              </p>
            )}
          </FormGroup>
        </Col>
      </Row>

      <div className="mb-4 border p-3">
        <h3>REPORTS</h3>

        <div className="mb-3">
          <FormGroup>
            <Form.Label className="required">LOGISTICS</Form.Label>
            <Controller
              control={control}
              name="logistics_report"
              render={({ field }) => (
                <TinyMCETextEditor field={field} setValue={setValue} />
              )}
            />

            {!!errors.logistics_report && (
              <p>
                <Badge bg="danger">{errors.logistics_report?.message}</Badge>
              </p>
            )}
          </FormGroup>
        </div>

        <Row className="mb-3">
          <Col className="mb-3" md={6}>
            <FormGroup>
              <Form.Label className="required">CLIENT FEEDBACK</Form.Label>
              <Controller
                control={control}
                name="client_feedback_report"
                render={({ field }) => (
                  <TinyMCETextEditor field={field} setValue={setValue} />
                )}
              />

              {!!errors.client_feedback_report && (
                <p>
                  <Badge bg="danger">
                    {errors.client_feedback_report?.message}
                  </Badge>
                </p>
              )}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Form.Label className="required">FINANCE</Form.Label>
              <Controller
                control={control}
                name="finance_report"
                render={({ field }) => (
                  <TinyMCETextEditor field={field} setValue={setValue} />
                )}
              />

              {!!errors.finance_report && (
                <p>
                  <Badge bg="danger">{errors.finance_report?.message}</Badge>
                </p>
              )}
            </FormGroup>
          </Col>
        </Row>

        <div className="mb-2">
          <FormGroup>
            <Form.Label className="required">DOGS</Form.Label>
            <Controller
              control={control}
              name="dogs_report"
              render={({ field }) => (
                <TinyMCETextEditor field={field} setValue={setValue} />
              )}
            />

            {!!errors.dogs_report && (
              <p>
                <Badge bg="danger">{errors.dogs_report?.message}</Badge>
              </p>
            )}
          </FormGroup>
        </div>
      </div>

      <Row className="mb-3">
        <Col className="mb-3" md={6}>
          <FormGroup>
            <Form.Label className="required">PLAN FOR NEXT WEEK</Form.Label>
            <Controller
              control={control}
              name="plan_for_next_week"
              render={({ field }) => (
                <TinyMCETextEditor field={field} setValue={setValue} />
              )}
            />

            {!!errors.plan_for_next_week && (
              <p>
                <Badge bg="danger">{errors.plan_for_next_week?.message}</Badge>
              </p>
            )}
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Form.Label className="required">
              ACTION TAKEN FROM THE LAST MEETING
            </Form.Label>
            <Controller
              control={control}
              name="decision_action"
              render={({ field }) => (
                <TinyMCETextEditor field={field} setValue={setValue} />
              )}
            />

            {!!errors.decision_action && (
              <p>
                <Badge bg="danger">{errors.decision_action?.message}</Badge>
              </p>
            )}
          </FormGroup>
        </Col>
      </Row>

      <div className="d-flex justify-content-center mb-3">
        {loading ? (
          <LoadingButton />
        ) : (
          <Button
            variant="success"
            type="submit"
            className="px-4 mt-3"
            disabled={!!loading || !isDirty}
          >
            Submit Report
          </Button>
        )}
      </div>
    </Form>
  );
};
