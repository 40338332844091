import React, { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { Card, Col, Row } from "react-bootstrap";
import { Loading } from "../../../Components/Loading";
import { Link, useSearchParams } from "react-router-dom";
import ReactPagination from "../../../Components/ReactPagination";
import { DisplayedRolesPermissionsTable } from "../../../Components/RolesPermissions/Roles/DisplayedRolesPermissionsTable";

const RolesPermissionsIndex = () => {
  const { get } = useAxios();
  const [roles, setRoles] = useState();
  const [fetching, setFetching] = useState(true);
  const [searchPageParams, setSearchPageParams] = useSearchParams();

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;
  const pageChangeHandler = (page) => {
    setSearchPageParams(
      {
        ...Object.fromEntries(searchPageParams),
        page,
      },
      { replace: true }
    );
  };

  const getRolesAndPermissions = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;

      const response = await get(
        `roles?sort=desc&page=${currentPage}&page_size=${pageSize}`
      );
      setRoles(response.data);
    } catch (error) {
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    setFetching(true);
    const rolesAndPermissions = async () => await getRolesAndPermissions();
    rolesAndPermissions();
  }, [currentPage]);

  return (
    <>
      {fetching && <Loading />}

      <Card className="container d-flex justify-content-center ms-3">
        <Card.Header className="d-flex justify-content-between mt-2 mb-2">
          <Card.Title>
            <div className="page-pretitle mb-3">ACCESS CONTROL</div>
            <Row className="mt-3">
              <Col md="auto">
                <h2 className="page-title">All Roles</h2>
              </Col>
            </Row>
          </Card.Title>
          <Row className="mt-3">
            <Col md="auto">
              <Link to="/roles/create" className="btn btn-primary">
                Create Role
              </Link>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          {!fetching && !!roles?.data?.length === 0 && (
            <div> No Roles found</div>
          )}

          {!!roles?.data.length && !fetching && (
            <Row>
              <Col className="p-0">
                <>
                  <DisplayedRolesPermissionsTable
                    roles={roles.data}
                    getRolesAndPermissions={getRolesAndPermissions}
                  />

                  <br />

                  <ReactPagination
                    links={roles.links}
                    onPageChange={pageChangeHandler}
                    current_page={roles.current_page}
                    last_page={roles.last_page}
                  />
                </>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default RolesPermissionsIndex;
