import React, { useEffect, useState } from "react";
import Select from "react-select";
import useAxios from "../../hooks/useAxios";
import { useSearchParams } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";

export const     EmployeeFilterSearch = ({
  getStaffs,
  initialSearchFilterInputs,
  onhandleFilteredSearchChange,
}) => {
  const { get } = useAxios();
  const [fetching, setFetching] = useState(true);
  const [departments, setDepartments] = useState({});
  const [isClearable, setIsClearable] = useState(true);
  const [clientOptions, setClientOptions] = useState([]);
  const [officeLocations, setOfficeLocations] = useState({});
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [searchPageParams, setSearchPageParams] = useSearchParams();
  const [clientOptionsReady, setClientOptionsReady] = useState(false);
  const [clientLocationsOptions, setClientLocationsOptions] = useState({});
  const [selectedClientLocationOption, setSelectedLocationClientOption] =
    useState(null);

  useEffect(() => {
    setFetching(true);
    const getStaffCreationData = async () => {
      try {
        const response = await get(`human_resources/staffs/create`);
        setDepartments(response.data.departments);
        setOfficeLocations(response.data.branches);

        const _clientOptions = [];
        const clientLocations = {};
        const clientId = searchPageParams.get("client") || "";
        const clientLocationId = searchPageParams.get("client_location") || "";

        response.data.clients.forEach((client) => {
          const currentClientLocationOptions = [];

          _clientOptions.push({
            value: client.id,
            label: client.name,
          });

          if (clientId === client.id) {
            setSelectedClient(client);
          }

          client.locations.forEach((location) => {
            currentClientLocationOptions.push({
              value: location.id,
              label: location.name,
            });

            if (clientLocationId === location.id) {
              setSelectedLocationClientOption(location);
            }
          });

          clientLocations[client.id] = currentClientLocationOptions;
        });

        setClientOptionsReady(true);

        setClientOptions(_clientOptions);
        setClientLocationsOptions(clientLocations);

      } catch (error) {
        // if (error.response.status === 500) {
        // }
      } finally {
        setFetching(false);
      }
    };
    getStaffCreationData();
  }, []);

  const [searchFilterInputs, setSearchFilterInputs] = useState({
    branch: initialSearchFilterInputs.branch || "",
    client: initialSearchFilterInputs.client || "",
    department: initialSearchFilterInputs.department || "",
    search_key: initialSearchFilterInputs.search_key || "",
    client_location: initialSearchFilterInputs.client_location || "",
  });

  return (
    <>
      {!fetching && !!clientOptionsReady && (
        <Form
          className="border mt-0 mb-4 p-3"
          method="GET"
          onSubmit={(event) => {
            event.preventDefault();
            onhandleFilteredSearchChange(searchFilterInputs);
          }}
        >
          <Row className="mb-1">
            <Col className="py-2 mx-1">
              <Form.Label>EMPLOYEE NAME/CODE</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                placeholder="Employee code or full name"
                defaultValue={searchFilterInputs.search_key}
                onChange={(event) =>
                  setSearchFilterInputs({
                    ...searchFilterInputs,
                    search_key: event.target.value,
                  })
                }
              />
            </Col>
          </Row>

          <Row className="mb-2">
            <Col className="py-2 mx-1" sm={6}>
              <Form.Label>CLIENT</Form.Label>

              <Select
                className="text-dark"
                name="client"
                isClearable={isClearable}
                placeholder="Select Client"
                defaultValue={
                  !!selectedClient?.id && {
                    label: selectedClient?.name,
                    value: selectedClient?.id,
                  }
                }
                onChange={(option) => {
                  if (!!option?.value) {
                    setSelectedClient(option);
                    setSearchFilterInputs({
                      ...searchFilterInputs,
                      client: option.value,
                    });

                    setLocationOptions(clientLocationsOptions[option.value]);
                  } else {
                    setLocationOptions({});
                  }
                }}
                options={clientOptions}
              />
            </Col>

            <Col className="py-2 mx-1">
              <Form.Label>CLIENT LOCATION</Form.Label>
              <Select
                className="text-dark"
                name="client_location"
                placeholder="Select Location"
                isClearable={isClearable}
                defaultValue={
                  !!selectedClientLocationOption?.id && {
                    label: selectedClientLocationOption?.name,
                    value: selectedClientLocationOption?.id,
                  }
                }
                onChange={(option) => {
                  if (!!option?.value) {
                    setSearchFilterInputs({
                      ...searchFilterInputs,
                      client_location: option.value,
                    });
                  } else {
                    setLocationOptions({});
                  }
                }}
                options={locationOptions}
              />

              <Form.Text className="text-danger">
                Please select a client to see location options.
              </Form.Text>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col className="py-2 mx-1" sm={6}>
              <Form.Label>BRANCH</Form.Label>
              <select
                name="branch"
                className="form-control"
                value={searchFilterInputs.branch}
                onChange={(event) =>
                  setSearchFilterInputs({
                    ...searchFilterInputs,
                    branch: event.target.value,
                  })
                }
              >
                <option value="">Select Branch</option>{" "}
                {Object.entries(officeLocations).map(([branch, id]) => (
                  <option value={id} key={id}>
                    {" "}
                    {branch}
                  </option>
                ))}
              </select>
            </Col>

            <Col className="py-2 mx-1">
              <Form.Label>DEPARTMENT</Form.Label>
              <Form.Select
                name="department"
                className="form-control"
                value={searchFilterInputs.department}
                onChange={(event) =>
                  setSearchFilterInputs({
                    ...searchFilterInputs,
                    department: event.target.value,
                  })
                }
              >
                <option value="">Select Department</option>{" "}
                {Object.entries(departments).map(([department, id]) => (
                  <option value={id} key={id}>
                    {`${department}`.replace(/_/g, " ")}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>

          <Button variant="secondary" className="me-3" type="submit">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
              </svg>
            </span>
            Search
          </Button>
        </Form>
      )}
    </>
  );
};
