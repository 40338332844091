import React, { useState } from "react";
import * as yup from "yup";
import {
  Form,
  Badge,
  Button,
  Alert,
  Row,
  Col,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
import { format} from "date-fns";
import DatePicker from "react-datepicker";
import useAxios from "../../hooks/useAxios.js";
import NumberFormat from "react-number-format";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { LoadingButton } from "../LoadingButtons.jsx";
import { yupResolver } from "@hookform/resolvers/yup";
import TinyMCETextEditor from "../TinyMCETextEditor.jsx";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast.jsx";
import { useClientData } from "../../hooks/useClientsData.jsx";

const schema = yup.object({
  reporterName: yup.string().required("Reporter's name required"),
  position: yup.string().required("Reporter's designatio required"),
  client_id: yup.string().required("Client required"),
  client_location_id: yup.string().required("Client location required"),
  incidentDate: yup.string().nullable().required("Date of incident required"),
  incidentTime: yup.string().required("Time of incident required"),
  reportDate: yup.string().nullable().required("Date of report required"),
  reportTime: yup.string().required("Time of report required"),
  incidentType: yup.string().required("Incident type required"),
  other_type: yup.string().max(225),
  amountLost: yup.string().required("The amount lost required"),
  amountInWords: yup.string().required("The amount in words is required"),
  report_method: yup.string().max(5000),
  incidentEvidences: yup
    .mixed()
    .test("type", "All uploaded file must be pdf or image.", (value) => {
      if (value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          return (
            value[i].type === "application/pdf" ||
            value[i].type === "image/jpeg" ||
            value[i].type === "image/png" ||
            value[i].type === "image/jpg"
          );
        }
      } else if (!value.length) {
        return true;
      }
    })
    .notRequired(),

  incidentIntro: yup
    .string()
    .max(5000)
    .required("Report introduction required "),
  incidentFindings: yup
    .string()
    .max(5000)
    .required("Findings of report required"),
  witnesses: yup.array().of(
    yup.object({
      name: yup.string(),
      mobile: yup.string(),
      personsInvolved: yup.string(),
    })
  ),
  incidentConclusion: yup
    .string()
    .max(5000)
    .required("Conclusion of report required"),
});

export const FullIncidentReportForm = ({ clients, editIncidentReport }) => {
  const {
    watch,
    control,
    setValue,
    setError,
    register,
    resetField,
    unregister,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      reporterName: editIncidentReport?.reporterName || "",
      position: editIncidentReport?.position || "",
      client_id: editIncidentReport?.clients?.id || "",
      client_location_id: editIncidentReport?.client_location_id || "",
      incidentDate: editIncidentReport?.incidentDate || "",
      incidentTime: editIncidentReport?.incidentTime || "",
      reportDate: editIncidentReport?.reportDate || "",
      reportTime: editIncidentReport?.reportTime || "",
      incidentType: editIncidentReport?.incidentType || "",
      other_type: editIncidentReport?.other_type || "",
      amountInWords: editIncidentReport?.amountInWords || "",
      amountLost: editIncidentReport?.amountLost || "",
      report_method: editIncidentReport?.report_method || "",
      incidentEvidences: editIncidentReport?.incidentEvidences || "",
      incidentIntro: editIncidentReport?.incidentIntro || "",
      incidentFindings: editIncidentReport?.incidentFindings || "",
      witnesses: [
        {
          name: "",
          mobile: "",
          personsInvolved: "",
        },
      ],
      incidentConclusion: editIncidentReport?.incidentConclusion || "",
    },
  });

  const navigate = useNavigate();
  const { postWithFile } = useAxios();
  const [loading, setLoading] = useState(false);
  const showOtherIncidentType = watch("incidentType");
  const [incidentType, setIncidentType] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [hasServerError, setHasServerError] = useState(false);

  const [isFilePicked, setIsFilePicked] = useState(false);
  const [incidentEvidentFiles, setIncidentEvidentFiles] = useState([]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "witnesses",
  });

  const [incidentDate, setIncidentDate] = useState("");
  const [reportDate, setReportDate] = useState("");
  const [incidentTime, setIncidentTime] = useState("");
  const [reportTime, setReportTime] = useState("");

  const onValueChange = (event) => {
    setIncidentType(event.currentTarget.value);
  };

  const handleIncidentFileEvidenceUpload = (event) => {
    setIncidentEvidentFiles(event.target.files);
  };

  const { clientOptions, clientOptionsReady, clientLocationsOptions } =
    useClientData({ clients });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const endpointPath = !editIncidentReport?.id
        ? "incidents/store"
        : `incidents/update/${editIncidentReport?.id}`;

      const today = format(new Date(), "yyyy-MM-dd", {
        awareOfUnicodeTokens: true,
      });

      const submitData = !editIncidentReport?.id
        ? {
            ...data,
            incidentDate: format(new Date(data.incidentDate), "yyyy-MM-dd", {
              awareOfUnicodeTokens: true,
            }),

            incidentTime: format(new Date(data.incidentTime), "HH:mm", {
              awareOfUnicodeTokens: true,
            }),

            reportDate: format(new Date(data.reportDate), "yyyy-MM-dd", {
              awareOfUnicodeTokens: true,
            }),

            reportTime: format(new Date(data.reportTime), "HH:mm", {
              awareOfUnicodeTokens: true,
            }),

            incidentEvidences: incidentEvidentFiles,
          }
        : {
            ...data,

            incidentTime: format(new Date(today + " " + data.incidentTime),
              "HH:mm",
              {
                awareOfUnicodeTokens: true,
              }
            ),

            reportTime: format(new Date(today + " " + data.reportTime),
              "HH:mm",
              {
                awareOfUnicodeTokens: true,
              }
            ),

            incidentEvidences: incidentEvidentFiles,
          };

      await postWithFile(
        endpointPath,
        submitData,
        true
      );
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Incident report successfully saved!", null, 5000);
      navigate("/incident-reports");
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 422) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry, something went wrong and your request was unsuccessful!",
          null,
          5000
        );
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error?.response?.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to create incident report!",
          null,
          5000
        );
      }
    }
  };

  return (
    <>
      {!!clientOptionsReady && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          {hasServerError && (
            <Alert variant="danger">
              <p>
                Something went wrong will processing your request. Please try
                again!
              </p>
            </Alert>
          )}

          <Row className="contact-details ms-0 me-0 mb-4 p-2 border">
            <Col className="reporter-name mt-2 mb-3" md={6}>
              <FormGroup controlId="reporterName">
                <Form.Label className="required">NAME OF REPORTER</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name of reporter"
                  {...register("reporterName")}
                />
                {!!errors.reporterName && (
                  <p>
                    <Badge bg="danger">{errors.reporterName?.message}</Badge>
                  </p>
                )}
              </FormGroup>
            </Col>

            <Col className="position mt-2 mb-2" md={6}>
              <FormGroup controlId="position">
                <Form.Label className="required">POSITION</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Position"
                  {...register("position")}
                />
                {!!errors.position && (
                  <p>
                    <Badge bg="danger">{errors.position?.message}</Badge>
                  </p>
                )}
              </FormGroup>
            </Col>
          </Row>

          <div className="clientDetail mt-4 mb-4 border">
            <Form.Label className="mt-2 p-2">CLIENT DETAILS</Form.Label>
            <Row className="d-flex justify-content-between ms-0 me-0 mb-1 p-2 ">
              <Col className=" client-name mb-4" md={6}>
                <Form.Group controlId="client ">
                  <Form.Label className="required">NAME</Form.Label>
                  <Controller
                    name="client_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <Select
                          className="text-dark"
                          name={field.name}
                          ref={field.ref}
                          options={clientOptions}
                          onChange={(selectedClientOption) => {
                            setValue("client_id", selectedClientOption.value, {
                              shouldValidate: true,
                            });
                            setValue("client_location_id", "", {
                              shouldValidate: true,
                            });
                            setLocationOptions(
                              clientLocationsOptions[
                                selectedClientOption?.value
                              ] ?? []
                            );
                          }}
                          defaultValue={
                            !!editIncidentReport?.client_id
                              ? {
                                  value: editIncidentReport?.clients?.id,
                                  label: `${editIncidentReport?.clients?.name}`,
                                }
                              : null
                          }
                          placeholder="Select Client"
                        />
                      );
                    }}
                  />
                  {!!errors.client_id && (
                    <p>
                      <Badge bg="danger">{errors.client_id?.message}</Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="client-location" md={6}>
                <Form.Group controlId="client-location ">
                  <Form.Label className="required">LOCATION</Form.Label>
                  <Controller
                    name="client_location_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <Select
                          className="text-dark"
                          name={field.name}
                          ref={field.ref}
                          options={locationOptions}
                          onChange={(selectedClientLocationOption) => {
                            setValue(
                              "client_location_id",
                              selectedClientLocationOption.value,
                              {
                                shouldValidate: true,
                              }
                            );
                          }}
                          defaultValue={
                            !!editIncidentReport?.client_offices?.id
                              ? {
                                  value: editIncidentReport?.client_offices?.id,
                                  label: `${editIncidentReport?.client_offices?.name}`,
                                }
                              : null
                          }
                          placeholder="Select client location"
                        />
                      );
                    }}
                  />
                  <Form.Text className="text-danger">
                    Please select a client to see location options.
                  </Form.Text>

                  {!!errors.client_location_id && (
                    <p>
                      <Badge bg="danger">
                        {errors.client_location_id?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>

          <Row className="incident-details ms-0 me-0 mb-4 p-2 border">
            <Col className="incident-date mt-2 mb-3" md={6}>
              <FormGroup controlId="incidentDate">
                <Form.Label className="required">INCIDENT DATE</Form.Label>
                <Controller
                  name="incidentDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      isClearable
                      strictParsing
                      showYearDropdown
                      showMonthDropdown
                      autoComplete="off"
                      maxDate={new Date()}
                      closeOnScroll={true}
                      dropdownMode="select"
                      selected={incidentDate}
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      onChange={(date) => {
                        setIncidentDate(date);
                        setValue("incidentDate", date, {
                          shouldValidate: true,
                        });
                      }}
                      placeholderText="Enter reported date"
                    />
                  )}
                />

                {!!errors.incidentDate && (
                  <p>
                    <Badge bg="danger">{errors.incidentDate?.message}</Badge>
                  </p>
                )}
              </FormGroup>
            </Col>

            <Col className="incident-time mt-2 mb-2" md={6}>
              <FormGroup controlId="incidenTime">
                <Form.Label className="required">INCIDENT TIME</Form.Label>
                <Controller
                  name="incidentTime"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      isClearable
                      showTimeSelect
                      timeIntervals={1}
                      timeCaption="Time"
                      showTimeSelectOnly
                      closeOnScroll={true}
                      dateFormat="HH:mm:ss"
                      selected={incidentTime}
                      className="form-control"
                      onChange={(time) => {
                        setIncidentTime(time);
                        setValue("incidentTime", time);
                      }}
                      placeholderText="Enter incident time"
                    />
                  )}
                />

                {!!errors.incidentTime && (
                  <p>
                    <Badge bg="danger">{errors.incidentTime?.message}</Badge>
                  </p>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row className="report-details ms-0 me-0 mb-4 p-2 border">
            <Col className="report-date mt-2 mb-3" md={6}>
              <FormGroup controlId="reportDate">
                <Form.Label className="required">REPORT DATE</Form.Label>
                <Controller
                  name="reportDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      isClearable
                      strictParsing
                      showYearDropdown
                      showMonthDropdown
                      autoComplete="off"
                      maxDate={new Date()}
                      closeOnScroll={true}
                      dropdownMode="select"
                      selected={reportDate}
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      onChange={(date) => {
                        setReportDate(date);
                        setValue("reportDate", date, {
                          shouldValidate: true,
                        });
                      }}
                      placeholderText="Enter reported date"
                    />
                  )}
                />
                {/* <Form.Control
                  type="date"
                  min={today}
                  max={today}
                  {...register("reportDate")}
                  value={reportDate}
                  onChange={(e) => setReportDate(e.target.value)}
                /> */}

                {!!errors.reportDate && (
                  <p>
                    <Badge bg="danger">{errors.reportDate?.message}</Badge>
                  </p>
                )}
              </FormGroup>
            </Col>

            <Col className="report-time mt-2" md={6}>
              <FormGroup controlId="reportTime">
                <Form.Label className="required">REPORT TIME</Form.Label>
                <Controller
                  name="reportTime"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      isClearable
                      showTimeSelect
                      timeIntervals={1}
                      timeCaption="Time"
                      showTimeSelectOnly
                      closeOnScroll={true}
                      dateFormat="HH:mm:ss"
                      selected={reportTime}
                      className="form-control"
                      onChange={(time) => {
                        setReportTime(time);
                        setValue("reportTime", time);
                      }}
                      placeholderText="Enter report time"
                    />
                  )}
                />

                {!!errors.reportTime && (
                  <p>
                    <Badge bg="danger">{errors.reportTime?.message}</Badge>
                  </p>
                )}
              </FormGroup>
            </Col>
          </Row>

          <div className="incident-type mb-4 p-2 border">
            <FormGroup controlId="incidentType">
              <Form.Label className="required  mt-2">
                INCIDENT TYPE (Select one below)
              </Form.Label>
              {["radio"].map((type) => (
                <div
                  key={`inline-${type}`}
                  className="mb-2"
                  name="incidentType"
                  onChange={onValueChange}
                >
                  <Form.Check
                    inline
                    value="THEFT"
                    label="THEFT"
                    type={type}
                    id={`inline-${type}-THEFT`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="BURGLARY"
                    label="BURGLARY"
                    type={type}
                    id={`inline-${type}-BURGLARY`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="ROBBERY"
                    label="ROBBERY"
                    type={type}
                    id={`inline-${type}-ROBBERY`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="FIRE"
                    label="FIRE"
                    type={type}
                    id={`inline-${type}-FIRE`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="ACCIDENT"
                    label="ACCIDENT"
                    type={type}
                    id={`inline-${type}-ACCIDENT`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="FLOOD"
                    label="FLOOD"
                    type={type}
                    id={`inline-${type}-FLOOD`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="ASSAULT"
                    label="ASSAULT"
                    type={type}
                    id={`inline-${type}-ASSAULT`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="COLLAPSED FENCE"
                    label="COLLAPSED FENCE"
                    type={type}
                    id={`inline-${type}-COLLAPSED FENCE`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="PROPERTY LOSS"
                    label="PROPERTY LOSS"
                    type={type}
                    id={`inline-${type}-PROPERTY LOSS`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="DEATH"
                    label="DEATH"
                    type={type}
                    id={`inline-${type}-DEATH`}
                    onClick={() => unregister("other_type")}
                    {...register("incidentType")}
                  />

                  <Form.Check
                    inline
                    value="OTHER"
                    label="OTHER"
                    type={type}
                    id={`inline-${type}-OTHER`}
                    {...register("incidentType")}
                  />
                </div>
              ))}

              {!!errors.incidentType && (
                <p>
                  <Badge bg="danger">{errors.incidentType?.message}</Badge>
                </p>
              )}
            </FormGroup>

            {showOtherIncidentType === "OTHER" && (
              <div className="mb-3">
                <FormGroup>
                  <Form.Label className="required">
                    PLEASE ENTER THE INCIDENT TYPE BELOW (IF OTHER)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    {...register("other_type", { required: true })}
                    onChange={(event) => {
                      setValue("other_type", event.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  />

                  {!!errors.other_type && (
                    <p>
                      <Badge bg="danger">{errors.other_type?.message}</Badge>
                    </p>
                  )}
                </FormGroup>
              </div>
            )}
          </div>

          <Row className="monetary-value d-flex ms-0 me-0 mb-4 p-2 border">
            <p className="bg-dark text-white fw-bold mt-2 ">
              VALUE OF ITEMS LOST, DAMAGED, OR STOLEN
            </p>

            <Col className="amountLost mb-2">
              <FormGroup controlId="word-monetary-value">
                <Form.Label>
                  <span className="form-hint">IN WORDS</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="value of items lost in words"
                  {...register("amountInWords")}
                />
                {!!errors.amountInWords && (
                  <p>
                    <Badge bg="danger">{errors.amountInWords?.message}</Badge>
                  </p>
                )}
              </FormGroup>
            </Col>

            <Col className="monetary-value-number mb-3" sm={6}>
              <FormGroup controlId="numerical-monetary-value">
                <Form.Label>
                  <span className="form-hint">(N.....................K)</span>
                </Form.Label>
                <Controller
                  name="amountLost"
                  control={control}
                  render={({ field }) => (
                    <NumberFormat {...field} className="form-control" />
                  )}
                />

                {!!errors.amountLost && (
                  <p>
                    <Badge bg="danger">{errors.amountLost?.message}</Badge>
                  </p>
                )}
              </FormGroup>
            </Col>
          </Row>

          <div className="witnesInformation mb-4 border">
            {fields.map((field, index) => (
              <Row
                key={field.id}
                className="d-flex justify-content-between mt-2 ms-0 me-0 mb-2 p-2 "
              >
                <Col className="witness-name mb-4" sm={4}>
                  <FormGroup controlId="witness">
                    <Form.Label>WITNESS TO INCIDENT</Form.Label>
                    <Form.Control
                      type="text"
                      key={index}
                      placeholder="Witness fullname"
                      {...register(`witnesses.${index}.name`, {
                        onChange: (event) => {
                          setValue(
                            `witnesses.${index}.name`,
                            event.target.value,
                            {
                              shouldValidate: true,
                            }
                          );
                        },
                      })}
                    />

                    {!!errors.witnesses?.[index]?.name && (
                      <p>
                        <Badge bg="danger">
                          {errors.witnesses?.[index]?.name?.message}
                        </Badge>
                      </p>
                    )}
                  </FormGroup>
                </Col>

                <Col className="witness-mobile mb-4" sm={4}>
                  <FormGroup controlId="witnessTelephone">
                    <Form.Label>TELEPHONE NUMBER</Form.Label>
                    <Form.Control
                      type="tel"
                      key={index}
                      placeholder="Telephone number"
                      {...register(`witnesses.${index}.mobile`, {
                        onChange: (event) => {
                          setValue(
                            `witnesses.${index}.mobile`,
                            event.target.value,
                            {
                              shouldValidate: true,
                            }
                          );
                        },
                      })}
                    />

                    {!!errors.witnesses?.[index]?.mobile && (
                      <p>
                        <Badge bg="danger">
                          {errors.witnesses?.[index]?.mobile?.message}
                        </Badge>
                      </p>
                    )}
                  </FormGroup>
                </Col>

                <Row sm={4}>
                  <Col className="peron-involved mt-2 mb-2">
                    <FormGroup controlId="personsInvolved">
                      <Form.Label>PERSON INVOLVED</Form.Label>
                      <Form.Control
                        type="text"
                        key={index}
                        name="personInvovled"
                        placeholder="Person Involved"
                        {...register(`witnesses.${index}.personsInvolved`)}
                        onChange={(event) => {
                          setValue(
                            `witnesses.${index}.personsInvolved`,
                            event.target.value,
                            {
                              shouldValidate: true,
                            }
                          );
                        }}
                      />

                      {!!errors.witnesses?.[index]?.personsInvolved && (
                        <p>
                          <Badge bg="danger">
                            {
                              errors.witnesses?.[index]?.personsInvolved
                                ?.message
                            }
                          </Badge>
                        </p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col className="align-self-center mt-3 pt-2">
                    <Button variant="danger" onClick={() => remove(index)}>
                      Remove Witness
                    </Button>
                  </Col>
                </Row>
              </Row>
            ))}
          </div>

          <Row className=" more-witness-button mb-4">
            <Form.Text className="text-danger fw-bold fs-4">
              IF THERE ARE NO WITNESS, CLICK THE REMOVE WITNESS BUTTON BEFORE
              SUBMITTING THE FORM.
            </Form.Text>
            <Col className="mt-4">
              <Button className="me-3" type="button" onClick={() => append({})}>
                Add Witness
              </Button>
            </Col>
          </Row>

          <div className="introduction mt-2 mb-4 p-3 border">
            <FormGroup controlId="introduction">
              <Form.Label className="required">INTRODUCTION</Form.Label>
              <Form.Text id="introductory" muted>
                (A brief statement on the background/purpose of the report).
              </Form.Text>
              <Controller
                control={control}
                name="incidentIntro"
                render={({ field }) => (
                  <TinyMCETextEditor field={field} setValue={setValue} />
                )}
              />

              {!!errors.incidentIntro && (
                <p>
                  <Badge bg="danger">{errors.incidentIntro?.message}</Badge>
                </p>
              )}
            </FormGroup>
          </div>

          <div className="investigation-methodology mb-4 p-3 border">
            <Form.Label>INVESTIGATION METHODOLOGY</Form.Label>

            <div className="process-used p-2 mb-4">
              <FormGroup controlId="processUsed">
                <Form.Text id="processUsed" muted>
                  Process Used (Briefly State) e.g. Written/Oral Statement,
                  Material Evidence, Interviews, Surveillance etc.
                </Form.Text>
                <Controller
                  control={control}
                  name="report_method"
                  render={({ field }) => (
                    <TinyMCETextEditor field={field} setValue={setValue} />
                  )}
                />
                {!!errors.report_method && (
                  <p>
                    <Badge bg="danger">{errors.report_method?.message}</Badge>
                  </p>
                )}
              </FormGroup>
            </div>

            <Form.Text className="fs-4" id="fileEvidenceObtained" muted>
              Evidence Obtained Including Sources, Documents, Photos, Charts (To
              be attached)
            </Form.Text>

            <div className="file-evidence-obtained mt-2 mb-2">
              {!!isFilePicked ? (
                <div>
                  <p>File name: {incidentEvidentFiles.name}</p>
                </div>
              ) : (
                <Form.Text className="text-danger">
                  Please upload any file evidence obtained.
                </Form.Text>
              )}

              <Row>
                <Col className="mb-4" md={9}>
                  <FormGroup controlId="incidentEvidents">
                    <Form.Control
                      className="text-dark"
                      {...register("incidentEvidences")}
                      type="file"
                      multiple
                      onChange={handleIncidentFileEvidenceUpload}
                      accept=".pdf, .jpeg, .png, .jpg"
                    />

                    {!!errors.incidentEvidences && (
                      <p>
                        <Badge bg="danger">
                          {errors.incidentEvidences?.message}
                        </Badge>
                      </p>
                    )}
                  </FormGroup>
                </Col>

                <Col md={3}>
                  <Button
                    variant="danger"
                    onClick={() => resetField("incidentEvidences")}
                  >
                    Remove upload
                  </Button>
                </Col>
              </Row>
            </div>
          </div>

          <div className="investigation-findings mb-4 p-3 border">
            <FormGroup controlId="investigation-findings">
              <Form.Label className="required">
                FINDINGS (MOST ESSENTIAL)
              </Form.Label>
              <Form.Text id="reportFindings" muted>
                Describe logically &nbsp;
                <span className="text-decoration-underline">What</span>
                &nbsp; happened, &nbsp;
                <span className="text-decoration-underline">When</span> it
                happened (date & time), &nbsp;
                <span className="text-decoration-underline">Where</span> it
                happened, &nbsp;
                <span className="text-decoration-underline">How</span> it
                happened, &nbsp;
                <span className="text-decoration-underline">Who</span> did what
                and &nbsp;
                <span className="text-decoration-underline">Why</span> it
                happened.
              </Form.Text>

              <Controller
                control={control}
                name="incidentFindings"
                render={({ field }) => (
                  <TinyMCETextEditor field={field} setValue={setValue} />
                )}
              />

              {!!errors.incidentFindings && (
                <p>
                  <Badge bg="danger">{errors.incidentFindings?.message}</Badge>
                </p>
              )}
            </FormGroup>
          </div>

          <div className="conclusion mb-4 p-3 border">
            <FormGroup controlId="reportconclusion">
              <Form.Label className="required">
                CONCLUSION AND RECOMMENDATION (BASED ON FINDINGS)
              </Form.Label>

              <Controller
                control={control}
                name="incidentConclusion"
                render={({ field }) => (
                  <TinyMCETextEditor field={field} setValue={setValue} />
                )}
              />
              {!!errors.incidentConclusion && (
                <p>
                  <Badge bg="danger">
                    {errors.incidentConclusion?.message}
                  </Badge>
                </p>
              )}
            </FormGroup>
          </div>

          <div className="submit col-md-12 text-center mb-4">
            {loading ? (
              <LoadingButton />
            ) : (
              <Button
                type="submit"
                variant="success"
                disabled={!!loading || !isDirty}
              >
                Submit
              </Button>
            )}
          </div>
        </Form>
      )}
    </>
  );
};
