import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  ListGroup,
  Row,
} from "react-bootstrap";
import { format } from "date-fns";
import useAxios from "../../../hooks/useAxios";
import { Loading } from "../../../Components/Loading";
import { permissions } from "../../../utils/permissions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { usePermissionAndDomain } from "../../../hooks/usePermissionAndDomain";

const ViewRolesPermissions = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { roleAndPermissionId } = useParams();
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [roleAndPermission, setRoleAndPermission] = useState({});

  useEffect(() => {
    setFetching(true);
    const getDetailedroleAndPermission = async () => {
      try {
        const response = await get(`roles/${roleAndPermissionId}/show`);
        setRoleAndPermission(response.data);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedroleAndPermission();
  }, []);

  return (
    <>
      {fetching && <Loading />}

      {!!roleAndPermission?.id && !fetching && (
        <Card>
          <Card.Header className="d-flex justify-content-between mt-2 mb-5">
            <Card.Title>
              <Row>
                <Col>
                  <h1>Role Details</h1>
                </Col>
              </Row>
            </Card.Title>

            <Row>
              <Col>
                <Button onClick={() => navigate(-1)}>Back</Button>
              </Col>

              <Col className="me-4">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  {hasPermission(permissions.EDIT_ROLES) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/roles/edit/${roleAndPermissionId}`}
                    >
                      Edit
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body className="mb-4">
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <label className="fw-bolder fs-3 ">ROLE</label>
                <Card.Text className="fs-3">
                  {roleAndPermission.name || "N/A"}
                </Card.Text>

                {!!Number(roleAndPermission?.is_branch_restricted) > 0 && (
                  <>
                    <span className="badge bg-info mb-1 text-uppercase">
                      Branch Restricted
                    </span>
                  </>
                )}

                <Card.Text className="fs-3"></Card.Text>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3">USERS COUNT</label>
                <Card.Text className="fs-3">
                  {roleAndPermission.users_count || "N/A"}
                </Card.Text>
              </ListGroup.Item>

              {!!roleAndPermission?.users?.length && (
                <ListGroup.Item>
                  <label className="fw-bolder fs-3">USERS </label>
                  <div className="fs-3 mt-3">
                    <ul>
                      {roleAndPermission.users.map((user) => (
                        <li key={user.id}>
                          {user.first_name} {user.surname}
                        </li>
                      ))}
                    </ul>
                  </div>
                </ListGroup.Item>
              )}

              <ListGroup.Item>
                <label className="fw-bolder fs-3">PERMISSIONS </label>
                <div className="fs-3 mt-3">
                  {(
                    <ul>
                      {roleAndPermission.permissions.map((permission, i) => (
                        <li key={permission.id}>
                          {permission.name.replace(/[^a-z ]/gi, " ")}
                        </li>
                      ))}

                      {Number(roleAndPermission?.is_super_admin) > 0 && (
                        <>
                          <br />
                          <span className="badge bg-success text-uppercase">
                            Super Admin
                          </span>
                        </>
                      )}
                    </ul>
                  ) || "N/A"}
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>

          <Card.Footer>
            <ListGroup className="list-group-flush">
              <ListGroup.Item className="d-flex justify-content-end">
                <Row>
                  <Col className="mb-3">
                    <label className="fw-bolder fs-3">CREATED</label>
                    <Card.Text className="fs-3">
                      {format(
                        new Date(roleAndPermission.created_at),
                        "EEE, MMM d, yyyy 'at' HH:mm:ss",
                        {
                          awareOfUnicodeTokens: true,
                        }
                      ) || "N/A"}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item className="d-flex justify-content-end">
                <Row>
                  <Col>
                    <label className="fw-bolder fs-3">UPDATED</label>
                    <Card.Text className="fs-3">
                      {format(
                        new Date(roleAndPermission.updated_at),
                        "EEE, MMM d, yyyy 'at' HH:mm:ss",
                        {
                          awareOfUnicodeTokens: true,
                        }
                      ) || "N/A"}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card.Footer>
        </Card>
      )}
    </>
  );
};

export default ViewRolesPermissions;
