import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Card, Col, Row } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { permissions } from "../../utils/permissions";
import { Link, useSearchParams } from "react-router-dom";
import ReactPagination from "../../Components/ReactPagination";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { DisplayedClientsTable } from "../../Components/Clients/DisplayedClientsTable";

const ClientIndex = () => {
  const { get } = useAxios();
  const [clients, setClients] = useState({});
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [searchPageParams, setSearchPageParams] = useSearchParams();

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;

  const pageChangeHandler = (page) => {
    setSearchPageParams(
      {
        ...Object.fromEntries(searchPageParams),
        page,
      },
      { replace: true }
    );
  };

  const getClients = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;
      const response =
        await get(`clients?sort=desc&page=${currentPage}&page_size=${pageSize}
        `);
      setClients(response.data.clients);
    } catch (error) {
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    setFetching(true);
    const clients = async () => await getClients();
    clients();
  }, [currentPage]);

  return (
    <>
      {!!fetching && <Loading />}

      <Card className="container d-flex justify-content-center ms-3">
        <Card.Header className="d-flex justify-content-between mt-2 mb-2">
          <Card.Title>
            <Row>
              <Col md="auto">
                <h2 className="page-title">Clients</h2>
              </Col>
            </Row>
          </Card.Title>
          {hasPermission(permissions.ADD_CLIENT) && (
          <Row>
            <Col md="auto">
              <Link to="/clients/create" className="btn btn-primary">
                Create Client
              </Link>
            </Col>
          </Row>
          )}
        </Card.Header>

        <Card.Body className="mt-4">
          {!fetching && !!clients?.data?.length === 0 && (
            <div> No Clients found</div>
          )}

          {!fetching && !!clients?.data?.length && (
            <Row>
              <Col className="p-0">
                <>
                  <DisplayedClientsTable clients={clients.data} />

                  <br />

                  <ReactPagination
                    links={clients.links}
                    onPageChange={pageChangeHandler}
                    current_page={clients.current_page}
                    last_page={clients.last_page}
                  />
                </>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default ClientIndex;
