import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Col, Form } from "react-bootstrap";

export const StatusParametersModal = () => {
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [selectedVehicleData, setSelecetedVehicleData] = useState({});
  const [selectedStatusParameter, setSelecetedStatusParameter] = useState({});
  let passedSetValueFunction = useRef(() => {});

  const [selectedStatusParameterOptions, setSelecetedStatusParameterOptions] =
    useState([]);
  const [selectedStatusParameterIndex, setSelecetedStatusParameterIndex] =
    useState(null);

  useEffect(() => {
    window.addEventListener("statusReportParams:add", (event) => {
      handleShow();
      setSelecetedVehicleData(event.detail.field);
      setSelecetedStatusParameterIndex(event.detail.paramIndex);
      setSelecetedStatusParameter(event.detail.vehicleStatusParameter);
      setSelecetedStatusParameterOptions(
        event.detail.vehicleStatusParameter.options
      );
      passedSetValueFunction.current = event.detail.handleConditionValue;
    });
  });

  return (
    <Modal
      size="sm"
      centered
      show={show}
      backdrop="static"
      keyboard={false}
      onHide={handleClose}
      className="modal modal-dialog-centered modal-blur fade"
    >
      <Modal.Header closeButton>
        <Modal.Title>{`${selectedVehicleData?.reg_number} - ${selectedVehicleData?.label}`}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          id="statusParametersModalForm"
          // onSubmit={handleSubmit(onSubmit)}
        >
          <div className="fs-3 fw-bolder text-center mb-3">
            {selectedStatusParameter?.name}
          </div>

          <div className="d-grid gap-2">
            {selectedStatusParameterOptions.map((statusParamsOption) => (
              <Button
                key={statusParamsOption}
                variant="secondary"
                className="text-center m-1"
                data-value={statusParamsOption}
                data-vehicle={selectedVehicleData.vehicle_id}
                data-parameter={selectedStatusParameterIndex}
                onClick={(event) => {
                  passedSetValueFunction.current(event.target.innerHTML);

                  handleClose();
                }}
              >
                {statusParamsOption}
              </Button>
            ))}
          </div>
          {/* {selectedStatusParameterOptions.map((statusParamsOption) => (
            <div key={statusParamsOption} className="text-center m-2">
              <Button
                variant="secondary"
                className="px-5"
                data-vehicle={selectedVehicleData?.id}
                data-parameter={selectedStatusParameterIndex}
              >
                {statusParamsOption}
              </Button>
            </div>
          ))} */}
        </Form>
      </Modal.Body>
    </Modal>
  );
};
