import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import { Loading } from "../../../Components/Loading";
import { useRolesAndPermissionsData } from "../../../hooks/useRolesAndPermissionsData";
import { DisplayedPermissionsAndDomainsTable } from "../../../Components/RolesPermissions/Permissions/DisplayedPermissionsAndDomainsTable";

const PermissionsDomainIndex = () => {
  const { fetching, permissions, fetchRolesAndPermissionsData } =
    useRolesAndPermissionsData(`permissions`);

  useEffect(() => {
    const getRolesAndPermissionsData = async () => {
      fetchRolesAndPermissionsData();
    };
    getRolesAndPermissionsData();
  }, []);

  return (
    <>
      {!!fetching ? (
        <Loading />
      ) : (
        <Card className="container d-flex justify-content-center ms-3">
          <Card.Header className="d-flex justify-content-between mt-2 mb-2">
            <Card.Title>
              <div className="page-pretitle mb-3">ACCESS CONTROL</div>
              <Row className="mt-3">
                <Col md="auto">
                  <h2 className="page-title">All Permissions</h2>
                </Col>
              </Row>
            </Card.Title>

            <Row className="mt-3">
              <Col md="auto">
                <Link to="/permissions/create" className="btn btn-primary">
                  Create permission
                </Link>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <Row>
              <Col className="p-0">
                <>
                  <DisplayedPermissionsAndDomainsTable
                    permissions={permissions}
                    getPermissionsAndDomains={fetchRolesAndPermissionsData}
                  />

                  <br />
                </>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default PermissionsDomainIndex;
