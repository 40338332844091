import React from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { statusBadge } from "./statusBadge";
import useAxios from "../../hooks/useAxios";
import { GiHamburgerMenu } from "react-icons/gi";
import { permissions } from "../../utils/permissions";
import { handleCustomEvent } from "../../utils/customEventLister";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";

export const ComplaintsDataTable = ({salaryComplaints, getSalaryComplaints}) => {
  const { axiosDelete } = useAxios();
  const { hasPermission } = usePermissionAndDomain();

  const onDeleteClick = (salaryComplaint) => {
    handleCustomEvent("show-confirmation-modal", {
      action: async () => {
        try {
          await axiosDelete(`salary-complaints/delete/${salaryComplaint.id}`);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          alertSuccess(
            `Complaint lodged for ${salaryComplaint?.employee_concerned?.first_name} ${salaryComplaint?.employee_concerned?.surname} successfully deleted!`,
            null,
            5000
          );
          getSalaryComplaints();
        } catch (error) {
          alertWarning("Your request failed!", null, 500);
        }
      },
      question: `Are you sure you want to delete complaint lodged for ${salaryComplaint?.employee_concerned?.first_name} ${salaryComplaint?.employee_concerned?.surname}?`,
    });
  };

  return (
    <Table
      hover
      bordered
      responsive
      className="table-vcenter table-mobile-md card-table"
      style={{ backgroundColor: 'white' }}
    >
      <thead>
        <tr>
          <th>COMPLAINT DATE</th>
          <th>CONCERNED EMPLOYEE</th>
          <th>COMPLAINT</th>
          <th>BRANCH</th>
          <th>PROCESS OWNER</th>
          <th>ATTENDED BY</th>
          <th>ACTIONS</th>
        </tr>
      </thead>

      <tbody>
      {salaryComplaints.map((salaryComplaint) => (
        <tr key={salaryComplaint.id}>
          <td data-label="COMPLAINT DATE">
            {format(
              new Date(salaryComplaint?.complaint_date),
              "dd-MM-yyyy",
              {
                awareOfUnicodeTokens: true,
              }
            ) || "N/A"}

            <div>
              {statusBadge(salaryComplaint.payment_status) || "N/A"}
            </div>

          </td>
          <td data-label="CONCERNED EMPLOYEE">
            {`${salaryComplaint?.employee_concerned?.first_name} ${salaryComplaint?.employee_concerned?.middle_name} ${salaryComplaint?.employee_concerned?.surname} ` || "N/A"}

            <div className="text-muted fs-5 font-italic">
              ({!!salaryComplaint?.employee_concerned?.client_location_id ? salaryComplaint?.employee_concerned?.client_location?.name : "N/A"})
            </div>
          </td>
          <td data-label="COMPLAINT">{salaryComplaint?.complaint_category}</td>
          <td data-label="BRANCH">{salaryComplaint?.employee_concerned?.office_location_id ? salaryComplaint?.employee_concerned?.office_location?.name : "N/A" }</td>
          <td data-label="PROCESS OWNER">{`${salaryComplaint?.process_owner?.first_name} ${salaryComplaint?.process_owner?.surname}` || "N/A"}
          </td>
          <td data-label="ATTENDED BY">{!!salaryComplaint?.attended_by_employee_code ? `${salaryComplaint?.attended_by?.first_name} ${salaryComplaint?.attended_by?.surname}` : "N/A"}
          </td>
          <td>
              <DropdownButton
                size="sm"
                id="dropdown-basic-button"
                title={<GiHamburgerMenu />}
                className="justify-content-center p-0"
              >                   
                {hasPermission(permissions.VIEW_SALARY_COMPLAINTS) && (
                  <Dropdown.Item
                    as={Link}
                    to={`/salary-complaints/view/${salaryComplaint.id}`}
                    size="sm"
                    className="justify-content-center"
                  >
                    View
                  </Dropdown.Item>
                )}

                {hasPermission(permissions.EDIT_SALARY_COMPLAINT) && salaryComplaint?.payment_status !== "paid" && (
                  <Dropdown.Item
                    as={Link}
                    size="sm"
                    className="justify-content-center"
                    to={`/salary-complaints/edit/${salaryComplaint.id}`}
                  >
                    Edit
                  </Dropdown.Item>
                )}

                {hasPermission(permissions.PROCESS_SALARY_COMPLAINT) && salaryComplaint?.payment_status !== "paid"  && (
                  <Dropdown.Item
                    as={Link}
                    size="sm"
                    className="justify-content-center"
                    to={`/salary-complaints/update-status/${salaryComplaint.id}`}
                  >
                    Payment Status
                  </Dropdown.Item>
                )}

                {hasPermission(permissions.DELETE_SALARY_COMPLAINT) && salaryComplaint?.payment_status === "pending" && !salaryComplaint?.attended_by_employee_code && (
                  <Dropdown.Item
                    as={Link}
                    size="sm"
                    to="#"
                    className="justify-content-center"
                    onClick={() => onDeleteClick(salaryComplaint)}
                  >
                    Delete
                  </Dropdown.Item>
                )}
                
              </DropdownButton>
            </td>
        </tr>

      ))}

      </tbody>
    </Table>
  )
}
