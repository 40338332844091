import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Button, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { GuardKitsRequestForm } from "../../Components/GuardKitsRequest/GuardKitsRequestForm";
import { Loading } from "../../Components/Loading";
import { Error404 } from "../Errors/Error404";

const EditGuardKitsRequest = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { guardKitsRequestId } = useParams();
  const [clients, setClients] = useState({});
  const [kitItems, setKitItems] = useState({});
  const [fetching, setFetching] = useState(true);
  const [guardKitsRequest, setGuardKitsRequest] = useState({});

  useEffect(() => {
    setFetching(true);
    const getDetailedGuardKitsRequest = async () => {
      try {
        const response = await get(`uniformrequest/${guardKitsRequestId}/edit`);
        setClients(response.data.clients);
        setKitItems(response.data.guardKits);
        setGuardKitsRequest(response.data.uniformRequest);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedGuardKitsRequest();
  }, []);

  return (
    <>
      {!!fetching && <Loading />}

      {!fetching && guardKitsRequest?.status !== "awaiting_approval" && (
        <Error404 />
      )}

      {!fetching && !!guardKitsRequest?.id && (
        <Card className="container">
          <Card.Header className="d-flex justify-content-between mt-0 mb-5">
            <Card.Title>
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">Edit Kits Request</h1>
                </div>
              </div>
            </Card.Title>

            <div className="row">
              <div className="col-md-12">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </div>
            </div>
          </Card.Header>

          <Card.Body>
            <GuardKitsRequestForm
              clients={clients}
              guardKitItems={kitItems}
              editGuardKitsRequest={guardKitsRequest}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default EditGuardKitsRequest;
