import React from "react";
import { Link } from "react-router-dom";
import { permissions } from "../../utils/permissions";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";

export const DisplayedWeeklyReportTable = ({ weeklyReports }) => {
  const { hasPermission } = usePermissionAndDomain();
  return (
    <>
      <Table
        responsive
        hover
        bordered
        className=" table-vcenter table-mobile-md card-table"
      >
        <thead>
          <tr>
            <th>BRANCH MANAGER</th>
            <th>OFFICE BRANCH</th>
            <th>MONTH</th>
            <th>WEEK</th>
            <th>REPORT DATE</th>
            <th>CONTROLS</th>
          </tr>
        </thead>

        <tbody>
          {weeklyReports.map((weeklyReport) => (
            <tr key={weeklyReport.id}>
              <td data-label="BRANCH MANAGER">
                {weeklyReport?.users?.first_name} {weeklyReport?.users?.surname}
              </td>
              <td data-label="OFFICE BRANCH">
                {weeklyReport?.office_location?.name}
              </td>
              <td data-label="MONTH">{weeklyReport.month}</td>
              <td data-label="WEEK">{weeklyReport.week}</td>
              <td data-label="REPORT DATE">{weeklyReport.date}</td>
              <td className="d-flex justify-content-center ">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  {hasPermission(permissions.VIEW_BRANCH_WEEKLY_REPORTS) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/weekly-reports/view/${weeklyReport.id}`}
                    >
                      View
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
