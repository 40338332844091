import { useEffect, useState } from "react";
import React, { Link, useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  ListGroup,
  Row,
} from "react-bootstrap";
import { format } from "date-fns";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { permissions } from "../../utils/permissions";
import { HtmlContent } from "../../Components/HtmlContent";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";

const ViewEscortSchedule = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  let { scheduleId } = useParams();
  const [clients, setClients] = useState({});
  const [drivers, setDrivers] = useState([]);
  const [officers, setOfficers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [escortSchedule, setEscortSchedule] = useState({});
  const [escortScheduleOfficerNeeds, setEscortScheduleOfficerNeeds] = useState(
    []
  );

  useEffect(() => {
    setFetching(true);
    const getDetailedEscortSchedule = async () => {
      try {
        const response = await get(`escorts/${scheduleId}`);
        setEscortSchedule(response.data.escortData);
        setClients(response.data.escortData.client);
        setDrivers(response.data.escortData.drivers);
        setOfficers(response.data.escortData.officers);
        setVehicles(response.data.escortData.vehicles);
        setEscortScheduleOfficerNeeds(
          response.data.escortData.escortscheduleofficerneeds
        );
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedEscortSchedule();
  }, [scheduleId]);

  const requestNeedsEndpoint = !escortScheduleOfficerNeeds?.length
    ? `/escort-schedules/add/officer_need/${scheduleId}`
    : `/escort-schedules/edit/officer_need/${scheduleId}`;

  return (
    <>
      {!!fetching && <Loading />}

      {!!escortSchedule?.id && !fetching && (
        <Card>
          <Card.Header className="d-flex justify-content-between mt-2 mb-5">
            <Card.Title>
              <Row>
                <Col>
                  <h1>Escort Schedule for {escortSchedule.escort_date}</h1>
                </Col>
              </Row>
            </Card.Title>
            <Row>
              <Col className="mb-2">
                <Button onClick={() => navigate(-1)}>Back</Button>
              </Col>

              <Col className="me-4" sm={6}>
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  {hasPermission(
                    permissions.ASSIGN_DRIVER_TO_AN_ESCORT_SCHEDULE
                  ) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/escort-schedules/addDrivers/${escortSchedule.id}`}
                    >
                      {!!escortSchedule?.drivers.length
                        ? "Update Driver"
                        : "Assign Driver"}
                    </Dropdown.Item>
                  )}

                  {hasPermission(
                    permissions.ASSIGN_VEHICLE_TO_AN_ESCORT_SCHEDULE
                  ) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={requestNeedsEndpoint}
                    >
                      {!!escortSchedule?.vehicles?.length
                        ? "Update vehicle"
                        : "Assign vehicle"}
                    </Dropdown.Item>
                  )}

                  {hasPermission(
                    permissions.ASSIGN_OFFICERS_TO_AN_ESCORT_SCHEDULE
                  ) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/escort-schedules/addOfficers/${escortSchedule.id}`}
                    >
                      {!!Object.keys(officers)?.length
                        ? "Update Officer"
                        : "Assign Officer"}
                    </Dropdown.Item>
                  )}

                  {hasPermission(
                    permissions.MAKE_REQUEST_FOR_ESCORT_SCHEDULE_NEEDS
                  ) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={requestNeedsEndpoint}
                    >
                      {!escortSchedule?.escortscheduleofficerneeds?.length
                        ? "Needs Request"
                        : "Edit Needs Request"}
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body
            className="client-name mt-3 mb-3 p-2 bg-light border"
            id="clientName"
          >
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <label className="fw-bolder fs-3">REQUESTER DETAILS</label>
                <Row className="mt-3">
                  <Col className="mb-3" md={6}>
                    <label className="fw-bolder fs-3">NAME</label>
                    <Card.Text className="fs-3-dark">
                      {escortSchedule?.requester_name || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col className="mb-3" md={6}>
                    <label className="fw-bolder fs-3">MOBILE</label>
                    <Card.Text className="fs-3">
                      {escortSchedule?.requester_mobile || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col md={12}>
                    <label className="fw-bolder fs-3">EMAIL</label>
                    <Card.Text className="fs-3">
                      {escortSchedule?.requester_email || "N/A"}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3">CLIENT DETAILS</label>
                <Row className="mt-3">
                  <Col className="mb-3" md={6}>
                    <label className="fw-bolder fs-3">NAME</label>
                    <Card.Text className="fs-3-dark">
                      {clients?.name || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col className="mb-3" md={6}>
                    <label className="fw-bolder fs-3">MOBILE</label>
                    <Card.Text className="fs-3">
                      {clients?.mobile || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col md={12}>
                    <label className="fw-bolder fs-3">EMAIL</label>
                    <Card.Text className="fs-3">
                      {clients.email || "N/A"}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3">ESCORT DURATION</label>
                <Card.Text className="fs-3">
                  {escortSchedule?.escort_days === "1"
                    ? "1 DAY"
                    : `${escortSchedule?.escort_days} DAYS`}
                </Card.Text>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col className="mb-3" md={4}>
                    <label className="fw-bolder fs-3">REQUEST DATE</label>
                    <Card.Text className="fs-3">
                      {escortSchedule?.request_date || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col className="mb-3" md={4}>
                    <label className="fw-bolder fs-3">ESCORT DATE</label>
                    <Card.Text className="fs-3">
                      {escortSchedule?.escort_date || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col md={4}>
                    <label className="fw-bolder fs-3">ESCORT TIME</label>
                    <Card.Text className="fs-3">
                      {escortSchedule?.escort_time || "NA"}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3">ESCORT NEEDS</label>
                <HtmlContent>
                  {escortSchedule?.escort_needs || "N/A"}
                </HtmlContent>
              </ListGroup.Item>

              {!!escortSchedule?.escortscheduleofficerneeds?.length && (
                <>
                  <ListGroup.Item>
                    <label className="fw-bolder fs-3">
                      ESCORT OFFICER REQUESTED NEEDS
                    </label>
                    {Object.values(escortScheduleOfficerNeeds).map(
                      (escortScheduleOfficerNeed) => (
                        <HtmlContent key={escortScheduleOfficerNeed.id}>
                          {escortScheduleOfficerNeed?.needs || "N/A"}
                        </HtmlContent>
                      )
                    )}
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <label className="fw-bolder fs-3">
                      ESCORT OFFICER REQUESTED AMOUNT
                    </label>
                    {Object.values(escortScheduleOfficerNeeds).map(
                      (escortScheduleOfficerNeed) => (
                        <Card.Text
                          className="fs-3"
                          key={escortScheduleOfficerNeed.id}
                        >
                          &#8358;
                          {Number(
                            escortSchedule?.escortscheduleofficerneeds[0]
                              ?.amount
                          ).toLocaleString() || "N/A"}
                        </Card.Text>
                      )
                    )}
                  </ListGroup.Item>
                </>
              )}

              <ListGroup.Item>
                <label className="fw-bolder fs-3">PICKUP POINT</label>
                <Card.Text className="fs-3">
                  {escortSchedule?.pickup_point || "N/A"}
                </Card.Text>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3">DESTINATION</label>
                <Card.Text className="fs-3">
                  {escortSchedule?.destination || "N/A"}
                </Card.Text>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3">OFFICE LOCATION</label>
                <Card.Text className="fs-3">
                  {escortSchedule?.office_location.name || "N/A"}
                </Card.Text>
              </ListGroup.Item>

              <ListGroup.Item className="assignment-details">
                <Row>
                  <Col className="mb-3 drivers" md={6}>
                    <label className="fw-bolder fs-3">DRIVERS</label>
                    {Object.values(drivers).map((driver) => (
                      <Card.Text className="fs-3" key={driver.id}>
                        {`${driver?.first_name} ${driver?.surname}` || "N/A"}
                      </Card.Text>
                    ))}
                  </Col>

                  <Col className="mb-3 officers" md={6}>
                    <label className="fw-bolder fs-3"> ESCORT OFFICERS</label>
                    {Object.values(officers).map((officer) => (
                      <Card.Text className="fs-3" key={officer.id}>
                        {`${officer?.first_name} ${officer?.surname}` || "N/A"}
                      </Card.Text>
                    ))}
                  </Col>
                </Row>

                <Row>
                  <Col className="vehicles mb-3" md={6}>
                    <label className="fw-bolder fs-3">VEHICLES</label>
                    {vehicles.map((vehicle) => (
                      <Card.Text className="fs-3" key={vehicle.id}>
                        {vehicle?.reg_number || "N/A"}
                      </Card.Text>
                    ))}
                  </Col>

                  {/* <Col className="vehicles-type" md={6}>
                    <label className="fw-bolder fs-3">VEHICLE TYPE</label>
                    <Card.Text className="fs-3">{"N/A"}</Card.Text>
                  </Col> */}
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col className="escort-type mb-3" sm={6}>
                    <label className="fw-bolder fs-3">ESCORT TYPE</label>
                    <Card.Text className="fs-3">
                      {escortSchedule?.escort_type || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col sm={6}>
                    <label className="request-type fw-bolder fs-3">
                      REQUEST MODE
                    </label>
                    <Card.Text className="fs-3">
                      {escortSchedule?.request_mode || "N/A"}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col className="mb-3" md={6}>
                    <label className="fw-bolder fs-3">ESCORT STATUS</label>
                    <Card.Text className="fs-3">
                      {escortSchedule?.escort_status || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col className="mb-3" md={6}>
                    <label className="fw-bolder fs-3">
                      STATUS UPDATE REASON
                    </label>
                    <Card.Text className="fs-3">
                      {escortSchedule?.change_reason || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col md={12}>
                    <label className="fw-bolder fs-3 mb-2">
                      EVIDENCE FOR STATUS UPDATE
                    </label>
                    <Card.Text className="fs-3">
                      <Link to="#">{escortSchedule?.escort_doc || "N/A"}</Link>
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3">CLIENT REMARKS</label>
                <Card.Text className="fs-3">
                  {escortSchedule?.client_remark || "N/A"}
                </Card.Text>
              </ListGroup.Item>
            </ListGroup>

            <ListGroup.Item>
              <label className="fw-bolder fs-3">CREATED BY</label>
              <Card.Text className="fs-3">
                {escortSchedule?.created_by}
              </Card.Text>
            </ListGroup.Item>
          </Card.Body>

          <Card.Footer>
            <ListGroup className="list-group-flush">
              <ListGroup.Item className="d-flex justify-content-end">
                <Row>
                  <Col className="mb-3">
                    <label className="fw-bolder fs-3">CREATED</label>
                    <Card.Text className="fs-3">
                      {"  "}{" "}
                      {format(
                        new Date(escortSchedule?.created_at),
                        "EEE, MMM d, yyyy 'at' HH:mm:ss",
                        {
                          awareOfUnicodeTokens: true,
                        }
                      )}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item className="d-flex justify-content-end">
                <Row>
                  <Col>
                    <label className="fw-bolder fs-3">UPDATED</label>{" "}
                    <Card.Text className="fs-3">
                      {" "}
                      {format(
                        new Date(escortSchedule?.updated_at),
                        "EEE, MMM d, yyyy 'at' HH:mm:ss",
                        {
                          awareOfUnicodeTokens: true,
                        }
                      )}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card.Footer>
        </Card>
      )}
    </>
  );
};

export default ViewEscortSchedule;
