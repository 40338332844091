import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { permissions } from "../../utils/permissions";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { VehicleDataTable } from "../../Components/Vehicles/DataTable/VehicleDataTable";

const VehicleIndex = () => {
  const { get } = useAxios();
  const [vehicles, setVehicles] = useState([]);
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [searchPageParams] = useSearchParams();

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;
  let searchKeyParams = searchPageParams.get("search_key") || "";
  let officeLoctionParams = searchPageParams.get("office_location") || "";

  const getVehicles = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;
      const response = await get(
        `vehicles?sort=desc&page=${currentPage}&page_size=${pageSize}&search_key=${searchKeyParams}&office_location=${officeLoctionParams}`
      );

      setVehicles(response.data.vehicles);
    } catch (error) {
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    setFetching(true);
    const initialiaseVehicles = async () => await getVehicles();
    initialiaseVehicles();
  }, [currentPage, officeLoctionParams, searchKeyParams]);

  return (
    <>
      {!!fetching && <Loading />}

      <Card className="container d-flex justify-content-center ms-3">
        <Card.Header className="d-flex justify-content-between mt-2 mb-2">
          <Card.Title>
            <Row>
              <Col md="auto">
                <h2 className="page-title">ALL VEHICLES</h2>
              </Col>
            </Row>
          </Card.Title>

          {hasPermission(permissions.CREATE_VEHICLE) && (
            <Row>
              <Col md="auto">
                <Link to="/vehicles/create" className="btn btn-primary">
                  Add Vehicle
                </Link>
              </Col>
            </Row>
          )}
        </Card.Header>

        <Card.Body className="mt-4">
          {!fetching && !!vehicles?.length === 0 && (
            <div> No Vehicles Found</div>
          )}

          {!fetching && !!vehicles?.length && (
            <>
              <Row>
                <Col className="p-0">
                  <>
                    <VehicleDataTable vehicles={vehicles} />
                  </>
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default VehicleIndex;
