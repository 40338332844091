import React, { useState } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { DataTable } from '../DisplayTable/DataTable';
import { Button } from 'react-bootstrap';

export const VendorWorksTable = ({maintenanceRecords}) => {
  const columns = [
    { accessorKey: "request_voucher_number", header: "VOUCHER NO." },
    { accessorKey: "vehicle.reg_number", header: "VEHICLE REG. NO." },
    { accessorKey: "vehicle_name", header: "VEHICLE" },
    { accessorKey: "request_amount", header: `REQUESTED (&#8358;)` },
    { accessorKey: "approved_amount", header: "APPROVEDED (&#8358;)" },
    { accessorKey: "reported_date", header: "REPORTED DATE" },
    { accessorKey: "status", header: "STATUS" },
  ];

  const data = maintenanceRecords;

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters,
      globalFilter,
    },
  });

  return (
    <div className="rounded-md border">
      <div>
        <div className="flex items-center py-4 ms-4 me-4 ">
          <input
            placeholder="Filter input..."
            value={globalFilter ?? ""}
            onChange={(event) => {
              return setGlobalFilter(event.target.value);
            }}
            className="max-w-sm form-control"
          />
        </div>
      </div>

      <DataTable data={data} columns={columns} table={table} />

      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            table.previousPage();
          }}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            table.nextPage();
          }}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
