import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AuthContext } from "../../auth";
import LogisticsDashboard from "./LogisticsDashboard";

const DashBoard = () => {
  const auth = useContext(AuthContext);

  const staffDepartment = auth?.user.department_id;

  return (
    <Container fluid>
      <div className="page-header d-print-none">
        {!!Number(staffDepartment === "2") ? (
          <LogisticsDashboard />
        ) : (
          <Row className="align-items-center">
            <Col>
              <div className="page-pretitle">Overview</div>
              <h2>Welcome</h2>
            </Col>
          </Row>
        )}
      </div>
    </Container>
  );
};

export default DashBoard;
