import React from 'react'
import { format } from 'date-fns';
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { startOfMonth } from 'date-fns';

export const TopVendorsTable = ({
  topVendors,
}) => {
  let startOfCurrentMonth = format(startOfMonth(new Date()), "yyyy-MM-dd", {
    awareOfUnicodeTokens: true,
  });

  let currentDateOfMonth = format(new Date(), "yyyy-MM-dd", {
    awareOfUnicodeTokens: true,
  });
  return (
    <Table
      hover
      bordered
      responsive
      className=" table-vcenter table-mobile-md card-table"
    >
      <thead>
        <tr>
          <th>VENDOR</th>
          <th>MAINTENANCE</th>
          <th>AMOUNT (&#8358;)</th>
        </tr>
      </thead>

      <tbody>
        {topVendors.map((topVendor) => (
          <tr key={topVendor?.vendor_id}>
            <td data-label="VENDOR">
              <Link
                to={`/maintenance-records?vendor_id=${topVendor?.vendor_id}&vehicle_id=&fromDate=${startOfCurrentMonth}&toDate=${currentDateOfMonth}&status=&vehicle_part_id=&voucher_number=&page=1`}
              >
                {topVendor?.vendor?.name || "N/A"}
              </Link>
            </td>

            <td data-label="MAINTENANCE">
              {topVendor?.maintenance_count || "N/A"}
            </td>
            <td data-label="AMOUNT (&#8358;)">
              {Number(topVendor?.amount).toLocaleString() || "N/A"}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
