import React, { useEffect, useState } from "react";
import * as yup from "yup";
import Select from "react-select";
import {
  Col,
  Nav,
  Row,
  Card,
  Form,
  Container,
  ListGroup,
  Button,
} from "react-bootstrap";
import { Loading } from "../Loading";
import useAxios from "../../hooks/useAxios";
import { LoadingButton } from "../LoadingButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { handleCustomEvent } from "../../utils/customEventLister";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import { useModifyEscortData } from "../../hooks/EscortsDataFetching/useModifyEscortData";

const schema = yup.object({
  drivers: yup.array().of(yup.string()).required(),
});

const AddDriver = () => {
  const navigate = useNavigate();
  let { scheduleId } = useParams();
  const [loading, setLoading] = useState(false);
  const { get, post, axiosDelete } = useAxios();
  const [assignedDrivers, setAssignedDrivers] = useState({});
  const { fetching, drivers, escortSchedule, fetchData, setFetching } =
    useModifyEscortData();

  const {
    setError,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      drivers: [""],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "drivers",
    control,
  });

  useEffect(() => {
    setFetching(true);
    const getData = async () => {
      fetchData();
    };
    getData();

    const getAssignedDriversDetails = async () => {
      try {
        const response = await get(`escorts/${scheduleId}`);

        if (response.status === 200) {
          setAssignedDrivers(response.data.escortData.drivers);
        } else {
          alert("You are not authorised!");
        }
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getAssignedDriversDetails();
  }, []);

  const onSubmit = async (data) => {
    try {
      const response = await post(`escorts/${scheduleId}/addDrivers`, {
        ...data,
      });
      setLoading(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Driver(s) successfully assigned!", null, 5000);
      navigate(-1);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 422) {
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error.response.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to assign driver to a schedule!",
          null,
          5000
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const driversOption = Object.keys(drivers).map((key) => ({
    value: key,
    label: drivers[key],
  }));

  const onDeleteClick = (assignedDriver, scheduleId) => {
    handleCustomEvent("show-confirmation-modal", {
      action: async () => {
        try {
          await axiosDelete(
            `escorts/${scheduleId}/${assignedDriver.id}/removeUser`
          );
          window.location.reload();
          alertSuccess("Driver successfully removed!", null, 5000);
        } catch (error) {
          alertWarning("Your request failed!", null, 500);
        }
      },
      question: `Are you sure you want to remove ${assignedDriver.first_name} ${assignedDriver.surname}?`,
    });
  };

  return (
    <>
      <div className="page-wrapper">
        <Container fluid>
          {!!fetching && <Loading />}

          {!!escortSchedule?.id && !fetching && (
            <>
              <div className="page-header d-print-none">
                <Row className="align-items-center">
                  <Col>
                    <div className="page-pretitle">DRIVERS </div>
                  </Col>
                </Row>
              </div>

              <div className="page-body">
                <Nav className="justify-content-end">
                  <Nav.Item>
                    <Button onClick={() => navigate(-1)}>Back</Button>
                  </Nav.Item>
                </Nav>

                <br />

                <>
                  <Card className="mb-4">
                    <Card.Header className="d-flex justify-content-between mt-2 mb-5">
                      <Card.Title>ESCORT SUMARRY </Card.Title>
                    </Card.Header>

                    <Card.Body>
                      <ListGroup className="list-group-flush">
                        <Card.Subtitle className="mb-2 text-muted">
                          ESCORT SCHEDULE
                        </Card.Subtitle>
                        <ListGroup.Item>
                          Client ID: {escortSchedule.client_id}
                        </ListGroup.Item>

                        <ListGroup.Item>
                          Escort Date: {escortSchedule.escort_date}
                        </ListGroup.Item>

                        <ListGroup.Item>
                          Escort Time: {escortSchedule.escort_time}
                        </ListGroup.Item>
                      </ListGroup>

                      <ListGroup className="list-group-flush mt-4">
                        <Card.Subtitle className="mb-2 text-muted">
                          ASSIGNED DRIVERS
                        </Card.Subtitle>
                        <ListGroup.Item>
                          {Object.values(assignedDrivers).map(
                            (assignedDriver) => (
                              <Row
                                className="mb-3 d-flex justify-content-between"
                                key={assignedDriver.id}
                              >
                                <Col>
                                  <Card.Text className="text-dark">
                                    {`${assignedDriver.first_name} ${assignedDriver.surname}` ||
                                      "N/A"}
                                  </Card.Text>
                                </Col>
                                <Col>
                                  <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={() =>
                                      onDeleteClick(assignedDriver, scheduleId)
                                    }
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              </Row>
                            )
                          )}
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                  <br />
                  <Form className="ms-4" onSubmit={handleSubmit(onSubmit)}>
                    <Form.Label> ASSIGN DRIVER</Form.Label>
                    {fields.map((field, index) => (
                      <Row key={field.id} className="mb-3">
                        <Col>
                          <Controller
                            name={`drivers.${index}`}
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                key={index}
                                value={field.label}
                                className="text-dark"
                                placeholder="-- Select Driver --"
                                options={driversOption}
                                onChange={(event) => {
                                  setValue(`drivers.${index}`, event.value);
                                }}
                              />
                            )}
                          />
                        </Col>
                        <Col>
                          <Button
                            variant="danger"
                            onClick={() => remove(index)}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    ))}

                    <div className="mt-3">
                      <Button
                        className="me-3"
                        type="button"
                        onClick={() => append({})}
                      >
                        ASSIGN
                      </Button>

                      {loading ? (
                        <LoadingButton />
                      ) : (
                        <Button
                          type="submit"
                          variant="success"
                          disabled={!!loading || !isDirty}
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                  </Form>
                </>
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default AddDriver;
