import React, { useEffect} from "react";
import { format } from "date-fns";
import { SERVER_URL } from "../../config";
import { HtmlContent } from "../../Components/HtmlContent";
import { Link, useNavigate} from "react-router-dom";
import { Loading } from "../../Components/Loading";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  ListGroup,
  Row,
} from "react-bootstrap";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { permissions } from "../../utils/permissions";
import { useGetIncidentViewData } from "../../hooks/useGetIncidentViewData";

const IncidentReportView = () => {
  // const { get } = useAxios();
  // let { reportId } = useParams();
  const navigate = useNavigate();
  // const [user, setUser] = useState([]);
  // const [client, setClient] = useState([]);
  // const [fetching, setFetching] = useState(true);
  // const [witnessInfo, setWitnessInfo] = useState([]);
  const { hasPermission } = usePermissionAndDomain();
  // const [uploadedFiles, setUploadedFiles] = useState([]);
  // const [clientLocation, setClientLocation] = useState([]);
  // const [incidentReport, setIncidentReport] = useState({});

  const {
    user,
    client,
    reportId,
    fetching,
    witnessInfo,
    uploadedFiles,
    incidentReport,
    fetchDetailedIncidentReport,
  } = useGetIncidentViewData();

  useEffect(() => {
    const getDetailedIncidentReport = async () => {
      fetchDetailedIncidentReport();
    };
    getDetailedIncidentReport();
  }, [reportId]);

  const getUploadedFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  return (
    <>
      {!!fetching && <Loading />}

      {!!incidentReport?.id && !fetching && (
        <Card>
          <Card.Header className="d-flex justify-content-between mt-2 mb-5">
            <Card.Title>
              <Row>
                <Col>
                  <h1>Incident Report for {incidentReport?.incidentDate}</h1>
                </Col>
              </Row>
            </Card.Title>

            <Row>
              <Col>
                <Button onClick={() => navigate(-1)}>Back</Button>
              </Col>

              <Col>
                <DropdownButton
                  id="dropdown-basic-button"
                  title="ACTION"
                  className="justify-content-center"
                >
                  {!incidentReport?.position &&
                    !incidentReport?.incidentIntro &&
                    !incidentReport?.incidentFindings &&
                    hasPermission(permissions.EDIT_INCIDENT_REPORT) && (
                      <Dropdown.Item
                        as={Link}
                        size="sm"
                        className="justify-content-center"
                        to={`/incident-reports/edit/${reportId}`}
                      >
                        Edit
                      </Dropdown.Item>
                    )}

                  {hasPermission(permissions.COMMENT_INCIDENT_REPORT) && (
                    <Dropdown.Item
                      as={Link}
                      size="sm"
                      className="justify-content-center"
                      to={`/incident-reports/add-comment/${reportId}`}
                    >
                      Comment
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body className="mb-4">
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <label className="fw-bolder fs-3">REPORTER DETAILS</label>
                <Row className="mt-3 ps-2">
                  <Col className="mb-3" sm={6}>
                    <label className="fw-bold fs-3">Name</label>
                    <Card.Text className="fs-3">
                      {incidentReport?.reporterName || "N/A"}
                    </Card.Text>
                  </Col>

                  {!!incidentReport?.position &&
                    !!incidentReport?.incidentIntro &&
                    !!incidentReport?.incidentFindings && (
                      <Col sm={6}>
                        <label className="fw-bold fs-3">Position</label>
                        <Card.Text className="fs-3">
                          {incidentReport?.position || "N/A"}{" "}
                        </Card.Text>
                      </Col>
                    )}
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3">INCIDENT DETAILS</label>
                <Row className="mt-3 ps-2">
                  <Col className="mb-3" sm={6}>
                    <label className="fw-bold fs-3">Date</label>
                    <Card.Text className="fs-3">
                      {incidentReport?.incidentDate || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col sm={6}>
                    <label className="fw-bold fs-3">Time</label>
                    <Card.Text className="fs-3">
                      {incidentReport?.incidentTime || "N/A"}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3">REPORT DETAILS</label>
                <Row className="mt-3 ps-2">
                  <Col className="mb-3" sm={6}>
                    <label className="fw-bold fs-3">Date</label>
                    <Card.Text className="fs-3">
                      {incidentReport?.reportDate || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col sm={6}>
                    <label className="fw-bold fs-3">Time</label>
                    <Card.Text className="fs-3">
                      {incidentReport?.reportTime || "N/A"}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3">CLIENT'S DETAILS</label>
                <Row className="mt-3 ps-2">
                  <Col className="mb-3" md={12}>
                    <label className="fw-bold fs-3">Name</label>
                    <Card.Text className="fs-3">
                      {client?.name || "N/A"}
                    </Card.Text>
                  </Col>

                  <Col md={12}>
                    <label className="fw-bold fs-3">Location</label>
                    <Card.Text className="fs-3">
                      {incidentReport?.client_offices?.name || "N/A"}{" "}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col className="mb-3" sm={6}>
                    <label className="fw-bolder fs-3"> INCIDENT TYPE</label>
                    <Card.Text className="fs-3">
                      {incidentReport?.incidentType || "N/A"}{" "}
                    </Card.Text>
                  </Col>

                  {!!incidentReport.other_type && (
                    <Col sm={6}>
                      <label className="fw-bolder fs-3">
                        OTHER INCIDENT TYPE
                      </label>
                      <Card.Text className="fs-3">
                        {incidentReport?.other_type || "N/A"}
                      </Card.Text>
                    </Col>
                  )}
                </Row>
              </ListGroup.Item>

              {!!incidentReport?.position &&
                !!incidentReport?.incidentIntro &&
                !!incidentReport?.incidentFindings && (
                  <>
                    <ListGroup.Item>
                      <label className="fw-bolder fs-3">
                        VALUE OF ITEMS LOST IN WORDS
                      </label>
                      <Card.Text className="fs-3">
                        {incidentReport?.amountInWords || "N/A"}{" "}
                      </Card.Text>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <label className="fw-bolder fs-3">
                        NUMERICAL VALUE OF ITEMS LOST
                      </label>
                      <Card.Text className="fs-3">
                        {incidentReport?.amountLost || "N/A"}{" "}
                      </Card.Text>
                    </ListGroup.Item>

                    {!!witnessInfo.length && (
                      <>
                        <ListGroup.Item>
                          {witnessInfo.map((witness) => (
                            <Row key={witness.id} className=" mb-3 p-2 ">
                              <Col className="witness-name mb-3" md={4}>
                                <label className="fw-bolder fs-3">
                                  WITNESS TO INCIDENT
                                </label>
                                <Card.Text className="fs-3">
                                  {witness?.name}
                                </Card.Text>
                              </Col>

                              <Col className="witness-telephone mb-3" md={4}>
                                <label className="fw-bolder fs-3">
                                  TELEPHONE NUMBER
                                </label>
                                <Card.Text className="fs-3">
                                  {witness?.mobile}
                                </Card.Text>
                              </Col>

                              <Col className="person-involved" md={4}>
                                <label className="fw-bolder fs-3">
                                  PERSON INVOLVED
                                </label>
                                <Card.Text className="fs-3">
                                  {witness?.personsInvolved}
                                </Card.Text>
                              </Col>
                            </Row>
                          ))}
                        </ListGroup.Item>
                      </>
                    )}

                    <ListGroup.Item>
                      <label className="fw-bolder fs-3 mb-2">
                        REPORT INTRODUCTION
                      </label>
                      <HtmlContent>
                        {incidentReport?.incidentIntro || "N/A"}
                      </HtmlContent>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <label className="fw-bolder fs-3 mb-3">
                        INVESTIGATION METHODOLOGY
                      </label>
                      <Row>
                        <Col className="mb-4" md={12}>
                          <label className="fw-bold fs-3">
                            Process Used to Obtain Evidence
                          </label>
                          <HtmlContent>
                            {incidentReport?.report_method || "N/A"}
                          </HtmlContent>
                        </Col>
                      </Row>

                      {uploadedFiles !== null ? (
                        <div>
                          <label className="fw-bold fs-3 mb-4">
                            Uploaded File Evidence
                          </label>

                          <br />

                          {uploadedFiles.map((uploadedFile, index) => (
                            <div key={index}>
                              <label className="fw-bold fs-4 d-flex justify-content-center">
                                {uploadedFile || "N/A"}
                              </label>

                              <Row className="mb-4">
                                {getUploadedFileExtension(
                                  uploadedFile
                                ).toLowerCase() === "pdf" ? (
                                  <>
                                    <Col className="uploaded-file-evidence mb-3 d-flex justify-content-center">
                                      <object
                                        data={`${SERVER_URL}/docEvidence/${uploadedFile}`}
                                        type="application/pdf"
                                        width="85%"
                                        height="500"
                                        aria-labelledby="uploaded-file-evidence"
                                      />

                                      <br />
                                    </Col>
                                  </>
                                ) : (
                                  <Col className="uploaded-file-evidence mb-3 d-flex justify-content-center">
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={`${SERVER_URL}/docEvidence/${uploadedFile}`}
                                    >
                                      <img
                                        src={`${SERVER_URL}/docEvidence/${uploadedFile}`}
                                        alt={uploadedFile}
                                        border={1}
                                        border-radius={4}
                                        padding={5}
                                        width={150}
                                      />
                                    </a>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>
                          <label className="fw-bold fs-3 mb-2">
                            Uploaded File Evidence
                          </label>

                          <p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                              <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                              <path d="M10 12l4 4m0 -4l-4 4" />
                            </svg>
                            No files uploaded
                          </p>
                        </div>
                      )}
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <label className="fw-bolder fs-3 mb-2">
                        REPORT FINDINGS
                      </label>
                      <HtmlContent>
                        {incidentReport?.incidentFindings || "N/A"}
                      </HtmlContent>{" "}
                    </ListGroup.Item>
                  </>
                )}

              <ListGroup.Item>
                <label className="fw-bolder fs-3 mb-2">REPORT CONCLUSION</label>
                <HtmlContent>
                  {incidentReport?.incidentConclusion || "N/A"}
                </HtmlContent>
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3 mb-2">COMMENTS</label>
                {!!incidentReport?.comments?.length ? (
                  <>
                    {incidentReport.comments.map((comment) => (
                      <div key={comment.id}>
                        <div>
                          <Card.Text className="fs-3">
                            {comment?.employee?.first_name}{" "}
                            {comment?.employee?.surname}
                            <span className="ms-3 text-muted">
                              {format(
                                new Date(comment?.created_at),
                                "EEE, MMM d, yyyy",
                                {
                                  awareOfUnicodeTokens: true,
                                }
                              )}
                            </span>
                          </Card.Text>

                          <HtmlContent>{comment?.comment || "N/A"}</HtmlContent>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <Card.Text className="fs-3">N/A</Card.Text>
                )}
              </ListGroup.Item>

              <ListGroup.Item>
                <label className="fw-bolder fs-3">CREATED BY</label>
                <Card.Text className="fs-3">
                  {user?.first_name} {user?.surname}
                </Card.Text>
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>

          <Card.Footer>
            <ListGroup className="list-group-flush">
              <ListGroup.Item className="d-flex justify-content-end">
                <Row>
                  <Col className="mb-3">
                    <label className="fw-bolder fs-3">CREATED</label>
                    <Card.Text className="fs-3">
                      {format(
                        new Date(incidentReport.created_at),
                        "EEE, MMM d, yyyy 'at' HH:mm:ss",
                        {
                          awareOfUnicodeTokens: true,
                        }
                      )}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item className="d-flex justify-content-end">
                <Row>
                  {/* <Col >
                    <label className="fw-bolder fs-3">Updated at</label>{" "}
                    <Card.Text className="fs-3">
                      {" "}
                      {incidentReport.updated_at}
                    </Card.Text>
                  </Col> */}
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card.Footer>
        </Card>
      )}
    </>
  );
};

export default IncidentReportView;
