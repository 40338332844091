import React from "react";
import { RequireAuth } from "../../auth";
import { Route, Routes } from "react-router-dom";
import { Loading } from "../../Components/Loading";
import { Error404 } from "../../Pages/Errors/Error404";
import GuardKitsRequestIndex from "../../Pages/GuardKitsRequest/index";
import CreateGuardKitsRequest from "../../Pages/GuardKitsRequest/create";
import ViewGuardKitsRequest from "../../Pages/GuardKitsRequest/view";
import EditGuardKitsRequest from "../../Pages/GuardKitsRequest/edit";
import KitRequestStatusUpdate from "../../Pages/GuardKitsRequest/statusUpdate";
import ApprovalWithAdjustment from "../../Pages/GuardKitsRequest/approvalWithAdjustment";
import KitRequestDisbursement from "../../Pages/GuardKitsRequest/kitRequestDisbursement";


export const GuardKitsRequestRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <GuardKitsRequestIndex />
              </React.Suspense>
            }
          />

          <Route
            path="/create"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateGuardKitsRequest />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="view/:guardKitsRequestId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewGuardKitsRequest />
              </React.Suspense>
            }
          />

          <Route
            path="edit/:guardKitsRequestId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditGuardKitsRequest />
              </React.Suspense>
            }
          />

          <Route
            path="status_update/:guardKitsRequestId"
            element={
              <React.Suspense fallback={<Loading />}>
                <KitRequestStatusUpdate />
              </React.Suspense>
            }
          />

          <Route
            path="adjusted_approval/:guardKitsRequestId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ApprovalWithAdjustment />
              </React.Suspense>
            }
          />
          <Route
            path="disburse/:guardKitsRequestId"
            element={
              <React.Suspense fallback={<Loading />}>
                <KitRequestDisbursement />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
};
