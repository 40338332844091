import React, { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { Loading } from "../../../Components/Loading";
import { Card, Col, Nav, Row } from "react-bootstrap";
import { permissions } from "../../../utils/permissions";
import { Link, useSearchParams } from "react-router-dom";
import ReactPagination from "../../../Components/ReactPagination";
import { usePermissionAndDomain } from "../../../hooks/usePermissionAndDomain";
import DisplayedVehiclePartsTable from "../../../Components/Vehicles/Parts/DisplayedVehiclePartsTable";

const VehiclePartsIndex = () => {
  const { get } = useAxios();
  const [fetching, setFetching] = useState(true);
  const [vehicleParts, setVehicleParts] = useState({});
  const { hasPermission } = usePermissionAndDomain();
  const [searchPageParams, setSearchPageParams] = useSearchParams();

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;

  const getVehicleParts = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;
      const response = await get(
        `vehicle_parts?sort=desc&page=${currentPage}&page_size=${pageSize}`
      );

      setVehicleParts(response.data.parts);
    } catch (error) {
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  const pageChangeHandler = (page) => {
    setSearchPageParams(
      {
        ...Object.fromEntries(searchPageParams),
        page,
      },
      { replace: true }
    );
  };

    useEffect(() => {
      setFetching(true);
      const initialiaseVehicleParts = async () => await getVehicleParts();
      initialiaseVehicleParts();
    }, [currentPage]);

  

  return (
    <>
      {!!fetching && <Loading />}

      <Card className="container d-flex justify-content-center ms-3">
        <Card.Header className="d-flex justify-content-between mt-2 mb-2">
          <Card.Title>
            <Row>
              <Col md="auto">
                <h2 className="page-title">ALL VEHICLE PARTS</h2>
              </Col>
            </Row>
          </Card.Title>

          {hasPermission(permissions.CREATE_VEHICLE_PART) && (
            <Row>
              <Col md="auto">
                <Link to="/vehicle-parts/create" className="btn btn-primary">
                  Add Part
                </Link>
              </Col>
            </Row>
          )}
        </Card.Header>

        <Card.Body className="mt-4">
          {!fetching && !!vehicleParts?.data?.length === 0 && (
            <div> No Vehicle Parts Found</div>
          )}

          {!fetching && !!vehicleParts?.data?.length && (
            <>
              <p className="text-end text-muted">
                Page {vehicleParts.current_page} of {vehicleParts.last_page}
              </p>

              <Row>
                <Col className="p-0">
                  <>
                    <DisplayedVehiclePartsTable
                      vehicleParts={vehicleParts.data}
                    />

                    <br />

                    <ReactPagination
                      links={vehicleParts.links}
                      onPageChange={pageChangeHandler}
                      current_page={vehicleParts.current_page}
                      last_page={vehicleParts.last_page}
                    />
                  </>
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default VehiclePartsIndex;
