import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  ListGroup,
  Nav,
  Row,
  Table,
} from "react-bootstrap";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import DisbursementRecordForm from "../../Components/GuardKitsRequest/DisbursementRecordForm";

const KitRequestDisbursement = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  const [status, setStatus] = useState();
  const [client, setClient] = useState({});
  let { guardKitsRequestId } = useParams();
  const [kitItems, setKitItems] = useState({});
  const [disburser, setDisburser] = useState({});
  const [collector, setCollector] = useState({});
  const [fetching, setFetching] = useState(true);
  const [clientLocation, setClientLocation] = useState({});
  const [kitRequestDetail, setKitRequestDetail] = useState({});
  const [isSummaryClicked, setIsSummaryClicked] = useState(false);

  useEffect(() => {
    setFetching(true);
    const getDetailedGuardKitsRequest = async () => {
      try {
        const response = await get(`uniformrequest/${guardKitsRequestId}/edit`);
        setDisburser(response.data.hq_staffs);
        setCollector(response.data.received_by);
        setStatus(response.data.uniformRequest.status);
        setKitItems(response.data.uniformRequest.items);
        setClient(response.data.uniformRequest.clients);
        setKitRequestDetail(response.data.uniformRequest);
        setClientLocation(response.data.uniformRequest.client_locations);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedGuardKitsRequest();
  }, []);

  return (
    <div className="page-wrapper">
      <Container fluid>
        {fetching ? (
          <Loading />
        ) : (
          <>
            <div className="page-header d-print-none">
              <Row className="align-items-center">
                <Col>
                  <div className="page-pretitle">KIT REQUEST DISBURSEMENT </div>
                </Col>
              </Row>
            </div>

            <div className="page-body">
              <Nav className="justify-content-end">
                <Nav.Item>
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </Nav.Item>
              </Nav>

              <br />

              <>
                <Card className="mb-5">
                  <Card.Header className="d-flex justify-content-between mt-2 mb-4">
                    <Nav className="fw-bolder fs-4 mb-3">
                      <Nav.Item
                        onClick={() => setIsSummaryClicked(!isSummaryClicked)}
                      >
                        <Nav.Link href="#">
                          <Card.Title>REQUEST SUMARRY </Card.Title>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon"
                            width="10"
                            height="10"
                            viewBox="0 0 20 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M15 4v8h3.586a1 1 0 0 1 .707 1.707l-6.586 6.586a1 1 0 0 1 -1.414 0l-6.586 -6.586a1 1 0 0 1 .707 -1.707h3.586v-8a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1z" />
                          </svg>{" "}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Card.Header>

                  <Card.Body className="mb-4">
                    <ListGroup className="list-group-flush">
                      {isSummaryClicked && (
                        <>
                          <ListGroup.Item>
                            <label className="fw-bolder fs-3">REQUEST ID</label>
                            <Card.Text className="fs-3">
                              {kitRequestDetail.id || "N/A"}
                            </Card.Text>
                          </ListGroup.Item>

                          <ListGroup.Item>
                            <label className="fw-bolder fs-3 ">
                              CLIENT DETAILS
                            </label>

                            <Row className="mb-3">
                              <Col className="mt-3 mb-3" md={6}>
                                <label className="fw-bold fs-3">NAME</label>
                                <Card.Text className="fs-3">
                                  {client.name || "N/A"}
                                </Card.Text>
                              </Col>

                              <Col className="mt-3" md={6}>
                                <label className="fw-bold fs-3">LOCATION</label>
                                <Card.Text className="fs-3">
                                  {clientLocation.name || "N/A"}
                                </Card.Text>
                              </Col>
                            </Row>

                            <Row className="mt-3 mb-3">
                              <Col>
                                <label className="fw-bold fs-3 ">
                                  NUMBER OF OPERATIVE
                                </label>
                                <Card.Text className="fs-3">
                                  {clientLocation.guards_num || "N/A"}
                                </Card.Text>
                              </Col>
                            </Row>

                            <Row className="mb-3">
                              <Col className="mt-3" md={6}>
                                <label className="fw-bold fs-3">
                                  LAST KIT ALLOCATION DATE
                                </label>
                                <Card.Text className="fs-3">
                                  {clientLocation.last_kit_allocation_date ||
                                    "N/A"}
                                </Card.Text>
                              </Col>
                              <Col className="mt-3" md={6}>
                                <label className="fw-bold fs-3">
                                  NEXT KIT ALLOCATION DATE
                                </label>
                                <Card.Text className="fs-3">
                                  {clientLocation.next_kit_allocation_date ||
                                    "N/A"}
                                </Card.Text>
                              </Col>
                            </Row>
                          </ListGroup.Item>

                          <ListGroup.Item>
                            <label className="fw-bolder fs-3">
                              ORDER STATUS
                            </label>
                            <Card.Text className="fs-3">
                              {`${kitRequestDetail.status}`.replace(
                                /\_/g,
                                " "
                              ) || "N/A"}
                            </Card.Text>
                          </ListGroup.Item>
                        </>
                      )}

                      <ListGroup.Item className="mt-4">
                        <label className="fw-bolder fs-3 ">
                          GUARD KIT ITEMS DETAILS
                        </label>
                        <Table
                          responsive
                          hover
                          bordered
                          className=" table-vcenter table-mobile-md card-table mt-3"
                        >
                          <thead>
                            <tr>
                              <th>NAME</th>
                              <th>QUANTITY APPROVED</th>
                              <th>QUANTITY DISBURSED</th>
                            </tr>
                          </thead>

                          <tbody>
                            {kitItems.map((kitItem) => (
                              <tr key={kitItem.id}>
                                <td data-label="NAME">
                                  {kitItem.guard_kit.name || "N/A"}
                                </td>

                                <td data-label="QUANTITY APPROVED">
                                  {kitItem.approved_quantity || "N/A"}
                                </td>

                                <td data-label="QUANTITY DISBURSED">
                                  {kitItem.quantity_disbursed || "N/A"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>

                <br />

                <div className="d-flex justify-content-center">
                  <Card className="mb-5" style={{ width: "80%" }}>
                    <Card.Header className="d-flex justify-content-between mt-2 mb-3">
                      <Card.Title>DISBURSEMENT RECORD</Card.Title>
                    </Card.Header>

                    <Card.Body>
                      <DisbursementRecordForm
                        status={status}
                        logisticsStaff={disburser}
                        clientOperative={collector}
                        kitRequestDetail={kitRequestDetail}
                      />
                    </Card.Body>
                  </Card>
                </div>
              </>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default KitRequestDisbursement;
