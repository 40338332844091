import React, { useState } from "react";
import * as yup from "yup";
import { format } from "date-fns";
import Select from "react-select";
import useAxios from "../../hooks/useAxios";
import { Navigate } from "react-router-dom";
import { LoadingButton } from "../LoadingButtons";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";
import { Alert, Badge, Button, Card, Col, Form, Row } from "react-bootstrap";

const schema = yup.object({
  vehicle_model_id: yup.string().required("Vehicle model is required"),
  purchase_date: yup.string().nullable().required("Date of purchase required"),
  status: yup.string().required("Vehicle status is required"),
  usage_type: yup.string().required("What is the vehicle used for?"),
  office_location_code: yup.string().required("Office branch is required"),
  mileage: yup.string(),
  primary_driver_employee_code: yup.string(),
  secondary_driver_employee_code: yup.string(),
  reg_number: yup.string().required("Registration number is required"),
  license_reg_date: yup
    .string()
    .nullable()
    .required("Registration date required"),
  license_expiry_date: yup
    .string()
    .nullable()
    .required("License expiry date required"),
  worthiness_expiry_date: yup
    .string()
    .nullable()
    .required("Road worthiness expiry date required"),
  insurance_company_id: yup.string().required("Select the insurance company"),
  insurance_type: yup.string().required("Select the insurance type"),
  insurance_expiry_date: yup
    .string()
    .nullable()
    .required("Select the insurance type"),
});

export const VehicleForm = ({
  drivers,
  statuses,
  usageTypes,
  editVehicle,
  vehicleModel,
  insuranceTypes,
  branchLocations,
  insuranceCompanies,
}) => {
  const {
    register,
    control,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "onBlur",
      vehicle_model_id: editVehicle?.vehicle_model?.id || "",
      purchase_date: editVehicle?.purchase_date || "",
      status: editVehicle?.status || "",
      usage_type: editVehicle?.usage_type || "",
      office_location_code: editVehicle?.office_location?.location_code || "",
      mileage: editVehicle?.mileage || "",
      primary_driver_employee_code:
        editVehicle?.primary_driver?.employee_code || "",
      secondary_driver_employee_code:
        editVehicle?.secondary_driver?.employee_code || "",
      reg_number: editVehicle?.reg_number || "",
      license_reg_date: editVehicle?.license_reg_date || "",
      license_expiry_date: editVehicle?.license_expiry_date || "",
      worthiness_expiry_date: editVehicle?.worthiness_expiry_date || "",
      insurance_company_id: editVehicle?.insurance_company?.id || "",
      insurance_type: editVehicle?.insurance_type || "",
      insurance_expiry_date: editVehicle?.insurance_expiry_date || "",
    },
  });

  const { post } = useAxios();

  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);

  const today = new Date().toISOString().slice(0, 10);
  const [purchaseDate, setPurchaseDate] = useState(
    format(new Date(), "yyyy-MM-dd", { awareOfUnicodeTokens: true })
  );

  const [registrationDate, setRegistrationDate] = useState(
    format(new Date(), "yyyy-MM-dd", { awareOfUnicodeTokens: true })
  );

  const [licenseExpiryDate, setLicenseExpiryDate] = useState(
    format(new Date(), "yyyy-MM-dd", { awareOfUnicodeTokens: true })
  );

  const [roadWorthinessExpiryDate, setRoadWorthinessExpiryDate] = useState(
    format(new Date(), "yyyy-MM-dd", { awareOfUnicodeTokens: true })
  );

  const [insuranceExpiryDate, setInsuranceExpiryDate] = useState(
    format(new Date(), "yyyy-MM-dd", { awareOfUnicodeTokens: true })
  );

  const modelOptions = vehicleModel.map((model) => ({
    value: model.id,
    label: model.name,
  }));

  const primaryDriverOptions = Object.keys(drivers).map((key) => ({
    value: key,
    label: drivers[key],
  }));

  const secondaryDriverOptions = Object.keys(drivers).map((key) => ({
    value: key,
    label: drivers[key],
  }));

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const endpointPath = !editVehicle?.id
        ? "vehicles/store"
        : `vehicles/${editVehicle.id}/update`;

      await post(endpointPath, data);

      setIsSaved(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertSuccess("Vehicle successfully saved!", null, 5000);
    } catch (error) {
      setLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      alertWarning(
        "Sorry, something went wrong and your request was unsuccessful!",
        null,
        5000
      );
      if (error?.response?.status === 422) {
        const handleServerErrors = (errors, setError) => {
          if (errors) {
            setLoading(false);
            const serverValidation = error.response.data.errors;
            return Object.keys(serverValidation).map((key) => {
              setError(key, {
                shouldFocus: true,
                type: "manual",
                message: serverValidation[key][0],
              });
            });
          }
        };
        handleServerErrors(error, setError);
        return;
      }
      if (error?.response?.status === 500) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        alertWarning(
          "Sorry. You are not authorised to create Vehicle!",
          null,
          5000
        );
      }
    }
  };

  return (
    <>
      {isSaved && (
        <Navigate
          to={
            !editVehicle?.id
              ? "/vehicles/"
              : `/vehicles/view/${editVehicle?.id}`
          }
          // to="#"
          replace={true}
        />
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        {hasServerError && (
          <Alert variant="danger">
            <p>
              Something went wrong will processing your request. Please try
              again!
            </p>
          </Alert>
        )}

        <Card className="creation-authorization">
          <Card.Body>
            <Row>
              <Col className="vehicle-model mb-4">
                <Form.Group controlId="vehicleModel">
                  <Form.Label className="required">MODEL</Form.Label>
                  <Controller
                    name="vehicle_model_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        name={field.name}
                        ref={field.ref}
                        className="text-dark"
                        options={modelOptions}
                        onChange={(modelOption) => {
                          setValue("vehicle_model_id", modelOption.value, {
                            shouldValidate: true,
                          });
                        }}
                        defaultValue={{
                          value: editVehicle?.vehicle_model?.id || "",
                          label: editVehicle?.vehicle_model?.name || "",
                        }}
                        placeholder={"Select model"}
                      />
                    )}
                  />

                  {!!errors.vehicle_model_id && (
                    <p>
                      <Badge bg="danger">
                        {errors.vehicle_model_id?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="purchase-date mb-4" md={6}>
                <Form.Group controlId="purchaseDate">
                  <Form.Label className="required">PURCHASE DATE</Form.Label>
                  <Form.Control
                    type="date"
                    max={today}
                    placeholder="Enter date"
                    {...register("purchase_date")}
                    value={purchaseDate}
                    onChange={(event) => setPurchaseDate(event.target.value)}
                  />

                  {!!errors.purchase_date && (
                    <p>
                      <Badge bg="danger">{errors.purchase_date?.message}</Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="vehecle-status mb-4">
                <Form.Group controlId="vehicleStatus">
                  <Form.Label className="required">STATUS</Form.Label>
                  <Form.Select {...register("status")}>
                    <option value=""> Select status </option>
                    {statuses.map((vehicleStatus, index) => (
                      <option key={index} value={vehicleStatus}>
                        {vehicleStatus}
                      </option>
                    ))}
                  </Form.Select>

                  {!!errors.status && (
                    <p>
                      <Badge bg="danger">{errors.status?.message}</Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="vehecle-usage-type mb-4" md={6}>
                <Form.Group controlId="usageType">
                  <Form.Label className="required">USAGE TYPE</Form.Label>
                  <Form.Select {...register("usage_type")}>
                    <option value=""> Select usage type </option>
                    {usageTypes.map((usageType, index) => (
                      <option key={index} value={usageType}>
                        {usageType}
                      </option>
                    ))}
                  </Form.Select>

                  {!!errors.usage_type && (
                    <p>
                      <Badge bg="danger">{errors.usage_type?.message}</Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="mileage mb-4">
                <Form.Group controlId="vendorEmail">
                  <Form.Label>MILEAGE</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="mileage"
                    {...register("mileage")}
                  />

                  {!!errors.mileage && (
                    <p>
                      <Badge bg="danger">{errors.mileage?.message}</Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="branch-location mb-4" md={6}>
                <Form.Group controlId="branchLocation">
                  <Form.Label className="required">BRANCH</Form.Label>
                  <Form.Select {...register("office_location_code")}>
                    <option value=""> Select branch </option>
                    {Object.entries(branchLocations).map(([location, id]) => (
                      <option value={id} key={id}>
                        {`${location}`.replace(/_/g, " ")}
                      </option>
                    ))}
                  </Form.Select>

                  {!!errors.office_location_code && (
                    <p>
                      <Badge bg="danger">
                        {errors.office_location_code?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="primary-driver mb-4">
                <Form.Group controlId="primaryDriver">
                  <Form.Label>PRIMARY DRIVER</Form.Label>
                  <Controller
                    name="primary_driver_employee_code"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        name={field.name}
                        ref={field.ref}
                        className="text-dark"
                        options={primaryDriverOptions}
                        onChange={(primaryDriverOption) => {
                          setValue(
                            "primary_driver_employee_code",
                            primaryDriverOption.value,
                            {
                              shouldValidate: true,
                            }
                          );
                        }}
                        defaultValue={
                          !!editVehicle?.primary_driver?.employee_code
                            ? {
                              value:
                                editVehicle?.primary_driver?.employee_code,
                              label: `${editVehicle?.primary_driver?.first_name} ${editVehicle?.primary_driver?.surname}`,
                            }
                            : null
                        }
                        placeholder={"Select primary driver"}
                      />
                    )}
                  />

                  {!!errors.primary_driver_employee_code && (
                    <p>
                      <Badge bg="danger">
                        {errors.primary_driver_employee_code?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="secondary-driver mb-4" md={6}>
                <Form.Group controlId="secondaryDriver">
                  <Form.Label>SECONDARY DRIVER</Form.Label>
                  <Controller
                    name="secondary_driver_employee_code"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        name={field.name}
                        ref={field.ref}
                        className="text-dark"
                        options={secondaryDriverOptions}
                        onChange={(option) => {
                          setValue(
                            "secondary_driver_employee_code",
                            option.value,
                            {
                              shouldValidate: true,
                            }
                          );
                        }}
                        defaultValue={
                          !!editVehicle?.secondary_driver?.employee_code
                            ? {
                              value:
                                editVehicle?.secondary_driver?.employee_code,
                              label: `${editVehicle?.secondary_driver?.first_name} ${editVehicle?.secondary_driver?.surname}`,
                            }
                            : null
                        }
                        placeholder="Select secondary driver"
                      />
                    )}
                  />

                  {!!errors.secondary_driver_employee_code && (
                    <p>
                      <Badge bg="danger">
                        {errors.secondary_driver_employee_code?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="registration-number mb-4">
                <Form.Group controlId="registrationNumber">
                  <Form.Label className="required">
                    REGISTRATION NUMBER
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Registration number"
                    {...register("reg_number")}
                  />

                  {!!errors.reg_number && (
                    <p>
                      <Badge bg="danger">{errors.reg_number?.message}</Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="registration-date mb-4" md={6}>
                <Form.Group controlId="registrationDate">
                  <Form.Label className="required">
                    REGISTRATION DATE
                  </Form.Label>
                  <Form.Control
                    type="date"
                    max={today}
                    placeholder="Registration date"
                    {...register("license_reg_date")}
                    value={registrationDate}
                    onChange={(event) =>
                      setRegistrationDate(event.target.value)
                    }
                  />

                  {!!errors.license_reg_date && (
                    <p>
                      <Badge bg="danger">
                        {errors.license_reg_date?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="license-expiry-date mb-4">
                <Form.Group controlId="licenseExpiryDate">
                  <Form.Label className="required">
                    LICENSE EXPIRY DATE
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="License Expiry date"
                    {...register("license_expiry_date")}
                    value={licenseExpiryDate}
                    onChange={(event) =>
                      setLicenseExpiryDate(event.target.value)
                    }
                  />

                  {!!errors.license_expiry_date && (
                    <p>
                      <Badge bg="danger">
                        {errors.license_expiry_date?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="road-worthiness-expiry-date mb-4" md={6}>
                <Form.Group controlId="roadWorthinessExpiryDate">
                  <Form.Label className="required">
                    ROAD WORTHINESS EXPIRY DATE
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Road worthiness expiry date"
                    {...register("worthiness_expiry_date")}
                    value={roadWorthinessExpiryDate}
                    onChange={(event) =>
                      setRoadWorthinessExpiryDate(event.target.value)
                    }
                  />

                  {!!errors.worthiness_expiry_date && (
                    <p>
                      <Badge bg="danger">
                        {errors.worthiness_expiry_date?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="insurance-company mb-4">
                <Form.Group controlId="insuranceCompany">
                  <Form.Label className="required">
                    INSURANCE COMPANY
                  </Form.Label>
                  <Form.Select {...register("insurance_company_id")}>
                    <option value=""> Select insurance company </option>
                    {Object.entries(insuranceCompanies).map(([company, id]) => (
                      <option value={id} key={id}>
                        {`${company}`.replace(/_/g, " ")}
                      </option>
                    ))}
                  </Form.Select>

                  {!!errors.insurance_company_id && (
                    <p>
                      <Badge bg="danger">
                        {errors.insurance_company_id?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col className="insurance-type mb-4" sm={6}>
                <Form.Group controlId="insuranceType">
                  <Form.Label className="required">INSURANCE TYPE</Form.Label>
                  <Form.Select {...register("insurance_type")}>
                    <option value=""> Select insurance type </option>
                    {insuranceTypes.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Select>

                  {!!errors.insurance_type && (
                    <p>
                      <Badge bg="danger">
                        {errors.insurance_type?.message}
                      </Badge>
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <div className="insurance-expiry-date mb-4">
              <Form.Group controlId="insuranceExpiryDate">
                <Form.Label className="required">
                  INSURANCE EXPIRY DATE
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Insurance expiry date"
                  {...register("insurance_expiry_date")}
                  value={insuranceExpiryDate}
                  onChange={(event) =>
                    setInsuranceExpiryDate(event.target.value)
                  }
                />

                {!!errors.insurance_expiry_date && (
                  <p>
                    <Badge bg="danger">
                      {errors.insurance_expiry_date?.message}
                    </Badge>
                  </p>
                )}
              </Form.Group>
            </div>
          </Card.Body>
        </Card>

        <Card.Footer className=" d-flex justify-content-center  ">
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              variant="success"
              type="submit"
              className=" d-flex justify-content-center mt-3"
              disabled={!!loading}
            >
              Submit
            </Button>
          )}
        </Card.Footer>
      </Form>
    </>
  );
};
