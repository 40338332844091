import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { Col, Row, Card, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import SignatureUploadForm from "../../Components/Staffs/SignatureUploadForm";

const SignatureUpload = () => {
  const { get } = useAxios();
  let { staffId } = useParams();
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [staff, setStaff] = useState({});
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    setFetching(true);
    const getDetailedStaff = async () => {
      try {
        const response = await get(`human_resources/staffs/${staffId}/show`);
        setStaff(response.data.userData);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getDetailedStaff();

    const getCreateStaffDetails = async () => {
      try {
        const response = await get(`human_resources/staffs/create`);
        setRoles(response.data.roles);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getCreateStaffDetails();
  }, []);

  
  return (
    <>
      <Card className="container">
        <Card.Header className="d-flex justify-content-between mt-0 ">
          <Card.Title>
            <Row>
              <Col>
                <h1 className="page-title">Upload Employee Signature</h1>
              </Col>
            </Row>
          </Card.Title>
          <Row>
            <Col>
              <Button onClick={() => navigate(-1)}>Back</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {!!fetching && <Loading />}
          {!!staff?.id && !fetching && (
            <SignatureUploadForm staff={staff} staffId={staffId} />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default SignatureUpload;
