import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Card, Col, Row } from "react-bootstrap";
import { Loading } from "../../Components/Loading";
import { permissions } from "../../utils/permissions";
import { Link, useSearchParams } from "react-router-dom";
import ReactPagination from "../../Components/ReactPagination";
import { alertWarning } from "../../Components/CustomAlerts/AlertToast";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { DisplayedWeeklyReportTable } from "../../Components/WeeklyReports/DisplayedWeeklyReportTable";

const WeeklyReportIndex = () => {
  const { get } = useAxios();
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [weeklyReports, setWeeklyReports] = useState({});
  const [searchPageParams, setSearchPageParams] = useSearchParams();

  let currentPage = searchPageParams.get("page");
  let pageSize = searchPageParams.get("page_size");

  const pageChangeHandler = (page) => {
    setSearchPageParams({ page }, { replace: true });
  };

  useEffect(() => {
    setFetching(true);
    const getWeeklyReports = async () => {
      try {
        pageSize = Number(pageSize) || 10;
        currentPage = Number(currentPage) || 1;
        const response = await get(
          `weekly_reports?page=${currentPage}&page_size=${pageSize}&sort=desc`
        );

        setWeeklyReports(response.data.weekly_reports);
      } catch (error) {
        setFetching(false);
        alertWarning(
          "Something went wrong with the server. Please Try Again!",
          null,
          5000
        );
      } finally {
        setFetching(false);
      }
    };
    getWeeklyReports();
  }, [currentPage]);

  return (
    <>
      {fetching && <Loading />}

      <Card className="container d-flex justify-content-center ms-3">
        <Card.Header className="d-flex justify-content-between mt-2 mb-5">
          <Card.Title>
            <Row>
              <Col md="auto">
                <h2 className="page-title">Weekly Reports</h2>
              </Col>
            </Row>
          </Card.Title>

          {hasPermission(permissions.CREATE_BRANCH_WEEKLY_REPORT) && (
            <Row>
              <Col md="auto">
                <Link to="/weekly-reports/create" className="btn btn-primary">
                  Create Report
                </Link>
              </Col>
            </Row>
          )}
        </Card.Header>

        <Card.Body className="mt-4">
          {!fetching && !weeklyReports?.data?.length && (
            <div> No weekly reports found</div>
          )}

          {!fetching && !!weeklyReports?.data?.length && (
            <>
              <DisplayedWeeklyReportTable weeklyReports={weeklyReports.data} />

              <br />

              <ReactPagination
                links={weeklyReports.links}
                onPageChange={pageChangeHandler}
                current_page={weeklyReports.current_page}
                last_page={weeklyReports.last_page}
              />
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default WeeklyReportIndex;
