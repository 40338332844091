import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Card, Button } from "react-bootstrap";
import { AuthContext } from "../../auth";
import { SERVER_URL } from "../../config";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import maleAvatar from "../../images/male_avatar.png";
import femaleAvatar from "../../images/female_avatar.jpg";
import { Link, useNavigate, useParams } from "react-router-dom";

const ViewProfile = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [profileDetails, setProfileDetails] = useState({});
  const [fetching, setFetching] = useState(true);
  const [uploadedSignatue, setUploadedSignatue] = useState("");
  const [pensionDetails, setPensionDetails] = useState("");

  useEffect(() => {
    setFetching(true);
    const getProfileDetails = async () => {
      try {
        const response = await get(`user/me`);
        setProfileDetails(response.data);
        setPensionDetails(response.data.staff_pfas);
        setUploadedSignatue(response.data.signature_path);
      } catch (error) {
        if (error.response.status === 500) {
        }
      } finally {
        setFetching(false);
      }
    };
    getProfileDetails();
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between mt-2 mb-5">
          <Card.Title>
            <Row>
              <Col>
                <h1>My Profile</h1>
              </Col>
            </Row>
          </Card.Title>

          <Row className="ms-1">
            <Col className="mb-2">
              <Button onClick={() => navigate(-1)}>Back</Button>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          {!!fetching && <Loading />}

          {!!profileDetails?.id && !fetching && (
            <>
              <div className="d-flex py-1 align-items-center mb-5">
                {profileDetails.gender == "female" ? (
                  <span>
                    <img
                      src={femaleAvatar}
                      width="110"
                      height="100"
                      alt="Female avatar"
                    />
                  </span>
                ) : (
                  <span>
                    <img
                      src={maleAvatar}
                      width="110"
                      height="100"
                      alt="Male avatar"
                    />
                  </span>
                )}
                <div>
                  <div className="text-uppercase font-weight-medium">
                    {`${profileDetails.first_name} ${profileDetails.surname}` ||
                      "N/A"}
                  </div>
                  <div className="text-muted text-uppercase">
                    {profileDetails.employee_code || "(NO EMPLOYEE CODE FOUND)"}
                  </div>
                  <div className="text-muted text-uppercase">
                    {profileDetails.designation.name}
                  </div>

                  {!!profileDetails?.roles?.length && (
                    <div className="text-muted text-uppercase">
                      {`Role: ${profileDetails.roles[0].name}`}
                    </div>
                  )}
                </div>
              </div>

              <div className="page-wrapper">
                <Row className="d-flex mb-5">
                  <Col md={3}>
                    <h3 className="pe-5">Personal Details</h3>
                  </Col>

                  <Col md={9}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col>
                            <strong>Date of birth</strong>
                            <p className="border-bottom py-2">
                              {profileDetails.date_of_birth || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Marital Status</strong>
                            <p className="border-bottom py-2">
                              {profileDetails.marital_status || "N/A"}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <strong>Phone</strong>
                            <p className="border-bottom py-2">
                              {profileDetails.phone || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Personal Email</strong>
                            <p className="border-bottom py-2">
                              {profileDetails.email || "N/A"}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <strong>Official Email</strong>
                            <p className="border-bottom py-2">
                              {profileDetails.email || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Address</strong>
                            <p className="border-bottom py-2">
                              {profileDetails.residential_address || "N/A"}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <strong>Nationality</strong>
                            <p className="border-bottom py-2">
                              {profileDetails.nationality || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>State of origin</strong>
                            <p className="border-bottom py-2">
                              {profileDetails.state_of_origin || "N/A"}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <strong>Local govt. of origin</strong>
                            <p className="border-bottom py-2">
                              {profileDetails.local_govt_of_origin || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Home town address</strong>
                            <p className="border-bottom py-2">
                              {profileDetails.home_town_address || "N/A"}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <strong>Identification</strong>
                            <p className="">
                              {profileDetails.identification_numbe || "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row className="d-flex mb-5">
                  <Col md={3}>
                    <h3 className="pe-5">Financial Details</h3>
                  </Col>

                  <Col>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col>
                            <strong>Bank</strong>
                            <p className="border-bottom py-2">
                              {profileDetails?.bank?.name || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Account Name</strong>
                            <p className="border-bottom py-2">
                              {profileDetails.account_name || "N/A"}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <strong>Account Number</strong>
                            <p className="border-bottom py-2">
                              {profileDetails.account_number || "N/A"}
                            </p>
                          </Col>

                          <Col>
                            <strong>Tax Id</strong>
                            <p className="border-bottom py-2">
                              {profileDetails.tax_id_number || "N/A"}
                            </p>
                          </Col>
                        </Row>

                        {!!pensionDetails?.id && (
                          <div className="allign-items-stretch row row-cols-lg-3 row-cols-md-2 row-cols-1">
                            <div className="col">
                              <strong>Bank</strong>
                              <p className="border-bottom py-2">
                                {profileDetails?.bank?.name || "N/A"}
                              </p>
                            </div>

                            <div className="col">
                              <strong>Account Name</strong>
                              <p className="border-bottom py-2">
                                {profileDetails.account_name || "N/A"}
                              </p>
                            </div>

                            <div className="col">
                              <strong>Account Number</strong>
                              <p className="border-bottom py-2">
                                {profileDetails.account_number || "N/A"}
                              </p>
                            </div>
                            <div className="col">
                              <strong>Tax Id</strong>
                              <p className="border-bottom py-2">
                                {profileDetails.tax_id_number || "N/A"}
                              </p>
                            </div>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row className="d-flex mb-5">
                  <Col md={3}>
                    <h3 className="pe-5">Signature</h3>
                  </Col>

                  <Col md={9}>
                    <Card>
                      <Card.Body>
                        <Row className="align-self-center">
                          <Col>
                            {!!uploadedSignatue ? (
                              <img
                                src={`${SERVER_URL}/${uploadedSignatue}`}
                                alt={uploadedSignatue}
                                padding={5}
                                width={150}
                              />
                            ) : (
                              "N/A"
                            )}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default ViewProfile;
