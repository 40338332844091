import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
} from "@tanstack/react-table";
import { Button, Col, Row } from 'react-bootstrap';
import { TbEdit } from "react-icons/tb";
import { TiDeleteOutline } from "react-icons/ti";
import { AiOutlineFolderView } from "react-icons/ai";
import useAxios from '../../hooks/useAxios';
import { HtmlContent } from '../HtmlContent';
import { permissions } from '../../utils/permissions';
import { DataTable } from '../DisplayTable/DataTable';
import { handleCustomEvent } from '../../utils/customEventLister';
import { usePermissionAndDomain } from '../../hooks/usePermissionAndDomain';
import { alertSuccess, alertWarning } from '../CustomAlerts/AlertToast';
import { PageCount } from '../DisplayTable/PageCount';

export const VendorsDataTable = ({ vendors, getVendors }) => {
    const { hasPermission } = usePermissionAndDomain();
    const { axiosDelete } = useAxios();

    const onDeleteClick = (vendor) => {
        handleCustomEvent("show-confirmation-modal", {
            action: async () => {
                try {
                    await axiosDelete(`vendors/${vendor.id}/destroy`);
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    alertSuccess(`${vendor.name}successfully deleted!`, null, 5000);
                    getVendors();
                } catch (error) {
                    alertWarning("Your request failed!", null, 500);
                }
            },
            question: `Are you sure you want to delete ${vendor.name}?`,
        });
    };


    const columns = [
        {
            id: "name",
            header: "NAME",
            cell: ({ row }) => {
                const vendor = row.original;
                return (
                    <>
                        {!!vendor?.name
                            ? `${vendor?.name}`
                            : "N/A"}
                    </>
                );
            },
        },

        {
            id: "primary_phone",
            header: "TELEPHONE",
            cell: ({ row }) => {
                const vendor = row.original;
                return (
                    <>
                        {!!vendor?.primary_phone
                            ? `${vendor?.primary_phone}`
                            : "N/A"}
                    </>
                );
            },
        },

        {
            id: "email",
            header: "EMAIL",
            cell: ({ row }) => {
                const vendor = row.original;
                return (
                    <>
                        {!!vendor?.email
                            ? `${vendor?.email}`
                            : "N/A"}
                    </>
                );
            },
        },

        {
            id: "address",
            header: "OFFICE ADDRESS",
            cell: ({ row }) => {
                const vendor = row.original;
                return (
                    <>
                        {!!vendor?.adreess
                            ? <HtmlContent>
                                {vendor?.address}
                            </HtmlContent>
                            : "N/A"

                        }
                    </>
                )
            }
        },
        {
            header: "ACTIONS",
            id: "actions",
            cell: ({ row }) => {
                const vendor = row.original;

                return (
                    <Row className="d-flex justify-content-between align-items-center">
                        <Col xl={4}>
                            {hasPermission(permissions.VIEW_VENDORS) && (
                                <Link
                                    title="View"
                                    to={`/vendors/view/${vendor.id}`}
                                    className="btn btn-primary"
                                    size="sm"
                                >
                                    <AiOutlineFolderView size={19} className="me-2" />
                                </Link>
                            )}
                        </Col>

                        <Col xl={4}>
                            {hasPermission(permissions.EDIT_VENDOR) && (
                                <Link
                                    title="Edit"
                                    to={`/vendors/edit/${vendor.id}`}
                                    className="btn btn-primary"
                                    size="sm"
                                >
                                    <TbEdit size={19} className="me-2" />
                                </Link>
                            )}
                        </Col>

                        <Col xl={4}>
                            {hasPermission(permissions.DELETE_VENDOR) &&
                                vendor.maintenance_records_count === "0" && (
                                    <Link
                                        title="Delete"
                                        to="#"
                                        className="btn btn-danger"
                                        size="sm"
                                        onClick={() => onDeleteClick(vendor)}
                                    >
                                        <TiDeleteOutline size={19} className="me-2" />
                                    </Link>
                                )}
                        </Col>
                    </Row>
                );
            },
        },
    ];

    const data = vendors;

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            columnFilters,
            globalFilter,
        },
    });

    return (
        <div className="rounded-md border">
            <div>
                <div className="flex items-center py-4 ms-4 me-4 ">
                    <input
                        placeholder="Filter input..."
                        value={globalFilter ?? ""}
                        onChange={(event) => {
                            return setGlobalFilter(event.target.value);
                        }}
                        className="max-w-sm form-control"
                    />
                </div>
            </div>

            <DataTable data={data} columns={columns} table={table} />

            <div className="flex items-center justify-end space-x-2 py-4">
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => {
                        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                        table.previousPage();
                    }}
                    disabled={!table.getCanPreviousPage()}
                >
                    Previous
                </Button>
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => {
                        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                        table.nextPage();
                    }}
                    disabled={!table.getCanNextPage()}
                >
                    Next
                </Button>

                <PageCount table={table} />
            </div>
        </div>
    );
}
