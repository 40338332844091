import React, { useState} from "react";
import {
    Col,
    Row,
    Form,
    Badge,
    FormGroup,
    Button,
    Alert,
} from "react-bootstrap";
import * as yup from "yup";
import { format } from "date-fns";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Navigate} from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { LoadingButton } from "../LoadingButtons";
import "react-datepicker/dist/react-datepicker.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TinyMCETextEditor from "../TinyMCETextEditor";
import { EmployeeSelector } from "./EmployeeSelector";
import { alertSuccess, alertWarning } from "../CustomAlerts/AlertToast";

const schema = yup.object({
    complaint_date: yup.string().nullable().required("Date of complaint required"),
    employee_code: yup.string().required("This field is required"),
    complaint_category: yup.string().required("This field is required"),
    complaint_comment: yup.string().max(5000),
    root_cause_category: yup.string().required("This field is required"),
    root_cause_comment: yup.string().max(5000),
    number_of_days_worked: yup.string(),
    account_name: yup.string(),
    account_number: yup.string(),
    bank_name: yup.string()

});

export const SalaryComplaintForm = ({ rootCauses, complaintCategories, editSalaryComplaint, bankNames }) => {

    const {
        watch,
        control,
        register,
        setError,
        setValue,
        handleSubmit,
        formState: { errors, isDirty},
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(schema),
        defaultValues: {
            mode: "onBlur",
            complaint_date: !!editSalaryComplaint?.id
                ? editSalaryComplaint?.complaint_date 
                : ""
            ,
            employee_code: editSalaryComplaint?.employee_concerned?.employee_code || "",
            reporter_employee_code: editSalaryComplaint?.process_owner?.employee_code || "",
            complaint_category: editSalaryComplaint?.complaint_category || "",
            complaint_comment: editSalaryComplaint?.complaint_comment || "",
            root_cause_category: editSalaryComplaint?.root_cause_category || "",
            root_cause_comment: editSalaryComplaint?.root_cause_comment || "",
            number_of_days_worked: editSalaryComplaint?.number_of_days_worked || "",
            account_name: editSalaryComplaint?.account_name || "",
            account_number: editSalaryComplaint?.account_number || "",
            bank_name: editSalaryComplaint?.bank_name || "",
        },
    })

    const { post } = useAxios();
    const [loading, setLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [complaintDate, setComplaintDate] = useState("");
    const [employeeConcerned, setEmployeeConcerned] = useState(!editSalaryComplaint?.employee_concerned?.id ? {} : editSalaryComplaint?.employee_concerned)

    const complaintCategoryOptions = Object.keys(complaintCategories).map((key) => ({
        value: key,
        label: complaintCategories[key],
    }));

    const rootCauseOptions = Object.keys(rootCauses).map((key) => ({
        value: key,
        label: rootCauses[key],
    }));


    const handleAffectedEmployee = (employee) => {
        setValue("employee_code", employee.employee_code)
        setEmployeeConcerned(employee)
    }

    const showBankInput = watch("root_cause_category");
    const showDaysWorkedInput = watch("root_cause_category");

    
    const onSubmit = async (data, errors) => {
        try {
            setLoading(true);

            const endpointPath = !editSalaryComplaint?.id
                ? "salary-complaints/store"
                : `salary-complaints/update/${editSalaryComplaint?.id}`
            ;

            await post(endpointPath, {
                ...data,
                complaint_date: format(new Date(data?.complaint_date), "yyyy-MM-dd", {
                    awareOfUnicodeTokens: true,
                }),
            });

            setIsSaved(true);
            alertSuccess("Salary complaint successfully saved!", null, 5000);
            
        } catch (error) {
            setLoading(false);
            setIsSaved(false);;

            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            alertWarning(
              "Sorry, something went wrong and your request was unsuccessful!",
              null,
              5000
            );
            if (error?.response?.status === 422) {
              const handleServerErrors = (errors, setError) => {
                if (errors) {
                  setLoading(false);
                  const serverValidation = error.response.data.errors;
                  return Object.keys(serverValidation).map((key) =>
                    setError(key, {
                      shouldFocus: true,
                      type: "manual",
                      message: serverValidation[key][0],
                    })
                  );
                }
              };
              handleServerErrors(error, setError);
              return;
            }
            if (error?.response?.status === 500) {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              alertWarning(
                "Something went wrong with the server. Please Try Again!",
                null,
                5000
              );
            } else {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              alertWarning(
                "Sorry. You are not authorised to create salary complaint!",
                null,
                5000
              );
            }
        }
    }


    return (
        <>
            {isSaved && <Navigate to={"/salary-complaints"} replace={true} />}

            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="mb-4 p-2 border">
                    <Col className="affected-employee mt-2 mb-3" xs={12}>
                        <Form.Label className="required">EMPLOYEE</Form.Label>
                        <EmployeeSelector 
                            defaultSelectedEmployee={editSalaryComplaint?.employee_concerned}
                            affectedStaffPlaceholder="Affected employee" 
                            onHandleAffectedEmployeeClick={(employee) => handleAffectedEmployee(employee)} 
                        />

                        {!!errors.employee_code && (
                            <p>
                            <Badge bg="danger">
                                {errors.employee_code?.message}
                            </Badge>
                            </p>
                        )}
                    </Col>

                    <Col className="complaint-date mt-2 mb-3" xs={12}>
                        <FormGroup controlId="complaintDate">
                            <Form.Label className="required">COMPLAINT DATE</Form.Label>
                            <Controller
                                name="complaint_date"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        isClearable
                                        strictParsing
                                        showYearDropdown
                                        showMonthDropdown
                                        autoComplete="off"
                                        maxDate={new Date()}
                                        closeOnScroll={true}
                                        dropdownMode="select"
                                        selected={complaintDate}
                                        dateFormat="dd-MM-yyyy"
                                        className="form-control"
                                        onChange={(date) => {
                                            setComplaintDate(date);
                                            setValue("complaint_date", date, {
                                                shouldValidate: true,
                                            });
                                        }}
                                        placeholderText="Enter complaint date"
                                    />
                                )}
                            />

                            {!!errors.complaint_date && (
                                <p>
                                    <Badge bg="danger">{errors.complaint_date?.message}</Badge>
                                </p>
                            )}
                        </FormGroup>
                    </Col>
                    
                    <Col className="number-of-days-worked mt-2 mb-3" md={12}>
                        <FormGroup controlId="numberOfDaysWorked">
                        <Form.Label className={showDaysWorkedInput === "Attendance not uploaded" && "required"}>
                            NUMBER OF DAYS WORKED
                        </Form.Label>
                        <Form.Control
                            type="number"
                            min={1}
                            placeholder="Number of days worked "
                            {...register(`number_of_days_worked`, {
                            onChange: (event) => {
                                setValue(
                                `number_of_days_worked`,
                                event.target.value,
                                {
                                    shouldValidate: true,
                                }
                                );
                            },
                            })}
                        />

                        {!!errors.number_of_days_worked && (
                            <p>
                            <Badge bg="danger">
                                {errors.number_of_days_worked?.message}
                            </Badge>
                            </p>
                        )}
                        </FormGroup>
                    </Col>
                </Row>

                {employeeConcerned?.id  && (
                    <Row className="mb-4 p-2 border">
                        <Col className="mt-2 mb-2" md={6}>Bank: {employeeConcerned?.bank_name}</Col>

                        <Col className="mt-2 mb-2" md={6}>Account Number: {employeeConcerned?.account_number}</Col>
                    </Row>
                )}

                {(showBankInput === "Promo Account" || editSalaryComplaint?.root_cause_category === "Promo Account") && (
                    <Alert variant="warning" className="text-uppercase text-center">
                        Please update the account details of the concerned Staff on HRIMAX. Thank you!
                    </Alert>
                )}

                
                <Row className="mb-4 p-2 border flex-column">
                    <Col className="complaint-category mt-2 mb-3">
                        <Form.Group>
                            <Form.Label className="required">COMPLAINT CATEGORY</Form.Label>
                            <Controller
                                name="complaint_category"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Select
                                        name={field.name}
                                        ref={field.ref}
                                        className="text-dark"
                                        options={complaintCategoryOptions}
                                        onChange={(complaintCategoryOption) => {
                                            setValue("complaint_category", complaintCategoryOption.value, {
                                                shouldValidate: true,
                                            });
                                        }}
                                        defaultValue={
                                            !!editSalaryComplaint?.id
                                                ? {
                                                value:
                                                    editSalaryComplaint?.complaint_category,
                                                label: editSalaryComplaint?.complaint_category,
                                                }
                                                : null
                                        }
                                        placeholder={"Select one"}
                                    />
                                )}
                            />

                            {!!errors.complaint_category && (
                                <p>
                                    <Badge bg="danger">{errors.complaint_category?.message}</Badge>
                                </p>
                            )}
                        </Form.Group>
                    </Col>

                    <Col className="complaint-comment mt-2 mb-4">
                        <FormGroup>
                            <Form.Label>COMPLAINT COMMENT</Form.Label>
                            <Controller
                                control={control}
                                name="complaint_comment"
                                render={({ field }) => (
                                    <TinyMCETextEditor field={field} setValue={setValue} />
                                )}
                            />

                            {!!errors.complaint_comment && (
                                <p>
                                    <Badge bg="danger">{errors.complaint_comment?.message}</Badge>
                                </p>
                            )}
                        </FormGroup>
                    </Col>
                </Row>

                <Row className=" mb-3 p-2 border flex-column">
                    <Col className="root-cause-category mt-2 mb-4">
                        <Form.Group>
                            <Form.Label className="required">ROOT CAUSE CATEGORY</Form.Label>
                            <Controller
                                name="root_cause_category"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Select
                                        name={field.name}
                                        ref={field.ref}
                                        className="text-dark"
                                        options={rootCauseOptions}
                                        onChange={(rootCauseOption) => {
                                            setValue("root_cause_category", rootCauseOption.value, {
                                                shouldValidate: true,
                                            });
                                        }}
                                        defaultValue={
                                            !!editSalaryComplaint?.id
                                                ? {
                                                value:
                                                    editSalaryComplaint?.root_cause_category,
                                                label: editSalaryComplaint?.root_cause_category,
                                                }
                                                : null
                                        }
                                        placeholder={"Select one"}
                                    />
                                )}
                            />

                            {!!errors.root_cause_category && (
                                <p>
                                    <Badge bg="danger">{errors.root_cause_category?.message}</Badge>
                                </p>
                            )}
                        </Form.Group>
                    </Col>

                    <Col className="root-cause-comment mt-2 mb-4">
                        <FormGroup>
                            <Form.Label>ROOT CAUSE COMMENT</Form.Label>
                            <Controller
                                control={control}
                                name="root_cause_comment"
                                render={({ field }) => (
                                    <TinyMCETextEditor field={field} setValue={setValue} />
                                )}
                            />

                            {!!errors.root_cause_comment && (
                                <p>
                                    <Badge bg="danger">{errors.root_cause_comment?.message}</Badge>
                                </p>
                            )}
                        </FormGroup>
                    </Col>
                </Row>
               

                <div className=" d-flex justify-content-center mt-4 mb-1  ">
                {loading ? (
                    <LoadingButton />
                ) : (
                    <Button
                    data-testid="bs-btn "
                    variant="success"
                    type="submit"
                    className=""
                    disabled={!!loading || !isDirty}
                    >
                    Submit
                    </Button>
                )}
                </div>
            </Form>
        </>
    )
}
