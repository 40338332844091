import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);
export const MaintenancePieChart = ({ maintenanceCost }) => {
  let labels = [];
  let myTotalValue = null;
  let myDataSet = [];

  Object.keys(maintenanceCost).map((data) => {
    if (data !== "total") {
      labels.push(data);
    }
  });

  Object.keys(maintenanceCost).map((data) => {
    if (data === "total") {
      myTotalValue = maintenanceCost[data];
    }
  });

  Object.keys(maintenanceCost).map((data) => {
    if (data !== "total") {
      myDataSet.push(
        `${Math.round(Number(maintenanceCost[data] / myTotalValue) * 100)}`
      );
    }
  });

  const chartData = {
    labels: labels,
    datasets: [
      {
        labels: "Maintenance Expenses",
        data: labels.map((myLabel, index) => myDataSet[index]),
        backgroundColor: [
          "rgb(74, 70, 255)",
          "rgb(54, 162, 235)",
          "rgb(220, 220, 220)",
          "rgb(177, 177, 177)",
        ],
        hoverOffset: 4,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.label + ": " + context.parsed + "%";
          },
        },
      },
    },
  };

  return <Doughnut data={chartData} options={options} />;
};
