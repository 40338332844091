import React, { useEffect, useState } from "react";
import {
  FaCar,
  FaBook,
  FaUsers,
  FaAngleRight,
  FaArrowCircleRight,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { format , startOfMonth } from "date-fns";
import { Loading } from "../../Components/Loading";
import { Row, Col, Card, ListGroup, Badge } from "react-bootstrap";
import { TopPartsTable } from "../../Components/Dashboard/Logistics/TopPartsTable";
import { TopVendorsTable } from "../../Components/Dashboard/Logistics/TopVendorsTable";
import { TopRequestersTable } from "../../Components/Dashboard/Logistics/TopRequestersTable";
import { TopRecordsBarChart } from "../../Components/Dashboard/Logistics/TopRecordsBarChart";
import { MaintenancePieChart } from "../../Components/Dashboard/Logistics/MaintenancePieChart";


const LogisticsDashboard = () => {
  const { get } = useAxios();
  const [fetching, setFetching] = useState(true);
  const [dashBoardStats, setDashBoardStats] = useState(true);

  const getLogisticsDashboard = async () => {
    setFetching(true);
    try {
      const response = await get("dashboard");
      setDashBoardStats(response.data);
    } catch (error) {
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    setFetching(true);
    const initialiaseDashboardData = async () => await getLogisticsDashboard();
    initialiaseDashboardData();
  }, []);

  const currentMonth = format(new Date(), "LLLL", {
    awareOfUnicodeTokens: true,
  });

  let startOfCurrentMonth = format(startOfMonth(new Date()), "yyyy-MM-dd", {
    awareOfUnicodeTokens: true,
  });

  let currentDateOfMonth = format(new Date(), "yyyy-MM-dd", {
    awareOfUnicodeTokens: true,
  });

  return (
    <>
      {!!fetching ? (
        <Loading />
      ) : (
        <div className="container">
          <div className="row mb-4">
            <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0 stretch-card transparent">
              <div className="card card-md h-100 bg-warning">
                <Link
                  to="/vehicles"
                  title="Click to view vehicles"
                  className="text-decoration-none"
                >
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className=" text-dark">
                          <h1>{dashBoardStats?.all_Vehicle}</h1>
                          <p className="card-text">Vehicles</p>
                        </div>
                      </div>
                      <div className="col-auto">
                        <span className="text-white">
                          <FaCar size={60} color="#343A40" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer bg-warning text-white text-center">
                    <span>More info </span>
                    <FaArrowCircleRight />
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0 stretch-card transparent">
              <div className="card card-md h-100 bg-danger">
                <Link
                  to="/drivers"
                  title="Click to view drivers"
                  className="text-decoration-none"
                >
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className=" text-white">
                          <h1>{dashBoardStats?.all_drivers}</h1>
                          <p className="card-text">Drivers</p>
                        </div>
                      </div>
                      <div className="col-auto">
                        <span className="text-white ">
                          <FaUsers size={60} color="#343A40" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer bg-danger text-white text-center ">
                    <span>More info </span>
                    <FaArrowCircleRight />
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0 stretch-card transparent">
              <div className="card card-md h-100 bg-primary text-white">
                <Link
                  to={`/maintenance-records?vendor_id=&vehicle_id=&fromDate=${startOfCurrentMonth}&toDate=${currentDateOfMonth}&status=${
                    "approved" || "approved+with+adjustment"
                  }&vehicle_part_id=&voucher_number=&page=1`}
                  title="Click to view completed maintenance records"
                  className="text-decoration-none"
                >
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="text-white">
                          <h1>
                            {dashBoardStats?.approved_maintenance_record_count}
                          </h1>
                          <p className="card-text">Maintenance Approved</p>
                          <p className="card-text">THIS MONTH</p>
                        </div>
                      </div>
                      <div className="col-auto">
                        <span className="text-white ">
                          <FaBook size={60} color="#343A40" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer bg-primary text-white text-center">
                    <span>More info </span>
                    <FaArrowCircleRight />
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0 stretch-card transparent">
              <div className="card card-md h-100 bg-success text-white">
                <Link
                  to={`/maintenance-records?vendor_id=&vehicle_id=&fromDate=${startOfCurrentMonth}&toDate=${currentDateOfMonth}&status=completed&vehicle_part_id=&voucher_number=&page=1`}
                  title="Click to view completed maintenance records"
                  className="text-decoration-none"
                >
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="text-white">
                          <h1>
                            {dashBoardStats?.completed_maintenance_record_count}
                          </h1>
                          <p className="card-text">Maintenance Completed</p>
                          <p className="card-text">THIS MONTH</p>
                        </div>
                      </div>
                      <div className="col-auto">
                        <span className="text-white ">
                          <FaBook size={60} color="#343A40" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer bg-success text-white text-center">
                    <span>More info </span>
                    <FaArrowCircleRight />
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <Row className="mb-2">
            <Col className="mb-4" sm={6}>
              <Card className="h-100">
                <Card.Body>
                  <p className="text-md-left text-center lead text-uppercase text-muted">
                    <strong>VEHICLES DUE FOR SERVICE</strong>
                    <span className="ms-3">
                      <Badge bg="secondary">
                        {dashBoardStats?.vehicle_due_for_service?.count}
                      </Badge>
                    </span>
                  </p>
                  <ListGroup className="list-group-flush">
                    {dashBoardStats?.vehicle_due_for_service?.vehicles.map(
                      (vehicleDetail, index) => (
                        <ListGroup.Item
                          key={index}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <Row>
                            <Col md={6}>
                              {`${vehicleDetail?.reg_number} (${vehicleDetail?.vehicle_model?.vehicle_make?.name} - ${vehicleDetail?.vehicle_model?.name})`}
                            </Col>

                            <Col md={6}>
                              {vehicleDetail?.office_location?.name}
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      )
                    )}
                  </ListGroup>
                </Card.Body>

                <Card.Footer className="text-end">
                  <Link
                    to={`/reminders`}
                    title="Click to view more expiring vehicle documents"
                    className="text-decoration-none"
                  >
                    <span>View More</span>
                    <FaAngleRight />
                  </Link>
                </Card.Footer>
              </Card>
            </Col>

            <Col className="mb-4" sm={6}>
              <Card className="h-100">
                <Card.Body>
                  <p className="text-center text-uppercase">
                    <strong>EXPIRING VEHICLE DOCUMENTS</strong>
                  </p>
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item className="d-flex justify-content-between align-items-center">
                      <Link to="#" className="text-decoration-none">
                        Road Worthiness
                      </Link>

                      <Badge bg="secondary">
                        {
                          dashBoardStats?.vehicle_expired_documents
                            ?.road_worthiness
                        }
                      </Badge>
                    </ListGroup.Item>

                    <ListGroup.Item className="d-flex justify-content-between align-items-center">
                      <Link to="#" className="text-decoration-none">
                        License
                      </Link>

                      <Badge bg="secondary">
                        {dashBoardStats?.vehicle_expired_documents?.license}
                      </Badge>
                    </ListGroup.Item>

                    <ListGroup.Item className="d-flex justify-content-between align-items-center">
                      <Link to="#" className="text-decoration-none">
                        Insurance
                      </Link>

                      <Badge bg="secondary">
                        {dashBoardStats?.vehicle_expired_documents?.insurance}
                      </Badge>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>

                <Card.Footer className="text-end">
                  <Link
                    to={`/reminders`}
                    title="Click to view more expiring vehicle documents"
                    className="text-decoration-none"
                  >
                    <span>View More</span>
                    <FaAngleRight />
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>

          <div className="card mb-4">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4">
                  <div className="row justify-content-center">
                    <div className="col-auto pr-1 text-right">
                      <h1 className=" text-right text-bold">
                        &#8358;
                        {Number(
                          dashBoardStats?.maintenance_cost_chart?.total
                        ).toLocaleString()}
                      </h1>
                    </div>
                    
                    <div className="flex-auto">
                      <span className="d-block text-primary lead pb-0 font-weight-bold">
                        {currentMonth}
                      </span>
                      <span className="d-block mt-n2"> Maintenance Cost</span>
                    </div>
                  </div>

                  <MaintenancePieChart
                    style={{width: "263.2px", display: "block", boxSizing: "border-box", height: "263px"}}
                    width="394"
                    height="394"
                    maintenanceCost={dashBoardStats?.maintenance_cost_chart}
                  />
                </div>

                <div className="col-sm-8">
                  <TopRecordsBarChart
                    style={{width: "541.4pageXOffset", display: "blockpageXOffset", boxSizing: "border-boxpageXOffset", height: "270pageXOffset"}}
                    width="812"
                    height="405"
                    topVehicles={dashBoardStats?.top_vehicle_records_graph}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <Card className="mb-4">
            <Card.Body>
              <Row>
                <Col sm={4}>
                  <Row>
                    <Col className="text-right" md={6}>
                      <h1>
                        &#8358;
                        {Number(
                          dashBoardStats?.maintenance_cost_chart?.total
                        ).toLocaleString()}
                      </h1>
                    </Col>
                    <Col sm={8}>
                      <span className="text-primary fs-2 fw-bold">
                        {currentMonth}
                      </span>
                      <span className="d-block fs-3">Maintenance Cost</span>
                    </Col>
                  </Row>
                  <MaintenancePieChart
                    maintenanceCost={dashBoardStats?.maintenance_cost_chart}
                  />
                </Col>

                <Col md={6}>
                  <TopRecordsBarChart
                    topVehicles={dashBoardStats?.top_vehicle_records_graph}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card> */}

          <Row className="mb-4">
            <Col className="mb-4" md={6}>
              <h5 className="text-uppercase fs-3">TOP PARTS</h5>
              <Card className="h-100">
                <TopPartsTable topParts={dashBoardStats?.top_parts} />

                {/* <Card.Footer className ="text-end">
                  <Link to="#" className="text-decoration-none">
                    <span>View More</span>
                    <FaAngleRight />
                  </Link>
                </Card.Footer> */}
              </Card>
            </Col>

            <Col className="mb-4" md={6}>
              <h5 className="text-uppercase fs-3">TOP VENDORS</h5>
              <Card className="h-100">
                <TopVendorsTable topVendors={dashBoardStats?.top_vendors} />
              </Card>
            </Col>
          </Row>

          <div>
            <h5 className="text-uppercase fs-3">TOP REQUESTERS</h5>
            <Card>
              <TopRequestersTable
                topRequesters={dashBoardStats?.top_requesters}
              />
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default LogisticsDashboard;
