import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { FaExclamationCircle } from "react-icons/fa";
import { permissions } from "../../utils/permissions";
import { Card, Col, Nav, Row } from "react-bootstrap";
import ReactPagination from "../../Components/ReactPagination";
import { usePermissionAndDomain } from "../../hooks/usePermissionAndDomain";
import { Link, Navigate, useParams, useSearchParams } from "react-router-dom";
import { DisplayedStatusReportsTable } from "../../Components/VehiclesStatusReport/DisplayedStatusReportsTable";
import { VehicleStatusReportFilterSearch } from "../../Components/VehiclesStatusReport/VehicleStatusReportFilterSearch";
import { Error404 } from "../Errors/Error404";

const VehicleStatusReportIndex = () => {
  const { get } = useAxios();
  const [weekRange, setWeekRange] = useState({});
  const [fetching, setFetching] = useState(true);
  const { hasPermission } = usePermissionAndDomain();
  const [statusReports, setStatusReports] = useState({});
  const [statusOptions, setStatusOptions] = useState([]);
  const [searchPageParams, setSearchPageParams] = useSearchParams();
  const [officeLocation, setOfficeLocation] = useState({});
  const [isFilterSearchClicked, setIsFilterSearchClicked] = useState(false);

  let pageSize;
  let currentPage = searchPageParams.get("page") || 1;
  let weekRangeParams = searchPageParams.get("weekNum") || "";
  let vehicleStatusParams = searchPageParams.get("status") || "";
  let officeLocationParams = searchPageParams.get("branch") || "";

  const getVehiclesStatusReport = async () => {
    setFetching(true);
    try {
      pageSize = Number(pageSize) || 10;
      currentPage = Number(currentPage) || 1;
      const response = await get(
        `vehicle_status?page=${currentPage}&page_size=${pageSize}&weekNum=${weekRangeParams}&status=${vehicleStatusParams}&branch=${officeLocationParams}`
      );

      setWeekRange(response.data.weeks);
      setStatusOptions(response.data.statuses);
      setOfficeLocation(response.data.branchs);
      setStatusReports(response.data.vehicleStatuses);
    } catch (error) {
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  const pageChangeHandler = (page) => {
    setSearchPageParams(
      {
        ...Object.fromEntries(searchPageParams),
        page,
      },
      { replace: true }
    );
  };

  useEffect(() => {
    setFetching(true);
    const initialiaseVehiclesStatusReport = async () =>
      await getVehiclesStatusReport();
    initialiaseVehiclesStatusReport();
  }, [currentPage, weekRangeParams, vehicleStatusParams, officeLocationParams]);

  const handleFilterSelectSearchChange = (values) => {
    setSearchPageParams(
      {
        ...values,
        page: 1,
      },
      { replace: true }
    );
  };

  return (
    <>
      {!!fetching ? (
        <Loading />
      ) : (
        <Card className="container d-flex justify-content-center ms-3">
          <Card.Header className="d-flex justify-content-between mt-2 mb-2">
            <Card.Title>
              <Row>
                <Col md="auto">
                  <h2 className="page-title">ALL VEHICLES STATUS REPORT</h2>
                </Col>
              </Row>
            </Card.Title>

            {hasPermission(permissions.CREATE_VEHICLES_STATUS_REPORT) && (
              <Row>
                <Col md="auto">
                  <Link
                    to="/vehicles/status-reports/create"
                    className="btn btn-primary"
                  >
                    Add Report
                  </Link>
                </Col>
              </Row>
            )}
          </Card.Header>
          <Card.Body className="mt-4">
            <Row>
              <Nav className="fw-bolder fs-4 mb-3">
                <Nav.Item>
                  <Nav.Link
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setIsFilterSearchClicked(!isFilterSearchClicked);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="6" cy="10" r="2" />
                      <line x1="6" y1="4" x2="6" y2="8" />
                      <line x1="6" y1="12" x2="6" y2="20" />
                      <circle cx="12" cy="16" r="2" />
                      <line x1="12" y1="4" x2="12" y2="14" />
                      <line x1="12" y1="18" x2="12" y2="20" />
                      <circle cx="18" cy="7" r="2" />
                      <line x1="18" y1="4" x2="18" y2="5" />
                      <line x1="18" y1="9" x2="18" y2="20" />
                    </svg>
                    Filter
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Row>

            <Row>
              <Col className="p-0">
                <>
                  {isFilterSearchClicked && (
                    <VehicleStatusReportFilterSearch
                      getVehiclesStatusReport={getVehiclesStatusReport}
                      onhandleFilteredSearchChange={(value) =>
                        handleFilterSelectSearchChange(value)
                      }
                      initialSearchFilterInputs={{
                        weekNum: weekRangeParams,
                        status: vehicleStatusParams,
                        branch: officeLocationParams,
                      }}
                      statusOptions={statusOptions}
                      officeLocation={officeLocation}
                    />
                  )}

                  {!!(
                    weekRangeParams ||
                    vehicleStatusParams ||
                    officeLocationParams
                  ) &&
                    !statusReports?.data?.length && (
                      <div className="mt-4 p-2 border text-muted text-center">
                        <span>
                          <FaExclamationCircle size={19} />
                        </span>

                        <span className="fs-2 ms-2 ">
                          No Vehicle Status Report Found!
                        </span>
                      </div>
                    )}

                  {((!!(
                    weekRangeParams ||
                    vehicleStatusParams ||
                    officeLocationParams
                  ) &&
                    !!statusReports?.data?.length) ||
                    !!statusReports?.data?.length) && (
                    <>
                      <p className="text-end text-muted">
                        Page {statusReports.current_page} of{" "}
                        {statusReports.last_page}
                      </p>

                      <DisplayedStatusReportsTable
                        weekRange={weekRange}
                        statusReports={statusReports.data}
                        getVehiclesStatusReport={getVehiclesStatusReport}
                      />

                      <br />

                      <ReactPagination
                        links={statusReports.links}
                        onPageChange={pageChangeHandler}
                        current_page={statusReports.current_page}
                        last_page={statusReports.last_page}
                      />
                    </>
                  )}
                </>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default VehicleStatusReportIndex;
