import React from "react";
import axios from "axios";
import { API_URL } from "../config";

const useAxios = () => {
  const token = localStorage.getItem("access_token");

  let config = {
    headers: {},
  };

  if (!!token) {
    config["headers"] = { Authorization: "Bearer " + token };
  }

  const get = async (endPoint) => {
    try {
      const response = await axios.get(`${API_URL}/${endPoint}`, config);
      localStorage.setItem(endPoint, JSON.stringify(response));

      return response;
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        const savedUrl = localStorage.getItem(endPoint);
        if (savedUrl) {
          return JSON.parse(localStorage.getItem(endPoint));
        }
      }
      return error
    }
  };

  const post = (endPoint, data) => {
    return axios.post(`${API_URL}/${endPoint}`, data, config);
  };
  const put = (endPoint, data) => {
    return axios.put(`${API_URL}/${endPoint}`, data, config);
  };

  const axiosDelete = (endPoint) => {
    return axios.delete(`${API_URL}/${endPoint}`, config);
  };

  const postWithFile = (endPoint, data, allowMultiple = true) => {
    const formData = new FormData();
    Object.keys(data).map((key) => {
      if (!!data[key] && data[key]?.constructor === FileList) {
        if (!!allowMultiple) {
          for (let i = 0; i < data[key].length; i++) {
            formData.append(`${key}[${i}]`, data[key].item(i));
          }
        } else {
          formData.append(key, data[key]?.item(0) ?? null);
        }
        return;
      }

      if (!Array.isArray(data[key])) {
        formData.append(key, data[key]);
        return;
      }

      data[key].forEach((arrayItem, index) => {
        Object.keys(arrayItem).map((itemProperty) => {
          formData.append(
            `${key}[${index}][${itemProperty}]`,
            arrayItem[itemProperty] ?? ""
          );
        });
      });
    });

    return axios.post(`${API_URL}/${endPoint}`, formData, {
      "Content-Type": "multipart/form-data",
      headers: { Authorization: "Bearer " + token },
    });
  };

  const putWithFile = (endPoint, data) => {
    config.headers["Content-Type"] = "multipart/form-data";
    return put(endPoint, data);
  };

  return { get, post, put, postWithFile, putWithFile, axiosDelete };
};

export default useAxios;
