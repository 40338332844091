import React from "react";
import { RequireAuth } from "../../auth";
import { Route, Routes } from "react-router-dom";
import { Loading } from "../../Components/Loading";
import { Error404 } from "../../Pages/Errors/Error404";
import GuardKitItemsIndex from "../../Pages/GuardKitItems/index";
import CreateGuardKitItem from "../../Pages/GuardKitItems/create";
import ViewGuardKitItem from "../../Pages/GuardKitItems/view";
import EditGuardKitItem from "../../Pages/GuardKitItems/edit";

export const GuardKitItemsRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route path="/">
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <GuardKitItemsIndex />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error404 />} />

          <Route
            path="/create"
            element={
              <React.Suspense fallback={<Loading />}>
                <CreateGuardKitItem />
              </React.Suspense>
            }
          />

          <Route
            path="view/:guardKitItemId"
            element={
              <React.Suspense fallback={<Loading />}>
                <ViewGuardKitItem />
              </React.Suspense>
            }
          />

          <Route
            path="update/:guardKitItemId"
            element={
              <React.Suspense fallback={<Loading />}>
                <EditGuardKitItem />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </RequireAuth>
  );
}
