import React, { useEffect, useState } from "react";
import {
    Col,
    Nav,
    Row,
    Card,
    Table,
    Button,
    Container,
} from "react-bootstrap";
import useAxios from "../../hooks/useAxios";
import { Loading } from "../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { PaymentStatusForm } from "../../Components/SalaryComplaints/PaymentStatusForm";
import { statusBadge } from "../../Components/SalaryComplaints/statusBadge";

const PaymentStatus = () => {
    const { get } = useAxios();
    const navigate = useNavigate();
    let { salaryComplaintId } = useParams();

    const [fetching, setFetching] = useState(true);
    const [rootCauses, setRootCauses] = useState({});
    const [statusOptions, setStatusOptions] = useState({});
    const [salaryComplaint, setSalaryComplaint] = useState({});

    useEffect(() => {
        setFetching(true);

        const getComplaintUpdateData = async () => {
            try {
                const response = await get(`salary-complaints/${salaryComplaintId}/update-status`);

                setRootCauses(response.data.rootCauses)
                setStatusOptions(response.data.statuses)
                setSalaryComplaint(response.data.salaryComplaint);
            } catch (error) {
                if (error.response.status === 500) {
                }
            } finally {
                setFetching(false);
            }
        };
        getComplaintUpdateData();
    }, []);

    return (
        <div className="page-wrapper">
            <Container fluid>
                {!!fetching && <Loading />}

                {!!salaryComplaint?.id && !fetching && (
                    <>
                        <div className="page-header d-print-none">
                            <Row className="align-items-center">
                                <Col>
                                    <div className="page-pretitle">
                                        SALARY COMPLAINT STATUS{" "}
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="page-body">
                            <Nav className="justify-content-end">
                                <Nav.Item>
                                <Button onClick={() => navigate(-1)}>Back</Button>
                                </Nav.Item>
                            </Nav>

                            <br />

                            <>
                                <Card className="mb-5">
                                    <Card.Header className="d-flex justify-content-between mt-2 mb-2">
                                        <Card.Title>COMPLAINT SUMARRY </Card.Title>
                                    </Card.Header>

                                    <Card.Body className="mb-4">
                                        <Row className="mb-1 g-0">
                                            <Col>
                                                <Table
                                                responsive
                                                hover
                                                bordered
                                                className="table table-sm table-bordered m-0"
                                                >
                                                <tbody>
                                                    <tr>
                                                        <th width={200}>COMPLAINT DATE</th>
                                                        <td>
                                                            {salaryComplaint?.complaint_date}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>CONCERNED EMPLOYEE</th>
                                                        <td className="text-uppercase">
                                                            {!!salaryComplaint?.employee_concerned?.id
                                                                ? `${salaryComplaint?.employee_concerned?.first_name} ${salaryComplaint?.employee_concerned?.surname}`
                                                                : "N/A"
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>COMPLAINT</th>
                                                        <td className="text-uppercase">
                                                            {salaryComplaint?.complaint_category}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>CLIENT LOCATION</th>
                                                        <td>
                                                           {salaryComplaint?.employee_concerned?.client_location?.name}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>BRANCH</th>
                                                        <td className="text-uppercase">
                                                        {salaryComplaint?.employee_concerned?.office_location?.name}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>PROCESS OWNER</th>
                                                        <td>
                                                        {!!salaryComplaint?.process_owner?.id
                                                                ? `${salaryComplaint?.process_owner?.first_name} ${salaryComplaint?.process_owner?.surname}`
                                                                : "N/A"
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>PAYMENT STATUS</th>
                                                        <td className="text-uppercase">
                                                            {statusBadge(salaryComplaint?.payment_status) ?? "N/A"}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                <Card className="mb-5">
                                    <Card.Header className="d-flex justify-content-between mt-2 mb-3">
                                        <Card.Title>Payment STATUS </Card.Title>
                                    </Card.Header>

                                    <Card.Body>
                                        <PaymentStatusForm
                                            rootCauses={rootCauses}
                                            statusOptions={statusOptions}
                                            salaryComplaint={salaryComplaint}
                                        />
                                    </Card.Body>
                                </Card>
                            </>
                        </div>
                    </>
                )}
            </Container>
        </div>
    )
}

export default PaymentStatus