import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import { HtmlContent } from "../HtmlContent";

export const NeedRequestSummary = ({ escortSchedule, children }) => {
  return (
    <Card className="mb-4">
      <Card.Header className="d-flex justify-content-between mt-2 mb-5">
        <Card.Title>ESCORT SUMARRY </Card.Title>
      </Card.Header>

      <Card.Body>
        <ListGroup className="list-group-flush">
          <Card.Subtitle className="mb-2 text-muted">
            ESCORT SCHEDULE
          </Card.Subtitle>

          <ListGroup.Item>
            <span className="fw-bold">Escort Date</span>:{" "}
            {escortSchedule?.escort_date}
          </ListGroup.Item>

          <ListGroup.Item>
            <span className="fw-bold">Escort Time:</span>{" "}
            {escortSchedule?.escort_time}
          </ListGroup.Item>

          {children}

          <ListGroup.Item>
            <span className="fw-bold">Additional Escort Needs:</span>

            <HtmlContent>{escortSchedule?.escort_needs}</HtmlContent>
          </ListGroup.Item>

          <ListGroup.Item>
            <span className="fw-bold">Escort Days:</span>
            {escortSchedule?.escort_days}
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  );
};
