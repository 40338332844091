import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const TypesStatPieChart = ({ statByTypes }) => {
  let labels = [];
  let myDataSet = [];

  Object.keys(statByTypes).map((incidentType) => {
    labels.push(incidentType);
    
  });

  Object.keys(statByTypes).map((data) => {
      myDataSet.push(
        statByTypes[data]
      );
    }
  );

  const chartData = {
    labels: labels,
    datasets: [
      {
        labels: "Incident Types",
        data: labels.map((myLabel, index) => myDataSet[index]),
        backgroundColor: [
          "rgb(128, 207, 214)",
          "rgb(59, 117, 137)",
          "rgb(68, 156, 181)",
          "rgb(31, 137, 195)",
          "rgb(0, 162, 203)",
          "rgb(135, 191, 226)",
          "rgb(132, 153, 174)",
          "rgb(132, 128, 151)",
          "rgb(207, 212, 216)",
          "rgb(71, 94, 135)",
          "rgb(66, 77, 95)",
          "rgb(145, 150, 153)",
        ],
        hoverOffset: 4,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.label + ": " + context.parsed;
          },
        },
      },
    },
  };

  return <Doughnut data={chartData} options={options} />;
};
